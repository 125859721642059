import React, { Fragment, useEffect } from 'react';
import './manageshop.scss';
import ShopRightbar from '../../components_shops/shoprightbar/ShopRightbar';
import { DataGrid } from '@material-ui/data-grid';
import { DeleteOutline, Edit } from '@material-ui/icons';
import {
  Paper,
  Typography,
  CircularProgress,
  Divider,
} from '@material-ui/core/';
import { productRows } from '../../dummyData';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDepartments } from '../../store/departments/repository_dept';
import { getShopPlans, getShops } from '../../store/api/apiRoutes';

const ShopPlanList = () => {
  // const dispatch = useDispatch();
  // const { departments, isFetching } = useSelector((state) => state.depts);
  const [shops, setShops] = useState([]);
  const [loading, setLoading] = useState(false);

  // console.log('depts na:', departments);

  // useEffect(() => {
  //   const data = dispatch(getDepartments());

  //   // setPagePosts(data);
  // }, [dispatch]);
  // const [data, setData] = useState(productRows);

  useEffect(() => {
    let abortController;

    const getShopsByAdmin = async () => {
      abortController = new AbortController();
      let signal = abortController.signal;

      setLoading(true);
      try {
        // the signal is passed into the request(s) we want to abort using this controller
        // const { data } = await api.getMarketCategories();
        getShops({ signal: signal }).then((response) => {
          console.log('SHopsOnAfricanSHops', response.data);
          setShops(response.data.data);
          setLoading(false);
          // console.warn(response.data)
          // console.warn(result)
        });
        // })
      } catch (error) {
        // console.log(error);
        alert({ message: error.message });
        setLoading(false);
      }
    };
    getShopsByAdmin();

    return () => abortController.abort();
  }, []);
  console.log('SHopsOnComponents', shops);

  const handleDelete = (id) => {
    // setData(data.filter((item) => item.id !== id));
  };

  const columns = [
    // { field: '_id', headerName: 'ID', width: 90 },
    {
      field: 'shopname',
      headerName: 'Shop Name',
      width: 300,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            <img
              className="productListImg"
              src={params.row.coverimage}
              alt=""
            />
            <h6>{params.row.shopname}</h6>
          </div>
        );
      },
    },
    // { field: 'stock', headerName: 'Stock', width: 200 },
    // {
    //   field: 'status',
    //   headerName: 'Status',
    //   width: 120,
    // },
    // {
    //   field: 'price',
    //   headerName: 'Price',
    //   width: 160,
    // },
    {
      field: 'action',
      headerName: 'Action',
      width: 300,
      renderCell: (params) => {
        // return (
        //   <>
        //     <Link to={"/product/" + params.row.id}>
        //       <button className="productListEdit">Edit</button>
        //     </Link>
        //     <DeleteOutline
        //       className="productListDelete"
        //       onClick={() => handleDelete(params.row.id)}
        //     />
        //   </>
        // );
        return (
          <>
            <div className="actionButtons">
              <Link to={'/admin/usershop/update/' + params.row._id}>
                <Edit className="userListEditIcon" />
                {/* <button className="userListEdit">Edit</button> */}
              </Link>
              <DeleteOutline
                className="userListDelete"
                onClick={() => handleDelete(params.row._id)}
              />
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      {/* Changing pages rendering starts here */}

      <>
        {/* start of table grid */}
        <div className="userList">
          <div className="userTitleContainer">
            <h1 className="userTitle"> List Shops </h1>
            <Link to="/admin/usershop/add">
              <button className="userAddButton">Add Shop </button>
            </Link>
          </div>

          {/* <div className="usertable"> */}

          {loading ? (
            <Paper elevation={6} className="loadingPaper">
              <CircularProgress size="7em" className="loadingPaper" />
            </Paper>
          ) : (
            <>
              {/* <h3>This is Shop List Page Here</h3> */}
              <DataGrid
                className="tableProps"
                rows={shops}
                disableSelectionOnClick
                columns={columns}
                getRowId={(row) => row._id}
                pageSize={8}
                checkboxSelection
              />
            </>
          )}
        </div>

        <>
          <ShopRightbar Shop />
        </>
      </>
      {/* Changing pages rendering ends here */}
    </>
  );
};

export default ShopPlanList;
