import { actionTypes } from './actionMarketcat';
import Cookies from 'js-cookie';

export const initState = {
  marketcategories: [],
  isFetching: false,
  error: false,
};

function reducer(state = initState, action) {
  switch (action.type) {
    case 'START_LOADING':
      return { ...state, isFetching: true };
    case 'END_LOADING':
      return { ...state, isFetching: false };
    //Get MARKETCAT
    case actionTypes.MARKETCAT_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.MARKETCAT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        marketcategories: action.payload,
        error: false,
      };
    case actionTypes.MARKETCAT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Get MARKETCATById:
    case actionTypes.GET_MARKETCAT_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.GET_MARKETCAT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        marketcategory: action.payload,
        error: false,
      };
    case actionTypes.GET_MARKETCAT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Update A MARKETCATnationsById:
    case actionTypes.UPDATE_MARKETCAT_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.UPDATE_MARKETCAT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        marketcategories: state.marketcategories.map((marketcategory) =>
          marketcategory._id === action.payload._id
            ? action.payload
            : marketcategory
        ),
        error: false,
      };
    case actionTypes.UPDATE_MARKETCAT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Create A New MARKETCATnationsById:
    case actionTypes.CREATE_MARKETCAT_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.CREATE_MARKETCAT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        marketcategories: [...state.marketcategories, action.payload],
        error: false,
      };
    case actionTypes.CREATE_MARKETCAT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };

    default:
      return { ...state };
  }
}
export default reducer;
