// import * as api from '../api/apiRoutes';
import {
  createApiFaq,
  getApiFaqById,
  getFaqs,
  updateFaqById,
} from '../api/apiRoutes';
import {
  faq,
  faqSuccess,
  faqFailure,
  getFaqById,
  getFaqByIdSuccess,
  getFaqByIdFailure,
  updateFaq,
  updateFaqSuccess,
  updateFaqFailure,
  createFaq,
  createFaqSuccess,
  createFaqFailure,
} from './actionFaq';
const Cookies = require('js-cookie');

//get all Market Categories
export const getRepoFaqs = () => async (dispatch) => {
  try {
    dispatch(faq());
    const { data } = await getFaqs();
    // console.log('procatData :', data);

    // dispatch({
    //   type: FETCH_ALL,
    //   payload: { data, currentPage, numberOfPages },
    // });
    dispatch(faqSuccess(data));
  } catch (error) {
    // console.log(error);
    dispatch(faqFailure({ error: error.message }));
  }
};

//get single Market
export const getFaqWithId = (id) => async (dispatch) => {
  dispatch(getFaqById());
  // console.log('Acton start');
  try {
    const { data } = await getApiFaqById(id);
    // console.log('MydesigByID :', data);

    if (data) {
      dispatch(getFaqByIdSuccess(data));
    }
  } catch (error) {
    // console.log(error);
    dispatch(getFaqByIdFailure({ error: error.message }));
  }
};

//update a Market
export const updateAdminFaq = (id, values) => async (dispatch) => {
  console.log('repoFAQValues', values);
  dispatch(updateFaq());
  try {
    const { data } = await updateFaqById(id, values);
    console.log('actionFAQData :', data);

    if (data) {
      dispatch(updateFaqSuccess(data));
      alert('Faq updated');
    }
  } catch (error) {
    // console.log(error);
    dispatch(updateFaqFailure({ message: error.message }));
  }
};

//Create a Market
export const createFaqRepo = (values) => async (dispatch) => {
  console.log('faqData', values);
  try {
    dispatch(createFaq());
    const { data } = await createApiFaq(values);
    console.log('FAQactionData :', data);

    if (data) {
      dispatch(createFaqSuccess(data));
      alert('Faq  added');
    }

    // console.log('newDesination:', data);
  } catch (error) {
    dispatch(createFaqFailure({ error: error.message }));
  }
};
