import React, { Fragment, useEffect } from 'react';
import './managecountry.scss';
import ShopRightbar from '../../components_shops/shoprightbar/ShopRightbar';
import { DataGrid } from '@material-ui/data-grid';
import { DeleteOutline, Edit } from '@material-ui/icons';
import { productRows } from '../../dummyData';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { getDepartments } from '../../store/departments/repository_dept';
import { getCountries } from '../../store/country/repository_country';
import {
  Paper,
  Typography,
  CircularProgress,
  Divider,
} from '@material-ui/core/';

const CountryList = () => {
  const dispatch = useDispatch();
  const { countries, isFetching } = useSelector((state) => state.countries);

  console.log('depts na:', countries);

  useEffect(() => {
    const data = dispatch(getCountries());

    // setPagePosts(data);
  }, [dispatch]);
  const [data, setData] = useState(productRows);

  const handleDelete = (id) => {
    // setData(data.filter((item) => item.id !== id));
  };

  const columns = [
    // { field: '_id', headerName: 'ID', width: 90 },
    {
      field: 'name',
      headerName: 'Country',
      width: 300,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            <img className="productListImg" src={params.row.flag} alt="" />
            <h6> {params.row.name}</h6>
          </div>
        );
      },
    },
    {
      field: 'isInOperation',
      headerName: 'Operation Status',
      width: 150,
      renderCell: (params) => {
        return (
          <div className="widgetLgStatus">
            {params.row.isInOperation === true ? (
              // <Button type="OrderPaid " />

              <h6 className="userListEdit">Operational</h6>
            ) : (
              // <Button
              //   type="OrderNotPaid "
              //   style={{ width: '30px!important' }}
              // />

              <h6 className="userListStatus">Un-Operational</h6>
            )}
          </div>
        );
      },
    },
    // { field: 'stock', headerName: 'Stock', width: 200 },
    // {
    //   field: 'status',
    //   headerName: 'Status',
    //   width: 120,
    // },
    // {
    //   field: 'price',
    //   headerName: 'Price',
    //   width: 160,
    // },
    {
      field: 'action',
      headerName: 'Action',
      width: 300,
      renderCell: (params) => {
        // return (
        //   <>
        //     <Link to={"/product/" + params.row.id}>
        //       <button className="productListEdit">Edit</button>
        //     </Link>
        //     <DeleteOutline
        //       className="productListDelete"
        //       onClick={() => handleDelete(params.row.id)}
        //     />
        //   </>
        // );
        return (
          <>
            <div className="actionButtons">
              <Link to={'/bcountry/update/' + params.row._id}>
                <Edit className="userListEditIcon" />
                {/* <button className="userListEdit">Edit</button> */}
              </Link>
              {/* <DeleteOutline
                className="userListDelete"
                onClick={() => handleDelete(params.row._id)}
              /> */}
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      {/* Changing pages rendering starts here */}

      <>
        <div className="userRight">
          <div className="userBottom">
            {/* start of table grid */}
            <div className="userList">
              <div className="userTitleContainer">
                <h1 className="userTitle">Business Countries</h1>
                <Link to="/bcountry/add">
                  <button className="userAddButton">Add Country</button>
                </Link>
              </div>

              {/* <div className="usertable"> */}
              {isFetching ? (
                <Paper elevation={6} className="loadingPaper">
                  <CircularProgress size="7em" className="loadingPaper" />
                </Paper>
              ) : (
                <DataGrid
                  className="tableProps"
                  rows={countries}
                  disableSelectionOnClick
                  columns={columns}
                  getRowId={(row) => row._id}
                  pageSize={20}
                  checkboxSelection
                />
              )}
              {/* <div className="productList">
                   <DataGrid
                    rows={data}
                    disableSelectionOnClick
                    columns={columns}
                    pageSize={8}
                    checkboxSelection
                    />
                    </div> */}
              {/* </div> */}
            </div>
            {/* end of table grid */}
          </div>
        </div>

        <>
          <ShopRightbar Shop />
        </>
      </>
      {/* Changing pages rendering ends here */}
    </>
  );
};

export default CountryList;
