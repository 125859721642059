import { DeleteOutline, Publish } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import useForm from '../../hooks/useForm';
import { AuthApi } from '../../apiconstant/Api';
import { useParams, useHistory, Redirect, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  createDepart,
  getDepartWithId,
  updateDepart,
} from '../../store/departments/repository_dept';
import {
  featureProdCatById,
  getDeptById,
  getProdCatById,
  unFeatureProdCatById,
} from '../../store/api/apiRoutes';
import { getDepartments } from '../../store/departments/repository_dept';
import {
  createProductCat,
  getProductCats,
  updateProductCats,
} from '../../store/procats/repository_procat';
import {
  createTradehub,
  getHubs,
  updateTradehub,
} from '../../store/tradehubs/repository_tradehub';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import app from '../../firebase';

// const initialFValues = {
//     id:0,
//     product:{},
//   }

const initialFValues = {
  id: 0,
  tradehub: '',
  name: '',
  featuredAt: '',
};

const ProductCatForm = () => {
  const { values, setValues, handleInputChange, resetForm } =
    useForm(initialFValues);
  // const { id } = useParams();
  const params = useParams();
  const { id } = params;

  const dispatch = useDispatch();
  const { hubs, isFetching } = useSelector((state) => state.hubs);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);

  const prodcategory = useSelector((state) =>
    id
      ? state.procats.prodcategories.find(
          (prodcategory) => prodcategory._id === id
        )
      : null
  );

  useEffect(() => {
    dispatch(getHubs());

    // dispatch(getProductCats());
    // if (prodcategory) setValues(prodcategory);
    if (id) {
      getProductCatData();
    }

    // setPagePosts(data);
  }, [dispatch]);

  const getProductCatData = async () => {
    setLoading(true);
    try {
      if (id) {
        await getProdCatById(id).then((response) => {
          // setDepartment(response.data);
          setValues(response.data);
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleCreate = async (e) => {
    e.preventDefault();

    if (file) {
      const fileName = new Date().getTime() + file.name;
      const storage = getStorage(app);
      // const storageRef = ref(storage, fileName);
      const storageRef = ref(storage, `/productcategories/${fileName}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion

      // start here

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
            default:
          }
        },
        (error) => {
          // Handle unsuccessful uploads
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            const categoryData = { ...values, categoryimage: downloadURL };

            dispatch(createProductCat(categoryData));
            resetForm();
            setFile(null);
          });
        }
      );
    } else {
      // console.log('valueWithOutImageFile :', values);

      dispatch(createProductCat(values));
      resetForm();
      setFile(null);
    }

    // try {
    //   dispatch(createProductCat(values));

    //   resetForm();
    // } catch (error) {
    //   console.log({ message: error.message });
    // }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    if (file) {
      const fileName = new Date().getTime() + file.name;
      const storage = getStorage(app);
      // const storageRef = ref(storage, fileName);
      const storageRef = ref(storage, `/productcategories/${fileName}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion

      // start here

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
            default:
          }
        },
        (error) => {
          // Handle unsuccessful uploads
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            const categoryData = { ...values, categoryimage: downloadURL };
            dispatch(updateProductCats(id, categoryData));
          });
        }
      );
    } else {
      // console.log('valueWithOutImageFile :', values);
      dispatch(updateProductCats(id, values));
    }

    // try {
    //   dispatch(updateProductCats(id, values));

    // } catch (error) {
    //   console.log({ message: error.message });
    // }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    alert('Deleted');
  };

  const handleFeatureCategory = async (e) => {
    e.preventDefault();

    if (window.confirm('Feature Category?')) {
      try {
        setLoading(true);
        //adminMakeHomeSlider
        const { data } = await featureProdCatById(values._id);

        if (data) {
          getProductCatData();
          setTimeout(
            function () {
              setLoading(false);
            }.bind(this),
            250
          );
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        //console.log({ error: JSON.stringify(error) });
        //{ error: error.message })
        // console.log({ error: error.message });
        console.log(
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        );
      }
    }
  };

  const handleUnFeatureCategory = async (e) => {
    e.preventDefault();

    if (window.confirm('Un-Feature Category?')) {
      try {
        setLoading(true);
        //adminMakeHomeSlider
        const { data } = await unFeatureProdCatById(values._id);

        if (data) {
          getProductCatData();
          setTimeout(
            function () {
              setLoading(false);
            }.bind(this),
            250
          );
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        );
      }
    }
  };

  return (
    <div className="shopErectionUpdate">
      {/* <span className="shopErectionUpdateTitle">Add a Product Category</span>
      <form
        className="shopErectionUpdateForm"
        onSubmit={id ? handleUpdate : handleCreate}
      >
        <div className="shopErectionUpdateLeft">
          <div className="productFormLeft">
            <label>Trade Hubs</label>
            <select
              style={{ maxWidth: '250px' }}
              className=""
              name="tradehub"
              value={values.tradehub}
              onChange={handleInputChange}
              id="department"
            >
              <option value="">Select an option</option>
              {hubs.map((hb) => (
                <option value={hb._id} onChange={handleInputChange}>
                  {hb.hubname}
                </option>
              ))}

            </select>
   
            <label>Product Category Name</label>
            <input
              type="text"
              placeholder="Department xxx"
              name="name"
              value={values.name}
              onChange={handleInputChange}
              className="shopErectionUpdateInput"
            />
            
          </div>

          <button
            className="shopErectionUpdateButton"
          >
            Add/Update ProductCat
          </button>
        </div>
      </form> */}

      <span className="shopErectionUpdateTitle">
        Create/Edit a Product Category
      </span>
      {id && values.isFeatured && (
        <button
          className="btn btn-warning"
          onClick={(e) => {
            handleUnFeatureCategory(e);
          }}
        >
          {loading ? 'processing...' : 'UnFeature Category'}
        </button>
      )}
      {id && !values.isFeatured && (
        <button
          className="btn btn-warning"
          onClick={(e) => {
            handleFeatureCategory(e);
          }}
        >
          {loading ? 'processing...' : 'Feature Category'}
        </button>
      )}

      {id && (
        <span className="right">
          Delete
          <DeleteOutline
            className="userListDelete right"
            onClick={(e) => handleDelete(e)}
          />
        </span>
      )}

      <hr />
      <form
        onSubmit={id ? handleUpdate : handleCreate}
        className="ps-form--account-setting"
      >
        <div className="ps-form__content">
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <label>Trade Hubs</label>
                <select
                  className="form-control"
                  name="tradehub"
                  value={values.tradehub}
                  onChange={handleInputChange}
                >
                  <option value="">Select an option</option>
                  {hubs.map((hb) => (
                    <option value={hb._id} onChange={handleInputChange}>
                      {hb.hubname}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-sm-12">
              <div className="form-group">
                <label>Product Category Name</label>
                <input
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-6">
          <label> Banner Image</label>
          <div className="form-group">
            <label htmlFor="file">
              <Publish className="shopErectionUpdateIcon" />
            </label>

            {/* <label htmlFor="file">
                  <CloudUploadOutlined className="ps-btn" />
                </label> */}

            <input
              type="file"
              id="file"
              style={{ display: 'none' }}
              name="file"
              // value={values.adImage}
              onChange={(e) => setFile(e.target.files[0])}
            />
            {file && <span>{file.name}</span>}
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            {values?.categoryimage && (
              <img
                className="userUpdateImg"
                src={values?.categoryimage}
                alt=""
              />
            )}
            {/* <img
                  style={{ borderRadius: '50%' }}
                  height={50}
                  src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.mm.bing.net%2Fth%3Fid%3DOIP.OlnxO753VRgHKDLLDzCKoAHaHw%26pid%3DApi&f=1"
                /> */}
            {/* <input
                                className="form-control"
                                type="text"
                                placeholder="Country"
                            /> */}
          </div>
        </div>

        {/* <div className="col-sm-12">
          <div className="form-group">
            <label>Is Featured</label>
            <select
             
              className="form-control"
              name="isFeatured"
              value={values.isFeatured}
              onChange={handleInputChange}
          
            >
              <option value="">Select an option</option>
              <option value={true}>Featured</option>
              <option value={false}>Not Featured</option>
            </select>
          </div>
        </div> */}

        <div className="form-group submit">
          <Link to={'/procats'} className="btn">
            Cancel
          </Link>
          <button className="ps-btn">Add/Update Product Category</button>
        </div>
      </form>
    </div>
  );
};

export default ProductCatForm;
