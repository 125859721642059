export const Users = [
  {
    id: 1,
    profilePicture: 'assets/person/1.jpeg',
    username: 'Ferazi Eke',
  },
  {
    id: 2,
    profilePicture: 'assets/person/2.jpeg',
    username: 'Janell Shrum',
  },
  {
    id: 3,
    profilePicture: 'assets/person/3.jpeg',
    username: 'Alex Durden',
  },
  {
    id: 4,
    profilePicture: 'assets/person/4.jpeg',
    username: 'Dora Hawks',
  },
  {
    id: 5,
    profilePicture: 'assets/person/5.jpeg',
    username: 'Thomas Holden',
  },
  {
    id: 6,
    profilePicture: 'assets/person/6.jpeg',
    username: 'Shirley Beauchamp',
  },
  {
    id: 7,
    profilePicture: 'assets/person/7.jpeg',
    username: 'Travis Bennett',
  },
  {
    id: 8,
    profilePicture: 'assets/person/8.jpeg',
    username: 'Kristen Thomas',
  },
  {
    id: 9,
    profilePicture: 'assets/person/9.jpeg',
    username: 'Gary Duty',
  },
  {
    id: 10,
    profilePicture: 'assets/person/10.jpeg',
    username: 'Ferazi Eke',
  },
];

export const Posts = [
  {
    id: 1,
    desc: 'Love For All, Hatred For None.',
    photo: 'assets/post/1.jpeg',
    date: '5 mins ago',
    userId: 1,
    like: 32,
    comment: 9,
  },
  {
    id: 2,
    photo: 'assets/post/2.jpeg',
    date: '15 mins ago',
    userId: 2,
    like: 2,
    comment: 1,
  },
  {
    id: 3,
    desc: 'Every moment is a fresh beginning.',
    photo: 'assets/post/3.jpeg',
    date: '1 hour ago',
    userId: 3,
    like: 61,
    comment: 2,
  },
  {
    id: 4,
    photo: 'assets/post/4.jpeg',
    date: '4 hours ago',
    userId: 4,
    like: 7,
    comment: 3,
  },
  {
    id: 5,
    photo: 'assets/post/5.jpeg',
    date: '5 hours ago',
    userId: 5,
    like: 23,
    comment: 5,
  },
  {
    id: 6,
    photo: 'assets/post/6.jpeg',
    date: '1 day ago',
    userId: 6,
    like: 44,
    comment: 6,
  },
  {
    id: 7,
    desc: 'Never regret anything that made you smile.',
    photo: 'assets/post/7.jpeg',
    date: '2 days ago',
    userId: 7,
    like: 52,
    comment: 3,
  },
  {
    id: 8,
    photo: 'assets/post/8.jpeg',
    date: '3 days ago',
    userId: 8,
    like: 15,
    comment: 1,
  },
  {
    id: 9,
    desc: 'Change the world by being yourself.',
    photo: 'assets/post/9.jpeg',
    date: '5 days ago',
    userId: 9,
    like: 11,
    comment: 2,
  },
  {
    id: 10,
    photo: 'assets/post/10.jpeg',
    date: '1 week ago',
    userId: 10,
    like: 104,
    comment: 12,
  },
];

export const userData = [
  {
    name: 'Jan',
    'Active User': 4000,
  },
  {
    name: 'Feb',
    'Active User': 3000,
  },
  {
    name: 'Mar',
    'Active User': 5000,
  },
  {
    name: 'Apr',
    'Active User': 4000,
  },
  {
    name: 'May',
    'Active User': 3000,
  },
  {
    name: 'Jun',
    'Active User': 2000,
  },
  {
    name: 'Jul',
    'Active User': 4000,
  },
  {
    name: 'Agu',
    'Active User': 3000,
  },
  {
    name: 'Sep',
    'Active User': 4000,
  },
  {
    name: 'Oct',
    'Active User': 1000,
  },
  {
    name: 'Nov',
    'Active User': 4000,
  },
  {
    name: 'Dec',
    'Active User': 3000,
  },
];

export const productData = [
  {
    name: 'Jan',
    Sales: 4000,
  },
  {
    name: 'Feb',
    Sales: 3000,
  },
  {
    name: 'Mar',
    Sales: 5000,
  },
];

export const userRows = [
  {
    id: 1,
    username: 'Jon Snowss',
    avatar:
      'https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    email: 'jon@gmail.com',
    status: 'active',
    transaction: '$120.00',
  },
  {
    id: 2,
    username: 'Jon Snow',
    avatar:
      'https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    email: 'jon@gmail.com',
    status: 'active',
    transaction: '$120.00',
  },
  {
    id: 3,
    username: 'Jon Snow',
    avatar:
      'https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    email: 'jon@gmail.com',
    status: 'active',
    transaction: '$120.00',
  },
  {
    id: 4,
    username: 'Jon Snow',
    avatar:
      'https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    email: 'jon@gmail.com',
    status: 'active',
    transaction: '$120.00',
  },
  {
    id: 5,
    username: 'Jon Snow',
    avatar:
      'https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    email: 'jon@gmail.com',
    status: 'active',
    transaction: '$120.00',
  },
  {
    id: 6,
    username: 'Jon Snow',
    avatar:
      'https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    email: 'jon@gmail.com',
    status: 'active',
    transaction: '$120.00',
  },
  {
    id: 7,
    username: 'Jon Snow',
    avatar:
      'https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    email: 'jon@gmail.com',
    status: 'active',
    transaction: '$120.00',
  },
  {
    id: 8,
    username: 'Jon Snow',
    avatar:
      'https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    email: 'jon@gmail.com',
    status: 'active',
    transaction: '$120.00',
  },
  {
    id: 9,
    username: 'Jon Snow',
    avatar:
      'https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    email: 'jon@gmail.com',
    status: 'active',
    transaction: '$120.00',
  },
  {
    id: 10,
    username: 'Jon Snow',
    avatar:
      'https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    email: 'jon@gmail.com',
    status: 'active',
    transaction: '$120.00',
  },
];

export const productRows = [
  {
    id: 1,
    name: 'Apple Airpods',
    img: 'https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    stock: 123,
    status: 'active',
    price: '$120.00',
  },
  {
    id: 2,
    name: 'Apple Airpods',
    img: 'https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    stock: 123,
    status: 'active',
    price: '$120.00',
  },
  {
    id: 3,
    name: 'Apple Airpods',
    img: 'https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    stock: 123,
    status: 'active',
    price: '$120.00',
  },
  {
    id: 4,
    name: 'Apple Airpods',
    img: 'https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    stock: 123,
    status: 'active',
    price: '$120.00',
  },
  {
    id: 5,
    name: 'Apple Airpods',
    img: 'https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    stock: 123,
    status: 'active',
    price: '$120.00',
  },
  {
    id: 6,
    name: 'Apple Airpods',
    img: 'https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    stock: 123,
    status: 'active',
    price: '$120.00',
  },
  {
    id: 7,
    name: 'Apple Airpods',
    img: 'https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    stock: 123,
    status: 'active',
    price: '$120.00',
  },
  {
    id: 8,
    name: 'Apple Airpods',
    img: 'https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    stock: 123,
    status: 'active',
    price: '$120.00',
  },
  {
    id: 9,
    name: 'Apple Airpods',
    img: 'https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    stock: 123,
    status: 'active',
    price: '$120.00',
  },
  {
    id: 10,
    name: 'Apple Airpods',
    img: 'https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
    stock: 123,
    status: 'active',
    price: '$120.00',
  },
];
