import * as api from '../api/apiRoutes';
import {
  hub,
  hubSuccess,
  hubFailure,
  getHubById,
  getHubByIdSuccess,
  getHubByIdFailure,
  updateHub,
  updateHubSuccess,
  updateHubFailure,
  createHub,
  createHubSuccess,
  createHubFailure,
} from './actionTradehub';
const Cookies = require('js-cookie');

export const getHubs = () => async (dispatch) => {
  try {
    dispatch(hub());
    const { data } = await api.getTradehubs();
    console.log('actionData :', data);

    dispatch(hubSuccess(data));
  } catch (error) {
    // console.log(error);
    dispatch(hubFailure({ message: error.message }));
  }
};

//get single hub
export const getTradehubWithId = (id) => async (dispatch) => {
  dispatch(getHubById());
  console.log('Acton start');
  try {
    const { data } = await api.getTradehubById(id);
    // console.log('MyDeptByID :', data);

    dispatch(getHubByIdSuccess(data));
  } catch (error) {
    console.log(error);
    dispatch(getHubByIdFailure({ message: error.message }));
  }
};

//update a Tradehub
export const updateTradehub = (id, values) => async (dispatch) => {
  console.log('repoValues', values);
  dispatch(updateHub());
  try {
    const { data } = await api.updateTradehubById(id, values);
    // console.log('actionData :', data);

    dispatch(updateHubSuccess(data));
    alert('Tradehub updated');
  } catch (error) {
    console.log(error);
    dispatch(updateHubFailure({ message: error.message }));
  }
};

//Create a Tradehub
export const createTradehub = (values) => async (dispatch) => {
  try {
    dispatch(createHub());
    const { data } = await api.createTradehub(values);
    // console.log('actionData :', data);
    dispatch(createHubSuccess(data));
    // console.log('newTradehub:', data);
    alert('Tradehub added');
  } catch (error) {
    console.log(error);
    dispatch(createHubFailure({ error: error.message }));
  }
};
