export const actionTypes = {
  AUTH_REQUEST: 'AUTH_REQUEST',
  AUTH_SUCCESS: 'AUTH_SUCCESS',
  AUTH_FAILURE: 'AUTH_FAILURE',

  LOG_OUT: 'LOG_OUT',

  //whenloading
  END_LOADING: 'END_LOADING',
  START_LOADING: 'START_LOADING',
};

export function auth() {
  return { type: actionTypes.AUTH_REQUEST };
}

export function authSuccess(data) {
  return { type: actionTypes.AUTH_SUCCESS, payload: data };
}

export function authFailure() {
  return { type: actionTypes.AUTH_FAILURE };
}

export function logOut() {
  return { type: actionTypes.LOG_OUT };
}
