import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { AuthContextProvider } from './context/AuthContext';
import { NewsContextProvider } from './context/news/NewsContext';
import { Provider } from 'react-redux';
import store from './store';
import './styles/style.scss';
import 'antd/dist/antd.min.css';

//Default Warniing Error Hide and disable all console.logs
console.log = console.warn = console.error = () => {};
ReactDOM.render(
  <React.StrictMode>
    {/* <AuthContextProvider>
      <NewsContextProvider> */}
    <Provider store={store}>
      <App />
    </Provider>
    {/* </NewsContextProvider>
    </AuthContextProvider> */}
  </React.StrictMode>,
  document.getElementById('root')
);
