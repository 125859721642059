import React, { useEffect, useState } from 'react';
import './newsblogsingle.scss';
import Topbar from '../../components/topbar/Topbar';
import SocialSidebar from '../../components/socialsidebar/SocialSidebar';
import NewsPostSingle from '../../components/newspostsingle/NewsPostSingle';
import BlogRightbar from '../../components/blogrightbar/BolgRightbar';
import { Link, useParams } from 'react-router-dom';
import { getPostById, getSinglePostById } from '../../store/api/apiRoutes';
import {
  Paper,
  Typography,
  CircularProgress,
  Divider,
} from '@material-ui/core/';
import useForm from '../../hooks/useForm';

const NewsBlogSingle = ({ user }) => {
  const params = useParams();

  const postId = params.id;

  console.log('pageProps:', postId);
  const [loading, setLoading] = useState(false);
  const [post, setPost] = useState({});
  // const { values, setValues } = useForm(initialFValues);

  useEffect(() => {
    const getPosttData = async () => {
      setLoading(true);
      try {
        if (postId) {
          await getSinglePostById(postId).then((response) => {
            setPost(response.data);
          });
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    getPosttData();
  }, []);

  console.log('SinglePostHere:', post);

  return (
    <>
      {/* <Topbar/>
       <div className="profile">
           <SocialSidebar/> */}

      <>
        <div className="profileRight">
          {loading ? (
            <Paper elevation={6} className="loadingPaper">
              <CircularProgress size="7em" className="loadingPaper" />
            </Paper>
          ) : (
            <div className="profileRightBottom">
              <NewsPostSingle loading={loading} post={post} user={user} />
            </div>
          )}
        </div>
        <BlogRightbar blog />
      </>

      {/* </div> */}
    </>
  );
};

export default NewsBlogSingle;
