export const actionTypes = {
  POSTCAT_REQUEST: 'POSTCAT_REQUEST',
  POSTCAT_SUCCESS: 'POSTCAT_SUCCESS',
  POSTCAT_FAILURE: 'POSTCAT_FAILURE',

  GET_POSTCAT_REQUEST: 'GET_POSTCAT_REQUEST',
  GET_POSTCAT_SUCCESS: 'GET_POSTCAT_SUCCESS',
  GET_POSTCAT_FAILURE: 'GET_POSTCAT_FAILURE',

  UPDATE_POSTCAT_REQUEST: 'UPDATE_POSTCAT_REQUEST',
  UPDATE_POSTCAT_SUCCESS: 'UPDATE_POSTCAT_SUCCESS',
  UPDATE_POSTCAT_FAILURE: 'UPDATE_POSTCAT_FAILURE',

  CREATE_POSTCAT_REQUEST: 'CREATE_POSTCAT_REQUEST',
  CREATE_POSTCAT_SUCCESS: 'CREATE_POSTCAT_SUCCESS',
  CREATE_POSTCAT_FAILURE: 'CREATE_POSTCAT_FAILURE',

  //whenloading
  END_LOADING: 'END_LOADING',
  START_LOADING: 'START_LOADING',
};

export function postcat() {
  return { type: actionTypes.POSTCAT_REQUEST };
}

export function postcatSuccess(postcats) {
  return { type: actionTypes.POSTCAT_SUCCESS, payload: postcats.data };
}

export function postcatFailure() {
  return { type: actionTypes.POSTCAT_FAILURE };
}

//Get POSTCAT by ID:
export function getPostcatById() {
  return { type: actionTypes.GET_POSTCAT_REQUEST };
}

export function getPostcatByIdSuccess(postcat) {
  return { type: actionTypes.GET_POSTCAT_SUCCESS, payload: postcat };
}

export function getPostcatByIdFailure() {
  return { type: actionTypes.GET_POSTCAT_FAILURE };
}

//Update POSTCAT by ID:
export function updatePostcat() {
  return { type: actionTypes.UPDATE_POSTCAT_REQUEST };
}

export function updatePostcatSuccess(data) {
  return {
    type: actionTypes.UPDATE_POSTCAT_SUCCESS,
    payload: data,
  };
}

export function updatePostcatFailure() {
  return { type: actionTypes.UPDATE_POSTCAT_FAILURE };
}

//create new POSTCAT:
export function createPostcat() {
  return { type: actionTypes.CREATE_POSTCAT_REQUEST };
}

export function createPostcatSuccess(data) {
  return {
    type: actionTypes.CREATE_POSTCAT_SUCCESS,
    payload: data,
  };
}

export function createPostcatFailure() {
  return { type: actionTypes.CREATE_POSTCAT_FAILURE };
}
