import * as api from '../api/apiRoutes';
import {
  legal,
  legalSuccess,
  legalFailure,
  getLegalById,
  getLegalByIdSuccess,
  getLegalByIdFailure,
  updateLegal,
  updateLegalSuccess,
  updateLegalFailure,
  createLegal,
  createLegalSuccess,
  createLegalFailure,
} from './actionLegal';
const Cookies = require('js-cookie');

//get all Market Categories
export const getLegalsRepo = () => async (dispatch) => {
  try {
    dispatch(legal());
    const { data } = await api.getLegals();
    // console.log('procatData :', data);

    // dispatch({
    //   type: FETCH_ALL,
    //   payload: { data, currentPage, numberOfPages },
    // });
    dispatch(legalSuccess(data));
  } catch (error) {
    // console.log(error);
    dispatch(legalFailure({ error: error.message }));
  }
};

//get single Market
export const getLegalWithId = (id) => async (dispatch) => {
  dispatch(getLegalById());
  // console.log('Acton start');
  try {
    const { data } = await api.getApiLegalById(id);
    // console.log('MydesigByID :', data);

    dispatch(getLegalByIdSuccess(data));
  } catch (error) {
    // console.log(error);
    dispatch(getLegalByIdFailure({ error: error.message }));
  }
};

//update a Market
export const updateLegalsRepo = (id, values) => async (dispatch) => {
  // console.log('repoValues', values);
  dispatch(updateLegal());
  try {
    const { data } = await api.updateLegalById(id, values);
    // console.log('actionData :', data);

    if (data) {
      dispatch(updateLegalSuccess(data));
      window.alert('Legal updated');
    }
  } catch (error) {
    // console.log(error);
    dispatch(updateLegalFailure({ message: error.message }));
  }
};

//Create a Market
export const createLegalRepo = (values) => async (dispatch) => {
  try {
    dispatch(createLegal());
    const { data } = await api.createLegal(values);
    // console.log('actionData :', data);
    dispatch(createLegalSuccess(data));
    // console.log('newDesination:', data);
    alert('Legal  added');
  } catch (error) {
    dispatch(createLegalFailure({ error: error.message }));
  }
};
