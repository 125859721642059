import { DeleteOutline, Publish } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import useForm from '../../hooks/useForm';
import { AuthApi } from '../../apiconstant/Api';
import { useParams, useHistory, Redirect, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  createDepart,
  getDepartWithId,
  updateDepart,
} from '../../store/departments/repository_dept';
import { getDeptById, getMarketCategoryById } from '../../store/api/apiRoutes';
import { getDepartments } from '../../store/departments/repository_dept';
import {
  createMarketCat,
  getMarketCats,
  updateMarketCats,
} from '../../store/marketcats/repository_marketcat';
import { getHubs } from '../../store/tradehubs/repository_tradehub';

// const initialFValues = {
//     id:0,
//     product:{},
//   }

const initialFValues = {
  id: 0,
  // tradehub: '',
  name: '',
};

const MarketCatForm = () => {
  const { values, setValues, handleInputChange, resetForm } =
    useForm(initialFValues);
  const params = useParams();
  const { id } = params;
  const { hubs, isFetching } = useSelector((state) => state.hubs);

  // const { department, iFetching } = useSelector((state) => state.depts);
  const marketcategory = useSelector((state) =>
    id ? state.marketcats.marketcategories.find((dpt) => dpt._id === id) : null
  );
  // const post = useSelector((state) => (currentId ? state.posts.posts.find((message) => message._id === currentId) : null));
  console.log('StateDept:', marketcategory);

  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // dispatch(getHubs());

    dispatch(getMarketCats());
    if (id) {
      const getMarketCategoryData = async () => {
        setLoading(true);
        try {
          if (id) {
            await getMarketCategoryById(id).then((response) => {
              // setDepartment(response.data);
              setValues(response.data);
            });
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      };
      getMarketCategoryData();
    }
  }, [dispatch, id, setValues]);

  const handleMarketCatSubmit = async (e) => {
    e.preventDefault();

    try {
      dispatch(createMarketCat(values));
      // alert('MarketCat Added');
      resetForm();
    } catch (error) {
      console.log({ message: error.message });
    }

    resetForm();
  };

  const handleMarketCatUpdate = async (e) => {
    e.preventDefault();

    try {
      dispatch(updateMarketCats(id, values));
      // alert('MarketCat Updated');
    } catch (error) {
      console.log({ message: error.message });
    }

    // resetForm();
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    alert('Deleted');
  };

  return (
    <div className="shopErectionUpdate">
      {/* <span className="shopErectionUpdateTitle">Add a Market Category</span>
      <form
        className="shopErectionUpdateForm"
        onSubmit={id ? handleMarketCatUpdate : handleMarketCatSubmit}
      >
        <div className="shopErectionUpdateLeft">
          <div className="productFormLeft">
           
            <label>Product Unit Name</label>
            <input
              type="text"
              placeholder="Department xxx"
              name="name"
              value={values.name}
              onChange={handleInputChange}
              className="shopErectionUpdateInput"
            />
         
          </div>

          <button
            className="shopErectionUpdateButton"
          >
            Add/Update Market Category
          </button>
        </div>
      </form> */}

      <span className="shopErectionUpdateTitle">
        Create/Edit a Market Category
      </span>
      {id && (
        <span className="right">
          Delete
          <DeleteOutline
            className="userListDelete right"
            onClick={(e) => handleDelete(e)}
          />
        </span>
      )}

      <hr />
      <form
        onSubmit={id ? handleMarketCatUpdate : handleMarketCatSubmit}
        className="ps-form--account-setting"
      >
        <div className="ps-form__content">
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <label>Market Category</label>
                <input
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="form-group submit">
          <Link to={'/marketcats'} className="btn">
            Cancel
          </Link>
          <button className="ps-btn">Add/Update Market Category</button>
        </div>
      </form>
    </div>
  );
};

export default MarketCatForm;
