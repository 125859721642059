export const actionTypes = {
  BANNER_REQUEST: 'BANNER_REQUEST',
  BANNER_SUCCESS: 'BANNER_SUCCESS',
  BANNER_FAILURE: 'BANNER_FAILURE',

  GET_BANNER_REQUEST: 'GET_BANNER_REQUEST',
  GET_BANNER_SUCCESS: 'GET_BANNER_SUCCESS',
  GET_BANNER_FAILURE: 'GET_BANNER_FAILURE',

  UPDATE_BANNER_REQUEST: 'UPDATE_BANNER_REQUEST',
  UPDATE_BANNER_SUCCESS: 'UPDATE_BANNER_SUCCESS',
  UPDATE_BANNER_FAILURE: 'UPDATE_BANNER_FAILURE',

  CREATE_BANNER_REQUEST: 'CREATE_BANNER_REQUEST',
  CREATE_BANNER_SUCCESS: 'CREATE_BANNER_SUCCESS',
  CREATE_BANNER_FAILURE: 'CREATE_BANNER_FAILURE',

  //whenloading
  END_LOADING: 'END_LOADING',
  START_LOADING: 'START_LOADING',
};

export function banner() {
  return { type: actionTypes.BANNER_REQUEST };
}

export function bannerSuccess(data) {
  return { type: actionTypes.BANNER_SUCCESS, payload: data.data };
}

export function bannerFailure() {
  return { type: actionTypes.BANNER_FAILURE };
}

//Get Banner by ID:
export function getBannerById() {
  return { type: actionTypes.GET_BANNER_REQUEST };
}

export function getBannerByIdSuccess(Banner) {
  return { type: actionTypes.GET_BANNER_SUCCESS, payload: Banner };
}

export function getBannerByIdFailure() {
  return { type: actionTypes.GET_BANNER_FAILURE };
}

//Update Banner by ID:
export function updateBanner() {
  return { type: actionTypes.UPDATE_BANNER_REQUEST };
}

export function updateBannerSuccess(data) {
  return {
    type: actionTypes.UPDATE_BANNER_SUCCESS,
    payload: data,
  };
}

export function updateBannerFailure() {
  return { type: actionTypes.UPDATE_BANNER_FAILURE };
}

//create new Banner:
export function createBanner() {
  return { type: actionTypes.CREATE_BANNER_REQUEST };
}

export function createBannerSuccess(data) {
  return {
    type: actionTypes.CREATE_BANNER_SUCCESS,
    payload: data,
  };
}

export function createBannerFailure() {
  return { type: actionTypes.CREATE_BANNER_FAILURE };
}
