import * as api from '../api/apiRoutes';
import {
  procat,
  procatSuccess,
  procatFailure,
  getProcatById,
  getProcatByIdSuccess,
  getProcatByIdFailure,
  updateProcat,
  updateProcatSuccess,
  updateProcatFailure,
  createProcat,
  createProcatSuccess,
  createProcatFailure,
} from './actionProcat';
const Cookies = require('js-cookie');

export const getProductCats = () => async (dispatch) => {
  try {
    dispatch(procat());
    const { data } = await api.getProdCats();
    console.log('procatData :', data);

    // dispatch({
    //   type: FETCH_ALL,
    //   payload: { data, currentPage, numberOfPages },
    // });
    dispatch(procatSuccess(data));
  } catch (error) {
    console.log(error);
    dispatch(procatFailure({ error: error.message }));
  }
};

//get single Desination
export const getProdcatWithId = (id) => async (dispatch) => {
  dispatch(getProcatById());
  // console.log('Acton start');
  try {
    const { data } = await api.getProdCatById(id);
    // console.log('MydesigByID :', data);

    dispatch(getProcatByIdSuccess(data));
  } catch (error) {
    console.log(error);
    dispatch(getProcatByIdFailure({ error: error.message }));
  }
};

//update a Product Category
export const updateProductCats = (id, values) => async (dispatch) => {
  // console.log('repoValues', values);
  dispatch(updateProcat());
  try {
    const { data } = await api.updateProdCatById(id, values);
    // console.log('actionData :', data);

    dispatch(updateProcatSuccess(data));
    alert('Product Category updated');
  } catch (error) {
    console.log(error);
    dispatch(updateProcatFailure({ message: error.message }));
  }
};

//Create a Desination
export const createProductCat = (values) => async (dispatch) => {
  try {
    dispatch(createProcat());
    const { data } = await api.createProdCat(values);
    // console.log('actionData :', data);
    dispatch(createProcatSuccess(data));
    // console.log('newDesination:', data);
    alert('Product Category added');
  } catch (error) {
    console.log(error);
    dispatch(createProcatFailure({ error: error.message }));
  }
};
