import { CloudUploadOutlined, Publish } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import useForm from '../../hooks/useForm';
import { AuthApi } from '../../apiconstant/Api';
import { useParams, useHistory, Redirect, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import app from '../../firebase';
import {
  createPartnerRepo,
  updatePartnersRepo,
} from '../../store/partners/repository_partner';
import {
  createBannerRepo,
  updateBannersRepo,
} from '../../store/barners/repository_barner';
import { getApiBannerById, getApiLegalById } from '../../store/api/apiRoutes';
import {
  createLegalRepo,
  updateLegalsRepo,
} from '../../store/legal/repository_legal';

// const initialFValues = {
//     id:0,
//     product:{},
//   }

const initialFValues = {
  id: 0,
  title: '',
  content: '',
  legalimage: '',
};

const PostForm = () => {
  const params = useParams();

  // const postId = params.id;
  // console.log('pageProps:', postId);
  const [loading, setLoading] = useState(false);
  // const [post, setPost] = useState({});

  const { values, setValues, handleInputChange, handleFile, resetForm } =
    useForm(initialFValues);
  const [file, setFile] = useState(null);
  const { id } = params;
  const history = useHistory();
  const dispatch = useDispatch();
  // const [postcats, setPostCats] = useState([]);
  // const { postcats } = useSelector((state) => state.postcates);

  // const post = useSelector((state) =>
  //   id ? state.posts.posts.find((pst) => pst._id === id) : null
  // );
  // const { post, isFetching } = useSelector((state) => state.posts);

  useEffect(() => {
    if (id) {
      getLegalData();
    }

    // if (id) {
    //   // dispatch(getPosts());
    //   dispatch(getPostWithId(id));
    //   setValues(post);
    // }

    // setPagePosts(data);
  }, [id, dispatch]);

  const getLegalData = async () => {
    setLoading(true);
    try {
      if (id) {
        await getApiLegalById(id).then((response) => {
          setValues(response.data);

          console.log('API Legal_Docs', response.data);
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // console.log('Post CHECK!D :', id);
  console.log('Banners CHECK :', values);

  const handleLegalSubmit = async (e) => {
    e.preventDefault();

    console.log('FAQ pst Values', values);

    // return;
    if (file) {
      const fileName = new Date().getTime() + file.name;
      const storage = getStorage(app);
      // const storageRef = ref(storage, fileName);
      const storageRef = ref(storage, `/legals/${fileName}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion

      // start here

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
            default:
          }
        },
        (error) => {
          // Handle unsuccessful uploads
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log('valueWithImageFile :', values, {
              adImage: downloadURL,
            });
            const banner = { ...values, legalimage: downloadURL };

            dispatch(createLegalRepo(banner));
            resetForm();
            setFile(null);
          });
        }
      );
    } else {
      // console.log('valueWithOutImageFile :', values);

      dispatch(createLegalRepo(values));
      resetForm();
      setFile(null);

      // createPostcatRepo(values).then((response) => {
      //   if (response.data.error) {
      //     // console.log(response.data.error);
      //   } else {
      //     // alert(response.data.success);
      //     alert('Ad added');
      //     // resetForm();
      //   }
      // });
    }
  };

  //HANDLE POST UPDATE
  const handleLegalUpdate = async (e) => {
    e.preventDefault();

    console.log(values);
    if (file) {
      const fileName = new Date().getTime() + file.name;
      const storage = getStorage(app);
      // const storageRef = ref(storage, fileName);
      const storageRef = ref(storage, `/legals/${fileName}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion

      // start here

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
            default:
          }
        },
        (error) => {
          // Handle unsuccessful uploads
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log('valueWithImageFile :', values, {
              adImage: downloadURL,
            });
            const banner = { ...values, legalimage: downloadURL };
            dispatch(updateLegalsRepo(id, banner));
            window.alert('Legal updated');
          });
        }
      );
    } else {
      // console.log('valueWithOutImageFile :', values);
      dispatch(updateLegalsRepo(id, values));
      window.alert('Legal updated');
    }
  };

  return (
    <div className="shopErectionUpdate">
      <form
        onSubmit={id ? handleLegalUpdate : handleLegalSubmit}
        className="ps-form--account-setting"
      >
        {/* confirmUpdate  updateUserDetails*/}
        <div className="ps-form__header">
          <h3>Create/Edit Banners</h3>
        </div>
        <div className="ps-form__content">
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Title Here"
                  name="title"
                  value={values?.title}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Title Here"
                  name="key"
                  value={values?.key}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            {/* <div className="col-sm-6">
              <div className="form-group">
                <select
                  style={{ maxWidth: '250px' }}
                  className="form-control"
                  name="postcategory"
                  value={values?.postcategory}
                  onChange={handleInputChange}
                  id="postcategory"
                >
                  <option value="">Select an option</option>
                  {postcats.map((cat) => (
                    <option key={cat._id} value={cat._id}>
                      {cat.name}
                    </option>
                  ))}
                </select>
              </div>
            </div> */}

            {/* <div className="col-sm-6">
                        <div className="form-group">
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Last name"
                            />
                        </div>
                    </div> */}

            {/* <div className="col-sm-6">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="phone"
                  // value={values?.phone}
                  placeholder="Phone Number"
                  required
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Postal Code"
                  name="postalCode"
                  // value={values?.postalCode}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Address"
                  name="address"
                  // value={values?.address}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="city"
                  placeholder="City"
                  // value={values?.city}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="country"
                  placeholder="Country"
                  // value={values?.country}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="col-sm-12">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  // placeholder="Address"
                  // value={values?.referralcode}
                  name="referralcode"
                  disabled={true}
                  onChange={handleInputChange}
                />
              </div>
            </div> */}

            <hr />
            <div className="col-sm-6">
              <label> Legal Image</label>
              <div className="form-group">
                {/* <input
                  className="form-control mb-1"
                  type="file"
                  name="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  id="file"
                  style={{ display: 'none' }}
                  placeholder=""
                />

                <label htmlFor="file">
                  <CloudUploadOutlined className="ps-btn" />
                </label> */}
                <label htmlFor="file">
                  <Publish className="shopErectionUpdateIcon" />
                </label>

                {/* <label htmlFor="file">
                  <CloudUploadOutlined className="ps-btn" />
                </label> */}

                <input
                  type="file"
                  id="file"
                  style={{ display: 'none' }}
                  name="file"
                  // value={values.adImage}
                  onChange={(e) => setFile(e.target.files[0])}
                />
                {file && <span>{file.name}</span>}

                {/* <input
                                className="form-control"
                                type="file"
                                name="file"
                                value={values?.profile.avatar}
                                // placeholder="City"
                                onChange={handleInputChange}
                            /> */}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                {values?.legalimage && (
                  <img
                    className="userUpdateImg"
                    src={values?.legalimage}
                    alt=""
                  />
                )}
                {/* <img
                  style={{ borderRadius: '50%' }}
                  height={50}
                  src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.mm.bing.net%2Fth%3Fid%3DOIP.OlnxO753VRgHKDLLDzCKoAHaHw%26pid%3DApi&f=1"
                /> */}
                {/* <input
                                className="form-control"
                                type="text"
                                placeholder="Country"
                            /> */}
              </div>
            </div>

            {/* <div className="col-sm-12">
              <div className="form-group">
             
                <input
                  className="form-control"
                  type="text"
                  placeholder="Short Text Here"
                  name="shortText"
                  value={values?.shortText}
                  onChange={handleInputChange}
                />
              </div>
            </div> */}

            <div className="col-sm-12">
              <div className="form-group">
                <textarea
                  id="summary-ckeditor"
                  className="form-control"
                  type="text"
                  placeholder="Content Text Here"
                  name="content"
                  value={values?.content}
                  // value={values?.aboutme}
                  onChange={handleInputChange}
                  rows="6"
                ></textarea>
              </div>
            </div>

            {/* <div className="col-sm-12">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Facebook"
                  name="facebook"
                  // value={values?.facebook}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Instagram"
                  name="instagram"
                  // value={values?.instagram}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Twitter"
                  name="twitter"
                  // value={values?.twitter}
                  onChange={handleInputChange}
                />
              </div>
            </div> */}
          </div>

          <div className="form-group submit">
            <Link to={'/listadvocacy'} className="btn">
              Cancel
            </Link>
            <button className="ps-btn">
              {loading ? 'processing...' : 'Add/Update Legal_Docs'}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PostForm;
