export const actionTypes = {
  MARKET_REQUEST: 'MARKET_REQUEST',
  MARKET_SUCCESS: 'MARKET_SUCCESS',
  MARKET_FAILURE: 'MARKET_FAILURE',

  GET_MARKET_REQUEST: 'GET_MARKET_REQUEST',
  GET_MARKET_SUCCESS: 'GET_MARKET_SUCCESS',
  GET_MARKET_FAILURE: 'GET_MARKET_FAILURE',

  UPDATE_MARKET_REQUEST: 'UPDATE_MARKET_REQUEST',
  UPDATE_MARKET_SUCCESS: 'UPDATE_MARKET_SUCCESS',
  UPDATE_MARKET_FAILURE: 'UPDATE_MARKET_FAILURE',

  CREATE_MARKET_REQUEST: 'CREATE_MARKET_REQUEST',
  CREATE_MARKET_SUCCESS: 'CREATE_MARKET_SUCCESS',
  CREATE_MARKET_FAILURE: 'CREATE_MARKET_FAILURE',

  //whenloading
  END_LOADING: 'END_LOADING',
  START_LOADING: 'START_LOADING',
};

export function market() {
  return { type: actionTypes.MARKET_REQUEST };
}

export function marketSuccess(markets) {
  return { type: actionTypes.MARKET_SUCCESS, payload: markets.data };
}

export function marketFailure() {
  return { type: actionTypes.MARKET_FAILURE };
}

//Get MARKETnation by ID:
export function getMarketById() {
  return { type: actionTypes.GET_MARKET_REQUEST };
}

export function getMarketByIdSuccess(market) {
  return { type: actionTypes.GET_MARKET_SUCCESS, payload: market };
}

export function getMarketByIdFailure() {
  return { type: actionTypes.GET_MARKET_FAILURE };
}

//Update MARKETnation by ID:
export function updateMarket() {
  return { type: actionTypes.UPDATE_MARKET_REQUEST };
}

export function updateMarketSuccess(data) {
  return {
    type: actionTypes.UPDATE_MARKET_SUCCESS,
    payload: data,
  };
}

export function updateMarketFailure() {
  return { type: actionTypes.UPDATE_MARKET_FAILURE };
}

//create new MARKETnation:
export function createMarket() {
  return { type: actionTypes.CREATE_MARKET_REQUEST };
}

export function createMarketSuccess(data) {
  return {
    type: actionTypes.CREATE_MARKET_SUCCESS,
    payload: data,
  };
}

export function createMarketFailure() {
  return { type: actionTypes.CREATE_MARKET_FAILURE };
}

// export function logOut() {
//   return { type: actionTypes.LOG_OUT };
// }
