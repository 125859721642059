import React, { Fragment } from 'react';
import './newsblog.scss';
import Topbar from '../../components/topbar/Topbar';
import SocialSidebar from '../../components/socialsidebar/SocialSidebar';
import NewsPost from '../../components/newspost/NewsPost';
import BlogRightbar from '../../components/blogrightbar/BolgRightbar';

const NewsBlog = () => {
  return (
    <>
      {/* <Topbar/>
        <div className="profile">
            <SocialSidebar/> */}
      <>
        <div className="profileRight">
          <div className="profileRightBottom">
            <NewsPost />
          </div>
        </div>
        <BlogRightbar blog />
      </>

      {/* </div> */}
    </>
  );
};

export default NewsBlog;
