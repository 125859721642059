import React from 'react';
import './profile.scss';
import Topbar from '../../components/topbar/Topbar';
import SocialSidebar from '../../components/socialsidebar/SocialSidebar';
import Feed from '../../components/feed/Feed';
import SocialRightbar from '../../components/socialrightbar/SocialRightbar';
import EditProfileComponent from '../../components/editprofile/EditProfileComponent';

const Profile = () => {
  return (
    <>
      {/* <Topbar/>
        <div className="profile">
            <SocialSidebar/> */}

      <>
        <div className="profileRight">
          <div className="profileRightTop">
            <div className="profileCover">
              <img
                src="assets/post/3.jpeg"
                alt=""
                className="profileCoverImg"
              />
              <img
                src="assets/person/7.jpeg"
                alt=""
                className="profileUserImg"
              />
            </div>
            <div className="profileInfo">
              <h4 className="profileInfoName">Ferazi Ekeh</h4>
              <span className="profileInfoDesc">
                Hello guys, Welcome to my profile
              </span>
            </div>
            <hr className="profileDivider" />
          </div>
          <div className="profileRightBottom">
            <EditProfileComponent />

            {/* <Feed /> */}

            <SocialRightbar profile />
          </div>
        </div>
      </>

      {/* </div> */}
    </>
  );
};

export default Profile;
