import { actionTypes } from './actionFaq';
import Cookies from 'js-cookie';

export const initState = {
  faqs: [],
  isFetching: false,
  error: false,
};

function reducer(state = initState, action) {
  switch (action.type) {
    case 'START_LOADING':
      return { ...state, isFetching: true };
    case 'END_LOADING':
      return { ...state, isFetching: false };
    //Get MARKET
    case actionTypes.FAQ_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.FAQ_SUCCESS:
      return {
        ...state,
        isFetching: false,
        faqs: action.payload,
        error: false,
      };
    case actionTypes.FAQ_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Get MARKETById:
    case actionTypes.GET_FAQ_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.GET_FAQ_SUCCESS:
      return {
        ...state,
        isFetching: false,
        faq: action.payload,
        error: false,
      };
    case actionTypes.GET_FAQ_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Update A MARKETnationsById:
    case actionTypes.UPDATE_FAQ_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.UPDATE_FAQ_SUCCESS:
      return {
        ...state,
        isFetching: false,
        faqs: state.faqs.map((faq) =>
          faq._id === action.payload._id ? action.payload : faq
        ),
        error: false,
      };
    case actionTypes.UPDATE_FAQ_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Create A New MARKETnationsById:
    case actionTypes.CREATE_FAQ_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.CREATE_FAQ_SUCCESS:
      return {
        ...state,
        isFetching: false,
        faqs: [...state.faqs, action.payload],
        error: false,
      };
    case actionTypes.CREATE_FAQ_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };

    default:
      return { ...state };
  }
}
export default reducer;
