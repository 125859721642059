import { actionTypes } from './actionStates';
import Cookies from 'js-cookie';

export const initState = {
  bstates: [],
  isFetching: false,
  error: false,
};

function reducer(state = initState, action) {
  switch (action.type) {
    case 'START_LOADING':
      return { ...state, isFetching: true };
    case 'END_LOADING':
      return { ...state, isFetching: false };
    //Get bnations
    case actionTypes.STATES_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.STATES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        bstates: action.payload,
        error: false,
      };
    case actionTypes.STATES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Get STATESnationsById:
    case actionTypes.GET_STATES_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.GET_STATES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        bstate: action.payload,
        error: false,
      };
    case actionTypes.GET_STATES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Update A STATESnationsById:
    case actionTypes.UPDATE_STATES_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.UPDATE_STATES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        bstates: state.bstates.map((state) =>
          state._id === action.payload._id ? action.payload : state
        ),
        error: false,
      };
    case actionTypes.UPDATE_STATES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Create A New STATESnationsById:
    case actionTypes.CREATE_STATES_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.CREATE_STATES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        bstates: [...state.bstates, action.payload],
        error: false,
      };
    case actionTypes.CREATE_STATES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };

    default:
      return { ...state };
  }
}
export default reducer;
