import { createContext, useEffect, useReducer } from 'react';
import { authReducer } from './AuthReducer';

import * as Cookies from 'js-cookie';

const INITIAL_STATE = {
  // user: Cookies.get('frontEndAuthUserCookie') || null,
  user: JSON.parse(localStorage.getItem('frontEndAuthUserCookie')) || null,
  isFetching: false,
  isAuthenticated: false,
  error: false,
};

export const AuthContext = createContext(INITIAL_STATE);

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, INITIAL_STATE);

  // useEffect(() =>{
  //     Cookies.set('frontEndAuthUserCookie', state.user, 60*1);
  // }, [state.user]);

  useEffect(() => {
    localStorage.setItem('frontEndAuthUserCookie', JSON.stringify(state.user));
  }, [state.user]);

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        isFetching: state.isFetching,
        isAuthenticated: state.isAuthenticated,
        error: state.error,
        dispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
