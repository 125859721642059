import { DeleteOutline, Publish } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import useForm from '../../hooks/useForm';
import { AuthApi } from '../../apiconstant/Api';
import { useParams, useHistory, Redirect, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  createDepart,
  getDepartWithId,
  updateDepart,
} from '../../store/departments/repository_dept';
import { getDeptById } from '../../store/api/apiRoutes';
import { getDepartments } from '../../store/departments/repository_dept';
import { message } from 'antd';

// const initialFValues = {
//     id:0,
//     product:{},
//   }

const initialFValues = {
  id: 0,
  name: '',
  key: '',
};

const DepartmentForm = () => {
  const { values, setValues, handleInputChange, resetForm } =
    useForm(initialFValues);
  const params = useParams();

  const { id } = params;

  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  // const [department, setDepartment] = useState({});

  useEffect(() => {
    if (id) {
      const getDeptData = async () => {
        setLoading(true);
        try {
          if (id) {
            await getDeptById(id).then((response) => {
              // setDepartment(response.data);
              setValues(response.data);
            });
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      };
      getDeptData();
    }
  }, [id, setValues]);

  const handleDepartmentSubmit = async (e) => {
    e.preventDefault();

    try {
      dispatch(createDepart(values));

      resetForm();
    } catch (error) {
      console.log({ message: error.message });
    }
  };

  const handleDepartmentUpdate = async (e) => {
    e.preventDefault();

    try {
      dispatch(updateDepart(id, values));
    } catch (error) {
      console.log({ message: error.message });
    }

    // resetForm();
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    if (window.confirm('Do you really want to delete?')) {
      alert('deleted');
    }
  };

  return (
    <div className="shopErectionUpdate">
      <span className="shopErectionUpdateTitle">Create/Edit a Department</span>
      {id && (
        <span className="right">
          Delete
          <DeleteOutline
            className="userListDelete right"
            onClick={(e) => handleDelete(e)}
          />
        </span>
      )}

      <hr />
      <form
        onSubmit={id ? handleDepartmentUpdate : handleDepartmentSubmit}
        className="ps-form--account-setting"
      >
        <div className="ps-form__content">
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <label>Depatment</label>
                <input
                  className="form-control"
                  type="text"
                  name="name"
                  value={values?.name}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="col-sm-12">
              <div className="form-group">
                <label>Key</label>
                <input
                  className="form-control"
                  type="text"
                  name="key"
                  value={values?.key}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="form-group submit">
          <Link to={'/departments'} className="btn">
            Cancel
          </Link>
          <button className="ps-btn">Add/Update Department</button>
        </div>
      </form>
    </div>
  );
};

export default DepartmentForm;
