import React from 'react';
import './manageshop.scss';
import ShopRightbar from '../../components_shops/shoprightbar/ShopRightbar';
import {
  CalendarToday,
  LocationSearching,
  MailOutline,
  PermIdentity,
  PhoneAndroid,
  Publish,
} from '@material-ui/icons';
import { Link, useParams } from 'react-router-dom';
import Chart from '../../components_shops/chart/Chart';
import { productData } from '../../dummyData';
import ShopForm from './ShopForm';

import { useState } from 'react';
import { useEffect } from 'react';
import {
  adminMakeShopBannerAd,
  adminMakeShopHomeSlider,
  adminMakeShopPromotion,
  adminUnMakeShopBannerAd,
  adminUnMakeShopHomeSlider,
  adminUnMakeShopPromotion,
  getShopById,
} from '../../store/api/apiRoutes';
import useForm from '../../hooks/useForm';
import ShopStatus from './ShopStatusForm';
import moment from 'moment';

const initialFValues = {
  id: 0,
  shopname: '',
  shopemail: '',
  logo: '',
};

const AddShop = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [expiry, setExpiry] = useState('');
  const [bannerExpiry, setBannerExpiry] = useState('');
  const [promoExpiry, setPromoExpiry] = useState('');
  // const [post, setPost] = useState({});

  const { values, setValues, handleInputChange, handleFile, resetForm } =
    useForm(initialFValues);
  const { id } = params;

  useEffect(() => {
    if (id) {
      getUserShop();
    }
  }, [id]);

  const getUserShop = async () => {
    setLoading(true);
    try {
      if (id) {
        await getShopById(id).then((response) => {
          setValues(response.data);
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
      setLoading(false);
    }
  };

  const handleMakeShopSlider = async (e) => {
    e.preventDefault();

    if (window.confirm('Make Shop Home Slider?')) {
      //adminBlockDisciplineUser

      console.log('Shop Expiry-data', expiry);
      const formatDate = moment(new Date(expiry)).format('YYYY-MM-DD');

      const newValues = { ...values, OnHomeSliderAdsExpiresAt: formatDate };

      try {
        console.log('Shop Post data', newValues);
        setLoading(true);

        const { data } = await adminMakeShopHomeSlider(
          values._id,
          // newValues?.OnHomeSliderAdsExpiresAt
          newValues
        );

        if (data) {
          getUserShop();
          setTimeout(
            function () {
              setLoading(false);
            }.bind(this),
            250
          );
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        //console.log({ error: JSON.stringify(error) });
        //{ error: error.message })
        // console.log({ error: error.message });
        console.log(
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        );
      }
    }
  };

  //Lift User Block
  const handleUnMakeShopSlider = async (e) => {
    e.preventDefault();

    if (window.confirm('Remove Shop As Home Slider?')) {
      const formatDate = moment(new Date(expiry)).format('YYYY-MM-DD');

      const newValues = { ...values, OnHomeSliderAdsExpiresAt: formatDate };

      try {
        console.log('Shop Post data', newValues);
        setLoading(true);

        const { data } = await adminUnMakeShopHomeSlider(
          values._id,
          // newValues?.OnHomeSliderAdsExpiresAt
          newValues
        );

        if (data) {
          getUserShop();
          // console.log('sucessData2', data);
          setTimeout(
            function () {
              setLoading(false);
            }.bind(this),
            250
          );
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        // console.log({ error: JSON.stringify(error) });
        console.log(
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        );
      }
    }
  };

  //Handle Make Shop Banner
  const handleMakeShopBanner = async (e) => {
    e.preventDefault();

    if (window.confirm('Make Shop Banner?')) {
      const formatDate = moment(new Date(bannerExpiry)).format('YYYY-MM-DD');

      const newValues = { ...values, OnBannersAdsExpiresAt: formatDate };

      try {
        setLoading(true);

        const { data } = await adminMakeShopBannerAd(values._id, newValues);
        console.log('Shop Up For Banner Status', data);
        // console.log('shipSuccessData', data); adminSuspendDisciplineUser
        // return;
        if (data) {
          getUserShop();
          // console.log('sucessData2', data);
          setTimeout(
            function () {
              setLoading(false);
            }.bind(this),
            250
          );
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        //console.log({ error: JSON.stringify(error) });
        //{ error: error.message })
        // console.log({ error: error.message });
        console.log(
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        );
      }
    }
  };

  //Un-Make Shop Banner
  const handleUnMakeShopBanner = async (e) => {
    e.preventDefault();

    if (window.confirm('Remove From Banner?')) {
      const formatDate = moment(new Date(bannerExpiry)).format('YYYY-MM-DD');

      const newValues = { ...values, OnBannersAdsExpiresAt: formatDate };

      try {
        setLoading(true);

        const { data } = await adminUnMakeShopBannerAd(values._id, newValues);
        // console.log('shipSuccessData', data); adminSuspendDisciplineUser
        // return;
        console.log('Shop For Banner lift', data);
        if (data) {
          getUserShop();
          // console.log('sucessData2', data);
          setTimeout(
            function () {
              setLoading(false);
            }.bind(this),
            250
          );
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        // console.log({ error: JSON.stringify(error) });
        // console.log({ error: error.message });
        console.log(
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        );
      }
    }
  };

  //Handle Make Shop Promotion
  const handleMakeShopPromotion = async (e) => {
    e.preventDefault();

    if (window.confirm('Make Shop Promotion?')) {
      const formatDate = moment(new Date(promoExpiry)).format('YYYY-MM-DD');

      const newValues = { ...values, OnPromotionAdsExpiresAt: formatDate };

      console.log('Shop For Promotion', newValues);
      try {
        setLoading(true);

        const { data } = await adminMakeShopPromotion(values._id, newValues);
        console.log('Shop For Promotion Response', data);
        // console.log('shipSuccessData', data); adminSuspendDisciplineUser
        // return;
        if (data) {
          getUserShop();
          // console.log('sucessData2', data);
          setTimeout(
            function () {
              setLoading(false);
            }.bind(this),
            250
          );
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        //console.log({ error: JSON.stringify(error) });
        //{ error: error.message })
        // console.log({ error: error.message });
        console.log(
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        );
      }
    }
  };

  //Handle Un-Make Shop Promotion
  const handleUnMakeShopPromotion = async (e) => {
    e.preventDefault();

    if (window.confirm('Remove Shop From Promotion?')) {
      const formatDate = moment(new Date(promoExpiry)).format('YYYY-MM-DD');

      const newValues = { ...values, OnPromotionAdsExpiresAt: formatDate };

      try {
        setLoading(true);

        const { data } = await adminUnMakeShopPromotion(values._id, newValues);
        console.log('Shop For Promotion', data);
        // console.log('shipSuccessData', data); adminSuspendDisciplineUser
        // return;
        if (data) {
          getUserShop();
          // console.log('sucessData2', data);
          setTimeout(
            function () {
              setLoading(false);
            }.bind(this),
            250
          );
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        //console.log({ error: JSON.stringify(error) });
        //{ error: error.message })
        // console.log({ error: error.message });
        console.log(
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        );
      }
    }
  };

  return (
    <>
      <>
        {/* Changing pages rendering starts here */}

        <>
          <div className="shoppingRight">
            <div className="shoppingRightBottom">
              {/* page starts */}
              <div className="user">
                <div className="userTitleContainer">
                  <h1 className="userTitle">Manage Users Shop</h1>
                </div>
                <div className="userContainer">
                  <div className="userShow">
                    <div className="userShowTop">
                      <img
                        src={
                          values?.coverimage
                            ? values?.coverimage
                            : values?.shopname[0]
                        }
                        alt=""
                        className="userShowImg"
                      />
                      <div className="userShowTopTitle">
                        <span className="userShowUsername">
                          {values?.shopname}
                        </span>
                        <span className="userShowUserTitle">
                          Software Engineer
                        </span>
                      </div>
                    </div>
                    <div className="userShowBottom">
                      <span className="userShowTitle">Account Details</span>
                      {/* <div className="userShowInfo">
                      <PermIdentity className="userShowIcon" />
                      <span className="userShowInfoTitle">annabeck99</span>
                    </div> */}
                      <div className="userShowInfo">
                        <CalendarToday className="userShowIcon" />
                        <span className="userShowInfoTitle">
                          {values?.createdAt}
                        </span>
                      </div>
                      <span className="userShowTitle">Contact Details</span>
                      <div className="userShowInfo">
                        <PhoneAndroid className="userShowIcon" />
                        <span className="userShowInfoTitle">
                          {values?.shopphone}
                        </span>
                      </div>
                      <div className="userShowInfo">
                        <MailOutline className="userShowIcon" />
                        <span className="userShowInfoTitle">
                          {values?.shopemail}
                        </span>
                      </div>
                      <div className="userShowInfo">
                        <LocationSearching className="userShowIcon" />
                        <span className="userShowInfoTitle">
                          {values?.address} | country
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="userUpdate">
                    <span className="userUpdateTitle">
                      Manage Shop Ads Status{' '}
                    </span>
                    {/* <div className="ps-form__header">
                    <h3>Discipline User </h3>
                  </div> */}

                    <ShopStatus
                      handleMakeShopSlider={handleMakeShopSlider}
                      handleUnMakeShopSlider={handleUnMakeShopSlider}
                      handleMakeShopBanner={handleMakeShopBanner}
                      handleUnMakeShopBanner={handleUnMakeShopBanner}
                      handleMakeShopPromotion={handleMakeShopPromotion}
                      handleUnMakeShopPromotion={handleUnMakeShopPromotion}
                      values={values}
                      handleInputChange={handleInputChange}
                      id={id}
                      loading={loading}
                      expiry={expiry}
                      setExpiry={setExpiry}
                      bannerExpiry={bannerExpiry}
                      setBannerExpiry={setBannerExpiry}
                      promoExpiry={promoExpiry}
                      setPromoExpiry={setPromoExpiry}
                    />
                  </div>
                </div>
              </div>
              {/* page ends         */}
            </div>
          </div>

          <>
            <ShopRightbar Shop />
          </>
        </>
        {/* Changing pages rendering ends here */}
      </>
    </>
  );
};

export default AddShop;
