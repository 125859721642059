import React from 'react'
import "./blogrightbar.scss";
import { Users } from "../../dummyData";
import Online from "../online/Online";

const SocialRightbar = ({ blog, guest }) => {

  const GuestRightbar = () => {
    return (
      <>
        <div className="birthdayContainer">
          <img className="birthdayImg" src="assets/gift.png" alt="" />
          <span className="birthdayText">
            <b>Pola Foster</b> and <b>3 other friends</b> have ads listed here today.
          </span>
        </div>
        <img className="rightbarAd" src="assets/ad.png" alt="" />
        
      </>
    );
  };
  

    const HomeRightbar = () => {
        return (
          <>
            <div className="birthdayContainer">
              <img className="birthdayImg" src="assets/gift.png" alt="" />
              <span className="birthdayText">
                <b>Pola Foster</b> and <b>3 other friends</b> have a birhday today.
              </span>
            </div>
            <img className="rightbarAd" src="assets/ad.png" alt="" />
            <h4 className="rightbarTitle">Online Friends</h4>
            <ul className="rightbarFriendList">
              {Users.map((u) => (
                <Online key={u.id} user={u} />
              ))}
            </ul>
          </>
        );
      };

      const ProfileRightbar = () => {
        return (
          <>
           
            <div className="profileRightbarTop">
                <h4 className="rightbarTitle">User information</h4>
                <div className="rightbarInfo">
                  <div className="rightbarInfoItem">
                    <span className="rightbarInfoKey">City:</span>
                    <span className="rightbarInfoValue">New York</span>
                  </div>
                  <div className="rightbarInfoItem">
                    <span className="rightbarInfoKey">From:</span>
                    <span className="rightbarInfoValue">Madrid</span>
                  </div>
                  <div className="rightbarInfoItem">
                    <span className="rightbarInfoKey">Relationship:</span>
                    <span className="rightbarInfoValue">Single</span>
                  </div>
                </div>
            </div>


            {/* <h4 className="rightbarTitle">User friends</h4>
            <div className="rightbarFollowings">
              <div className="rightbarFollowing">
                <img
                  src="assets/person/1.jpeg"
                  alt=""
                  className="rightbarFollowingImg"
                />
                <span className="rightbarFollowingName">John Carter</span>
              </div>
              <div className="rightbarFollowing">
                <img
                  src="assets/person/2.jpeg"
                  alt=""
                  className="rightbarFollowingImg"
                />
                <span className="rightbarFollowingName">John Carter</span>
              </div>
              <div className="rightbarFollowing">
                <img
                  src="assets/person/3.jpeg"
                  alt=""
                  className="rightbarFollowingImg"
                />
                <span className="rightbarFollowingName">John Carter</span>
              </div>
              <div className="rightbarFollowing">
                <img
                  src="assets/person/4.jpeg"
                  alt=""
                  className="rightbarFollowingImg"
                />
                <span className="rightbarFollowingName">John Carter</span>
              </div>
              <div className="rightbarFollowing">
                <img
                  src="assets/person/5.jpeg"
                  alt=""
                  className="rightbarFollowingImg"
                />
                <span className="rightbarFollowingName">John Carter</span>
              </div>
              <div className="rightbarFollowing">
                <img
                  src="assets/person/6.jpeg"
                  alt=""
                  className="rightbarFollowingImg"
                />
                <span className="rightbarFollowingName">John Carter</span>
              </div>
            </div> */}
            <aside> 
              {/* Blog category */}
              <div className="block blog-module">
                <div className="sidebar-bar-title">
                  <h3>Blog Categories</h3>
                </div>
                <div className="block_content"> 
                  {/* layered */}
                  <div className="layered layered-category">
                    <div className="layered-content">
                      <ul className="tree-menu">
                        <li><a href="#"><i className="fa fa-angle-right" />&nbsp; Images</a></li>
                        <li><i className="fa fa-angle-right" />&nbsp; <a href="#">Audio</a></li>
                        <li><i className="fa fa-angle-right" />&nbsp; <a href="#">Photos</a></li>
                        <li><i className="fa fa-angle-right" />&nbsp; <a href="#">Diet &amp; Fitness</a></li>
                        <li><i className="fa fa-angle-right" />&nbsp; <a href="#">Slider</a></li>
                      </ul>
                    </div>
                  </div>
                  {/* ./layered */} 
                </div>
              </div>
              {/* ./blog category  */} 
              {/* Popular Posts */}
              <div className="block blog-module">
                <div className="sidebar-bar-title">
                  <h3>Popular Posts</h3>
                </div>
                <div className="block_content"> 
                  {/* layered */}
                  <div className="layered">
                    <div className="layered-content">
                      <ul className="blog-list-sidebar">
                        <li>
                          <div className="post-thumb"> <a href="#"><img src="/images/blog-img1.jpg" alt="Blog" /></a> </div>
                          <div className="post-info">
                            <h5 className="entry_title"><a href="#">Lorem ipsum dolor</a></h5>
                            <div className="post-meta"> <span className="date"><i className="pe-7s-date" /> 2014-08-05</span> <span className="comment-count"> <i className="pe-7s-comment-o" /> 3 </span> </div>
                          </div>
                        </li>
                        <li>
                          <div className="post-thumb"> <a href="#"><img src="/images/blog-img1.jpg" alt="Blog" /></a> </div>
                          <div className="post-info">
                            <h5 className="entry_title"><a href="#">Lorem ipsum dolor</a></h5>
                            <div className="post-meta"> <span className="date"><i className="pe-7s-date" /> 2014-08-05</span> <span className="comment-count"> <i className="pe-7s-comment-o" /> 3 </span> </div>
                          </div>
                        </li>
                        <li>
                          <div className="post-thumb"> <a href="#"><img src="/images/blog-img1.jpg" alt="Blog" /></a> </div>
                          <div className="post-info">
                            <h5 className="entry_title"><a href="#">Lorem ipsum dolor</a></h5>
                            <div className="post-meta"> <span className="date"><i className="pe-7s-date" /> 2014-08-05</span> <span className="comment-count"> <i className="pe-7s-comment-o" /> 3 </span> </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* ./layered */} 
                </div>
              </div>
              {/* ./Popular Posts */} 
              {/* Recent Comments */}
              <div className="block blog-module">
                <div className="sidebar-bar-title">
                  <h3>Recent Comments</h3>
                </div>
                <div className="block_content"> 
                  {/* layered */}
                  <div className="layered">
                    <div className="layered-content">
                      <ul className="recent-comment-list">
                        <li>
                          <h5><a href="#">Lorem ipsum dolor sit amet</a></h5>
                          <div className="comment"> "Consectetuer adipis. Mauris accumsan nulla vel diam. Sed in..." </div>
                          <div className="author">Posted by <a href="#">Admin</a></div>
                        </li>
                        <li>
                          <h5><a href="#">Lorem ipsum dolor sit amet</a></h5>
                          <div className="comment"> "Consectetuer adipis. Mauris accumsan nulla vel diam. Sed in..." </div>
                          <div className="author">Posted by <a href="#">Admin</a></div>
                        </li>
                        <li>
                          <h5><a href="#">Lorem ipsum dolor sit amet</a></h5>
                          <div className="comment"> "Consectetuer adipis. Mauris accumsan nulla vel diam. Sed in..." </div>
                          <div className="author">Posted by <a href="#">Admin</a></div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* ./layered */} 
                </div>
              </div>
              {/* ./Recent Comments */} 
              {/* tags */}
              <div className="popular-tags-area block">
                <div className="sidebar-bar-title">
                  <h3>Popular Tags</h3>
                </div>
                <div className="tag">
                  <ul>
                    <li><a href="#">Boys</a></li>
                    <li><a href="#">Camera</a></li>
                    <li><a href="#">good</a></li>
                    <li><a href="#">Computers</a></li>
                    <li><a href="#">Phone</a></li>
                    <li><a href="#">clothes</a></li>
                    <li><a href="#">girl</a></li>
                    <li><a href="#">shoes</a></li>
                    <li><a href="#">women</a></li>
                    <li><a href="#">accessoties</a></li>
                    <li><a href="#">View All Tags</a></li>
                  </ul>
                </div>
              </div>
              {/* ./tags */} 
              {/* Banner */}
              <div className="single-img-add sidebar-add-slider">
                <div id="carousel-example-generic" className="carousel slide" data-ride="carousel"> 
                  {/* Indicators */}
                  <ol className="carousel-indicators">
                    <li data-target="#carousel-example-generic" data-slide-to={0} className="active" />
                    <li data-target="#carousel-example-generic" data-slide-to={1} />
                    <li data-target="#carousel-example-generic" data-slide-to={2} />
                  </ol>
                  {/* Wrapper for slides */}
                  <div className="carousel-inner" role="listbox">
                    <div className="item active"> <img src="/images/add-slide1.jpg" alt="slide1" />
                      <div className="carousel-caption">
                        <h3><a href="single_product.html" title=" Sample Product">Sale Up to 50% off</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        <a href="#" className="info">shopping Now</a> </div>
                    </div>
                    <div className="item"> <img src="/images/add-slide2.jpg" alt="slide2" />
                      <div className="carousel-caption">
                        <h3><a href="single_product.html" title=" Sample Product">Smartwatch Collection</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        <a href="#" className="info">All Collection</a> </div>
                    </div>
                    <div className="item"> <img src="/images/add-slide3.jpg" alt="slide3" />
                      <div className="carousel-caption">
                        <h3><a href="single_product.html" title=" Sample Product">Summer Sale</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                      </div>
                    </div>
                  </div>
                  {/* Controls */} 
                  <a className="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev"> <span className="glyphicon glyphicon-chevron-left" aria-hidden="true" /> <span className="sr-only">Previous</span> </a> <a className="right carousel-control" href="#carousel-example-generic" role="button" data-slide="next"> <span className="glyphicon glyphicon-chevron-right" aria-hidden="true" /> <span className="sr-only">Next</span> </a> </div>
              </div>
              {/* ./Banner */} 
            </aside>
          </>
        );
      };

      

    return (
        <div className="rightbar">
            <div className="rightbarWrapper">
                {blog ? <ProfileRightbar /> : guest ? <GuestRightbar/> : <HomeRightbar />}
            </div>
        </div>
    )
}

export default SocialRightbar
