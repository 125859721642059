import React, { useEffect } from 'react';
import '../../style.scss';
import './shoperection.scss';
import Topbar from '../../components/topbar/Topbar';
import SocialSidebar from '../../components/socialsidebar/SocialSidebar';
import SingleProduct from '../../components/singleproduct/SingleProduct';
import ShoppingRightbar from '../../components/shoppingrightbar/ShoppingRightbar';
import PageContent from '../../components/PageContent';
import { Visibility } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import ShoppingFeed from '../../components/shoppingfeed/ShoppingFeed';
import Chart from '../../components_shops/chart/Chart';
import { productData } from '../../dummyData';
import { useDispatch, useSelector } from 'react-redux';
import { getShopplans } from '../../store/shopplans/repository_shopplan';
import { shopplan } from '../../store/shopplans/actionShopplan';
import {
  Paper,
  Typography,
  CircularProgress,
  Divider,
} from '@material-ui/core/';

const ShopPlans = () => {
  const dispatch = useDispatch();
  const { shopplans, isFetching } = useSelector((state) => state.shopsubs);

  useEffect(() => {
    const data = dispatch(getShopplans());

    // setPagePosts(data);
  }, [dispatch]);
  console.log('Shops na:', shopplans);

  return (
    <>
      {/* <Topbar/>
        <div className="shopping">
            <SocialSidebar/> */}
      <>
        <div className="shoppingRight">
          <div className="shoppingRightBottom">
            <div className="product">
              <div className="productTop">
                <div className="productTopLeft">
                  <Chart
                    data={productData}
                    dataKey="Sales"
                    title="Shop Plan Performance"
                  />
                  {/* <div className="productInfoTop">
                                   <img src="https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500" alt="" className="productInfoImg" />
                                   <span className="productName">(*********) Shop name</span>
                               </div>
                               <div className="productInfoBottom">
                                   <div className="productInfoItem">
                                       <span className="productInfoKey">id:</span>
                                       <span className="productInfoValue">123</span>
                                   </div>
                                   <div className="productInfoItem">
                                       <span className="productInfoKey">sales:</span>
                                       <span className="productInfoValue">5123</span>
                                   </div>
                                   <div className="productInfoItem">
                                       <span className="productInfoKey">active:</span>
                                       <span className="productInfoValue">yes</span>
                                   </div>
                                   <div className="productInfoItem">
                                       <span className="productInfoKey">in stock:</span>
                                       <span className="productInfoValue">no</span>
                                   </div>
                               </div> */}
                </div>
                <div className="productTopRight">
                  <div className="productInfoTop">
                    <img
                      src="https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
                      alt=""
                      className="productInfoImg"
                    />
                    <span className="productName">(*********) Shop Name</span>
                  </div>
                  <div className="productInfoBottom">
                    <div className="productInfoItem">
                      <span className="productInfoKey">id:</span>
                      <span className="productInfoValue">123</span>
                    </div>
                    <div className="productInfoItem">
                      <span className="productInfoKey">sales:</span>
                      <span className="productInfoValue">5123</span>
                    </div>
                    <div className="productInfoItem">
                      <span className="productInfoKey">active:</span>
                      <span className="productInfoValue">yes</span>
                    </div>
                    <div className="productInfoItem">
                      <span className="productInfoKey">in stock:</span>
                      <span className="productInfoValue">no</span>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="productBottom">
                <>
                  <div className="col-main col-xs-12">
                    {/* Slider was here  */}
                    <div className="shop-inner">
                      {/* start of price plan bootsnip */}
                      <div class="container-fluid">
                        <div class="row row-flex">
                          {isFetching ? (
                            <Paper elevation={6} className="loadingPaper">
                              <CircularProgress
                                size="7em"
                                className="loadingPaper"
                              />
                            </Paper>
                          ) : (
                            shopplans.map((shopplan) => (
                              <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                                <div
                                  class={
                                    shopplan.plansname === 'Starter'
                                      ? 'price-table pt-bg-black'
                                      : shopplan.plansname === 'Basic'
                                      ? 'price-table pt-bg-blue'
                                      : 'price-table pt-bg-red'
                                  }
                                >
                                  <div>
                                    <span>{shopplan.plansname}</span>
                                    <span>Price N{shopplan.price}/mo</span>
                                    <span>Features included!</span>
                                  </div>
                                  <ul>
                                    <li>
                                      No of Products :{' '}
                                      {shopplan.numberofproducts}
                                    </li>
                                    <li>
                                      Images Per Product :{' '}
                                      {shopplan.numberoffeaturedimages}
                                    </li>
                                    <li>
                                      Dashboard Analytics:{' '}
                                      {shopplan.dashboardandanalytics}{' '}
                                    </li>
                                    <li>On Page SEO : {shopplan.adsbost}</li>
                                    <li>24/6 Support : {shopplan.support}</li>
                                  </ul>
                                  <Link to={`/selected/plan/${shopplan._id}`}>
                                    purchase
                                  </Link>
                                </div>
                              </div>
                            ))
                          )}
                          {/* <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                            <div class="price-table pt-bg-black">
                              <div>
                                <span>basic</span>
                                <span>Price $99.99/mo</span>
                                <span>Features included!</span>
                              </div>
                              <ul>
                                <li>Domain name</li>
                                <li>Social Media Integration</li>
                                <li>First Month Hosting</li>
                                <li>On Page SEO</li>
                                <li>24/6 Support</li>
                              </ul>
                              <Link to="/selected/plan">purchase</Link>
                            </div>
                          </div>

                          <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                            <div class="price-table pt-bg-green">
                              <div>
                                <span>basic</span>
                                <span>Price $99.99/mo</span>
                                <span>Features included!</span>
                              </div>
                              <ul>
                                <li>Domain name</li>
                                <li>Social Media Integration</li>
                                <li>First Month Hosting</li>
                                <li>On Page SEO</li>
                                <li>24/6 Support</li>
                              </ul>
                              <Link to="/selected/plan">purchase</Link>
                            </div>
                          </div>
                          <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                            <div class="price-table pt-bg-red">
                              <div>
                                <span>basic</span>
                                <span>Price $99.99/mo</span>
                                <span>Features included!</span>
                              </div>
                              <ul>
                                <li>Domain name</li>
                                <li>Social Media Integration</li>
                                <li>First Month Hosting</li>
                                <li>On Page SEO</li>
                                <li>24/6 Support</li>
                              </ul>
                              <Link to="/selected/plan">purchase</Link>
                            </div>
                          </div>
                          <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                            <div class="price-table pt-bg-blue">
                              <div>
                                <span>basic</span>
                                <span>Price $99.99/mo</span>
                                <span>Features included!</span>
                              </div>
                              <ul>
                                <li>Domain name</li>
                                <li>Social Media Integration</li>
                                <li>First Month Hosting</li>
                                <li>On Page SEO</li>
                                <li>24/6 Support</li>
                              </ul>
                              <Link to="/selected/plan">purchase</Link>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      {/* end of price plan snip */}
                      {/* <div className="page-title">
            <h2>Shop Plans</h2>
          </div> */}
                    </div>
                  </div>
                </>
              </div>
            </div>

            {/*  */}
          </div>
        </div>
        <ShoppingRightbar Shopping />
      </>

      {/*            
        </div> */}
    </>
  );
};

export default ShopPlans;
