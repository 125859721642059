export const actionTypes = {
  DESIG_REQUEST: 'DESIG_REQUEST',
  DESIG_SUCCESS: 'DESIG_SUCCESS',
  DESIG_FAILURE: 'DESIG_FAILURE',

  GET_DESIG_REQUEST: 'GET_DESIG_REQUEST',
  GET_DESIG_SUCCESS: 'GET_DESIG_SUCCESS',
  GET_DESIG_FAILURE: 'GET_DESIG_FAILURE',

  UPDATE_DESIG_REQUEST: 'UPDATE_DESIG_REQUEST',
  UPDATE_DESIG_SUCCESS: 'UPDATE_DESIG_SUCCESS',
  UPDATE_DESIG_FAILURE: 'UPDATE_DESIG_FAILURE',

  CREATE_DESIG_REQUEST: 'CREATE_DESIG_REQUEST',
  CREATE_DESIG_SUCCESS: 'CREATE_DESIG_SUCCESS',
  CREATE_DESIG_FAILURE: 'CREATE_DESIG_FAILURE',

  //whenloading
  END_LOADING: 'END_LOADING',
  START_LOADING: 'START_LOADING',
};

export function desig() {
  return { type: actionTypes.DESIG_REQUEST };
}

export function desigSuccess(departments) {
  return { type: actionTypes.DESIG_SUCCESS, payload: departments.data };
}

export function desigFailure() {
  return { type: actionTypes.DESIG_FAILURE };
}

//Get Designation by ID:
export function getDesigById() {
  return { type: actionTypes.GET_DESIG_REQUEST };
}

export function getDesigByIdSuccess(designation) {
  return { type: actionTypes.GET_DESIG_SUCCESS, payload: designation };
}

export function getDesigByIdFailure() {
  return { type: actionTypes.GET_DESIG_FAILURE };
}

//Update Designation by ID:
export function updateDesig() {
  return { type: actionTypes.UPDATE_DESIG_REQUEST };
}

export function updateDesigSuccess(data) {
  return {
    type: actionTypes.UPDATE_DESIG_SUCCESS,
    payload: data,
  };
}

export function updateDesigFailure() {
  return { type: actionTypes.UPDATE_DESIG_FAILURE };
}

//create new Designation:
export function createDesig() {
  return { type: actionTypes.CREATE_DESIG_REQUEST };
}

export function createDesigSuccess(data) {
  return {
    type: actionTypes.CREATE_DESIG_SUCCESS,
    payload: data,
  };
}

export function createDesigFailure() {
  return { type: actionTypes.CREATE_DESIG_FAILURE };
}

// export function logOut() {
//   return { type: actionTypes.LOG_OUT };
// }
