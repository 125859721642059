import * as api from '../api/apiRoutes';
import {
  banner,
  bannerSuccess,
  bannerFailure,
  getBannerById,
  getBannerByIdSuccess,
  getBannerByIdFailure,
  updateBanner,
  updateBannerSuccess,
  updateBannerFailure,
  createBanner,
  createBannerSuccess,
  createBannerFailure,
} from './actionBarner';
const Cookies = require('js-cookie');

//get all Market Categories
export const getBannersRepo = () => async (dispatch) => {
  try {
    dispatch(banner());

    console.log('BarnersFrmRepo before ');

    const { data } = await api.getBanners();
    // console.log('procatData :', data);

    // dispatch({
    //   type: FETCH_ALL,
    //   payload: { data, currentPage, numberOfPages },
    // });
    if (data) {
      console.log('BarnersFrmRepo', data);
      dispatch(bannerSuccess(data));
    }
  } catch (error) {
    // console.log(error);
    dispatch(bannerFailure({ error: error.message }));
  }
};

//get single Market
export const getBannerWithId = (id) => async (dispatch) => {
  dispatch(getBannerById());
  // console.log('Acton start');
  try {
    const { data } = await api.getApiBannerById(id);
    // console.log('MydesigByID :', data);

    dispatch(getBannerByIdSuccess(data));
  } catch (error) {
    // console.log(error);
    dispatch(getBannerByIdFailure({ error: error.message }));
  }
};

//update a Market
export const updateBannersRepo = (id, values) => async (dispatch) => {
  // console.log('repoValues', values);
  dispatch(updateBanner());
  try {
    const { data } = await api.updateBannerById(id, values);
    // console.log('actionData :', data);

    dispatch(updateBannerSuccess(data));
    alert('Banner updated');
  } catch (error) {
    // console.log(error);
    dispatch(updateBannerFailure({ message: error.message }));
  }
};

//Create a Market
export const createBannerRepo = (values) => async (dispatch) => {
  try {
    dispatch(createBanner());
    const { data } = await api.createBanner(values);
    // console.log('actionData :', data);
    dispatch(createBannerSuccess(data));
    // console.log('newDesination:', data);
    alert('Banner  added');
  } catch (error) {
    dispatch(createBannerFailure({ error: error.message }));
  }
};
