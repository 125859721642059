import * as api from '../api/apiRoutes';
import {
  info,
  infoSuccess,
  infoFailure,
  getInfoById,
  getInfoByIdSuccess,
  getInfoByIdFailure,
  updateInfo,
  updateInfoSuccess,
  updateInfoFailure,
  createInfo,
  createInfoSuccess,
  createInfoFailure,
} from './actionInfo';
const Cookies = require('js-cookie');

//get all Market Categories
export const getInfosRepo = () => async (dispatch) => {
  try {
    dispatch(info());
    const { data } = await api.getInfos();
    // console.log('procatData :', data);

    // dispatch({
    //   type: FETCH_ALL,
    //   payload: { data, currentPage, numberOfPages },
    // });
    dispatch(infoSuccess(data));
  } catch (error) {
    // console.log(error);
    dispatch(infoFailure({ error: error.message }));
  }
};

//get single Market
export const getInfoWithId = (id) => async (dispatch) => {
  dispatch(getInfoById());
  // console.log('Acton start');
  try {
    const { data } = await api.getApiInfoById(id);
    // console.log('MydesigByID :', data);

    dispatch(getInfoByIdSuccess(data));
  } catch (error) {
    // console.log(error);
    dispatch(getInfoByIdFailure({ error: error.message }));
  }
};

//update a Market
export const updateInfosRepo = (id, values) => async (dispatch) => {
  // console.log('repoValues', values);
  dispatch(updateInfo());
  try {
    const { data } = await api.updateInfoById(id, values);
    // console.log('actionData :', data);

    if (data) {
      dispatch(updateInfoSuccess(data));
      window.alert('Info updated');
    }
  } catch (error) {
    // console.log(error);
    dispatch(updateInfoFailure({ message: error.message }));
  }
};

//Create a Market
export const createInfoRepo = (values) => async (dispatch) => {
  try {
    dispatch(createInfo());
    const { data } = await api.createInfo(values);
    // console.log('actionData :', data);
    dispatch(createInfoSuccess(data));
    // console.log('newDesination:', data);
    alert('Info  added');
  } catch (error) {
    dispatch(createInfoFailure({ error: error.message }));
  }
};
