import React, { Fragment, useEffect, useState } from 'react';
import './manageshop.scss';
import ShopRightbar from '../../../components_shops/shoprightbar/ShopRightbar';
import { DataGrid } from '@material-ui/data-grid';
import { DeleteOutline, Edit } from '@material-ui/icons';
import {
  Paper,
  Typography,
  CircularProgress,
  Divider,
} from '@material-ui/core/';
import { productRows } from '../../../dummyData';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getDepartments } from '../../../store/departments/repository_dept';
import { getShops } from '../../../store/shops/repository_shops';
import { adminGetOrders } from '../../../store/api/apiRoutes';
import { Pagination, Select } from 'antd';
import TableOrdersItems from '../../../components_shops/shared/tables/TableOrdersItems';
import ModuleOrderShippingInformation from '../../../components_shops/partials/orders/ModuleOrderShippingInformation';
import ModuleOrderBillingInformation from '../../../components_shops/partials/orders/ModuleOrderBillingInformation';

const { Option } = Select;
const OrderListDetail = () => {
  const dispatch = useDispatch();
  // const { shops, isFetching } = useSelector((state) => state.shops);

  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    // const data = dispatch(getShops());

    fetchOrders();
    // setPagePosts(data);
  }, [dispatch]);

  const fetchOrders = async () => {
    setLoading(true);

    const { data } = await adminGetOrders();
    if (data) {
      setOrders(data.MOrders);
      // Cookies.remove('cart');
      // setCookie('cart', [], { path: '/' });
      // dispatch(setCartItemsSuccess([]));
      setTimeout(
        function () {
          setLoading(false);
        }.bind(this),
        250
      );
    }
    console.log('adminOrderList', data);

    // SetLoading(false);
  };

  const handleDelete = (id) => {
    // setData(data.filter((item) => item.id !== id));
  };

  const Button = ({ type }) => {
    return <button className={'widgetLgButton ' + type}>{type}</button>;
  };

  const columns = [
    { field: '_id', headerName: 'ID', width: 90 },
    // { field: 'refOrderId', headerName: 'ID', width: 90 },
    {
      field: 'refOrderId',
      headerName: 'Refernce',
      width: 120,
      // renderCell: (params) => {
      //   return (
      //     <div className="productListItem">
      //       <img className="productListImg" src={params.row.shoplogo} alt="" />
      //       {params.row.shopname}
      //     </div>
      //   );
      // },
    },
    {
      field: 'isPaid',
      headerName: 'Payment Status',
      width: 150,
      renderCell: (params) => {
        return (
          <div className="widgetLgStatus">
            {params.row.isPaid === true ? (
              <Button type="OrderPaid " />
            ) : (
              <Button
                type="OrderNotPaid "
                style={{ width: '30px!important' }}
              />
            )}
          </div>
        );
      },
    },
    {
      field: 'paidAt',
      headerName: 'Payment Date',
      width: 90,
      // renderCell: (params) => {
      //   return (
      //     <div className="widgetLgStatus">
      //       {params.row.isPaid === true ? (
      //         <Button type="OrderPaid" />
      //       ) : (
      //         <Button type="OrderNotPaid" style={{ width: '30px!important' }} />
      //       )}
      //     </div>
      //   );
      // },
    },
    {
      field: 'isDelivered',
      headerName: 'Delivery Status',
      width: 200,
      renderCell: (params) => {
        return (
          <>
            {params.row.isPaid === true ? (
              <div>
                {params.row.isPaid === true &&
                params.row.isDelivered === true ? (
                  <Button type="Delivered" />
                ) : (
                  <Button type="PendingDelivery" />
                )}
              </div>
            ) : (
              <Button type="OrderNotPaid" />
            )}
          </>
        );
      },
    },

    // { field: 'stock', headerName: 'Stock', width: 200 },
    // {
    //   field: 'status',
    //   headerName: 'Status',
    //   width: 120,
    // },
    // {
    //   field: 'price',
    //   headerName: 'Price',
    //   width: 160,
    // },
    {
      field: 'action',
      headerName: 'Action',
      width: 200,
      renderCell: (params) => {
        // return (
        //   <>
        //     <Link to={"/product/" + params.row.id}>
        //       <button className="productListEdit">Edit</button>
        //     </Link>
        //     <DeleteOutline
        //       className="productListDelete"
        //       onClick={() => handleDelete(params.row.id)}
        //     />
        //   </>
        // );
        return (
          <>
            <div className="actionButtons">
              {/* /adminorders/update/ */}
              {/* /admin/manageorders/:id */}
              <Link to={'/admin/manageorders/' + params.row._id}>
                <Edit className="userListEditIcon" />
                {/* <button className="userListEdit">Edit</button> */}
              </Link>
              <DeleteOutline
                className="userListDelete"
                onClick={() => handleDelete(params.row._id)}
              />
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      {/* Changing pages rendering starts here */}

      <>
        {/* start of table grid */}
        <div className="userList">
          <section className="ps-dashboard">
            <div className="ps-section__left">
              <div className="row">
                <div className="col-md-4">
                  <ModuleOrderShippingInformation />
                </div>
                <div className="col-md-4">
                  <ModuleOrderBillingInformation />
                </div>
                <div className="col-md-4">
                  <ModuleOrderShippingInformation />
                </div>
              </div>
              <div className="ps-card ps-card--track-order">
                <div className="ps-card__header">
                  <h4>#ABD-235711</h4>
                </div>
                <div className="ps-card__content">
                  <div className="table-responsive">
                    <table className="table ps-table">
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>Quantity</th>
                          <th>Price</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <a href="#">
                              Herschel Leather Duffle Bag In Brown Color
                            </a>
                          </td>
                          <td>1</td>
                          <td>$29.59</td>
                          <td>$29.59</td>
                        </tr>
                        <tr>
                          <td>
                            <a href="#">
                              Herschel Leather Duffle Bag In Brown Color
                            </a>
                          </td>
                          <td>1</td>
                          <td>$29.59</td>
                          <td>$29.59</td>
                        </tr>
                        <tr>
                          <td>
                            <a href="#">
                              Herschel Leather Duffle Bag In Brown Color
                            </a>
                          </td>
                          <td>1</td>
                          <td>$29.59</td>
                          <td>$29.59</td>
                        </tr>
                        <tr>
                          <td colSpan="3">
                            <strong>Sub Total:</strong>
                          </td>
                          <td>
                            <strong>$199.90</strong>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="3">
                            <strong>Shipping Charge:</strong>
                          </td>
                          <td>
                            <strong>$24.00</strong>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="3">
                            <strong>Estimated:</strong>
                          </td>
                          <td>
                            <strong>$12.00</strong>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="3">
                            <strong>Total:</strong>
                          </td>
                          <td>
                            <strong>$211.00</strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="ps-section__right">
              <div className="ps-card ps-card--track-order">
                <div className="ps-card__header">
                  <h4>Track Order</h4>
                </div>
                <div className="ps-card__content">
                  <div className="ps-block--track-order">
                    <div className="ps-block__header">
                      <div className="row">
                        <div className="col-6">
                          <figure>
                            <figcaption>Order ID:</figcaption>
                            <p>#ABD-235711</p>
                          </figure>
                        </div>
                        <div className="col-6">
                          <figure>
                            <figcaption>Tracking ID:</figcaption>
                            <p>21191818abs1</p>
                          </figure>
                        </div>
                      </div>
                    </div>
                    <div className="ps-block__content">
                      <div className="ps-block__timeline">
                        <figure className="active">
                          <figcaption>Order Placed</figcaption>
                          <p>
                            Sep 19, 2020 <small>7:00am</small>
                          </p>
                        </figure>
                        <figure className="active">
                          <figcaption>Packed</figcaption>
                          <p>
                            Sep 19, 2020 <small>10:00am</small>
                          </p>
                        </figure>
                        <figure className="active">
                          <figcaption>Shipped</figcaption>
                          <p>
                            Sep 19, 2020 <small>4:00pm</small>
                          </p>
                        </figure>
                        <figure>
                          <figcaption>Delivered</figcaption>
                          <p>Estimated delivery within 1 day</p>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <>
          <ShopRightbar Shop />
        </>
      </>
      {/* Changing pages rendering ends here */}
    </>
  );
};

export default OrderListDetail;
