export const actionTypes = {
  ADMINUSER_REQUEST: 'ADMINUSER_REQUEST',
  ADMINUSER_SUCCESS: 'ADMINUSER_SUCCESS',
  ADMINUSER_FAILURE: 'ADMINUSER_FAILURE',

  GET_ADMINUSER_REQUEST: 'GET_ADMINUSER_REQUEST',
  GET_ADMINUSER_SUCCESS: 'GET_ADMINUSER_SUCCESS',
  GET_ADMINUSER_FAILURE: 'GET_ADMINUSER_FAILURE',

  UPDATE_ADMINUSER_REQUEST: 'UPDATE_ADMINUSER_REQUEST',
  UPDATE_ADMINUSER_SUCCESS: 'UPDATE_ADMINUSER_SUCCESS',
  UPDATE_ADMINUSER_FAILURE: 'UPDATE_ADMINUSER_FAILURE',

  CREATE_ADMINUSER_REQUEST: 'CREATE_ADMINUSER_REQUEST',
  CREATE_ADMINUSER_SUCCESS: 'CREATE_ADMINUSER_SUCCESS',
  CREATE_ADMINUSER_FAILURE: 'CREATE_ADMINUSER_FAILURE',

  //whenloading
  END_LOADING: 'END_LOADING',
  START_LOADING: 'START_LOADING',
};

export function adminuser() {
  return { type: actionTypes.ADMINUSER_REQUEST };
}

export function adminuserSuccess(data) {
  return { type: actionTypes.ADMINUSER_SUCCESS, payload: data };
}

export function adminuserFailure() {
  return { type: actionTypes.ADMINUSER_FAILURE };
}

//Get USER by ID:
export function getAdminUserById() {
  return { type: actionTypes.GET_ADMINUSER_REQUEST };
}

export function getAdminUserByIdSuccess(user) {
  return { type: actionTypes.GET_ADMINUSER_SUCCESS, payload: user };
}

export function getAdminUserByIdFailure() {
  return { type: actionTypes.GET_ADMINUSER_FAILURE };
}

//Update USER by ID:
export function updateAdminUser() {
  return { type: actionTypes.UPDATE_ADMINUSER_REQUEST };
}

export function updateAdminUserSuccess(data) {
  return {
    type: actionTypes.UPDATE_ADMINUSER_SUCCESS,
    payload: data,
  };
}

export function updateAdminUserFailure() {
  return { type: actionTypes.UPDATE_ADMINUSER_FAILURE };
}

//create new USER:
export function createAdminUser() {
  return { type: actionTypes.CREATE_ADMINUSER_REQUEST };
}

export function createAdminUserSuccess(data) {
  return {
    type: actionTypes.CREATE_ADMINUSER_SUCCESS,
    payload: data,
  };
}

export function createAdminUserFailure() {
  return { type: actionTypes.CREATE_ADMINUSER_FAILURE };
}
