export const actionTypes = {
  PRODUNIT_REQUEST: 'PRODUNIT_REQUEST',
  PRODUNIT_SUCCESS: 'PRODUNIT_SUCCESS',
  PRODUNIT_FAILURE: 'PRODUNIT_FAILURE',

  GET_PRODUNIT_REQUEST: 'GET_PRODUNIT_REQUEST',
  GET_PRODUNIT_SUCCESS: 'GET_PRODUNIT_SUCCESS',
  GET_PRODUNIT_FAILURE: 'GET_PRODUNIT_FAILURE',

  UPDATE_PRODUNIT_REQUEST: 'UPDATE_PRODUNIT_REQUEST',
  UPDATE_PRODUNIT_SUCCESS: 'UPDATE_PRODUNIT_SUCCESS',
  UPDATE_PRODUNIT_FAILURE: 'UPDATE_PRODUNIT_FAILURE',

  CREATE_PRODUNIT_REQUEST: 'CREATE_PRODUNIT_REQUEST',
  CREATE_PRODUNIT_SUCCESS: 'CREATE_PRODUNIT_SUCCESS',
  CREATE_PRODUNIT_FAILURE: 'CREATE_PRODUNIT_FAILURE',

  //whenloading
  END_LOADING: 'END_LOADING',
  START_LOADING: 'START_LOADING',
};

export function produnit() {
  return { type: actionTypes.PRODUNIT_REQUEST };
}

export function produnitSuccess(produnits) {
  return { type: actionTypes.PRODUNIT_SUCCESS, payload: produnits.data };
}

export function produnitFailure() {
  return { type: actionTypes.PRODUNIT_FAILURE };
}

//Get PRODUNITnation by ID:
export function getProdunitById() {
  return { type: actionTypes.GET_PRODUNIT_REQUEST };
}

export function getProdunitByIdSuccess(produnit) {
  return { type: actionTypes.GET_PRODUNIT_SUCCESS, payload: produnit };
}

export function getProdunitByIdFailure() {
  return { type: actionTypes.GET_PRODUNIT_FAILURE };
}

//Update PRODUNITnation by ID:
export function updateProdunit() {
  return { type: actionTypes.UPDATE_PRODUNIT_REQUEST };
}

export function updateProdunitSuccess(data) {
  return {
    type: actionTypes.UPDATE_PRODUNIT_SUCCESS,
    payload: data,
  };
}

export function updateProdunitFailure() {
  return { type: actionTypes.UPDATE_PRODUNIT_FAILURE };
}

//create new PRODUNITnation:
export function createProdunit() {
  return { type: actionTypes.CREATE_PRODUNIT_REQUEST };
}

export function createProdunitSuccess(data) {
  return {
    type: actionTypes.CREATE_PRODUNIT_SUCCESS,
    payload: data,
  };
}

export function createProdunitFailure() {
  return { type: actionTypes.CREATE_PRODUNIT_FAILURE };
}

// export function logOut() {
//   return { type: actionTypes.LOG_OUT };
// }
