import { actionTypes } from './actionBarner';
import Cookies from 'js-cookie';

export const initState = {
  banners: [],
  isFetching: false,
  error: false,
};

function reducer(state = initState, action) {
  switch (action.type) {
    case 'START_LOADING':
      return { ...state, isFetching: true };
    case 'END_LOADING':
      return { ...state, isFetching: false };
    //Get MARKET
    case actionTypes.BANNER_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.BANNER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        banners: action.payload,
        error: false,
      };
    case actionTypes.BANNER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Get MARKETById:
    case actionTypes.GET_BANNER_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.GET_BANNER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        banner: action.payload,
        error: false,
      };
    case actionTypes.GET_BANNER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Update A MARKETnationsById:
    case actionTypes.UPDATE_BANNER_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.UPDATE_BANNER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        banners: state.banners.map((banner) =>
          banner._id === action.payload._id ? action.payload : banner
        ),
        error: false,
      };
    case actionTypes.UPDATE_BANNER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Create A New MARKETnationsById:
    case actionTypes.CREATE_BANNER_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.CREATE_BANNER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        banners: [...state.banners, action.payload],
        error: false,
      };
    case actionTypes.CREATE_BANNER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };

    default:
      return { ...state };
  }
}
export default reducer;
