import { CloudUploadOutlined, Edit, Publish } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import useForm from '../../hooks/useForm';
import { AuthApi } from '../../apiconstant/Api';
import { useParams, useHistory, Redirect, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import app from '../../firebase';
import {
  createPartnerRepo,
  updatePartnersRepo,
} from '../../store/partners/repository_partner';
import {
  createBannerRepo,
  updateBannersRepo,
} from '../../store/barners/repository_barner';
import {
  getApiAdminUserById,
  getApiBannerById,
  getDesigByDepartmentId,
} from '../../store/api/apiRoutes';
import StaffPersonalInformation from '../../components_shops/staffcards/StaffPersonalInformation';
import StaffInformation from '../../components_shops/staffcards/StaffInformation';
import { set } from 'react-hook-form';
import { getDepartments } from '../../store/departments/repository_dept';
import { getDesigById } from '../../store/api/apiRoutes';
import {
  createAdminUserRepo,
  updateAdminUsersRepo,
} from '../../store/adminusers/repository_adminuser';

// const initialFValues = {
//     id:0,
//     product:{},
//   }

const initialFValues = {
  id: 0,
  name: '',
  email: '',
  password: '',
  gender: '',
  isAdmin: '',
  isCompany: '',
  isShopExist: '',

  isBlocked: '',
  department: '',
  designation: '',

  avatar: '',
  aboutme: '',

  facebook: '',
  instagram: '',
  twitter: '',
  phone: '',
  address: '',
  postalCode: '',
  city: '',
  country: '',
  referralcode: '',
  downlines: [],
};

const AdminStaffForm = () => {
  const [editMode, setEditMode] = useState(false);
  const params = useParams();

  const [loading, setLoading] = useState([false]);

  const [designationsList, setDesignationList] = useState([]);

  const { values, setValues, handleInputChange, handleFile, resetForm } =
    useForm(initialFValues);
  const [file, setFile] = useState(null);
  const { id } = params;
  const history = useHistory();
  const dispatch = useDispatch();

  const { departments, isFetching } = useSelector((state) => state.depts);

  useEffect(() => {
    // dispatch(getDepartments());

    if (id) {
      getAdminUserData();
    }
  }, [id, dispatch]);

  useEffect(() => {
    dispatch(getDepartments());

    // if (id) {
    //   getAdminUserData();
    // }

    if (values?.department) {
      const getDesignationData = async () => {
        setLoading(true);
        try {
          if (values?.department) {
            await getDesigByDepartmentId(values?.department).then(
              (response) => {
                console.log('DesigByDept Component', response);
                // setDepartment(response.data);
                setDesignationList(response.data);
              }
            );
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      };
      getDesignationData();
    }
  }, [values?.department, setValues, dispatch]);

  const getAdminUserData = async () => {
    setLoading(true);
    try {
      if (id) {
        await getApiAdminUserById(id).then((response) => {
          setValues(response.data);

          // console.log('API ADMIN-STAFF', response.data);
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleAdminUserSubmit = async (e) => {
    e.preventDefault();

    if (file) {
      const fileName = new Date().getTime() + file.name;
      const storage = getStorage(app);
      // const storageRef = ref(storage, fileName);
      const storageRef = ref(storage, `/adminusers/${fileName}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion

      // start here

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
            default:
          }
        },
        (error) => {
          // Handle unsuccessful uploads
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            const adminUserFormData = { ...values, avatar: downloadURL };

            dispatch(createAdminUserRepo(adminUserFormData));
            resetForm();
            setFile(null);
          });
        }
      );
    } else {
      dispatch(createAdminUserRepo(values));
      resetForm();
      setFile(null);
    }
  };

  //HANDLE POST UPDATE
  const handleAdminUserUpdate = async (e) => {
    e.preventDefault();

    if (file) {
      const fileName = new Date().getTime() + file.name;
      const storage = getStorage(app);
      // const storageRef = ref(storage, fileName);
      const storageRef = ref(storage, `/adminusers/${fileName}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion

      // start here

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
            default:
          }
        },
        (error) => {
          // Handle unsuccessful uploads
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            const adminUserFormData = { ...values, avatar: downloadURL };
            dispatch(updateAdminUsersRepo(id, adminUserFormData));
          });
        }
      );
    } else {
      // console.log('valueWithOutImageFile :', values);
      dispatch(updateAdminUsersRepo(id, values));
      // createPostcatRepo(values).then((response) => {
      //   if (response.data.error) {
      //     // console.log(response.data.error);
      //   } else {
      //     // alert(response.data.success);
      //     alert('Ad added');
      //     // resetForm();
      //   }
      // });
    }
  };

  const handEditMode = (e) => {
    e.preventDefault();

    setEditMode(!editMode);
  };

  // console.log('DesigByDept', designationsList);

  return (
    <div className="shopErectionUpdate">
      {id && (
        <section className="ps-dashboard">
          <div className="ps-section__left">
            <div className="row">
              <div className="col-md-6">
                <StaffPersonalInformation admin={values} />
              </div>
              <div className="col-md-6">
                <StaffInformation admin={values} />
              </div>
            </div>
            {/* {put form here} */}
          </div>
        </section>
      )}

      <h5
        style={{
          backgroundColor: 'lightblue',
          padding: '5px',
          width: '150px',
          borderRadius: '4px',
        }}
      >
        Add/Edit Staff Info{' '}
        <span>
          {' '}
          <Edit className="userListEditIcon" onClick={(e) => handEditMode(e)} />
        </span>
      </h5>

      {editMode && (
        <form
          onSubmit={id ? handleAdminUserUpdate : handleAdminUserSubmit}
          className="ps-form--account-setting"
        >
          {/* confirmUpdate  updateUserDetails  designationsList */}
          <div className="ps-form__header">
            <h3>Create/Edit Banners</h3>
          </div>
          <div className="ps-form__content">
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Name</label>
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    value={values?.name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Email</label>
                  <input
                    className="form-control"
                    type="text"
                    name="email"
                    value={values?.email}
                    onChange={handleInputChange}
                    required
                    autocomplete="false"
                  />
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <label>Department</label>
                  <select
                    // style={{ maxWidth: '250px' }}
                    className="form-control"
                    name="department"
                    value={values.department}
                    onChange={handleInputChange}
                    required
                    // id="department"
                  >
                    <option value="">Select an option</option>
                    {departments.map((dt) => (
                      <option value={dt._id} onChange={handleInputChange}>
                        {dt.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <label>Designation</label>
                  <select
                    className="form-control"
                    type="text"
                    name="designation"
                    value={values?.designation}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select an option</option>
                    {designationsList.map((dt) => (
                      <option value={dt._id} onChange={handleInputChange}>
                        {dt.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <hr />
              <div className="col-sm-6">
                <label> Avatar</label>
                <div className="form-group">
                  <label htmlFor="file">
                    <Publish className="shopErectionUpdateIcon" />
                  </label>
                  <input
                    type="file"
                    id="file"
                    style={{ display: 'none' }}
                    name="file"
                    // value={values.adImage}
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                  {file && <span>{file.name}</span>}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  {values?.avatar && (
                    <img
                      className="userUpdateImg"
                      src={values?.avatar}
                      alt=""
                    />
                  )}
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <label>Phone</label>
                  <input
                    className="form-control"
                    type="text"
                    name="phone"
                    value={values?.phone}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              {!id && (
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      className="form-control"
                      type="password"
                      name="password"
                      value={values?.password}
                      onChange={handleInputChange}
                      required
                      autocomplete="false"
                    />
                  </div>
                </div>
              )}

              <div className="col-sm-6">
                <div className="form-group">
                  <label>Gender</label>
                  <select
                    // style={{ maxWidth: '250px' }}
                    className="form-control"
                    name="gender"
                    value={values.gender}
                    onChange={handleInputChange}
                    required
                    // id="department"
                  >
                    <option value="">Select an option</option>
                    <option value={1}>Male</option>
                    <option value={2}>Female</option>
                    <option value={3}>Others</option>
                  </select>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <label>Pathfinder Key</label>
                  <input
                    className="form-control"
                    type="text"
                    name="key"
                    value={values?.key}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="form-group submit">
              <Link to={'/staff'} className="btn">
                Cancel
              </Link>
              <button className="ps-btn">Add/Update Staff Data</button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default AdminStaffForm;
