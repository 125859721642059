import * as api from '../api/apiRoutes';
import {
  post,
  postSuccess,
  postFailure,
  getPostById,
  getPostByIdSuccess,
  getPostByIdFailure,
  updatePost,
  updatePostSuccess,
  updatePostFailure,
  createPost,
  createPostSuccess,
  createPostFailure,
} from './actionPost';
const Cookies = require('js-cookie');

//get all Posts
export const getAllPosts = () => async (dispatch) => {
  try {
    dispatch(post());
    const { data } = await api.getNewsPosts();
    // console.log('procatData :', data);

    // dispatch({
    //   type: FETCH_ALL,
    //   payload: { data, currentPage, numberOfPages },
    // });
    dispatch(postSuccess(data));
  } catch (error) {
    // console.log(error);
    dispatch(postFailure({ error: error.message }));
  }
};

//get all Admin Posts
export const getPosts = () => async (dispatch) => {
  try {
    dispatch(post());
    const { data } = await api.getAdminPosts();
    // console.log('procatData :', data);

    // dispatch({
    //   type: FETCH_ALL,
    //   payload: { data, currentPage, numberOfPages },
    // });
    dispatch(postSuccess(data));
  } catch (error) {
    // console.log(error);
    dispatch(postFailure({ error: error.message }));
  }
};

//get single Market
export const getPostWithId = (id) => async (dispatch) => {
  dispatch(getPostById());
  // console.log('Acton start');
  try {
    const { data } = await api.getPostById(id);
    // console.log('MydesigByID :', data);

    dispatch(getPostByIdSuccess(data));
  } catch (error) {
    // console.log(error);
    dispatch(getPostByIdFailure({ error: error.message }));
  }
};

//update a Market
export const updateAdminPost = (id, values) => async (dispatch) => {
  console.log('repoPstValues', values);
  dispatch(updatePost());
  try {
    const { data } = await api.updatePostById(id, values);
    // console.log('actionData :', data);
    if (data) {
      dispatch(updatePostSuccess(data));
      alert('Post  updated');
    }
  } catch (error) {
    // console.log(error);
    dispatch(updatePostFailure({ message: error.message }));
  }
};

//Create a Market
export const createPostRepo = (values) => async (dispatch) => {
  try {
    dispatch(createPost());
    const { data } = await api.createPost(values);
    // console.log('actionData :', data);
    dispatch(createPostSuccess(data));
    // console.log('newDesination:', data);
    alert('Post added');
  } catch (error) {
    dispatch(createPostFailure({ error: error.message }));
  }
};
