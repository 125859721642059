import { actionTypes } from './actionInfo';
import Cookies from 'js-cookie';

export const initState = {
  infos: [],
  isFetching: false,
  info: {},
  error: false,
};

function reducer(state = initState, action) {
  switch (action.type) {
    case 'START_LOADING':
      return { ...state, isFetching: true };
    case 'END_LOADING':
      return { ...state, isFetching: false };
    //Get MARKET
    case actionTypes.INFO_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.INFO_SUCCESS:
      return {
        ...state,
        isFetching: false,
        infos: action.payload,
        error: false,
      };
    case actionTypes.INFO_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Get MARKETById:
    case actionTypes.GET_INFO_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.GET_INFO_SUCCESS:
      return {
        ...state,
        isFetching: false,
        info: action.payload,
        error: false,
      };
    case actionTypes.GET_INFO_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Update A MARKETnationsById:
    case actionTypes.UPDATE_INFO_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.UPDATE_INFO_SUCCESS:
      return {
        ...state,
        isFetching: false,
        infos: state.infos.map((info) =>
          info._id === action.payload._id ? action.payload : info
        ),
        error: false,
      };
    case actionTypes.UPDATE_INFO_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };

    //Create A New MARKETnationsById:
    case actionTypes.CREATE_INFO_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.CREATE_INFO_SUCCESS:
      return {
        ...state,
        isFetching: false,
        infos: [...state.infos, action.payload],
        error: false,
      };
    case actionTypes.CREATE_INFO_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };

    default:
      return { ...state };
  }
}
export default reducer;
