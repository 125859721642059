import React from 'react';
import './shoperection.scss';
import ShopRightbar from '../../components_shops/shoprightbar/ShopRightbar';
import {
  CalendarToday,
  LocationSearching,
  MailOutline,
  PermIdentity,
  PhoneAndroid,
  Publish,
} from '@material-ui/icons';
import { useParams, useHistory, Redirect, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ShoppingRightbar from '../../components/shoppingrightbar/ShoppingRightbar';
import ShopForm from './ShopForm';
import { useEffect } from 'react';
import { getShopplanWithId } from '../../store/shopplans/repository_shopplan';
import {
  Paper,
  // Typography,
  CircularProgress,
  // Divider,
} from '@material-ui/core/';

const CreateShopForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { shopplan, isFetching } = useSelector((state) => state.shopsubs);

  // console.log('ShopPlanByID :', shopplan);

  useEffect(() => {
    dispatch(getShopplanWithId(id));
  }, []);

  return (
    <>
      {/* Changing pages rendering starts here */}

      <>
        <div className="shoppingRight">
          <div className="shoppingRightBottom">
            {/* page starts */}
            <div className="shopErection">
              <div className="shopErectionTitleContainer">
                <h1 className="shopErectionTitle">
                  Selected Shop Plan is "Plan B"
                </h1>
                {/* <Link to="/newshopErection">
          <button className="shopErectionAddButton">Create</button>
        </Link> */}
              </div>
              <div className="shopErectionContainer">
                <div className="shopErectionShow">
                  <div className="shopErectionShowTop">
                    <img
                      src="https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
                      alt=""
                      className="shopErectionShowImg"
                    />
                    <div className="shopErectionShowTopTitle">
                      <span className="shopErectionShowshopErectionname">
                        Anna Becker
                      </span>
                      <span className="shopErectionShowshopErectionTitle">
                        Software Engineer
                      </span>
                    </div>
                  </div>
                  <div className="shopErectionShowBottom">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <div class="price-table pt-bg-black">
                        {/* <div>
                          <span>basic</span>
                          <span>Price $99.99/mo</span>
                          <span>Features included!</span>
                        </div> */}
                        {/* <ul>
                          <li>Domain name</li>
                          <li>Social Media Integration</li>
                          <li>First Month Hosting</li>
                          <li>On Page SEO</li>
                          <li>24/6 Support</li>
                        </ul>
                         */}
                        {isFetching ? (
                          <Paper elevation={6} className="loadingPaper">
                            <CircularProgress
                              size="7em"
                              className="loadingPaper"
                            />
                          </Paper>
                        ) : (
                          <>
                            <div>
                              <span>{shopplan?.plansname}</span>
                              <span>Price N{shopplan?.price}/mo</span>
                              <span>Features included!</span>
                            </div>
                            <ul>
                              <li>
                                No of Products : {shopplan?.numberofproducts}
                              </li>
                              <li>
                                Images Per Product :{' '}
                                {shopplan?.numberoffeaturedimages}
                              </li>
                              <li>
                                Dashboard Analytics:{' '}
                                {shopplan?.dashboardandanalytics}{' '}
                              </li>
                              <li>On Page SEO : {shopplan?.adsbost}</li>
                              <li>24/6 Support : {shopplan?.support}</li>
                            </ul>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="shopErectionUpdate">
                  <span className="shopErectionUpdateTitle">Shop Details</span>
                  <ShopForm shopplan={shopplan} />
                </div>
              </div>
            </div>
            {/* page ends         */}
          </div>
        </div>

        <>
          <ShoppingRightbar Shopping />
        </>
      </>
      {/* Changing pages rendering ends here */}
    </>
  );
};

export default CreateShopForm;
