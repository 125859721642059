import { CloudUploadOutlined, Publish } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import useForm from '../../hooks/useForm';
import { AuthApi } from '../../apiconstant/Api';
import { useParams, useHistory, Redirect, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  createDepart,
  getDepartWithId,
  updateDepart,
} from '../../store/departments/repository_dept';
import { getDeptById, getPostById } from '../../store/api/apiRoutes';
import { getDepartments } from '../../store/departments/repository_dept';
import {
  createPostcatRepo,
  getPostCats,
  getPostcatWithId,
} from '../../store/postcats/repository_postcat';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import app from '../../firebase';
import {
  createPostRepo,
  getPosts,
  getPostWithId,
  updateAdminPost,
} from '../../store/postss/repository_post';

// const initialFValues = {
//     id:0,
//     product:{},
//   }

const initialFValues = {
  id: 0,
  postcategory: '',
  title: '',
  featuredImage: '',
  content: '',
};

const PostForm = () => {
  const params = useParams();

  // const postId = params.id;
  // console.log('pageProps:', postId);
  const [loading, setLoading] = useState(false);
  // const [post, setPost] = useState({});

  const { values, setValues, handleInputChange, handleFile, resetForm } =
    useForm(initialFValues);
  const [file, setFile] = useState(null);
  const { id } = params;
  const history = useHistory();
  const dispatch = useDispatch();
  // const [postcats, setPostCats] = useState([]);
  const { postcats } = useSelector((state) => state.postcates);

  // const post = useSelector((state) =>
  //   id ? state.posts.posts.find((pst) => pst._id === id) : null
  // );
  // const { post, isFetching } = useSelector((state) => state.posts);

  useEffect(() => {
    dispatch(getPostCats());

    const getPosttData = async () => {
      setLoading(true);
      try {
        if (id) {
          await getPostById(id).then((response) => {
            setValues(response.data);
          });
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    if (id) {
      getPosttData();
    }

    // if (id) {
    //   // dispatch(getPosts());
    //   dispatch(getPostWithId(id));
    //   setValues(post);
    // }

    // setPagePosts(data);
  }, [id, dispatch]);

  console.log('Post CHECK!D :', id);
  console.log('Post CHECK :', values);

  const handlePostSubmit = async (e) => {
    e.preventDefault();

    // try {
    //   createAd(values).then((response) => {
    //     if (response.data.error) {
    //       console.log(response.data.error);
    //     } else {
    //       // alert(response.data.success);
    //       alert('Plan added');
    //       // resetForm();
    //     }
    //   });
    // } catch (error) {
    //   console.log({ message: error.message });
    // }
    // // resetForm();
    console.log(values);
    if (file) {
      const fileName = new Date().getTime() + file.name;
      const storage = getStorage(app);
      // const storageRef = ref(storage, fileName);
      const storageRef = ref(storage, `/posts/${fileName}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion

      // start here

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
            default:
          }
        },
        (error) => {
          // Handle unsuccessful uploads
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log('valueWithImageFile :', values, {
              adImage: downloadURL,
            });
            const advert = { ...values, featuredImage: downloadURL };

            dispatch(createPostRepo(advert));
            resetForm();
            setFile(null);
            // createPostcatRepo(advert).then((response) => {
            //   if (response.data.error) {
            //     // console.log(response.data.error);
            //   } else {
            //     // alert(response.data.success);
            //     alert('Post added');
            //     // resetForm();
            //   }
            // });
          });
        }
      );
    } else {
      // console.log('valueWithOutImageFile :', values);

      dispatch(createPostRepo(values));
      resetForm();
      // setFile(null);

      // createPostcatRepo(values).then((response) => {
      //   if (response.data.error) {
      //     // console.log(response.data.error);
      //   } else {
      //     // alert(response.data.success);
      //     alert('Ad added');
      //     // resetForm();
      //   }
      // });
    }
  };

  //HANDLE POST UPDATE
  const handlePostUpdate = async (e) => {
    e.preventDefault();

    console.log(values);
    if (file) {
      const fileName = new Date().getTime() + file.name;
      const storage = getStorage(app);
      // const storageRef = ref(storage, fileName);
      const storageRef = ref(storage, `/posts/${fileName}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion

      // start here

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
            default:
          }
        },
        (error) => {
          // Handle unsuccessful uploads
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log('valueWithImageFile :', values, {
              adImage: downloadURL,
            });
            const advert = { ...values, featuredImage: downloadURL };
            dispatch(updateAdminPost(id, advert));
            // createPostcatRepo(advert).then((response) => {
            //   if (response.data.error) {
            //     // console.log(response.data.error);
            //   } else {
            //     // alert(response.data.success);
            //     alert('Post added');
            //     // resetForm();
            //   }
            // });
          });
        }
      );
    } else {
      // console.log('valueWithOutImageFile :', values);
      dispatch(updateAdminPost(id, values));
      // createPostcatRepo(values).then((response) => {
      //   if (response.data.error) {
      //     // console.log(response.data.error);
      //   } else {
      //     // alert(response.data.success);
      //     alert('Ad added');
      //     // resetForm();
      //   }
      // });
    }
  };

  return (
    <div className="shopErectionUpdate">
      {/* <span className="shopErectionUpdateTitle">Manage Posts </span>
      <form
        className="shopErectionUpdateForm"
        onSubmit={id ? handlePostUpdate : handlePostSubmit}
      >
        <div className="shopErectionUpdateLeft">
          <div className="productFormLeft">
            <label>Title</label>
            <input
              type="text"
              placeholder="Product xxx"
              name="title"
              value={values.title}
              onChange={handleInputChange}
              className="shopErectionUpdateInput"
            />

            <label>Category</label>
            <select
              style={{ maxWidth: '250px' }}
              className=""
              name="postcategory"
              value={values.postcategory}
              onChange={handleInputChange}
              id="hub"
            >
              <option value="">Select an option</option>
              {postcats.map((cat) => (
                <option key={cat._id} value={cat._id}>
                  {cat.name}
                </option>
              ))}
            </select>
          </div>

          <div className="shopErectionUpdateItem">
            <label>Descripttion</label>
            <textarea
              style={{ maxWidth: '400px' }}
              id="summary-ckeditor"
              name="content"
              value={values.content}
              onChange={handleInputChange}
              className="form-control col-md-12"
              placeholder="Description"
            ></textarea>
          </div>
        </div>

        <div className="shopErectionUpdateRight">
          <div className="shopErectionUpdateUpload">
            {values.featuredImage && (
              <img
                className="userUpdateImg"
                src={values.featuredImage}
                alt=""
              />
            )}
            <label htmlFor="file">
              <Publish className="shopErectionUpdateIcon" />
            </label>

            <input
              type="file"
              id="file"
              style={{ display: 'none' }}
              name="file"
              // value={values.adImage}
              onChange={(e) => setFile(e.target.files[0])}
            />
          </div>

          <Link to={'/listposts'} className="shopErectionUpdateButton">
            Cancel
          </Link>
          <button className="shopErectionUpdateButton">Add Post</button>
        </div>
      </form> */}

      <form
        onSubmit={id ? handlePostUpdate : handlePostSubmit}
        className="ps-form--account-setting"
      >
        {/* confirmUpdate  updateUserDetails*/}
        <div className="ps-form__header">
          <h3>Account Information</h3>
        </div>
        <div className="ps-form__content">
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                {/* <input
                  className="form-control"
                  type="text"
                  name="name"
                  placeholder="First name"
                  // value={values.name}
                  required
                  onChange={handleInputChange}
                /> */}
                <input
                  className="form-control"
                  type="text"
                  placeholder="Post Title"
                  name="title"
                  value={values?.title}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <select
                  style={{ maxWidth: '250px' }}
                  className="form-control"
                  name="postcategory"
                  value={values?.postcategory}
                  onChange={handleInputChange}
                  id="postcategory"
                >
                  <option value="">Select an option</option>
                  {postcats.map((cat) => (
                    <option key={cat._id} value={cat._id}>
                      {cat.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* <div className="col-sm-6">
                        <div className="form-group">
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Last name"
                            />
                        </div>
                    </div> */}

            {/* <div className="col-sm-6">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="phone"
                  // value={values?.phone}
                  placeholder="Phone Number"
                  required
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Postal Code"
                  name="postalCode"
                  // value={values?.postalCode}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Address"
                  name="address"
                  // value={values?.address}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="city"
                  placeholder="City"
                  // value={values?.city}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="country"
                  placeholder="Country"
                  // value={values?.country}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="col-sm-12">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  // placeholder="Address"
                  // value={values?.referralcode}
                  name="referralcode"
                  disabled={true}
                  onChange={handleInputChange}
                />
              </div>
            </div> */}

            <hr />
            <div className="col-sm-6">
              <div className="form-group">
                {/* <input
                  className="form-control mb-1"
                  type="file"
                  name="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  id="file"
                  style={{ display: 'none' }}
                  placeholder=""
                />

                <label htmlFor="file">
                  <CloudUploadOutlined className="ps-btn" />
                </label> */}
                <label htmlFor="file">
                  <Publish className="shopErectionUpdateIcon" />
                </label>

                {/* <label htmlFor="file">
                  <CloudUploadOutlined className="ps-btn" />
                </label> */}

                <input
                  type="file"
                  id="file"
                  style={{ display: 'none' }}
                  name="file"
                  // value={values.adImage}
                  onChange={(e) => setFile(e.target.files[0])}
                />
                {file && <span>{file.name}</span>}

                {/* <input
                                className="form-control"
                                type="file"
                                name="file"
                                value={values?.profile.avatar}
                                // placeholder="City"
                                onChange={handleInputChange}
                            /> */}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                {values?.featuredImage && (
                  <img
                    className="userUpdateImg"
                    src={values?.featuredImage}
                    alt=""
                  />
                )}
                {/* <img
                  style={{ borderRadius: '50%' }}
                  height={50}
                  src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.mm.bing.net%2Fth%3Fid%3DOIP.OlnxO753VRgHKDLLDzCKoAHaHw%26pid%3DApi&f=1"
                /> */}
                {/* <input
                                className="form-control"
                                type="text"
                                placeholder="Country"
                            /> */}
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <textarea
                  id="summary-ckeditor"
                  className="form-control"
                  type="text"
                  name="content"
                  value={values?.content}
                  // value={values?.aboutme}
                  onChange={handleInputChange}
                  rows="6"
                ></textarea>
              </div>
            </div>

            {/* <div className="col-sm-12">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Facebook"
                  name="facebook"
                  // value={values?.facebook}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Instagram"
                  name="instagram"
                  // value={values?.instagram}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Twitter"
                  name="twitter"
                  // value={values?.twitter}
                  onChange={handleInputChange}
                />
              </div>
            </div> */}
          </div>

          <div className="form-group submit">
            <Link to={'/listposts'} className="btn">
              Cancel
            </Link>
            <button className="ps-btn">Add/Update Post</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PostForm;
