import { DeleteOutline, Publish } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import useForm from '../../hooks/useForm';
import { AuthApi } from '../../apiconstant/Api';
import { useParams, useHistory, Redirect, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  createDepart,
  getDepartWithId,
  updateDepart,
} from '../../store/departments/repository_dept';
import {
  featureMarket,
  getDeptById,
  getMarketById,
  unFeatureMarket,
} from '../../store/api/apiRoutes';
import { getDepartments } from '../../store/departments/repository_dept';
import { getCountries } from '../../store/country/repository_country';
import {
  createMarketRepo,
  getMarkets,
  updateMarkets,
} from '../../store/markets/repository_market';
import { getStates } from '../../store/states/repository_states';
import { getMarketCats } from '../../store/marketcats/repository_marketcat';

// const initialFValues = {
//     id:0,
//     product:{},
//   }

const initialFValues = {
  id: 0,
  name: '',
  businesscountry: '',
  businezstate: '',
  marketcategory: '',
  zipCode: '',
  localityaddress: '',
  marketbanner: '',
};

const MarketForm = () => {
  const { values, setValues, handleInputChange, resetForm } =
    useForm(initialFValues);
  const params = useParams();
  const { id } = params;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [countryId, setCountryId] = useState('');

  const { countries, isFetching } = useSelector((state) => state.countries);
  console.log('StateCountyMrkt:', countries);

  const { bstates } = useSelector((state) => state.bstates);
  const { marketcategories } = useSelector((state) => state.marketcats);

  useEffect(() => {
    dispatch(getCountries());
    dispatch(getStates());

    dispatch(getMarketCats());

    if (id) {
      getMarketData();
    }
  }, [dispatch, id, setValues]);

  const getMarketData = async () => {
    setLoading(true);
    try {
      if (id) {
        await getMarketById(id).then((response) => {
          // setDepartment(response.data);
          setValues(response.data);
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleMarketSubmit = async (e) => {
    e.preventDefault();

    try {
      dispatch(createMarketRepo(values));
      // alert('Market Added');
      resetForm();
    } catch (error) {
      console.log({ message: error.message });
    }

    resetForm();
  };

  const handleMarketUpdate = async (e) => {
    e.preventDefault();

    try {
      dispatch(updateMarkets(id, values));
      e.stopPropagation();
      // alert('Department Updated');
    } catch (error) {
      console.log({ message: error.message });
    }

    // resetForm();
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    alert('Deleted');
  };

  const handleFeatureMarket = async (e) => {
    e.preventDefault();

    if (window.confirm('Feature Category?')) {
      try {
        setLoading(true);
        //adminMakeHomeSlider
        const { data } = await featureMarket(values._id);

        if (data) {
          getMarketData();
          setTimeout(
            function () {
              setLoading(false);
            }.bind(this),
            250
          );
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        //console.log({ error: JSON.stringify(error) });
        //{ error: error.message })
        // console.log({ error: error.message });
        console.log(
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        );
      }
    }
  };

  const handleUnFeatureMarket = async (e) => {
    e.preventDefault();

    if (window.confirm('Un-Feature Category?')) {
      try {
        setLoading(true);
        //adminMakeHomeSlider
        const { data } = await values._id;

        if (data) {
          getMarketData();
          setTimeout(
            function () {
              setLoading(false);
            }.bind(this),
            250
          );
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        );
      }
    }
  };

  return (
    <div className="shopErectionUpdate">
      <span className="shopErectionUpdateTitle">Create/Edit a Market</span>
      {id && values.isFeatured && (
        <button
          className="btn btn-warning"
          onClick={(e) => {
            handleUnFeatureMarket(e);
          }}
        >
          {loading ? 'processing...' : 'UnFeature Market'}
        </button>
      )}
      {id && !values.isFeatured && (
        <button
          className="btn btn-warning"
          onClick={(e) => {
            handleFeatureMarket(e);
          }}
        >
          {loading ? 'processing...' : 'Feature Market'}
        </button>
      )}

      {id && (
        <span className="right">
          Delete
          <DeleteOutline
            className="userListDelete right"
            onClick={(e) => handleDelete(e)}
          />
        </span>
      )}

      <hr />
      <form
        onSubmit={id ? handleMarketUpdate : handleMarketSubmit}
        className="ps-form--account-setting"
      >
        <div className="col-sm-12">
          <div className="form-group">
            <label>Country</label>
            <select
              // style={{ maxWidth: '250px' }}
              className="form-control"
              name="businesscountry"
              value={values.businesscountry}
              onChange={handleInputChange}
              id="hub"
            >
              <option value="">Select an option</option>
              {countries.map((cty) => (
                <option value={cty._id}>{cty.name}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-sm-12">
          <div className="form-group">
            <label>State</label>
            <select
              // style={{ maxWidth: '250px' }}
              className="form-control"
              name="businezstate"
              value={values.businezstate}
              onChange={handleInputChange}
              id="category"
            >
              <option value="">Select an option</option>
              {bstates.map((bst) => (
                <option value={bst._id}>{bst.name}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-sm-12">
          <div className="form-group">
            <label>Market Category</label>
            <select
              // style={{ maxWidth: '250px' }}
              className="form-control"
              name="marketcategory"
              value={values.marketcategory}
              onChange={handleInputChange}
              id="category"
            >
              <option value="">Select an option</option>
              {marketcategories.map((mktcat) => (
                <option value={mktcat._id}>{mktcat.name}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="ps-form__content">
          <div className="col-sm-12">
            <div className="form-group">
              <label>Market name</label>
              <input
                type="text"
                name="name"
                value={values.name}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
          </div>
        </div>

        <div className="ps-form__content">
          <div className="col-sm-12">
            <div className="form-group">
              <label>Zipcode</label>
              <input
                type="text"
                placeholder="Zip xxx"
                name="zipCode"
                value={values.zipCode}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
          </div>
        </div>

        <div className="ps-form__content">
          <div className="col-sm-12">
            <div className="form-group">
              <label>Locality Address</label>
              <input
                type="text"
                name="localityaddress"
                value={values.localityaddress}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
          </div>
        </div>

        <div className="form-group submit">
          <Link to={'/listmarkets'} className="btn">
            Cancel
          </Link>
          <button className="ps-btn">Add/Update Market</button>
        </div>
      </form>
    </div>
  );
};

export default MarketForm;
