import React, { useEffect, useState } from 'react';
import ShopRightbar from '../../components_shops/shoprightbar/ShopRightbar';
// import "./usersCustomer.scss"
import {
  CalendarToday,
  DeleteOutline,
  LocationSearching,
  MailOutline,
  PermIdentity,
  PhoneAndroid,
  Publish,
} from '@material-ui/icons';
import { useParams, useHistory, Redirect, Link } from 'react-router-dom';
import useForm from '../../hooks/useForm';
import {
  createAd,
  getAdById,
  getDeptById,
  getTradehubs,
  updateAdById,
} from '../../store/api/apiRoutes';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import app from '../../firebase';
import { useSelector } from 'react-redux';

const initialFValues = {
  id: 0,
  title: '',
  tradehub: '',
  adImage: '',
  hyperlink: '',
  shortText: '',
};

const AdsFormPane = () => {
  const { values, setValues, handleInputChange, handleFile, resetForm } =
    useForm(initialFValues);
  const [file, setFile] = useState(null);
  const { id } = useParams();
  const history = useHistory();
  const [hubs, setHubs] = useState([]);
  // const { hubs } = useSelector((state) => state.hubs);

  useEffect(() => {
    const getTradeHubs = async () => {
      const tradehubs = await getTradehubs().then((response) => {
        setHubs(response.data.data);
      });
    };

    getTradeHubs();
  }, []);

  useEffect(() => {
    if (id) {
      getAdData();
    }
  }, [id, setValues]);
  console.log('HUBS:', hubs);

  const getAdData = async () => {
    // const tradehubs = await getTradehubs().then((response) => {
    //   setHubs(response.data.data);
    // });

    try {
      if (id) {
        console.log('ads IDDDDS:', await getAdById(id));
        // return;
        await getAdById(id).then((response) => {
          console.log('ADS_Response', response);
          setValues(response.data);
        });
      }
    } catch (error) {
      console.log(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  };

  console.log('GET ADS DETAILS', values);

  const handleAdSubmit = async (e) => {
    e.preventDefault();

    if (file) {
      const fileName = new Date().getTime() + file.name;
      const storage = getStorage(app);
      // const storageRef = ref(storage, fileName);
      const storageRef = ref(storage, `/ads/${fileName}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion

      // start here

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
            default:
          }
        },
        (error) => {
          // Handle unsuccessful uploads
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log('valueWithImageFile :', values, {
              adImage: downloadURL,
            });
            const advert = { ...values, adImage: downloadURL };

            createAd(advert).then((response) => {
              if (response.data.error) {
                // console.log(response.data.error);
              } else {
                // alert(response.data.success);
                alert('Ad added');
                // resetForm();
              }
            });
          });
        }
      );
    } else {
      // console.log('valueWithOutImageFile :', values);
      createAd(values).then((response) => {
        if (response.data.error) {
          // console.log(response.data.error);
        } else {
          // alert(response.data.success);
          alert('Ad added');
          // resetForm();
        }
      });
    }
  };

  const handleAdUpdate = async (e) => {
    e.preventDefault();

    try {
      // console.log('storage values are :', values);
      // console.log('UpdateRoutes', updateShopPlanById);
      // await updateAdById(id, values).then((response) => {
      //   if (response.data.error) {
      //     console.log(response.data.error);
      //   } else {
      //     //  alert('department updated');
      //     console.log(response.data);
      //     alert('Plan updated');
      //     history.push('/listplans');
      //     // resetForm();
      //   }
      // });
    } catch (error) {
      console.log({ message: error.message });
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    alert('Deleted');
  };

  return (
    <>
      <div className="user">
        <div className="userContainer">
          <div className="userShow">
            <div className="userShowTop">
              <img
                src="https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
                alt=""
                className="userShowImg"
              />
              <div className="userShowTopTitle">
                <span className="userShowUsername">Anna Becker</span>
                <span className="userShowUserTitle">Software Engineer</span>
              </div>
            </div>
            <div className="userShowBottom">
              <span className="userShowTitle">Account Details</span>
              <div className="userShowInfo">
                <PermIdentity className="userShowIcon" />
                <span className="userShowInfoTitle">annabeck99</span>
              </div>
              <div className="userShowInfo">
                <CalendarToday className="userShowIcon" />
                <span className="userShowInfoTitle">10.12.1999</span>
              </div>
              <span className="userShowTitle">Contact Details</span>
              <div className="userShowInfo">
                <PhoneAndroid className="userShowIcon" />
                <span className="userShowInfoTitle">+1 123 456 67</span>
              </div>
              <div className="userShowInfo">
                <MailOutline className="userShowIcon" />
                <span className="userShowInfoTitle">annabeck99@gmail.com</span>
              </div>
              <div className="userShowInfo">
                <LocationSearching className="userShowIcon" />
                <span className="userShowInfoTitle">New York | USA</span>
              </div>
            </div>
          </div>
          <div className="userUpdate">
            {/* <span className="userUpdateTitle">Manage Ads</span>
            <form
              className="userUpdateForm"
              onSubmit={id ? handleAdUpdate : handleAdSubmit}
            >
              <div className="userUpdateLeft">
                <div className="userUpdateItem">
                  <label>Title</label>
                  <input
                    type="text"
                    placeholder="annabeck99"
                    className="userUpdateInput"
                    name="title"
                    value={values.title}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="userUpdateItem">
                  <label>Hub </label>
                  <select
                    name="tradehub"
                    value={values.tradehub}
                    onChange={handleInputChange}
                    id="idStock"
                  >
                    <option value="">Select a corresponding Hub for ads</option>
                    {hubs.map((hub) => (
                      <option key={hub._id} value={hub._id}>
                        {hub.hubname}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="userUpdateItem">
                  <label>Ads Link</label>
                  <input
                    type="text"
                    placeholder="annabeck99"
                    className="userUpdateInput"
                    name="hyperlink"
                    value={values.hyperlink}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="shopErectionUpdateItem">
                  <label>Descripttion</label>
                  <textarea
                    style={{ maxWidth: '400px' }}
                    id="summary-ckeditor"
                    name="shortText"
                    value={values.shortText}
                    onChange={handleInputChange}
                    className="form-control col-md-12"
                    placeholder="Description"
                  ></textarea>
                </div>
              </div>
              <div className="userUpdateRight">
                <div className="userUpdateUpload">
                  {values.adImage && (
                    <img
                      className="userUpdateImg"
                      src={values.adImage}
                      alt=""
                    />
                  )}

                  <label htmlFor="file">
                    <Publish className="userUpdateIcon" />
                  </label>
                  <input
                    type="file"
                    id="file"
                    style={{ display: 'none' }}
                    name="file"
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </div>
                <button className="userUpdateButton">Submit</button>
              </div>
            </form> */}

            <span className="shopErectionUpdateTitle">Create/Edit Ads</span>
            {id && (
              <span className="right">
                Delete
                <DeleteOutline
                  className="userListDelete right"
                  onClick={(e) => handleDelete(e)}
                />
              </span>
            )}

            <hr />
            <form
              onSubmit={id ? handleAdUpdate : handleAdSubmit}
              className="ps-form--account-setting"
            >
              <div className="ps-form__content">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Title</label>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        value={values.title}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Hub </label>
                      <select
                        name="tradehub"
                        value={values.tradehub}
                        onChange={handleInputChange}
                        id="idStock"
                        className="form-control"
                      >
                        <option value="">
                          Select a corresponding Hub for ads
                        </option>
                        {hubs.map((hub) => (
                          <option key={hub._id} value={hub._id}>
                            {hub.hubname}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Ads Link</label>
                      <input
                        type="text"
                        className="form-control"
                        name="hyperlink"
                        value={values.hyperlink}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  {/* <div className="col-sm-12">
                    <div className="form-group"></div>
                  </div> */}

                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Descripttion</label>
                      <textarea
                        id="summary-ckeditor"
                        name="shortText"
                        value={values.shortText}
                        onChange={handleInputChange}
                        className="form-control col-md-12"
                      ></textarea>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <label> Post Image</label>
                    <div className="form-group">
                      <label htmlFor="file">
                        <Publish className="shopErectionUpdateIcon" />
                      </label>

                      <input
                        className="form-control"
                        type="file"
                        id="file"
                        style={{ display: 'none' }}
                        name="file"
                        onChange={(e) => setFile(e.target.files[0])}
                      />
                      {file && <span>{file.name}</span>}
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-group">
                      {values.adImage && (
                        <img
                          className="userUpdateImg"
                          src={values.adImage}
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group submit">
                <Link to={'/listads'} className="btn">
                  Cancel
                </Link>
                <button className="ps-btn">Add/Update Ads</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdsFormPane;
