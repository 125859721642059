import React, { useEffect, useState } from 'react';
import ShopRightbar from '../../components_shops/shoprightbar/ShopRightbar';
import './usersCustomer.scss';
import {
  CalendarToday,
  LocationSearching,
  MailOutline,
  PermIdentity,
  PhoneAndroid,
  Publish,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import UserCustomerForm from './UserCustomerform';
import {
  adminBlockDisciplineUser,
  adminSuspendDisciplineUser,
  adminUnBlockDisciplineUser,
  adminUnSuspendDisciplineUser,
  getApiBannerById,
  getApiUserById,
} from '../../store/api/apiRoutes';
import { useParams } from 'react-router-dom';
import useForm from '../../hooks/useForm';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const initialFValues = {
  id: 0,
  name: '',
  email: '',
  avatar: '',
};

const UsersCustomer = () => {
  const params = useParams();

  const [loading, setLoading] = useState(false);
  // const [post, setPost] = useState({});

  const { values, setValues, handleInputChange, handleFile, resetForm } =
    useForm(initialFValues);
  const [file, setFile] = useState(null);
  const { id } = params;
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      getUserDataForDiscipline();
    }
  }, [id, dispatch]);

  const getUserDataForDiscipline = async () => {
    setLoading(true);
    try {
      if (id) {
        await getApiUserById(id).then((response) => {
          setValues(response.data);

          // console.log('API USERDETAILS', response.data);
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleUserBlockUpdate = async (e) => {
    e.preventDefault();

    if (window.confirm('Block User?')) {
      //adminBlockDisciplineUser

      try {
        setLoading(true);

        const { data } = await adminBlockDisciplineUser(values._id);

        if (data) {
          getUserDataForDiscipline();
          setTimeout(
            function () {
              setLoading(false);
            }.bind(this),
            250
          );
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        //console.log({ error: JSON.stringify(error) });
        //{ error: error.message })
        // console.log({ error: error.message });
        console.log(
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        );
      }
    }
  };

  //Lift User Block
  const handleUserUnBlockUpdate = async (e) => {
    e.preventDefault();

    if (window.confirm('UnBlock User?')) {
      try {
        setLoading(true);

        const { data } = await adminUnBlockDisciplineUser(values._id);
        if (data) {
          getUserDataForDiscipline();
          setTimeout(
            function () {
              setLoading(false);
            }.bind(this),
            250
          );
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        // console.log({ error: JSON.stringify(error) });
        console.log(
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        );
      }
    }
  };

  //Handle User Suspension
  const handleUserSuspendUpdate = async (e) => {
    e.preventDefault();

    if (window.confirm('Suspend User?')) {
      try {
        setLoading(true);

        const { data } = await adminSuspendDisciplineUser(values._id);

        if (data) {
          getUserDataForDiscipline();

          setTimeout(
            function () {
              setLoading(false);
            }.bind(this),
            250
          );
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);

        console.log(
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        );
      }
    }
  };

  //Lift User Suspension
  const handleUserUnSuspendUpdate = async (e) => {
    e.preventDefault();

    if (window.confirm('Lift Suspension?')) {
      try {
        setLoading(true);

        const { data } = await adminUnSuspendDisciplineUser(values._id);

        if (data) {
          getUserDataForDiscipline();
          setTimeout(
            function () {
              setLoading(false);
            }.bind(this),
            250
          );
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        );
      }
    }
  };

  return (
    <>
      {/* Changing pages rendering starts here */}

      <>
        <div className="shoppingRight">
          <div className="shoppingRightBottom">
            {/* page starts */}
            <div className="user">
              <div className="userTitleContainer">
                <h1 className="userTitle">Edit User</h1>
              </div>
              <div className="userContainer">
                <div className="userShow">
                  <div className="userShowTop">
                    <img src={values?.avatar} alt="" className="userShowImg" />
                    <div className="userShowTopTitle">
                      <span className="userShowUsername">{values?.name}</span>
                      <span className="userShowUserTitle">
                        Software Engineer
                      </span>
                    </div>
                  </div>
                  <div className="userShowBottom">
                    <span className="userShowTitle">Account Details</span>
                    {/* <div className="userShowInfo">
                      <PermIdentity className="userShowIcon" />
                      <span className="userShowInfoTitle">annabeck99</span>
                    </div> */}
                    <div className="userShowInfo">
                      <CalendarToday className="userShowIcon" />
                      <span className="userShowInfoTitle">
                        {values?.createdAt}
                      </span>
                    </div>
                    <span className="userShowTitle">Contact Details</span>
                    <div className="userShowInfo">
                      <PhoneAndroid className="userShowIcon" />
                      <span className="userShowInfoTitle">{values?.phone}</span>
                    </div>
                    <div className="userShowInfo">
                      <MailOutline className="userShowIcon" />
                      <span className="userShowInfoTitle">{values?.email}</span>
                    </div>
                    <div className="userShowInfo">
                      <LocationSearching className="userShowIcon" />
                      <span className="userShowInfoTitle">
                        {values?.address} | country
                      </span>
                    </div>
                  </div>
                </div>

                <div className="userUpdate">
                  <span className="userUpdateTitle">Discipline User </span>
                  {/* <div className="ps-form__header">
                    <h3>Discipline User </h3>
                  </div> */}

                  <UserCustomerForm
                    handleUserBlockUpdate={handleUserBlockUpdate}
                    handleUserUnBlockUpdate={handleUserUnBlockUpdate}
                    handleUserSuspendUpdate={handleUserSuspendUpdate}
                    handleUserUnSuspendUpdate={handleUserUnSuspendUpdate}
                    values={values}
                    handleInputChange={handleInputChange}
                    id={id}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
            {/* page ends         */}
          </div>
        </div>

        <>
          <ShopRightbar Shop />
        </>
      </>
      {/* Changing pages rendering ends here */}
    </>
  );
};

export default UsersCustomer;
