export const actionTypes = {
  COUNTRY_REQUEST: 'COUNTRY_REQUEST',
  COUNTRY_SUCCESS: 'COUNTRY_SUCCESS',
  COUNTRY_FAILURE: 'COUNTRY_FAILURE',

  GET_COUNTRY_REQUEST: 'GET_COUNTRY_REQUEST',
  GET_COUNTRY_SUCCESS: 'GET_COUNTRY_SUCCESS',
  GET_COUNTRY_FAILURE: 'GET_COUNTRY_FAILURE',

  UPDATE_COUNTRY_REQUEST: 'UPDATE_COUNTRY_REQUEST',
  UPDATE_COUNTRY_SUCCESS: 'UPDATE_COUNTRY_SUCCESS',
  UPDATE_COUNTRY_FAILURE: 'UPDATE_COUNTRY_FAILURE',

  CREATE_COUNTRY_REQUEST: 'CREATE_COUNTRY_REQUEST',
  CREATE_COUNTRY_SUCCESS: 'CREATE_COUNTRY_SUCCESS',
  CREATE_COUNTRY_FAILURE: 'CREATE_COUNTRY_FAILURE',

  //whenloading
  END_LOADING: 'END_LOADING',
  START_LOADING: 'START_LOADING',
};

export function country() {
  return { type: actionTypes.COUNTRY_REQUEST };
}

export function countrySuccess(countries) {
  return { type: actionTypes.COUNTRY_SUCCESS, payload: countries.data };
}

export function countryFailure() {
  return { type: actionTypes.COUNTRY_FAILURE };
}

//Get Department by ID:
export function getCountryById() {
  return { type: actionTypes.GET_COUNTRY_REQUEST };
}

export function getCountryByIdSuccess(department) {
  return { type: actionTypes.GET_COUNTRY_SUCCESS, payload: department };
}

export function getCountryByIdFailure() {
  return { type: actionTypes.GET_COUNTRY_FAILURE };
}

//Update Department by ID:
export function updateCountry() {
  return { type: actionTypes.UPDATE_COUNTRY_REQUEST };
}

export function updateCountrySuccess(data) {
  return {
    type: actionTypes.UPDATE_COUNTRY_SUCCESS,
    payload: data,
  };
}

export function updateCountryFailure() {
  return { type: actionTypes.UPDATE_COUNTRY_FAILURE };
}

//create new Department:
export function createCountry() {
  return { type: actionTypes.CREATE_COUNTRY_REQUEST };
}

export function createCountrySuccess(data) {
  return {
    type: actionTypes.CREATE_COUNTRY_SUCCESS,
    payload: data,
  };
}

export function createCountryFailure() {
  return { type: actionTypes.CREATE_COUNTRY_FAILURE };
}

// export function logOut() {
//   return { type: actionTypes.LOG_OUT };
// }
