export const actionTypes = {
  FAQ_REQUEST: 'FAQ_REQUEST',
  FAQ_SUCCESS: 'FAQ_SUCCESS',
  FAQ_FAILURE: 'FAQ_FAILURE',

  GET_FAQ_REQUEST: 'GET_FAQ_REQUEST',
  GET_FAQ_SUCCESS: 'GET_FAQ_SUCCESS',
  GET_FAQ_FAILURE: 'GET_FAQ_FAILURE',

  UPDATE_FAQ_REQUEST: 'UPDATE_FAQ_REQUEST',
  UPDATE_FAQ_SUCCESS: 'UPDATE_FAQ_SUCCESS',
  UPDATE_FAQ_FAILURE: 'UPDATE_FAQ_FAILURE',

  CREATE_FAQ_REQUEST: 'CREATE_FAQ_REQUEST',
  CREATE_FAQ_SUCCESS: 'CREATE_FAQ_SUCCESS',
  CREATE_FAQ_FAILURE: 'CREATE_FAQ_FAILURE',

  //whenloading
  END_LOADING: 'END_LOADING',
  START_LOADING: 'START_LOADING',
};

export function faq() {
  return { type: actionTypes.FAQ_REQUEST };
}

export function faqSuccess(data) {
  return { type: actionTypes.FAQ_SUCCESS, payload: data.data };
}

export function faqFailure() {
  return { type: actionTypes.FAQ_FAILURE };
}

//Get FAQ by ID:
export function getFaqById() {
  return { type: actionTypes.GET_FAQ_REQUEST };
}

export function getFaqByIdSuccess(Faq) {
  return { type: actionTypes.GET_FAQ_SUCCESS, payload: Faq };
}

export function getFaqByIdFailure() {
  return { type: actionTypes.GET_FAQ_FAILURE };
}

//Update FAQ by ID:
export function updateFaq() {
  return { type: actionTypes.UPDATE_FAQ_REQUEST };
}

export function updateFaqSuccess(data) {
  return {
    type: actionTypes.UPDATE_FAQ_SUCCESS,
    payload: data,
  };
}

export function updateFaqFailure() {
  return { type: actionTypes.UPDATE_FAQ_FAILURE };
}

//create new FAQ:
export function createFaq() {
  return { type: actionTypes.CREATE_FAQ_REQUEST };
}

export function createFaqSuccess(data) {
  return {
    type: actionTypes.CREATE_FAQ_SUCCESS,
    payload: data,
  };
}

export function createFaqFailure() {
  return { type: actionTypes.CREATE_FAQ_FAILURE };
}
