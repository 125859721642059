import React from 'react';

const ModuleOrderBillingInformation = ({ order }) => {
  return (
    <div className="ps-card ps-card--order-information ps-card--small">
      <div className="ps-card__header">
        <h4>Billing Information</h4>
      </div>
      <div className="ps-card__content">
        <p>
          <strong>Payment Status:</strong> {order?.isPaid ? 'Paid' : 'Not Paid'}
        </p>
        <p>
          <strong>Payment Type:</strong> Visa
        </p>
        <p>
          <strong>Provider:</strong> **** **** **** *2121
        </p>
        <p>
          <strong>Valid Date:</strong> {order.paidAt}
        </p>
      </div>
    </div>
  );
};

export default ModuleOrderBillingInformation;
