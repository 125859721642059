import React, { useEffect, useState } from 'react';
import './manageshop.scss';
import ShopRightbar from '../../../components_shops/shoprightbar/ShopRightbar';
import {
  CalendarToday,
  LocationSearching,
  MailOutline,
  PermIdentity,
  PhoneAndroid,
  Publish,
} from '@material-ui/icons';
import { Link, useParams, useHistory } from 'react-router-dom';
import Chart from '../../../components_shops/chart/Chart';
import { productData } from '../../../dummyData';
import {
  adminFinanceApproveWithdrawals,
  adminFinancePayoutWithdrawals,
  adminFinanceGetWithdrawalsById,
} from '../../../store/api/apiRoutes';
import { Paper, CircularProgress } from '@material-ui/core/';

const ManageWithdrawals = () => {
  const router = useHistory();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState({});

  useEffect(() => {
    // const data = dispatch(getShops());

    fetchSingleWithdrawal();
    // setPagePosts(data);
  }, [id]);

  const fetchSingleWithdrawal = async () => {
    setLoading(true);

    const { data } = await adminFinanceGetWithdrawalsById(id);
    if (data) {
      setOrder(data);

      // Cookies.remove('cart');
      // setCookie('cart', [], { path: '/' });
      // dispatch(setCartItemsSuccess([]));
      setTimeout(
        function () {
          setLoading(false);
        }.bind(this),
        250
      );
    } else {
      setLoading(false);
    }
    console.log('adminSingleWithdraw', data);

    console.log('adminSingleWithdraw2Check', order);

    // SetLoading(false);
  };

  const handlePackApproval = async (e) => {
    e.preventDefault();
    console.log('Your order is packaged');

    try {
      setLoading(true);

      const { data } = await adminFinanceApproveWithdrawals(order._id);
      console.log('WithdrawalSuccessData', data);
      // return;
      if (data) {
        fetchSingleWithdrawal();

        setTimeout(
          function () {
            setLoading(false);
            // router.push('/finance/withdrwals'); handlePayOut
          }.bind(this),
          250
        );
      }
    } catch (error) {
      setLoading(false);
      console.log({ error: JSON.stringify(error) });
    }
  };

  const handlePayOut = async (e) => {
    e.preventDefault();
    console.log('Your order is packaged');

    try {
      setLoading(true);

      const { data } = await adminFinancePayoutWithdrawals(order._id);
      console.log('WithdrawalSuccessData', data);
      // return;
      if (data) {
        fetchSingleWithdrawal();

        setTimeout(
          function () {
            setLoading(false);
            // router.push('/finance/withdrwals');
          }.bind(this),
          250
        );
      }
    } catch (error) {
      setLoading(false);
      console.log({ error: JSON.stringify(error) });
    }
  };

  //   const handleShip = async (e) => {
  //     e.preventDefault();
  //     console.log('Your order is shipped');
  //     try {
  //       setLoading(true);
  //       const { data } = await adminShipOrders(order._id);
  //       if (data) {
  //         fetchSingleWithdrawal();
  //         setTimeout(
  //           function () {
  //             setLoading(false);
  //           }.bind(this),
  //           250
  //         );
  //       }
  //     } catch (error) {
  //       setLoading(false);
  //       console.log({ error: JSON.stringify(error) });
  //     }
  //   };

  //   const handleDelivery = async (e) => {
  //     e.preventDefault();
  //     console.log('Your order is delivered');

  //     if (window.confirm('Deliver Order?')) {
  //       try {
  //         setLoading(true);
  //         console.log('orderIDToDeliver', order._id);

  //         const { data } = await adminDeliverOrders(order._id);
  //         if (data) {
  //           fetchSingleWithdrawal();
  //           setTimeout(
  //             function () {
  //               setLoading(false);
  //             }.bind(this),
  //             250
  //           );
  //         }
  //       } catch (error) {
  //         setLoading(false);
  //         console.log({ error: JSON.stringify(error) });
  //       }
  //     }
  //   };

  //   const CompleteOrderAndCashout = async (e) => {
  //     e.preventDefault();
  //     console.log('Order Completed and Cash recovered');
  //     try {
  //       setLoading(true);
  //       const { data } = await adminFinanceCompleteOrders(order._id);

  //       if (data) {
  //         console.log('Ending Function');
  //         setTimeout(
  //           function () {
  //             setLoading(false);
  //           }.bind(this),
  //           250
  //         );
  //       }
  //     } catch (error) {
  //       setLoading(false);
  //       console.log({ error: JSON.stringify(error) });
  //     }
  //   };

  return (
    <>
      {/* Changing pages rendering starts here */}

      <>
        <div className="shopRight">
          <div className="shopBottom">
            <div className="product">
              <div className="productTop">
                <div className="productTopLeft">
                  <Chart
                    data={productData}
                    dataKey="Sales"
                    title="Sales Performance"
                  />
                </div>
                <div className="productTopRight">
                  <div className="productInfoTop">
                    <img
                      src="https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
                      alt=""
                      className="productInfoImg"
                    />
                    <span className="productName">
                      Order: {order.refOrderId}
                    </span>
                  </div>
                  <div
                    className="productInfoBottom"
                    // style={{ maxHeight: '200px' }}
                  >
                    <div className="productInfoItem">
                      <span className="productInfoKey">Withdrawal_id: </span>
                      <span className="productInfoValue">{order._id}</span>
                    </div>
                    {/* <div className="productInfoItem">
                      <span className="productInfoKey">ref_id: </span>
                      <span className="productInfoValue">
                        {order.refOrderId}
                      </span>
                    </div> */}
                    {/* <div className="productInfoItem">
                      <span className="productInfoKey">Name:</span>
                      <span className="productInfoValue">
                        {order.accountname}
                      </span>
                    </div> */}
                    <div className="productInfoItem">
                      <span className="productInfoKey">Account</span>
                      <span className="productInfoValue">
                        {order.accountnumber}
                      </span>
                    </div>
                    <div className="productInfoItem">
                      <span className="productInfoKey">Amount:</span>
                      <span className="productInfoValue">
                        N{order.amount}.00
                      </span>
                      {/* <p>{JSON.stringify(order, 0, null)}</p> */}
                    </div>
                  </div>
                </div>
              </div>
              <br />
              {loading ? (
                <>
                  {/* <Paper elevation={6} className="loadingPaper">
                  <CircularProgress size="7em" className="loadingPaper" />
                </Paper> */}
                  <CircularProgress size="7em" className="loadingPaper" />
                </>
              ) : (
                <div className="productBottom">
                  <section className="ps-dashboard">
                    <div className="ps-section__left">
                      <div className="ps-card ps-card--track-order">
                        <div className="ps-card__header">
                          <h4>#{order._id}</h4>
                        </div>
                        <div className="ps-card__content">
                          <div className="table-responsive">
                            <table className="table ps-table">
                              <thead>
                                <tr>
                                  <td colSpan="6">
                                    <strong>Withdrawal for Approval:</strong>
                                  </td>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td colSpan="3">
                                    <strong>Account Name:</strong>
                                  </td>
                                  <td>
                                    <strong>{order.accountname}.00</strong>
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan="3">
                                    <strong>Account Number:</strong>
                                  </td>
                                  <td>
                                    <strong>{order.accountnumber}</strong>
                                  </td>
                                </tr>
                                {/* <tr>
                                <td colSpan="3">
                                  <strong>Estimated (V.A.T) 7.5%:</strong>
                                </td>
                                <td>
                                  <strong>N{order.taxPrice}.00 </strong>
                                </td>
                              </tr> */}
                                <tr>
                                  <td colSpan="3">
                                    <strong>Withdrawal Amount:</strong>
                                  </td>
                                  <td>
                                    <strong>
                                      {/* $211.00 */}N{order.amount}.00
                                    </strong>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* right side starts */}
                    <div className="ps-section__right">
                      <div className="ps-card ps-card--track-order">
                        <div className="ps-card__header">
                          <h4>Track Withdrawal</h4>
                        </div>
                        <div className="ps-card__content">
                          <div className="ps-block--track-order">
                            <div className="ps-block__header">
                              <div className="row">
                                {/* <div className="col-6">
                                <figure>
                                  <figcaption>Order ID:</figcaption>
                                  <p>{order._id}</p>
                                </figure>
                              </div> */}
                                <div className="col-6">
                                  <figure>
                                    <figcaption>Tracking ID:</figcaption>
                                    <p>{order._id}</p>
                                  </figure>
                                </div>
                              </div>
                            </div>
                            <div className="ps-block__content">
                              <div className="ps-block__timeline">
                                <figure className="active">
                                  <figcaption>Withdrawal Placed</figcaption>
                                  <p>
                                    {order.createdAt} <small>7:00am</small>
                                  </p>
                                  <p>
                                    <small>info</small> {order.info}
                                  </p>
                                  <p>
                                    <small>trasaction</small>{' '}
                                    {order.transaction}
                                  </p>
                                </figure>

                                {order.isApprovedAndPaid ? (
                                  <>
                                    <figure className="active">
                                      <figcaption>Approved</figcaption>
                                      <p>
                                        {order.approvedAt}{' '}
                                        <small>10:00am</small>
                                      </p>
                                    </figure>
                                  </>
                                ) : (
                                  <>
                                    <figure className="inactive">
                                      <figcaption>Status</figcaption>
                                      <p>
                                        Withdrawal Has not been placed but not
                                        approved yet
                                      </p>
                                    </figure>
                                    <figure className="inactive">
                                      <button
                                        className="shopErectionUpdateButton"
                                        // className="marginTeft: 10px"
                                        onClick={handlePackApproval}
                                      >
                                        Approve Withdrawal
                                      </button>
                                    </figure>
                                  </>
                                )}

                                {order.isApprovedAndPaid && (
                                  <>
                                    <figure className="inactive">
                                      <figcaption>Status</figcaption>
                                      <p>Withdrawal Has not been approved</p>
                                    </figure>
                                    <figure className="inactive">
                                      <button
                                        className="shopErectionUpdateButton"
                                        // className="marginTeft: 10px"
                                        onClick={(e) => {
                                          handlePayOut(e);
                                        }}
                                      >
                                        Payout Earning
                                      </button>
                                    </figure>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* right side ends */}

                    <Link to="/finance/withdrwals">
                      <h4
                        style={{
                          color: 'red',
                          fontSize: '20px',
                          alignItem: 'flexEnd',
                        }}
                      >
                        X{' '}
                      </h4>
                    </Link>
                  </section>
                </div>
              )}
            </div>
          </div>
        </div>

        <>
          <ShopRightbar Shop />
        </>
      </>

      {/* Changing pages rendering ends here */}
    </>
  );
};

export default ManageWithdrawals;
