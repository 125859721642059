import React from 'react'
import "./productList.scss";
import Topbar from "../../components/topbar/Topbar"
import SocialSidebar from "../../components/socialsidebar/SocialSidebar"
import AdminBoard from "../../components_admin/adminboard/AdminBoard"
import AdminRightbar from "../../components_admin/adminrightbar/AdminRightbar";
import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline, Edit } from "@material-ui/icons";
import { productRows } from "../../dummyData";
import { Link } from "react-router-dom";
import { useState } from "react";

const ProductList = () => {

    const [data, setData] = useState(productRows);

    const handleDelete = (id) => {
      setData(data.filter((item) => item.id !== id));
    };

    const columns = [
        { field: "id", headerName: "ID", width: 90 },
        {
          field: "product",
          headerName: "Product",
          width: 150,
          renderCell: (params) => {
            return (
              <div className="productListProduct">
                <img className="productListImg" src={params.row.img} alt="" />
                {params.row.name}
              </div>
            );
          },
        },
        { field: "stock", headerName: "Status", width: 150 },
        {
          field: "status",
          headerName: "Status",
          width: 120,
        },
        {
          field: "price",
          headerName: "Price",
          width: 160,
        },
        {
          field: "action",
          headerName: "Action",
          width: 170,
          renderCell: (params) => {
            return (
              <>
                <div className="actionButtons">
                <Link to={"/customer/" + params.row.id}>
                    <Edit className="productListEditIcon"/>
                  {/* <button className="productListEdit">Edit</button> */}
                </Link>
                <DeleteOutline
                  className="productListDelete"
                  onClick={() => handleDelete(params.row.id)}
                />
                </div>
              
              </>
            );
          },
        },
      ];


    return (
        <>
         

                {/* Changing pages rendering starts here */}
                
                <>
                    {/* <div className="adminRight">
                
                        <div className="adminBottom"> */}
                            
                        <>
                        <div className="productList">
                                <div className="userTitleContainer">
                                    <h1 className="productTitle">My Products</h1>
                                    <Link to="/newProduct">
                                    <button className="userAddButton">Create</button>
                                    </Link>
                                </div>
                            
                            <div className="producttable">
                            <DataGrid className="tableProps"
                                rows={data}
                                columns={columns}
                                pageSize={8}
                                checkboxSelection
                                disableSelectionOnClick
                            />
                            </div>



                        </div>
                        </>
                        
                        {/* </div>
                       
                                            
                    </div> */}

                    <>
                    <AdminRightbar Admin/>
                    </>
                </>
                 {/* Changing pages rendering ends here */}
                
           
        </>
    )
}

export default ProductList
