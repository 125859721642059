import * as api from '../api/apiRoutes';
import { auth, authSuccess, authFailure } from './actionAuth';
const Cookies = require('js-cookie');


export const loginAdmin = (formData) => async (dispatch) => {
  try {
    //get page Barners
    dispatch(auth());
    const { data } = await api.adminSigin(formData);

    dispatch(authSuccess(data));
    // console.log('Pots here :', data);
    Cookies.set('authUserInfo', JSON.stringify(data), '1h');

    // router.push('/');
    // alert('login success');
    // console.log('see posts oh', data);
  } catch (error) {
    console.log({ error: error.message });
    dispatch(authFailure({ error: error.message }));
    alert(error);
  }
};

