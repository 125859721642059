import React, { Fragment, useEffect, useState } from 'react';
import './manageshop.scss';
import ShopRightbar from '../../../components_shops/shoprightbar/ShopRightbar';
import { DataGrid } from '@material-ui/data-grid';
import { DeleteOutline, Edit } from '@material-ui/icons';
import {
  Paper,
  Typography,
  CircularProgress,
  Divider,
} from '@material-ui/core/';
import { productRows } from '../../../dummyData';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getDepartments } from '../../../store/departments/repository_dept';
import { getShops } from '../../../store/shops/repository_shops';
import {
  adminGetOrders,
  adminDeleteOrders,
  adminFinanceGetOrders,
  adminFinanceGetWithdrawals,
} from '../../../store/api/apiRoutes';
import { Pagination, Select } from 'antd';
import TableOrdersItems from '../../../components_shops/shared/tables/TableOrdersItems';
import { SyncOutlined, PlayCircleOutlined } from '@ant-design/icons';

const { Option } = Select;
const WithdrawalList = () => {
  const dispatch = useDispatch();
  // const { shops, isFetching } = useSelector((state) => state.shops);

  const [loading, setLoading] = useState(false);
  const [withdrawals, setWithdrawals] = useState([]);

  useEffect(() => {
    // const data = dispatch(getShops());

    fetchWithdrawals();
    // setPagePosts(data);
  }, [dispatch]);

  const fetchWithdrawals = async () => {
    // e.preventDefault();
    setLoading(true);

    const { data } = await adminFinanceGetWithdrawals();
    if (data) {
      console.log('adminWithdrawalListData', data);
      setWithdrawals(data.MWithdrawals);
      // Cookies.remove('cart'); .MWithdrawals
      // setCookie('cart', [], { path: '/' });
      // dispatch(setCartItemsSuccess([]));
      setTimeout(
        function () {
          setLoading(false);
        }.bind(this),
        250
      );
    }

    // SetLoading(false);
  };
  console.log('adminWithdrawalList', withdrawals);

  // const handleDelete = (id) => {

  //   if (window.confirm('Delete?')) {
  //     setLoading(true);
  //     orders.filter((item) => item.id !== id);

  //     adminDeleteOrders(id)
  //       .then((res) => {
  //         setTimeout(
  //           function () {
  //             fetchWithdrawals();
  //             setLoading(false);
  //           }.bind(this),
  //           250
  //         );

  //         alert(`${res.data.order.refOrderId} is deleted`);
  //       })
  //       .catch((err) => {
  //         if (err.response.status === 400) alert(err.response.message);
  //         console.log(err);
  //       });
  //   }
  // };

  const Button = ({ type }) => {
    return <button className={'widgetLgButton ' + type}>{type}</button>;
  };

  const columns = [
    {
      field: 'accountnumber',
      headerName: 'Account Number',
      width: 120,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            <h6> {params.row.accountnumber}</h6>
          </div>
        );
      },
    },
    // { field: 'refOrderId', headerName: 'ID', width: 90 },
    {
      field: 'accountname',
      headerName: 'Name',
      width: 200,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            <h6> {params.row.accountname}</h6>
          </div>
        );
      },
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 120,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            <h6>N {params.row.amount}</h6>
          </div>
        );
      },
    },
    {
      field: 'isPaid',
      headerName: 'Payment Status',
      width: 150,
      renderCell: (params) => {
        return (
          <div className="widgetLgStatus">
            {params.row.isApprovedAndPaid === true ? (
              // <Button type="OrderPaid " />
              <span className="ps-badge success">Appoved/Paid</span>
            ) : (
              // <Button
              //   type="OrderNotPaid "
              //   style={{ width: '30px!important' }}
              // />
              <span className="ps-badge gray">UnApproved</span>
            )}
          </div>
        );
      },
    },

    {
      field: 'isDelivered',
      headerName: 'Delivery Status',
      width: 200,
      renderCell: (params) => {
        return (
          <>
            {params.row.isApprovedAndPaid === true ? (
              <div>
                {params.row.isApprovedAndPaid === true &&
                params.row.isPayedOut === true ? (
                  // <Button type="Delivered" />
                  <span className="ps-badge success">Paid Out</span>
                ) : (
                  // <Button type="PendingDelivery" />
                  <span className="ps-badge gray">Pending Payout</span>
                )}
              </div>
            ) : (
              <span className="ps-badge gray">Processing</span>
            )}
          </>
        );
      },
    },

    // { field: 'stock', headerName: 'Stock', width: 200 },
    // {
    //   field: 'status',
    //   headerName: 'Status',
    //   width: 120,
    // },
    // {
    //   field: 'price',
    //   headerName: 'Price',
    //   width: 160,
    // },
    {
      field: 'action',
      headerName: 'Action',
      width: 200,
      renderCell: (params) => {
        // return (
        //   <>
        //     <Link to={"/product/" + params.row.id}>
        //       <button className="productListEdit">Edit</button>
        //     </Link>
        //     <DeleteOutline
        //       className="productListDelete"
        //       onClick={() => handleDelete(params.row.id)}
        //     />
        //   </>
        // );
        return (
          <>
            <div className="actionButtons">
              {/* /adminorders/update/ */}
              {/* /admin/manageorders/:id */}
              <Link to={'/finance/manage_withdrwals/' + params.row._id}>
                <Edit className="userListEditIcon" />
                {/* <button className="userListEdit">Edit</button> */}
              </Link>
              {/* <DeleteOutline
                className="userListDelete"
                onClick={(e) => handleDelete(params.row._id)}
              /> */}
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      {/* Changing pages rendering starts here */}

      <>
        {/* start of table grid */}
        <div className="userList">
          <div className="userTitleContainer">
            <h1 className="userTitle"> Finance Manage Withdrawals </h1>
          </div>

          {loading ? (
            <>
              <Paper elevation={6} className="loadingPaper">
                <CircularProgress size="7em" className="loadingPaper" />
              </Paper>
              {/* <SyncOutlined
                spin
                className="d-flex justify-content-center display-1 text-danger p-5"
              /> */}
            </>
          ) : (
            <DataGrid
              className="tableProps"
              rows={withdrawals}
              disableSelectionOnClick
              columns={columns}
              getRowId={(row) => row._id}
              pageSize={20}
              checkboxSelection
            />
          )}
        </div>

        <>
          <ShopRightbar Shop />
        </>
      </>
      {/* Changing pages rendering ends here */}
    </>
  );
};

export default WithdrawalList;
