import { DeleteOutline, Publish } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import useForm from '../../hooks/useForm';
import { AuthApi } from '../../apiconstant/Api';
import { useParams, useHistory, Redirect, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  createDepart,
  getDepartWithId,
  updateDepart,
} from '../../store/departments/repository_dept';
import { getDeptById, getProdUnitById } from '../../store/api/apiRoutes';
import { getDepartments } from '../../store/departments/repository_dept';
import {
  createProductUnit,
  getProductUnits,
  updateProductUnits,
} from '../../store/produnits/repository_produnit';
import { getHubs } from '../../store/tradehubs/repository_tradehub';

// const initialFValues = {
//     id:0,
//     product:{},
//   }

const initialFValues = {
  id: 0,
  tradehub: '',
  unitname: '',
};

const ProductUnitForm = () => {
  const { values, setValues, handleInputChange, resetForm } =
    useForm(initialFValues);
  // const { id } = useParams();
  const params = useParams();
  const { id } = params;

  const dispatch = useDispatch();
  const { hubs, isFetching } = useSelector((state) => state.hubs);
  const [loading, setLoading] = useState(false);

  // const produnit = useSelector((state) =>
  //   id
  //     ? state.produnits.produnits.find((produnit) => produnit._id === id)
  //     : null
  // );

  useEffect(() => {
    dispatch(getHubs());

    // dispatch(getProductUnits());
    // if (produnit) setValues(produnit);
    if (id) {
      const getUnitsData = async () => {
        setLoading(true);
        try {
          if (id) {
            await getProdUnitById(id).then((response) => {
              // setDepartment(response.data);
              setValues(response.data);
            });
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      };
      getUnitsData();
    }

    // setPagePosts(data);
  }, [dispatch]);
  console.log('hunUmits:', hubs);

  const handleCreate = async (e) => {
    e.preventDefault();

    // console.log('storage values are :', values);
    try {
      dispatch(createProductUnit(values));
      // alert('Department Added');
      resetForm();
    } catch (error) {
      console.log({ message: error.message });
    }

    // resetForm();
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    // console.log('storage values are :', values);
    try {
      dispatch(updateProductUnits(id, values));
      // alert('Department Updated');
    } catch (error) {
      console.log({ message: error.message });
    }

    // resetForm();
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    alert('Deleted');
  };

  return (
    <div className="shopErectionUpdate">
      {/* <span className="shopErectionUpdateTitle">Add a Product Unit</span>
      <form
        className="shopErectionUpdateForm"
        onSubmit={id ? handleUpdate : handleCreate}
      >
        <div className="shopErectionUpdateLeft">
          <div className="productFormLeft">
            <label>Trade Hubs</label>
            <select
              style={{ maxWidth: '250px' }}
              className=""
              name="tradehub"
              value={values.tradehub}
              onChange={handleInputChange}
              id="tradehub"
            >
              <option value="">Select an option</option>
              {hubs.map((hb) => (
                <option value={hb._id} onChange={handleInputChange}>
                  {hb.hubname}
                </option>
              ))}

              
            </select>
           
            <label>Product Unit Name</label>
            <input
              type="text"
              placeholder="Department xxx"
              name="unitname"
              value={values.unitname}
              onChange={handleInputChange}
              className="shopErectionUpdateInput"
            />
         
          </div>

          <button
           
            className="shopErectionUpdateButton"
          >
            Add/Update ProductUnit
          </button>
        </div>
      </form> */}

      <span className="shopErectionUpdateTitle">
        Create/Edit a Product Unit
      </span>
      {id && (
        <span className="right">
          Delete
          <DeleteOutline
            className="userListDelete right"
            onClick={(e) => handleDelete(e)}
          />
        </span>
      )}

      <hr />
      <form
        onSubmit={id ? handleUpdate : handleCreate}
        className="ps-form--account-setting"
      >
        <div className="ps-form__content">
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <label>Trade Hubs</label>
                <select
                  // style={{ maxWidth: '250px' }}
                  className="form-control"
                  name="tradehub"
                  value={values.tradehub}
                  onChange={handleInputChange}
                >
                  <option value="">Select an option</option>
                  {hubs.map((hb) => (
                    <option value={hb._id} onChange={handleInputChange}>
                      {hb.hubname}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-sm-12">
              <div className="form-group">
                <label>Product Unit Name</label>
                <input
                  type="text"
                  name="unitname"
                  value={values.unitname}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="form-group submit">
          <Link to={'/produnits'} className="btn">
            Cancel
          </Link>
          <button className="ps-btn">Add/Update Product Unit</button>
        </div>
      </form>
    </div>
  );
};

export default ProductUnitForm;
