import * as api from '../api/apiRoutes';
import {
  partner,
  partnerSuccess,
  partnerFailure,
  getPartnerById,
  getPartnerByIdSuccess,
  getPartnerByIdFailure,
  updatePartner,
  updatePartnerSuccess,
  updatePartnerFailure,
  createPartner,
  createPartnerSuccess,
  createPartnerFailure,
} from './actionPartners';
const Cookies = require('js-cookie');

//get all Market Categories
export const getPartnersRepo = () => async (dispatch) => {
  try {
    dispatch(partner());
    const { data } = await api.getPartners();
    // console.log('procatData :', data);

    // dispatch({
    //   type: FETCH_ALL,
    //   payload: { data, currentPage, numberOfPages },
    // });
    dispatch(partnerSuccess(data));
  } catch (error) {
    // console.log(error);
    dispatch(partnerFailure({ message: error.message }));
  }
};

//get single Market
export const getPartnerWithId = (id) => async (dispatch) => {
  dispatch(getPartnerById());
  // console.log('Acton start');
  try {
    const { data } = await api.getPartnerById(id);
    // console.log('MydesigByID :', data);

    dispatch(getPartnerByIdSuccess(data));
  } catch (error) {
    // console.log(error);
    dispatch(getPartnerByIdFailure({ message: error.message }));
  }
};

//update a Market
export const updatePartnersRepo = (id, values) => async (dispatch) => {
  // console.log('repoValues', values);
  dispatch(updatePartner());
  try {
    const { data } = await api.updatePartnerById(id, values);
    // console.log('actionData :', data);

    dispatch(updatePartnerSuccess(data));
    alert('Partner Cat updated');
  } catch (error) {
    // console.log(error);
    dispatch(updatePartnerFailure({ message: error.message }));
  }
};

//Create a Market
export const createPartnerRepo = (values) => async (dispatch) => {
  try {
    dispatch(createPartner());
    const { data } = await api.createPartner(values);
    // console.log('actionData :', data);
    if (data) {
      dispatch(createPartnerSuccess(data));
      // console.log('newDesination:', data);
      alert('Partner added');
    }
  } catch (error) {
    dispatch(createPartnerFailure({ message: error.message }));
  }
};
