// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAr8p5srvGfuBIqqtsUhFQwgFjtSdSuvzg',
  authDomain: 'africanshopsecommerce.firebaseapp.com',
  projectId: 'africanshopsecommerce',
  storageBucket: 'africanshopsecommerce.appspot.com',
  messagingSenderId: '248778875159',
  appId: '1:248778875159:web:fca2c9daf5f2e7e7679752',
  measurementId: 'G-WJH9CH067R',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default app;
