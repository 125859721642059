import React, { useState } from 'react';

function useForm(initialFValues) {
  const [values, setValues] = useState(initialFValues);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleFile = (e) => {
    setValues(e.target.files[0]);
  };

  const resetForm = () => {
    setValues(initialFValues);
  };

  return {
    values,
    setValues,
    handleInputChange,
    handleFile,
    resetForm,
  };
}

export default useForm;
