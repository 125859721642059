import React from 'react'
import {Link } from "react-router-dom";
import "./adminrightbar.scss";
import {
    LineStyle,
    Timeline,
    TrendingUp,
    PermIdentity,
    Storefront,
    AttachMoney,
    BarChart,
    MailOutline,
    DynamicFeed,
    ChatBubbleOutline,
    WorkOutline,
    Report,
  }  from "@material-ui/icons";

  
const AdminRightbar = () => {
  
    return (
      
        <div className="adminrightbar">
            <div className="adminbarWrapper">
                <div className="adminbarMenu">
                    <h3 className="adminbarTitle">
                        Afrizone Admin Dashboard
                    </h3>
                    <ul className="adminbarList">
                    <Link to="/admin/dashboard">
                        <li className="adminbarListItem active">
                            <LineStyle className="adminbarIcon"/>
                            Home
                        </li>
                    </Link>
                        <li className="adminbarListItem">  
                            <Timeline className="adminbarIcon"/>
                            Analytics
                        </li>
                        <li className="adminbarListItem"> 
                            <TrendingUp className="adminbarIcon"/>
                            Sales
                        </li>
                    </ul>
                </div>
                <div className="adminbarMenu">
          <h3 className="adminbarTitle">Quick Menu</h3>
          <ul className="adminbarList">
          <Link to="#">
              <li className="adminbarListItem">
                <PermIdentity className="adminbarIcon" />
                Users
              </li>
         </Link>
         <Link to="/products">
              <li className="adminbarListItem">
                <Storefront className="adminbarIcon" />
                Products
              </li>
          </Link>
            <li className="adminbarListItem">
              <AttachMoney className="adminbarIcon" />
              Transactions
            </li>
            <li className="adminbarListItem">
              <BarChart className="adminbarIcon" />
              Reports
            </li>
          </ul>
        </div>
        <div className="adminbarMenu">
          <h3 className="adminbarTitle">Notifications</h3>
          <ul className="adminbarList">
            <li className="adminbarListItem">
              <MailOutline className="adminbarIcon" />
              Mail
            </li>
            <li className="adminbarListItem">
              <DynamicFeed className="adminbarIcon" />
              Feedback
            </li>
            <li className="adminbarListItem">
              <ChatBubbleOutline className="adminbarIcon" />
              Messages
            </li>
          </ul>
        </div>
        <div className="adminbarMenu">
          <h3 className="adminbarTitle">Staff</h3>
          <ul className="adminbarList">
            <li className="adminbarListItem">
              <WorkOutline className="adminbarIcon" />
              Manage
            </li>
            <li className="adminbarListItem">
              <Timeline className="adminbarIcon" />
              Analytics
            </li>
            <li className="adminbarListItem">
              <Report className="adminbarIcon" />
              Reports
            </li>
          </ul>
        </div>
        {/* scroll check starts */}
        <div className="adminbarMenu">
          <h3 className="adminbarTitle">Staff</h3>
          <ul className="adminbarList">
            <li className="adminbarListItem">
              <WorkOutline className="adminbarIcon" />
              Manage
            </li>
            <li className="adminbarListItem">
              <Timeline className="adminbarIcon" />
              Analytics
            </li>
            <li className="adminbarListItem">
              <Report className="adminbarIcon" />
              Reports
            </li>
          </ul>
        </div>
        <div className="adminbarMenu">
          <h3 className="adminbarTitle">Staff</h3>
          <ul className="adminbarList">
            <li className="adminbarListItem">
              <WorkOutline className="adminbarIcon" />
              Manage
            </li>
            <li className="adminbarListItem">
              <Timeline className="adminbarIcon" />
              Analytics
            </li>
            <li className="adminbarListItem">
              <Report className="adminbarIcon" />
              Reports
            </li>
          </ul>
        </div>
        <div className="adminbarMenu">
          <h3 className="adminbarTitle">Staff</h3>
          <ul className="adminbarList">
            <li className="adminbarListItem">
              <WorkOutline className="adminbarIcon" />
              Manage
            </li>
            <li className="adminbarListItem">
              <Timeline className="adminbarIcon" />
              Analytics
            </li>
            <li className="adminbarListItem">
              <Report className="adminbarIcon" />
              Reports
            </li>
          </ul>
        </div>
        <div className="adminbarMenu">
          <h3 className="adminbarTitle">Staff</h3>
          <ul className="adminbarList">
            <li className="adminbarListItem">
              <WorkOutline className="adminbarIcon" />
              Manage
            </li>
            <li className="adminbarListItem">
              <Timeline className="adminbarIcon" />
              Analytics
            </li>
            <li className="adminbarListItem">
              <Report className="adminbarIcon" />
              Reports
            </li>
          </ul>
        </div>
        {/* scroll check ends */}
            </div>
        </div>
    )
}

export default AdminRightbar
