import { actionTypes } from './actionDept';
import Cookies from 'js-cookie';

export const initState = {
  departments: [],
  department: {},
  isFetching: false,
  error: false,
};

function reducer(state = initState, action) {
  switch (action.type) {
    case 'START_LOADING':
      return { ...state, isFetching: true };
    case 'END_LOADING':
      return { ...state, isFetching: false };
    //Get departments
    case actionTypes.DEPT_REQUEST:
      return {
        ...state,
        isFetching: true,
        departments: [],
        error: false,
      };
    case actionTypes.DEPT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        departments: action.payload,
        error: false,
      };
    case actionTypes.DEPT_FAILURE:
      return {
        ...state,
        isFetching: false,
        departments: [],
        error: true,
      };
    //Get departmentsById:
    case actionTypes.GET_DEPT_REQUEST:
      return {
        ...state,
        isFetching: true,
        department: {},
        error: false,
      };
    case actionTypes.GET_DEPT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        department: action.payload,
        error: false,
      };
    case actionTypes.GET_DEPT_FAILURE:
      return {
        ...state,
        isFetching: false,
        department: {},
        error: true,
      };
    //Update A departmentsById:
    case actionTypes.UPDATE_DEPT_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.UPDATE_DEPT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        departments: state.departments.map((department) =>
          department._id === action.payload._id ? action.payload : department
        ),
        error: false,
      };
    case actionTypes.UPDATE_DEPT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Create A New departmentsById:
    case actionTypes.CREATE_DEPT_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.CREATE_DEPT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        departments: [...state.departments, action.payload],
        error: false,
      };
    case actionTypes.CREATE_DEPT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };

    default:
      return { ...state };
  }
}
export default reducer;
