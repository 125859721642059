import React from 'react';

const ModuleOrderShippingInformation = ({ order }) => {
  return (
    <div className="ps-card ps-card--order-information ps-card--small">
      <div className="ps-card__header">
        <h4>Shipping Information</h4>
      </div>
      <div className="ps-card__content">
        <h5>{order.shippingAddress?.fullName}</h5>
        <p>
          <strong>Address:</strong>
          {/* 117-195 Iroquois Ave London, ON N6C 2K9{' '} */}
          {order.shippingAddress?.address}
        </p>
        <p>
          <strong>Phone No:</strong>{' '}
          {order.shippingAddress?.phone
            ? order.shippingAddress?.phone
            : 'No Phone Contact'}
        </p>
        <p>
          <strong>Email:</strong> {order.shippingAddress?.prefContact}
        </p>
      </div>
    </div>
  );
};

export default ModuleOrderShippingInformation;
