import React, { useEffect } from 'react';
import ShopRightbar from '../../components_shops/shoprightbar/ShopRightbar';
// import "./usersCustomer.scss"
import {
  CalendarToday,
  DeleteOutline,
  LocationSearching,
  MailOutline,
  PermIdentity,
  PhoneAndroid,
  Publish,
} from '@material-ui/icons';
import { Link, useParams, useHistory } from 'react-router-dom';
import useForm from '../../hooks/useForm';
import { useDispatch, useSelector } from 'react-redux';
import {
  createPostcatRepo,
  getPostCats,
  updatePostcats,
} from '../../store/postcats/repository_postcat';

const initialFValues = {
  id: 0,
  name: '',
};

const PostCatFormPane = () => {
  const { values, setValues, handleInputChange, resetForm } =
    useForm(initialFValues);
  const { id } = useParams();

  // const { department, iFetching } = useSelector((state) => state.depts);
  const postcat = useSelector((state) =>
    id ? state.postcates.postcats.find((pst) => pst._id === id) : null
  );
  // const post = useSelector((state) => (currentId ? state.posts.posts.find((message) => message._id === currentId) : null));
  console.log('StateDept:', postcat);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getPostCats());
    if (postcat) setValues(postcat);
  }, []);

  const handlePostCatSubmit = async (e) => {
    e.preventDefault();

    console.log('storage values are :', values);
    try {
      dispatch(createPostcatRepo(values));
      // alert('Department Added');
      resetForm();
    } catch (error) {
      console.log({ message: error.message });
    }

    // resetForm();
  };

  const handlePostCatUpdate = async (e) => {
    e.preventDefault();

    // console.log('storage values are :', values);
    try {
      dispatch(updatePostcats(id, values));
      // alert('Department Updated');
    } catch (error) {
      console.log({ message: error.message });
    }

    // resetForm();
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    alert('Deleted');
  };

  return (
    <>
      {/* <div className="shoppingRight"> */}
      {/* <div className="shoppingRightBottom"> */}
      {/* page starts */}
      <div className="user">
        {/* <div className="userTitleContainer">
          <h1 className="userTitle">Edit User</h1>
         
        </div> */}
        <div className="userContainer">
          <div className="userShow">
            <div className="userShowTop">
              <img
                src="https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
                alt=""
                className="userShowImg"
              />
              <div className="userShowTopTitle">
                <span className="userShowUsername">Anna Becker</span>
                <span className="userShowUserTitle">Software Engineer</span>
              </div>
            </div>
            <div className="userShowBottom">
              <span className="userShowTitle">Account Details</span>
              <div className="userShowInfo">
                <PermIdentity className="userShowIcon" />
                <span className="userShowInfoTitle">annabeck99</span>
              </div>
              <div className="userShowInfo">
                <CalendarToday className="userShowIcon" />
                <span className="userShowInfoTitle">10.12.1999</span>
              </div>
              <span className="userShowTitle">Contact Details</span>
              <div className="userShowInfo">
                <PhoneAndroid className="userShowIcon" />
                <span className="userShowInfoTitle">+1 123 456 67</span>
              </div>
              <div className="userShowInfo">
                <MailOutline className="userShowIcon" />
                <span className="userShowInfoTitle">annabeck99@gmail.com</span>
              </div>
              <div className="userShowInfo">
                <LocationSearching className="userShowIcon" />
                <span className="userShowInfoTitle">New York | USA</span>
              </div>
            </div>
          </div>
          <div className="userUpdate">
            {/* <span className="userUpdateTitle">Manage Post Categories</span>
            <form
              className="userUpdateForm"
              onSubmit={id ? handlePostCatUpdate : handlePostCatSubmit}
            >
              <div className="userUpdateLeft">
                <div className="userUpdateItem">
                  <label>Category Name</label>
                  <input
                    type="text"
                    placeholder="annabeck99"
                    className="userUpdateInput"
                    name="name"
                    value={values.name}
                    onChange={handleInputChange}
                  />
                </div>
               
              </div>
              <div className="userUpdateRight">
                
                <button className="userUpdateButton">Submit</button>
              </div>
            </form> */}

            <span className="shopErectionUpdateTitle">Create/Edit Ads</span>
            {id && (
              <span className="right">
                Delete
                <DeleteOutline
                  className="userListDelete right"
                  onClick={(e) => handleDelete(e)}
                />
              </span>
            )}

            <hr />

            <form
              onSubmit={id ? handlePostCatUpdate : handlePostCatSubmit}
              className="ps-form--account-setting"
            >
              <div className="ps-form__content">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Category Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={values.name}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group submit">
                <Link to={'/listpostcats'} className="btn">
                  Cancel
                </Link>
                <button className="ps-btn">Add/Update Category</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostCatFormPane;
