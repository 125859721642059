import React, { Fragment, useEffect } from 'react';
import './manageprocat.scss';
import ShopRightbar from '../../components_shops/shoprightbar/ShopRightbar';
import { DataGrid } from '@material-ui/data-grid';
import { DeleteOutline, Edit } from '@material-ui/icons';
import {
  Paper,
  Typography,
  CircularProgress,
  Divider,
} from '@material-ui/core/';
import { productRows } from '../../dummyData';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDepartments } from '../../store/departments/repository_dept';
import { getProductCats } from '../../store/procats/repository_procat';

const ShopProductList = () => {
  const dispatch = useDispatch();
  const { prodcategories, isFetching } = useSelector((state) => state.procats);

  console.log('prodcats na:', prodcategories);

  useEffect(() => {
    const data = dispatch(getProductCats());

    // setPagePosts(data);
  }, [dispatch]);
  const [data, setData] = useState(productRows);

  const handleDelete = (id) => {
    // setData(data.filter((item) => item.id !== id));
  };

  const columns = [
    // { field: '_id', headerName: 'ID', width: 90 },
    {
      field: 'name',
      headerName: 'Produc Category',
      width: 300,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            <img
              className="productListImg"
              src={params.row.categoryimage}
              alt=""
            />
            <h6> {params.row.name}</h6>
          </div>
        );
      },
    },
    // { field: 'stock', headerName: 'Stock', width: 200 },
    // {
    //   field: 'status',
    //   headerName: 'Status',
    //   width: 120,
    // },
    // {
    //   field: 'price',
    //   headerName: 'Price',
    //   width: 160,
    // },
    {
      field: 'action',
      headerName: 'Action',
      width: 300,
      renderCell: (params) => {
        // return (
        //   <>
        //     <Link to={"/product/" + params.row.id}>
        //       <button className="productListEdit">Edit</button>
        //     </Link>
        //     <DeleteOutline
        //       className="productListDelete"
        //       onClick={() => handleDelete(params.row.id)}
        //     />
        //   </>
        // );
        return (
          <>
            <div className="actionButtons">
              <Link to={'/procat/update/' + params.row._id}>
                <Edit className="userListEditIcon" />
              </Link>
              {/* <DeleteOutline
                className="userListDelete"
                onClick={() => handleDelete(params.row._id)}
              /> */}
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      {/* Changing pages rendering starts here */}

      <>
        {/* start of table grid */}
        <div className="userList">
          <div className="userTitleContainer">
            <h1 className="userTitle">Product Categories</h1>
            <Link to="/procat/add">
              <button className="userAddButton">Add Product Cat</button>
            </Link>
          </div>

          {/* <div className="usertable"> */}
          {isFetching ? (
            <Paper elevation={6} className="loadingPaper">
              <CircularProgress size="7em" className="loadingPaper" />
            </Paper>
          ) : (
            <DataGrid
              className="tableProps"
              rows={prodcategories}
              disableSelectionOnClick
              columns={columns}
              getRowId={(row) => row._id}
              pageSize={20}
              checkboxSelection
            />
          )}
        </div>

        <>
          <ShopRightbar Shop />
        </>
      </>
      {/* Changing pages rendering ends here */}
    </>
  );
};

export default ShopProductList;
