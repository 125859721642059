// import * as api from '../api/apiRoutes';
import {
  getApiAdminUsers,
  getApiAdminUserById,
  updateApiAdminUserById,
  createApiAdminUser,
} from '../api/apiRoutes';
import {
  adminuser,
  adminuserSuccess,
  adminuserFailure,
  getAdminUserById,
  getAdminUserByIdSuccess,
  getAdminUserByIdFailure,
  updateAdminUser,
  updateAdminUserSuccess,
  updateAdminUserFailure,
  createAdminUser,
  createAdminUserSuccess,
  createAdminUserFailure,
} from './actionAdminUsers';
const Cookies = require('js-cookie');

//get all Market users
export const getAllAdminUsersRepo = () => async (dispatch) => {
  try {
    dispatch(adminuser());

    console.log('BarnersFrmRepo before ');

    const { data } = await getApiAdminUsers();
    // console.log('procatData :', data);

    // dispatch({
    //   type: FETCH_ALL,
    //   payload: { data, currentPage, numberOfPages },
    // });
    if (data) {
      console.log('UsersFrmRepo1', data);
      // console.log('UsersFrmRepo222', data.filteredUsers);
      dispatch(adminuserSuccess(data));
    }
  } catch (error) {
    // console.log(error);
    dispatch(adminuserFailure({ error: error.message }));
  }
};

//get single ADMINUSER
export const getAdminUserWithId = (id) => async (dispatch) => {
  dispatch(getAdminUserById());
  // console.log('Acton start');
  try {
    const { data } = await getApiAdminUserById(id);
    // console.log('MydesigByID :', data);

    dispatch(getAdminUserByIdSuccess(data));
  } catch (error) {
    // console.log(error);
    dispatch(getAdminUserByIdFailure({ error: error.message }));
  }
};

//update a ADMIN USER
export const updateAdminUsersRepo = (id, values) => async (dispatch) => {
  // console.log('repoValues', values);
  dispatch(updateAdminUser());
  try {
    const { data } = await updateApiAdminUserById(id, values);
    // console.log('actionData :', data);

    dispatch(updateAdminUserSuccess(data));
    alert('User updated');
  } catch (error) {
    // console.log(error);
    dispatch(updateAdminUserFailure({ message: error.message }));
  }
};

//Create a Market
export const createAdminUserRepo = (values) => async (dispatch) => {
  try {
    dispatch(createAdminUser());
    const { data } = await createApiAdminUser(values);
    // console.log('actionData :', data);
    if (data) {
      dispatch(createAdminUserSuccess(data));
      // console.log('newDesination:', data);
      alert('Admin User  added');
    }
  } catch (error) {
    dispatch(createAdminUserFailure({ error: error.message }));
  }
};
