import {
  CloudUploadOutlined,
  DeleteOutline,
  Publish,
} from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import useForm from '../../hooks/useForm';
import { AuthApi } from '../../apiconstant/Api';
import { useParams, useHistory, Redirect, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getApiInfoById } from '../../store/api/apiRoutes';

import {
  createInfoRepo,
  updateInfosRepo,
} from '../../store/info/repository_info';

// const initialFValues = {
//     id:0,
//     product:{},
//   }

const initialFValues = {
  id: 0,
  title: '',
  content: '',
  key: '',
};

const PostForm = () => {
  const params = useParams();

  // const postId = params.id;
  // console.log('pageProps:', postId);
  const [loading, setLoading] = useState(false);

  const { info, isFetching } = useSelector((state) => state.infos);
  // const [post, setPost] = useState({});

  const { values, setValues, handleInputChange, handleFile, resetForm } =
    useForm(initialFValues);
  const [file, setFile] = useState(null);
  const { id } = params;
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      getLegalData();
    }

    // setPagePosts(data);
  }, [id, dispatch]);

  const getLegalData = async () => {
    setLoading(true);
    try {
      if (id) {
        await getApiInfoById(id).then((response) => {
          setValues(response.data);

          console.log('API Legal_Docs', response.data);
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleInfoSubmit = async (e) => {
    e.preventDefault();

    try {
      dispatch(createInfoRepo(values));

      resetForm();
    } catch (error) {
      console.log({ message: error.message });
    }
  };

  //HANDLE POST UPDATE
  const handleInfoUpdate = async (e) => {
    e.preventDefault();

    try {
      dispatch(updateInfosRepo(id, values));
    } catch (error) {
      console.log({ message: error.message });
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    if (window.confirm('Do you really want to delete?')) {
      alert('deleted');
    }
  };

  return (
    <div className="shopErectionUpdate">
      <span className="shopErectionUpdateTitle">Create/Edit a Information</span>
      {id && (
        <span className="right">
          Delete
          <DeleteOutline
            className="userListDelete right"
            onClick={(e) => handleDelete(e)}
          />
        </span>
      )}

      <hr />
      <form
        onSubmit={id ? handleInfoUpdate : handleInfoSubmit}
        className="ps-form--account-setting"
      >
        {/* confirmUpdate  updateUserDetails*/}
        {/* <div className="ps-form__header">
          <h3>Create/Edit Infos</h3>
        </div> */}
        <div className="ps-form__content">
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Title Here"
                  name="title"
                  value={values?.title}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Title Here"
                  name="key"
                  value={values?.key}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <hr />

            <div className="col-sm-12">
              <div className="form-group">
                <textarea
                  id="summary-ckeditor"
                  className="form-control"
                  type="text"
                  placeholder="Content Text Here"
                  name="content"
                  value={values?.content}
                  onChange={handleInputChange}
                  rows="6"
                ></textarea>
              </div>
            </div>
          </div>

          <div className="form-group submit">
            <Link to={'/listinfo'} className="btn">
              Cancel
            </Link>
            <button className="ps-btn">
              {isFetching ? 'processing...' : 'Add/Update Info_Docs'}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PostForm;
