import * as api from '../api/apiRoutes';

import {
  postcat,
  postcatSuccess,
  postcatFailure,
  getPostcatById,
  getPostcatByIdSuccess,
  getPostcatByIdFailure,
  updatePostcat,
  updatePostcatSuccess,
  updatePostcatFailure,
  createPostcat,
  createPostcatSuccess,
  createPostcatFailure,
} from './actionPostCat';
const Cookies = require('js-cookie');

//get all Market Categories
export const getPostCats = () => async (dispatch) => {
  try {
    dispatch(postcat());
    const { data } = await api.getPostcats();
    // console.log('procatData :', data);

    // dispatch({
    //   type: FETCH_ALL,
    //   payload: { data, currentPage, numberOfPages },
    // });
    dispatch(postcatSuccess(data));
  } catch (error) {
    // console.log(error);
    dispatch(postcatFailure({ error: error.message }));
  }
};

//get single Market
export const getPostcatWithId = (id) => async (dispatch) => {
  dispatch(getPostcatById());
  // console.log('Acton start');
  try {
    const { data } = await api.getPostcatById(id);
    // console.log('MydesigByID :', data);

    dispatch(getPostcatByIdSuccess(data));
  } catch (error) {
    // console.log(error);
    dispatch(getPostcatByIdFailure({ error: error.message }));
  }
};

//update a Market
export const updatePostcats = (id, values) => async (dispatch) => {
  // console.log('repoValues', values);
  dispatch(updatePostcat());
  try {
    const { data } = await api.updatePostcatById(id, values);
    // console.log('actionData :', data);

    dispatch(updatePostcatSuccess(data));
    alert('Postcat Cat updated');
  } catch (error) {
    // console.log(error);
    dispatch(updatePostcatFailure({ message: error.message }));
  }
};

//Create a Market
export const createPostcatRepo = (values) => async (dispatch) => {
  try {
    dispatch(createPostcat());
    const { data } = await api.createPostcat(values);
    // console.log('actionData :', data);
    dispatch(createPostcatSuccess(data));
    // console.log('newDesination:', data);
    alert('Postcat Cat added');
  } catch (error) {
    dispatch(createPostcatFailure({ error: error.message }));
  }
};
