import * as api from '../api/apiRoutes';
import {
  dept,
  deptSuccess,
  deptFailure,
  getDeptById,
  getDeptByIdSuccess,
  getDeptByIdFailure,
  updateDept,
  updateDeptSuccess,
  updateDeptFailure,
  createDept,
  createDeptSuccess,
  createDeptFailure,
} from './actionDept';
const Cookies = require('js-cookie');

export const getDepartments = () => async (dispatch) => {
  try {
    dispatch(dept());
    const { data } = await api.getDepts();
    console.log('actionData :', data);

    dispatch(deptSuccess(data));
  } catch (error) {
    console.log(error);
    dispatch(deptFailure(error));
  }
};

//get single department
export const getDepartWithId = (id) => async (dispatch) => {
  dispatch(getDeptById());
  console.log('Acton start');
  try {
    const { data } = await api.getDeptById(id);
    console.log('MyDeptByID :', data);

    dispatch(getDeptByIdSuccess(data));
  } catch (error) {
    console.log(error);
    dispatch(getDeptByIdFailure(error));
  }
};

//update a department
export const updateDepart = (id, values) => async (dispatch) => {
  console.log('repoValues', values);
  dispatch(updateDept());
  try {
    const { data } = await api.updateDeptById(id, values);
    // console.log('actionData :', data);

    dispatch(updateDeptSuccess(data));
    alert('department updated');
  } catch (error) {
    console.log(error);
    dispatch(updateDeptFailure({ message: error.message }));
  }
};

//Create a department
export const createDepart = (values) => async (dispatch) => {
  console.log('newDepttData :', values);
  try {
    dispatch(createDept());
    const { data } = await api.createDepartMent(values);
    // console.log('actionData :', data);
    dispatch(createDeptSuccess(data));
    // console.log('newDepartment:', data);
    alert('department added');
  } catch (error) {
    console.log(error);
    dispatch(createDeptFailure({ error: error.message }));
  }
};
