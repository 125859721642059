import React from 'react';
import "./socialsidebar.scss";
import {Bookmark, Group, HelpOutline, RssFeed, WorkOutline, School, Event,} from "@material-ui/icons";
import CloseFriend from "../closefriends/CloseFriend"
import { Users } from "../../dummyData";


const SocialSidebar = () => {
        
    return (
            
        <div className="socialsidebar">
            <div className="sidebarWrapper">
                <ul className="sidebarList">
                    <li className="sidebarListItem">
                            <RssFeed className="sidebarIcon"/>
                            <span className="sidebarListItemText">Feeds</span>
                    </li>
                    <li className="sidebarListItem">
                            <RssFeed className="sidebarIcon"/>
                            <span className="sidebarListItemText">Chats</span>
                    </li>
                    <li className="sidebarListItem">
                            <RssFeed className="sidebarIcon"/>
                            <span className="sidebarListItemText">Videos</span>
                    </li>
                    <li className="sidebarListItem">
                            <Group className="sidebarIcon"/>
                            <span className="sidebarListItemText">Groups</span>
                    </li>
                    <li className="sidebarListItem">
                            <Bookmark className="sidebarIcon"/>
                            <span className="sidebarListItemText">Bookmarks</span>
                    </li>
                    <li className="sidebarListItem">
                            <HelpOutline className="sidebarIcon"/>
                            <span className="sidebarListItemText">Questions</span>
                    </li>
                    <li className="sidebarListItem">
                            <WorkOutline className="sidebarIcon"/>
                            <span className="sidebarListItemText">Jobs</span>
                    </li>
                    <li className="sidebarListItem">
                            <Event className="sidebarIcon"/>
                            <span className="sidebarListItemText">Events</span>
                    </li>
                    <li className="sidebarListItem">
                            <School className="sidebarIcon"/>
                            <span className="sidebarListItemText">Courses</span>
                    </li>
                    <button className="sidebarButton">Show more</button>
                    <hr className="sidebarHr"/>
                    <ul className="sidebarFriendList">
                        {Users.map((u) => (
                                 <CloseFriend key={u.id} user={u}/>
                        ))}
                       
                        
                    </ul>
                </ul>
            </div>
        </div>
    )
}

export default SocialSidebar
