import { actionTypes } from './actionAuth';
import Cookies from 'js-cookie';

export const initState = {
  user: Cookies.get('authUserInfo')
    ? JSON.parse(Cookies.get('authUserInfo'))
    : null,
  isFetching: false,
  error: false,
};

function reducer(state = initState, action) {
  switch (action.type) {
    case 'START_LOADING':
      return { ...state, isFetching: true };
    case 'END_LOADING':
      return { ...state, isFetching: false };
    case actionTypes.AUTH_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.AUTH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        user: action.payload,
        error: false,
      };
    case actionTypes.AUTH_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case actionTypes.LOG_OUT:
      Cookies.remove('authUserInfo');
      return {
        ...state,
        isFetching: false,
        user: null,
        error: null,
      };
    // case actionTypes.SINGLEAUTH_SUCCESS:
    //   return { ...state, AUTH: action.payload };
    default:
      return { ...state };
  }
}
export default reducer;
