import { CloudUploadOutlined, Publish } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import useForm from '../../hooks/useForm';
import { AuthApi } from '../../apiconstant/Api';
import { useParams, useHistory, Redirect, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { CircularProgress, Paper } from '@material-ui/core';

// const initialFValues = {
//     id:0,
//     product:{},
//   }

const ShopStatus = ({
  handleMakeShopSlider,
  handleUnMakeShopSlider,
  handleMakeShopBanner,
  handleUnMakeShopBanner,
  handleMakeShopPromotion,
  handleUnMakeShopPromotion,

  values,
  handleInputChange,
  id,
  loading,

  expiry,
  setExpiry,

  bannerExpiry,
  setBannerExpiry,

  promoExpiry,
  setPromoExpiry,
}) => {
  return (
    <div className="shopErectionUpdate">
      {loading ? (
        <Paper elevation={6} className="loadingPaper">
          <CircularProgress size="7em" className="loadingPaper" />
        </Paper>
      ) : (
        <>
          <form
            // onSubmit={id && handleUserBlockUpdate}
            onSubmit={
              id && !values.isOnHomeSliderAds
                ? handleMakeShopSlider
                : handleUnMakeShopSlider
            }
            className="ps-form--account-setting"
          >
            <div className="ps-form__content">
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label>
                      Make Shop Slider:{' '}
                      {values.isOnHomeSliderAds &&
                        'This shop is currently Africanshops Home slider'}{' '}
                      {!values.isOnHomeSliderAds &&
                        'This Shop isnt currently Africanshops Home slider'}
                    </label>
                    <select
                      // style={{ maxWidth: '250px' }}
                      className="form-control"
                      name="isOnHomeSliderAds"
                      value={values.isOnHomeSliderAds}
                      onChange={handleInputChange}
                      disabled
                      required
                      // id="department"
                    >
                      <option value="">Select an option</option>
                      <option value="true">true</option>
                      <option value="false">false</option>
                    </select>
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="form-group">
                    <label>Expiry date</label>
                    <input
                      type="date"
                      className="form-control"
                      // name="OnHomeSliderAdsExpiresAt"
                      // value={values.OnHomeSliderAdsExpiresAt}
                      // onChange={handleInputChange}
                      name="expiry"
                      value={expiry}
                      onChange={(e) => setExpiry(e.target.value)}
                      // required
                    />
                  </div>
                </div>
              </div>

              <div className="form-group submit">
                <Link to={'/listshops'} className="btn">
                  Cancel
                </Link>
                {/* <button className="ps-btn">Block User </button> */}
                {values.isOnHomeSliderAds && (
                  <button className="ps-btn">Un-Make Slider </button>
                )}
                {!values.isOnHomeSliderAds && values.coverimage && (
                  <button className="ps-btn">Make Slider </button>
                )}
              </div>
            </div>
          </form>

          <form
            onSubmit={
              id && !values.isOnBannersAds
                ? handleMakeShopBanner
                : handleUnMakeShopBanner
            }
            className="ps-form--account-setting"
          >
            <div className="ps-form__content">
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label>
                      Suspend/Un-Suspend :{' '}
                      {values.isOnBannersAds &&
                        'This shop is currently Africanshops Banner Ad'}{' '}
                      {!values.isOnBannersAds &&
                        'This shop isnt currently Africanshops Banner Ad'}
                    </label>
                    <select
                      // style={{ maxWidth: '250px' }}
                      className="form-control"
                      name="isOnBannersAds"
                      value={values.isOnBannersAds}
                      onChange={handleInputChange}
                      disabled
                      required
                      // id="department"
                    >
                      <option value="">Select an option</option>
                      <option value="true">true</option>
                      <option value="false">false</option>
                    </select>
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="form-group">
                    <label>Expiry date</label>
                    <input
                      type="date"
                      className="form-control"
                      // name="OnBannersAdsExpiresAt"
                      // value={values.OnBannersAdsExpiresAt}
                      // onChange={handleInputChange}

                      name="bannerExpiry"
                      value={bannerExpiry}
                      onChange={(e) => setBannerExpiry(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group submit">
                <Link to={'/listshops'} className="btn">
                  Cancel
                </Link>
                {values.isOnBannersAds && (
                  <button className="ps-btn">Remove As Banner </button>
                )}
                {!values.isOnBannersAds && values.coverimage && (
                  <button className="ps-btn">Make Banner </button>
                )}
              </div>
            </div>
          </form>

          {/* Promotion handling */}

          <form
            onSubmit={
              id && !values.isOnPromotionAds
                ? handleMakeShopPromotion
                : handleUnMakeShopPromotion
            }
            className="ps-form--account-setting"
          >
            <div className="ps-form__content">
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label>
                      Promote/End-Promo :{' '}
                      {values.isOnPromotionAds &&
                        'This shop is currently Africanshops Promotion Ad'}{' '}
                      {!values.isOnPromotionAds &&
                        'This shop isnt currently Africanshops Promotion Ad'}
                    </label>
                    <select
                      // style={{ maxWidth: '250px' }}
                      className="form-control"
                      name="isOnBannersAds"
                      value={values.isOnPromotionAds}
                      onChange={handleInputChange}
                      disabled
                      required
                      // id="department"
                    >
                      <option value="">Select an option</option>
                      <option value="true">true</option>
                      <option value="false">false</option>
                    </select>
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="form-group">
                    <label>Expiry date</label>
                    <input
                      type="date"
                      className="form-control"
                      // name="OnBannersAdsExpiresAt"
                      // value={values.OnBannersAdsExpiresAt}
                      // onChange={handleInputChange}

                      name="bannerExpiry"
                      value={promoExpiry}
                      onChange={(e) => setPromoExpiry(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group submit">
                <Link to={'/listshops'} className="btn">
                  Cancel
                </Link>
                {values.isOnPromotionAds && (
                  <button className="ps-btn">End Promo </button>
                )}
                {!values.isOnPromotionAds && values.coverimage && (
                  <button className="ps-btn">Promote </button>
                )}
              </div>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default ShopStatus;
