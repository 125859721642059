import { actionTypes } from './actionTradehub';
import Cookies from 'js-cookie';

export const initState = {
  hubs: [],
  isFetching: false,
  error: false,
};

function reducer(state = initState, action) {
  switch (action.type) {
    case 'START_LOADING':
      return { ...state, isFetching: true };
    case 'END_LOADING':
      return { ...state, isFetching: false };
    //Get hubs
    case actionTypes.TRADEHUB_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.TRADEHUB_SUCCESS:
      return {
        ...state,
        isFetching: false,
        hubs: action.payload,
        error: false,
      };
    case actionTypes.TRADEHUB_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Get hubsById:
    case actionTypes.GET_TRADEHUB_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.GET_TRADEHUB_SUCCESS:
      return {
        ...state,
        isFetching: false,
        hub: action.payload,
        error: false,
      };
    case actionTypes.GET_TRADEHUB_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Update A hubsById:
    case actionTypes.UPDATE_TRADEHUB_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.UPDATE_TRADEHUB_SUCCESS:
      return {
        ...state,
        isFetching: false,
        hubs: state.hubs.map((tradehub) =>
          tradehub._id === action.payload._id ? action.payload : tradehub
        ),
        error: false,
      };
    case actionTypes.UPDATE_TRADEHUB_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Create A New hubsById:
    case actionTypes.CREATE_TRADEHUB_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.CREATE_TRADEHUB_SUCCESS:
      return {
        ...state,
        isFetching: false,
        hubs: [...state.hubs, action.payload],
        error: false,
      };
    case actionTypes.CREATE_TRADEHUB_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };

    default:
      return { ...state };
  }
}
export default reducer;
