import { ContactsOutlined, DeleteOutline, Publish } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import useForm from '../../hooks/useForm';
import { AuthApi } from '../../apiconstant/Api';
import { useParams, useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  createCountryRepo,
  getCountries,
  updateCountryRepo,
} from '../../store/country/repository_country';
import { deleteCountryById, getCountryById } from '../../store/api/apiRoutes';
import {
  getStorage,
  ref,
  deleteObject,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import app from '../../firebase';

// const initialFValues = {
//     id:0,
//     product:{},
//   }

const initialFValues = {
  id: 0,
  name: '',
  isInOperation: '',
  flag: '',
};

const CountryForm = () => {
  const { values, setValues, handleInputChange, resetForm } =
    useForm(initialFValues);
  // const { id } = useParams();
  const [file, setFile] = useState(null);
  const params = useParams();
  const { id } = params;

  // const { department, iFetching } = useSelector((state) => state.depts);
  // const country = useSelector((state) =>
  //   id ? state.countries.countries.find((cty) => cty._id === id) : null
  // );
  // const post = useSelector((state) => (currentId ? state.posts.posts.find((message) => message._id === currentId) : null));
  // console.log('StateDept:', country);

  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getCountries());
    // if (country) setValues(country);
    if (id) {
      getCountryData(id);
    }
  }, [dispatch, id, setValues]);

  const getCountryData = async (id) => {
    setLoading(true);
    try {
      if (id) {
        await getCountryById(id).then((response) => {
          // setDepartment(response.data);
          console.log('countryDATA', response.data);
          setValues(response.data);
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleCountryCreate = async (e) => {
    e.preventDefault();

    if (file) {
      const fileName = new Date().getTime() + file.name;
      const storage = getStorage(app);
      // const storageRef = ref(storage, fileName);
      const storageRef = ref(storage, `/flags/${fileName}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion

      // start here

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
            default:
          }
        },
        (error) => {
          // Handle unsuccessful uploads
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            const countryData = { ...values, flag: downloadURL };

            dispatch(createCountryRepo(countryData));
            resetForm();
            setFile(null);
          });
        }
      );
    } else {
      // console.log('valueWithOutImageFile :', values);

      dispatch(createCountryRepo(values));
      resetForm();
      setFile(null);
    }

    // try {
    //   dispatch(createCountryRepo(values));
    //   // alert('Department Added');
    //   resetForm();
    // } catch (error) {
    //   console.log({ message: error.message });
    // }
  };

  const handleCountryUpdate = async (e) => {
    e.preventDefault();

    if (file) {
      const fileName = new Date().getTime() + file.name;
      const storage = getStorage(app);
      // const storageRef = ref(storage, fileName);
      const storageRef = ref(storage, `/flags/${fileName}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion

      // start here
      // Create a reference to the file to delete
      const desertRef = ref(storage, `${values.flag}`);

      // Delete the file
      if (values.flag) {
        deleteObject(desertRef)
          .then(() => {
            // File deleted successfully
            uploadTask.on(
              'state_changed',
              (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                  case 'paused':
                    console.log('Upload is paused');
                    break;
                  case 'running':
                    console.log('Upload is running');
                    break;
                  default:
                }
              },
              (error) => {
                // Handle unsuccessful uploads
              },
              () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                  console.log('countryFlag', downloadURL);
                  const countryData = { ...values, flag: downloadURL };
                  dispatch(updateCountryRepo(id, countryData));
                });

                history.push('/bcountries');
              }
            );
          })
          .catch((error) => {
            // Uh-oh, an error occurred!
            console.log(error);
          });
      } else {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused');
                break;
              case 'running':
                console.log('Upload is running');
                break;
              default:
            }
          },
          (error) => {
            // Handle unsuccessful uploads
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              console.log('countryFlag', downloadURL);
              const countryData = { ...values, flag: downloadURL };
              dispatch(updateCountryRepo(id, countryData));
            });

            history.push('/bcountries');
          }
        );
      }
    } else {
      // console.log('valueWithOutImageFile :', values);
      dispatch(updateCountryRepo(id, values));
      // history.push('/bstates')
    }

    // try {
    //   dispatch(updateCountryRepo(id, values));
    //   // alert('Department Updated');
    // } catch (error) {
    //   console.log({ message: error.message });
    // }
  };

  const handleDelete = async (e) => {
    e.preventDefault();

    if (window.confirm('Delete state?')) {
      setLoading(true);

      try {
        if (id) {
          if (values.flag) {
            console.log('deleteCountryAnd Image', values.flag);
            const storage = getStorage(app);

            const desertRef = ref(storage, `${values.flag}`);

            deleteObject(desertRef)
              .then(() => {
                // File deleted successfully
                deleteCountryById(id).then((response) => {
                  // setDepartment(response.data);
                  // setValues(response.data);
                  alert(response.data.message);
                  // <Redirect to="/admin/listorders" />;
                  history.push('/bcountries');
                });
                setLoading(false);
              })
              .catch((error) => {
                // Uh-oh, an error occurred!
                console.log(error);
              });
          } else {
            await deleteCountryById(id).then((response) => {
              // setDepartment(response.data);
              // setValues(response.data);
              alert(response.data.message);
              // <Redirect to="/admin/listorders" />;
              history.push('/bcountries');
            });
            setLoading(false);
          }
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }

    // alert('Deleted');
  };

  return (
    <div className="shopErectionUpdate">
      {/* <span className="shopErectionUpdateTitle">Add a Country</span>
      <form
        className="shopErectionUpdateForm"
        onSubmit={id ? handleCountryUpdate : handleCountryCreate}
      >
        <div className="shopErectionUpdateLeft">
          <div className="productFormLeft">
            
            <label>Country Name</label>
            <input
              type="text"
              placeholder="Product xxx"
              name="name"
              value={values.name}
              onChange={handleInputChange}
              className="shopErectionUpdateInput"
            />
          </div>
          
        </div>

        <div className="shopErectionUpdateRight">
          

          <button className="shopErectionUpdateButton">
            Add/Update B_Country
          </button>
        </div>
      </form> */}

      <span className="shopErectionUpdateTitle">Create/Edit a Country</span>
      {id && (
        <span className="right">
          Delete
          <DeleteOutline
            className="userListDelete right"
            onClick={(e) => handleDelete(e)}
          />
        </span>
      )}

      <hr />
      <form
        onSubmit={id ? handleCountryUpdate : handleCountryCreate}
        className="ps-form--account-setting"
      >
        <div className="ps-form__content">
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <label>Country Name</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Country Here"
                  name="name"
                  value={values.name}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="col-sm-12">
              <div className="form-group">
                <label>Is Operational</label>
                <select
                  // style={{ maxWidth: '250px' }}
                  className="form-control"
                  name="isInOperation"
                  value={values.isInOperation}
                  onChange={handleInputChange}
                  // id="department"
                >
                  <option value="">Select an option</option>
                  <option value={true}>In Operation</option>
                  <option value={false}>Not In Operation</option>
                </select>
              </div>
            </div>

            <div s="col-sm-6">
              <label> Country Flag</label>
              <div className="form-group">
                <label htmlFor="file">
                  <Publish className="shopErectionUpdateIcon" />
                </label>

                <input
                  type="file"
                  id="file"
                  style={{ display: 'none' }}
                  name="file"
                  // value={values.adImage}
                  onChange={(e) => setFile(e.target.files[0])}
                />
                {file && <span>{file.name}</span>}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                {values?.flag && (
                  <img className="userUpdateImg" src={values?.flag} alt="" />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="form-group submit">
          <Link to={'/bcountries'} className="btn">
            Cancel
          </Link>
          <button className="ps-btn">Add/Update Country</button>
        </div>
      </form>
    </div>
  );
};

export default CountryForm;
