import React from 'react'
import "./admindashboard.scss";
import Topbar from "../../components/topbar/Topbar"
import SocialSidebar from "../../components/socialsidebar/SocialSidebar"
// import SingleProduct from "../../components/singleproduct/SingleProduct";
import AdminBoard from "../../components_admin/adminboard/AdminBoard"
import AdminRightbar from "../../components_admin/adminrightbar/AdminRightbar";



const AdminDashboard = () => {
    return (
        <>
         

                {/* Changing pages rendering starts here */}
                
                <>
                    <div className="adminRight">
                
                        <div className="adminBottom">
                            
                        <AdminBoard/>
                        
                        </div>
                       
                                            
                    </div>

                    <>
                    <AdminRightbar Admin/>
                    </>
                </>
                 {/* Changing pages rendering ends here */}
                
           
        </>
    )
}

export default AdminDashboard
