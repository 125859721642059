import * as api from '../api/apiRoutes';
import {
  produnit,
  produnitSuccess,
  produnitFailure,
  getProdunitById,
  getProdunitByIdSuccess,
  getProdunitByIdFailure,
  updateProdunit,
  updateProdunitSuccess,
  updateProdunitFailure,
  createProdunit,
  createProdunitSuccess,
  createProdunitFailure,
} from './actionProdunit';
const Cookies = require('js-cookie');

export const getProductUnits = () => async (dispatch) => {
  try {
    dispatch(produnit());
    const { data } = await api.getProdUnits();
    console.log('procatData :', data);

    // dispatch({
    //   type: FETCH_ALL,
    //   payload: { data, currentPage, numberOfPages },
    // });
    dispatch(produnitSuccess(data));
  } catch (error) {
    console.log(error);
    dispatch(produnitFailure({ error: error.message }));
  }
};

//get single Desination
export const getProdUnitWithId = (id) => async (dispatch) => {
  dispatch(getProdunitById());
  // console.log('Acton start');
  try {
    const { data } = await api.getProdUnitById(id);
    // console.log('MydesigByID :', data);

    dispatch(getProdunitByIdSuccess(data));
  } catch (error) {
    console.log(error);
    dispatch(getProdunitByIdFailure({ error: error.message }));
  }
};

//update a Product Category
export const updateProductUnits = (id, values) => async (dispatch) => {
  // console.log('repoValues', values);
  dispatch(updateProdunit());
  try {
    const { data } = await api.updateProdUnitById(id, values);
    // console.log('actionData :', data);

    dispatch(updateProdunitSuccess(data));
    alert('Product unit updated');
  } catch (error) {
    console.log(error);
    dispatch(updateProdunitFailure({ message: error.message }));
  }
};

//Create a Desination
export const createProductUnit = (values) => async (dispatch) => {
  try {
    dispatch(createProdunit());
    const { data } = await api.createProdUnit(values);
    // console.log('actionData :', data);
    dispatch(createProdunitSuccess(data));
    // console.log('newDesination:', data);
    alert('Product unit added');
  } catch (error) {
    console.log(error);
    dispatch(createProdunitFailure({ error: error.message }));
  }
};
