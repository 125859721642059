import { actionTypes } from './actionPostCat';
import Cookies from 'js-cookie';

export const initState = {
  postcats: [],
  isFetching: false,
  error: false,
};

function reducer(state = initState, action) {
  switch (action.type) {
    case 'START_LOADING':
      return { ...state, isFetching: true };
    case 'END_LOADING':
      return { ...state, isFetching: false };
    //Get MARKET
    case actionTypes.POSTCAT_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.POSTCAT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        postcats: action.payload,
        error: false,
      };
    case actionTypes.POSTCAT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Get MARKETById:
    case actionTypes.GET_POSTCAT_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.GET_POSTCAT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        postcat: action.payload,
        error: false,
      };
    case actionTypes.GET_POSTCAT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Update A MARKETnationsById:
    case actionTypes.UPDATE_POSTCAT_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.UPDATE_POSTCAT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        postcats: state.postcats.map((postcat) =>
          postcat._id === action.payload._id ? action.payload : postcat
        ),
        error: false,
      };
    case actionTypes.UPDATE_POSTCAT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Create A New MARKETnationsById:
    case actionTypes.CREATE_POSTCAT_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.CREATE_POSTCAT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        postcats: [...state.postcats, action.payload],
        error: false,
      };
    case actionTypes.CREATE_POSTCAT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };

    default:
      return { ...state };
  }
}
export default reducer;
