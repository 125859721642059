import React, { Fragment, useEffect } from 'react';
import './managedepartments.scss';
import ShopRightbar from '../../components_shops/shoprightbar/ShopRightbar';
import { DataGrid } from '@material-ui/data-grid';
import { DeleteOutline, Edit } from '@material-ui/icons';
import {
  Paper,
  Typography,
  CircularProgress,
  Divider,
} from '@material-ui/core/';
import { productRows } from '../../dummyData';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDepartments } from '../../store/departments/repository_dept';

const ShopProductList = () => {
  const dispatch = useDispatch();
  const { departments, isFetching } = useSelector((state) => state.depts);

  useEffect(() => {
    const data = dispatch(getDepartments());

    // setPagePosts(data);
  }, [dispatch]);

  const handleDelete = (id) => {
    // setData(data.filter((item) => item.id !== id));
  };

  const columns = [
    // { field: '_id', headerName: 'ID', width: 90 },
    {
      field: 'name',
      headerName: 'Department',
      width: 300,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            {/* <img className="productListImg" src={params.row.img} alt="" /> */}
            <h6> {params.row.name}</h6>
          </div>
        );
      },
    },

    {
      field: 'key',
      headerName: 'Pathfinder',
      width: 300,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            {/* <img className="productListImg" src={params.row.img} alt="" /> */}
            <h6> {params.row.key}</h6>
          </div>
        );
      },
    },
    // { field: 'stock', headerName: 'Stock', width: 200 },
    // {
    //   field: 'status',
    //   headerName: 'Status',
    //   width: 120,
    // },
    // {
    //   field: 'price',
    //   headerName: 'Price',
    //   width: 160,
    // },
    {
      field: 'action',
      headerName: 'Action',
      width: 300,
      renderCell: (params) => {
        // return (
        //   <>
        //     <Link to={"/product/" + params.row.id}>
        //       <button className="productListEdit">Edit</button>
        //     </Link>
        //     <DeleteOutline
        //       className="productListDelete"
        //       onClick={() => handleDelete(params.row.id)}
        //     />
        //   </>
        // );
        return (
          <>
            <div className="actionButtons">
              <Link to={'/department/update/' + params.row._id}>
                <Edit className="userListEditIcon" />
                {/* <button className="userListEdit">Edit</button> */}
              </Link>
              <DeleteOutline
                className="userListDelete"
                onClick={() => handleDelete(params.row._id)}
              />
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      {/* Changing pages rendering starts here */}

      <>
        {/* start of table grid */}
        <div className="userRight">
          <div className="userBottom">
            <div className="userList">
              <div className="userTitleContainer">
                <h1 className="userTitle">Departments</h1>
                <Link to="/department/add">
                  <button className="userAddButton">Add Department</button>
                </Link>
              </div>

              {/* <div className="usertable"> */}
              {isFetching ? (
                <Paper elevation={6} className="loadingPaper">
                  <CircularProgress size="7em" className="loadingPaper" />
                </Paper>
              ) : (
                <DataGrid
                  className="tableProps"
                  rows={departments}
                  disableSelectionOnClick
                  columns={columns}
                  getRowId={(row) => row._id}
                  pageSize={8}
                  checkboxSelection
                />
              )}
            </div>
          </div>
        </div>

        <>
          <ShopRightbar Shop />
        </>
      </>
      {/* Changing pages rendering ends here */}
    </>
  );
};

export default ShopProductList;
