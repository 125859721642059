import { actionTypes } from './actionPost';
import Cookies from 'js-cookie';

export const initState = {
  posts: [],
  post: {},
  isFetching: false,
  error: false,
};

function reducer(state = initState, action) {
  switch (action.type) {
    case 'START_LOADING':
      return { ...state, isFetching: true };
    case 'END_LOADING':
      return { ...state, isFetching: false };
    //Get Admin Posts
    case actionTypes.POST_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.POST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        posts: action.payload,
        error: false,
      };
    case actionTypes.POST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Get Admin Posts ById:
    case actionTypes.GET_POST_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.GET_POST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        post: action.payload,
        error: false,
      };
    case actionTypes.GET_POST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Update A Admin Posts ById:
    case actionTypes.UPDATE_POST_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.UPDATE_POST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        posts: state.posts.map((post) =>
          post._id === action.payload._id ? action.payload : post
        ),
        error: false,
      };
    case actionTypes.UPDATE_POST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Create Admin Posts ById:
    case actionTypes.CREATE_POST_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.CREATE_POST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        posts: [...state.posts, action.payload],
        error: false,
      };
    case actionTypes.CREATE_POST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };

    default:
      return { ...state };
  }
}
export default reducer;
