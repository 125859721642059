import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './shoprightbar.scss';
import {
  LineStyle,
  Timeline,
  TrendingUp,
  PermIdentity,
  Storefront,
  AttachMoney,
  BarChart,
  MailOutline,
  DynamicFeed,
  ChatBubbleOutline,
  WorkOutline,
  Report,
  Money,
} from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { getDeptById } from '../../store/api/apiRoutes';

const ShopRightbar = () => {
  const { isFetching, user, error } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [deptData, setDeptData] = useState({});

  useEffect(() => {
    if (user) {
      checkAdminDepartment();
    }
  }, [user]);

  console.log('User_Deoartment', deptData);

  const checkAdminDepartment = async () => {
    setLoading(true);
    try {
      if (user) {
        await getDeptById(user.department).then((response) => {
          // setDepartment(response.data);
          setDeptData(response.data);
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const checkAdminDesignation = async () => {};

  console.log('ShopRightBarLinks', user);
  return (
    <div className="shoprightbar">
      {user?.isAdmin && (
        <div className="shopbarWrapper">
          {/* Human Resource */}
          {(deptData.name === 'Human Resource' ||
            deptData.name === 'Admin') && (
            <div className="shopbarMenu">
              <h3 className="shopbarTitle">Dashboard</h3>
              <ul className="shopbarList">
                <Link to="/shop/dashboard">
                  <li className="shopbarListItem active">
                    <LineStyle className="shopbarIcon" />
                    Dashboard
                  </li>
                </Link>
                <Link to="/customers">
                  <li className="shopbarListItem">
                    <PermIdentity className="shopbarIcon" />
                    AfricanShop Users
                  </li>
                </Link>
                <li className="shopbarListItem">
                  <Timeline className="shopbarIcon" />
                  Analytics
                </li>
                <li className="shopbarListItem">
                  <TrendingUp className="shopbarIcon" />
                  Sales
                </li>
                <Link to="/staff">
                  <li className="shopbarListItem">
                    <PermIdentity className="shopbarIcon" />
                    ControlPanel Users
                  </li>
                </Link>
              </ul>
            </div>
          )}

          {/* Admin Actions */}
          {deptData.name === 'Admin' && (
            <div className="shopbarMenu">
              <h3 className="shopbarTitle">Admin Tasks</h3>
              <ul className="shopbarList">
                <Link to="/shop/dashboard">
                  <li className="shopbarListItem active">
                    <LineStyle className="shopbarIcon" />
                    Dashboard
                  </li>
                </Link>
                <Link to="/listinfo">
                  <li className="shopbarListItem">
                    <WorkOutline className="shopbarIcon" />
                    Informations
                  </li>
                </Link>
                <Link to="/departments">
                  <li className="shopbarListItem">
                    <PermIdentity className="shopbarIcon" />
                    Manage Department
                  </li>
                </Link>
                <Link to="/designations">
                  <li className="shopbarListItem">
                    <PermIdentity className="shopbarIcon" />
                    Manage Designation
                  </li>
                </Link>
                <Link to="/bcountries">
                  <li className="shopbarListItem">
                    <PermIdentity className="shopbarIcon" />
                    Business Countries
                  </li>
                </Link>
                <Link to="/bstates">
                  <li className="shopbarListItem">
                    <PermIdentity className="shopbarIcon" />
                    Business States
                  </li>
                </Link>

                <Link to="/tradehubs">
                  <li className="shopbarListItem">
                    <AttachMoney className="shopbarIcon" />
                    Trade Hubs
                  </li>
                </Link>
                <li className="shopbarListItem">
                  <BarChart className="shopbarIcon" />
                  Reports
                </li>
              </ul>
            </div>
          )}

          {/* Sales and Marketing Actions */}
          {(deptData.name === 'Sales And Marketing' ||
            deptData.name === 'Admin') && (
            <div className="shopbarMenu">
              <h3 className="shopbarTitle">Sales & Marketting</h3>
              <ul className="shopbarList">
                <Link to="/shop/dashboard">
                  <li className="shopbarListItem active">
                    <LineStyle className="shopbarIcon" />
                    Dashboard
                  </li>
                </Link>
                <Link to="/procats">
                  <li className="shopbarListItem">
                    <DynamicFeed className="shopbarIcon" />
                    Product Categories
                  </li>
                </Link>
                <Link to="/produnits">
                  <li className="shopbarListItem">
                    <DynamicFeed className="shopbarIcon" />
                    Product Units
                  </li>
                </Link>
                <Link to="/marketcats">
                  <li className="shopbarListItem">
                    <DynamicFeed className="shopbarIcon" />
                    Market Categories
                  </li>
                </Link>
                <Link to="/listmarkets">
                  <li className="shopbarListItem">
                    <DynamicFeed className="shopbarIcon" />
                    Markets
                  </li>
                </Link>
                <Link to="/listplans">
                  <li className="shopbarListItem">
                    <DynamicFeed className="shopbarIcon" />
                    Shop Plans
                  </li>
                </Link>
                <Link to="/listshops">
                  <li className="shopbarListItem">
                    <DynamicFeed className="shopbarIcon" />
                    Shops
                  </li>
                </Link>
                <Link to="/admin/listorders">
                  <li className="shopbarListItem">
                    <DynamicFeed className="shopbarIcon" />
                    Orders
                  </li>
                </Link>
              </ul>
            </div>
          )}

          {/* {(deptData.name === 'C.E.O' || deptData.name === 'Admin') && ()} */}
          {/* Leaders Routs for analytics */}
          {(deptData.name === 'C.E.O' || deptData.name === 'Admin') && (
            <div className="shopbarMenu">
              <h3 className="shopbarTitle">Company Shop</h3>
              <ul className="shopbarList">
                <Link to="/shop/dashboard">
                  <li className="shopbarListItem active">
                    <LineStyle className="shopbarIcon" />
                    Dashboard
                  </li>
                </Link>
                <li className="shopbarListItem">
                  <WorkOutline className="shopbarIcon" />
                  Manage
                </li>
                <Link to="/myshop/products">
                  <li className="shopbarListItem">
                    <Storefront className="shopbarIcon" />
                    Products
                  </li>
                </Link>
                <li className="shopbarListItem">
                  <Timeline className="shopbarIcon" />
                  Analytics
                </li>
                <li className="shopbarListItem">
                  <Report className="shopbarIcon" />
                  Reports
                </li>
              </ul>
            </div>
          )}

          {/* Finannce Departments and Actions */}
          {(deptData.name === 'Finance' ||
            // deptData.name === 'C.E.O' ||
            deptData.name === 'Admin') && (
            <div className="shopbarMenu">
              <h3 className="shopbarTitle">Finance and Accounts</h3>
              <ul className="shopbarList">
                <Link to="/shop/dashboard">
                  <li className="shopbarListItem active">
                    <LineStyle className="shopbarIcon" />
                    Dashboard
                  </li>
                </Link>
                <li className="shopbarListItem">
                  <WorkOutline className="shopbarIcon" />
                  Manage
                </li>
                <li className="shopbarListItem">
                  <Timeline className="shopbarIcon" />
                  Analytics
                </li>
                <Link to="/finance/cashout_orderslist">
                  <li className="shopbarListItem">
                    <Storefront className="shopbarIcon" />
                    Cash Out Orders
                  </li>
                </Link>

                <li className="shopbarListItem">
                  <Report className="shopbarIcon" />
                  Account Reports
                </li>

                <Link to="/finance/withdrwals">
                  <li className="shopbarListItem">
                    <Money className="shopbarIcon" />
                    Withdrawal Requests
                  </li>
                </Link>
              </ul>
            </div>
          )}

          {/* Editors Departments and Actions */}
          {(deptData.name === 'Editors' || deptData.name === 'Admin') && (
            <div className="shopbarMenu">
              <h3 className="shopbarTitle">Editors</h3>
              <ul className="shopbarList">
                <Link to="/shop/dashboard">
                  <li className="shopbarListItem active">
                    <LineStyle className="shopbarIcon" />
                    Dashboard
                  </li>
                </Link>
                <Link to="/listads">
                  <li className="shopbarListItem">
                    <DynamicFeed className="shopbarIcon" />
                    Advertisements
                  </li>
                </Link>
                <Link to="/listpostcats">
                  <li className="shopbarListItem">
                    <DynamicFeed className="shopbarIcon" />
                    Post Categories
                  </li>
                </Link>
                <Link to="/listposts">
                  <li className="shopbarListItem">
                    <DynamicFeed className="shopbarIcon" />
                    News Posts
                  </li>
                </Link>
                <Link to="/listfaqs">
                  <li className="shopbarListItem">
                    <DynamicFeed className="shopbarIcon" />
                    FAQs
                  </li>
                </Link>
                <Link to="/listpartners">
                  <li className="shopbarListItem">
                    <DynamicFeed className="shopbarIcon" />
                    Partners
                  </li>
                </Link>
                <Link to="/listbanners">
                  <li className="shopbarListItem">
                    <DynamicFeed className="shopbarIcon" />
                    Banners
                  </li>
                </Link>
                <li className="shopbarListItem">
                  <WorkOutline className="shopbarIcon" />
                  Manage
                </li>
                <li className="shopbarListItem">
                  <Timeline className="shopbarIcon" />
                  Analytics
                </li>
                <li className="shopbarListItem">
                  <Report className="shopbarIcon" />
                  Reports
                </li>
              </ul>
            </div>
          )}

          {/* {(deptData.name === 'C.E.O' || deptData.name === 'Admin') && ()} */}
          {/* Advocacy/Legal */}
          {(deptData.name === 'Advocacy/Legal' ||
            deptData.name === 'Admin') && (
            <div className="shopbarMenu">
              <h3 className="shopbarTitle">Advocacy</h3>
              <ul className="shopbarList">
                <Link to="/shop/dashboard">
                  <li className="shopbarListItem active">
                    <LineStyle className="shopbarIcon" />
                    Dashboard
                  </li>
                </Link>
                <Link to="/listadvocacy">
                  <li className="shopbarListItem">
                    <WorkOutline className="shopbarIcon" />
                    Avocacy Documents
                  </li>
                </Link>
              </ul>
            </div>
          )}

          {/* For main start up admin user accountname starts here */}
          {user.name === 'Eke Ferdinand Udo' && deptData.name !== 'Admin' && (
            <>
              <div className="shopbarMenu">
                <h3 className="shopbarTitle">Dashboard</h3>
                <ul className="shopbarList">
                  <Link to="/shop/dashboard">
                    <li className="shopbarListItem active">
                      <LineStyle className="shopbarIcon" />
                      Dashboard
                    </li>
                  </Link>
                  <Link to="/customers">
                    <li className="shopbarListItem">
                      <PermIdentity className="shopbarIcon" />
                      AfricanShop Users
                    </li>
                  </Link>
                  <li className="shopbarListItem">
                    <Timeline className="shopbarIcon" />
                    Analytics
                  </li>
                  <li className="shopbarListItem">
                    <TrendingUp className="shopbarIcon" />
                    Sales
                  </li>
                  <Link to="/staff">
                    <li className="shopbarListItem">
                      <PermIdentity className="shopbarIcon" />
                      ControlPanel Users
                    </li>
                  </Link>
                </ul>
              </div>

              <div className="shopbarMenu">
                <h3 className="shopbarTitle">Admin Tasks</h3>
                <ul className="shopbarList">
                  <Link to="/shop/dashboard">
                    <li className="shopbarListItem active">
                      <LineStyle className="shopbarIcon" />
                      Dashboard
                    </li>
                  </Link>
                  <Link to="/departments">
                    <li className="shopbarListItem">
                      <PermIdentity className="shopbarIcon" />
                      Manage Department
                    </li>
                  </Link>
                  <Link to="/designations">
                    <li className="shopbarListItem">
                      <PermIdentity className="shopbarIcon" />
                      Manage Designation
                    </li>
                  </Link>
                  <Link to="/bcountries">
                    <li className="shopbarListItem">
                      <PermIdentity className="shopbarIcon" />
                      Business Countries
                    </li>
                  </Link>
                  <Link to="/bstates">
                    <li className="shopbarListItem">
                      <PermIdentity className="shopbarIcon" />
                      Business States
                    </li>
                  </Link>

                  <Link to="/tradehubs">
                    <li className="shopbarListItem">
                      <AttachMoney className="shopbarIcon" />
                      Trade Hubs
                    </li>
                  </Link>
                  <li className="shopbarListItem">
                    <BarChart className="shopbarIcon" />
                    Reports
                  </li>
                </ul>
              </div>
            </>
          )}

          {/* For main start up admin user accountname ends here */}
        </div>
      )}
    </div>
  );
};

export default ShopRightbar;
