import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import './style.scss';

import ProtectedRoute from './authroutes/ProtectedRoute';

import Topbar from './components/topbar/Topbar';
import SocialSidebar from './components/socialsidebar/SocialSidebar';
import Home from './pages/home/Home';
import Login from './pages/login/Login';
import Profile from './pages/profile/Profile';
import EditProfile from './pages/profile/EditProfile';
// import Register from "./pages/register/Register";
// e-commerce concerns
import ShoppingArea from './pages/shopping/ShoppingArea';
import ProductDetail from './pages/productdetails/ProductDetail';
// Newsblog concerns
import NewsBlog from './pages/newsblog/NewsBlog';
import NewsBlogSingle from './pages/newsblogsingle/NewsBlogSingle';
// Shop concerns
import ShopDashboard from './pages_shops/shopdashboard/ShopDashboard';
import UserList from './pages_shops/userList/UserList';
import UsersCustomer from './pages_shops/usersCustomer/UsersCustomer';
import ShopProductList from './pages_shops/shopproducts/ShopProductList';
import ShopUpdateProduct from './pages_shops/shopproducts/ShopUpdateProduct';
import AddProducts from './pages_shops/shopproducts/AddProducts';
//departments
import DepartmentList from './pages_shops/departments/DepartmentList';
import AddDepartment from './pages_shops/departments/AddDepartment';
import UpdateDepartment from './pages_shops/departments/UpdateDepartment';
//designations
import DesignationList from './pages_shops/designations/DesignationList';
import AddDesignation from './pages_shops/designations/AddDesignation';
import UpdateDesignation from './pages_shops/designations/UpdateDesignation';
//Business Country
import CountryList from './pages_shops/businesscountry/CountryList';
import AddCountry from './pages_shops/businesscountry/AddCountry';
import UpdateCountry from './pages_shops/businesscountry/UpdateCountry';
//Business States
import StateList from './pages_shops/bstates/StateList';
import AddState from './pages_shops/bstates/AddState';
import UpdateState from './pages_shops/bstates/UpdateState';
//TradeHubs
import TradehubList from './pages_shops/tradehubs/TradehubList';
import AddTradehub from './pages_shops/tradehubs/AddTradehub';
import UpdateTradehub from './pages_shops/tradehubs/UpdateTradehub';
//Product Categories
import ProductCatList from './pages_shops/productcategory/ProductCatList';
import AddProductCat from './pages_shops/productcategory/AddProductCat';
import UpdateProductCat from './pages_shops/productcategory/UpdateProductCat';
//Product Units
import ProductUnitList from './pages_shops/productunits/ProductUnitList';
import AddProductUnit from './pages_shops/productunits/AddProductUnit';
import UpdateProductUnit from './pages_shops/productunits/UpdateProductUnit';
//Market Category
import MarketCatList from './pages_shops/marketcategories/MarketCatList';
import AddMarketCat from './pages_shops/marketcategories/AddMarketCat';
import UpdateMarketCat from './pages_shops/marketcategories/UpdateMarketCat';
//Markets
import MarketList from './pages_shops/markets/MarketList';
import AddMarket from './pages_shops/markets/AddMarket';
import UpdateMarket from './pages_shops/markets/UpdateMarket';
//Shop Plans
import ShopPlanList from './pages_shops/shopplans/ShopPlanList';
import AddShopPlan from './pages_shops/shopplans/AddShopPlan';
import UpdateShopPlan from './pages_shops/shopplans/UpdateShopPlan';
//User Shops
// import ShopList from './pages_shops/shops/shoporder/OrderList';
import ShopList from './pages_shops/shops/ShopList';
import AddShop from './pages_shops/shops/AddShop';
// import UpdateShop from './pages_shops/shops/UpdateOrder';
import UpdateShop from './pages_shops/shops/UpdateShop';

//Editors Ads
import AdsList from './pages_shops/ads/AdsList';
import AddAds from './pages_shops/ads/AddAds';
import UpdateAds from './pages_shops/ads/UpdateAds';

//Editors Post Categories
import PostCatList from './pages_shops/postcategory/PostCatList';
import AddPostCat from './pages_shops/postcategory/AddPostCat';
import UpdatePostCat from './pages_shops/postcategory/UpdatePostCat';

//Editors Posts
import PostList from './pages_shops/posts/PostList';
import AddPost from './pages_shops/posts/AddPost';
import UpdatePost from './pages_shops/posts/UpdatePost';

//Editors Partners
import PartnerList from './pages_shops/partners/PartnerList';
import AddPartner from './pages_shops/partners/AddPartner';
import UpdatePartner from './pages_shops/partners/UpdatePartner';
//Editors Faqs
import FaqList from './pages_shops/faqs/FaqList';
import AddFaq from './pages_shops/faqs/AddFaq';
import UpdateFaq from './pages_shops/faqs/UpdateFaq';

//Editors Banners
import BannerList from './pages_shops/barners/BannerList';
import AddBanner from './pages_shops/barners/AddBanner';
import UpdateBanner from './pages_shops/barners/UpdateBanner';

//Advocacy Concers Oages
import AdvocacyDocsList from './pages_shops/advocacy/AdvocacyDocsList';
import AddAdvocacyDocs from './pages_shops/advocacy/AddAdvocacyDocs';
import UpdateAdvocacyDocs from './pages_shops/advocacy/UpdateAdvocacyDocs';

//Info Concerns Pages
import InfoList from './pages_shops/infos/InfoList';
import AddInfo from './pages_shops/infos/AddInfo';
import UpdateInfo from './pages_shops/infos/UpdateInfo';

// Admin concerns
import AdminDashboard from './pages_admin/admindashboard/AdminDashboard';
import ProductList from './pages_admin/productList/ProductList';
import GuestHome from './pages/guesthome/GuestHome';

import { Fragment, useContext } from 'react';
import { AuthContext } from './context/AuthContext';

import Cart from './pages/cart/Cart';
import Markets from './pages/markets/Markets';
import StateMarketLead from './pages/markets/StateMarketLead';
import OpertionalShops from './pages/markets/OpertionalShops';
import ShopProducts from './pages/markets/ShopProducts';
import ShopPlans from './pages/shoperection/ShopPlans';
import CreateShopForm from './pages/shoperection/CreateShopForm';
import { useDispatch, useSelector } from 'react-redux';
//shop orders
import OrderList from './pages_shops/shops/shoporder/OrderList';
import UpdateOrder from './pages_shops/shops/shoporder/UpdateOrder';
import OrderListDetail from './pages_shops/shops/shoporder/OrderListDetail';
//finance handle shop orders
import FinanceOrderList from './pages_shops/finance/cashoutorders/FinanceOrderList';
import FinanceUpdateOrder from './pages_shops/finance/cashoutorders/FinanceUpdateOrder';
import FinanceOrderListDetail from './pages_shops/finance/cashoutorders/FinanceOrderListDetail';
import StaffList from './pages_shops/staffList/StaffList';
import AdminStaff from './pages_shops/staffList/AdminStaff';
import WithdrawalList from './pages_shops/finance/withdrawals/WithdrawalList';
import ApprovedWithdrawalList from './pages_shops/finance/withdrawals/ApprovedWithdrawalList';
import ManageWithdrawals from './pages_shops/finance/withdrawals/ManageWithdrawals';
import AddStaff from './pages_shops/staffList/AddStaff';
import DisciplineStaff from './pages_shops/staffList/DisciplineStaff';

// console.log = console.warn = console.error = () => {};
function App() {
  // const { user } = useContext(AuthContext);
  const { isFetching, user, error } = useSelector((state) => state.auth);

  return (
    <Router>
      <Topbar user={user} />

      <div className="componentsContainer">
        <SocialSidebar />
        <Switch>
          <Route exact path="/">
            <GuestHome />
          </Route>

          <Route path="/login">{user ? <Redirect to="/" /> : <Login />}</Route>

          <Route exact path="/feeds">
            <Home />
          </Route>

          {/*================== shopping area concern starts ==========================*/}
          {/* <Route path="/shopping/area">
            <ShoppingArea />
          </Route>

          <Route path="/product/detail">
            <ProductDetail />
          </Route>
          <Route path="/cart" component={Cart} />
          <Route path="/markets" component={Markets} />
          <Route path="/state_province/markets" component={StateMarketLead} />
          <Route path="/operational/market/shops" component={OpertionalShops} />
          <Route path="/shop/products" component={ShopProducts} /> */}

          {/*=============================== shopping area concern ends ===================*/}

          {/*========================= News blog area concern starts============= */}
          <Route path="/newsblog">
            <NewsBlog />
          </Route>

          <Route path="/news/single/:id">
            <NewsBlogSingle user={user} />
          </Route>
          {/*==================== Newsblog area concern ends=================== */}

          {/*============================================================================================================ */}
          {/*======================Shop area concern starts====================================================================================== */}
          <ProtectedRoute path="/profile" component={Profile} />

          <ProtectedRoute path="/edit/profile/" component={EditProfile} />

          {/*====================== Shop area concern starts=======================================*/}
          {user && user?.isShopExist === false ? (
            <ProtectedRoute
              path="/selected/plan/:id"
              component={CreateShopForm}
            />
          ) : (
            <Redirect to="/" />
          )}
          <ProtectedRoute path="/setup/shop" component={ShopPlans} />

          <ProtectedRoute path="/shop/dashboard" component={ShopDashboard} />

          {/* Admin Users/ControlPanel Users */}
          <ProtectedRoute path="/staff" component={StaffList} />
          <ProtectedRoute path="/add/adminusers" component={AddStaff} />
          <ProtectedRoute path="/adminstaff/:id" component={AdminStaff} />
          <ProtectedRoute
            path="/descipline/staff/:id"
            component={DisciplineStaff}
          />

          {/* Users/Custommers */}
          <ProtectedRoute path="/customers" component={UserList} />
          <ProtectedRoute path="/customer/:id" component={UsersCustomer} />

          {/* departments */}
          <ProtectedRoute path="/departments" component={DepartmentList} />
          <ProtectedRoute path="/department/add" component={AddDepartment} />
          <ProtectedRoute
            path="/department/update/:id"
            component={UpdateDepartment}
          />
          {/* designations */}
          <ProtectedRoute path="/designations" component={DesignationList} />
          <ProtectedRoute path="/designation/add" component={AddDesignation} />
          <ProtectedRoute
            path="/designation/update/:id"
            component={UpdateDesignation}
          />
          {/* Business Countries */}
          <ProtectedRoute path="/bcountries" component={CountryList} />
          <ProtectedRoute path="/bcountry/add" component={AddCountry} />
          <ProtectedRoute
            path="/bcountry/update/:id"
            component={UpdateCountry}
          />
          {/* Business States */}
          <ProtectedRoute path="/bstates" component={StateList} />
          <ProtectedRoute path="/bstate/add" component={AddState} />
          <ProtectedRoute path="/bstate/update/:id" component={UpdateState} />

          {/* Trade Hubs */}
          <ProtectedRoute path="/tradehubs" component={TradehubList} />
          <ProtectedRoute path="/tradehub/add" component={AddTradehub} />
          <ProtectedRoute
            path="/tradehub/update/:id"
            component={UpdateTradehub}
          />

          {/* Product Categories */}
          <ProtectedRoute path="/procats" component={ProductCatList} />
          <ProtectedRoute path="/procat/add" component={AddProductCat} />
          <ProtectedRoute
            path="/procat/update/:id"
            component={UpdateProductCat}
          />

          {/* Product Units */}
          <ProtectedRoute path="/produnits" component={ProductUnitList} />
          <ProtectedRoute path="/produnit/add" component={AddProductUnit} />
          <ProtectedRoute
            path="/produnit/update/:id"
            component={UpdateProductUnit}
          />
          {/* Market Categories */}
          <ProtectedRoute path="/marketcats" component={MarketCatList} />
          <ProtectedRoute path="/marketcat/add" component={AddMarketCat} />
          <ProtectedRoute
            path="/marketcat/update/:id"
            component={UpdateMarketCat}
          />
          {/* Market */}
          <ProtectedRoute path="/listmarkets" component={MarketList} />
          <ProtectedRoute path="/market/add" component={AddMarket} />
          <ProtectedRoute path="/market/update/:id" component={UpdateMarket} />

          {/* Shop Plan */}
          <ProtectedRoute path="/listplans" component={ShopPlanList} />
          <ProtectedRoute path="/plan/add" component={AddShopPlan} />
          <ProtectedRoute path="/plan/update/:id" component={UpdateShopPlan} />

          {/* Shops  concern starts*/}
          <ProtectedRoute path="/listshops" component={ShopList} />
          <ProtectedRoute path="/admin/usershop/add" component={AddShop} />
          <ProtectedRoute
            path="/admin/usershop/update/:id"
            component={UpdateShop}
          />

          {/*============= Shops area concern ends======================================= */}
          {/* Orders  concern starts*/}
          <ProtectedRoute path="/admin/listorders" component={OrderList} />
          <ProtectedRoute
            path="/admin/manageorders/:id"
            component={UpdateOrder}
          />
          {/* <ProtectedRoute
            path="/admin/ordersdetails/:id"
            component={OrderListDetail}
          /> */}
          {/* <ProtectedRoute path="/usershop/add" component={AddShop} />
          <ProtectedRoute path="/usershop/update/:id" component={UpdateShop} /> */}
          {/*============= Shops area concern ends======================================= */}

          {/* Ads */}
          <ProtectedRoute path="/listads" component={AdsList} />
          <ProtectedRoute path="/editorads/add" component={AddAds} />
          <ProtectedRoute path="/editorads/update/:id" component={UpdateAds} />

          {/* Post Categories */}
          <ProtectedRoute path="/listpostcats" component={PostCatList} />
          <ProtectedRoute path="/editorpostcat/add" component={AddPostCat} />
          <ProtectedRoute
            path="/editorpostcat/update/:id"
            component={UpdatePostCat}
          />

          {/* Posts */}
          <ProtectedRoute path="/listposts" component={PostList} />
          <ProtectedRoute path="/editorpost/add" component={AddPost} />
          <ProtectedRoute
            path="/editorpost/update/:id"
            component={UpdatePost}
          />

          {/* Partners */}
          <ProtectedRoute path="/listpartners" component={PartnerList} />
          <ProtectedRoute path="/editorpartner/add" component={AddPartner} />
          <ProtectedRoute
            path="/editorpartner/update/:id"
            component={UpdatePartner}
          />

          {/* Faqs */}
          <ProtectedRoute path="/listfaqs" component={FaqList} />
          <ProtectedRoute path="/editorfaq/add" component={AddFaq} />
          <ProtectedRoute path="/editorfaq/update/:id" component={UpdateFaq} />

          {/* Banners */}
          <ProtectedRoute path="/listbanners" component={BannerList} />
          <ProtectedRoute path="/editorbanner/add" component={AddBanner} />
          <ProtectedRoute
            path="/editorbanner/update/:id"
            component={UpdateBanner}
          />

          {/*============= Advocates area concern ends======================================= */}
          {/* AdvocacyDocs */}
          <ProtectedRoute path="/listadvocacy" component={AdvocacyDocsList} />
          <ProtectedRoute path="/advocacy/add" component={AddAdvocacyDocs} />
          <ProtectedRoute
            path="/advocacy/update/:id"
            component={UpdateAdvocacyDocs}
          />

          {/*============= Infos area concern ends======================================= */}
          {/* InfoDocs */}
          <ProtectedRoute path="/listinfo" component={InfoList} />
          <ProtectedRoute path="/info/add" component={AddInfo} />
          <ProtectedRoute path="/info/update/:id" component={UpdateInfo} />

          {/*============= Refactor Area======================================= */}
          {/* manage products */}
          <ProtectedRoute path="/myshop/products" component={ShopProductList} />
          <ProtectedRoute path="/shop/add_products" component={AddProducts} />
          <ProtectedRoute
            path="/shop/product/:id"
            component={ShopUpdateProduct}
          />

          {/*============= shop area concern ends======================================= */}

          {/* Admin area concern starts */}

          <ProtectedRoute path="/admin/dashboard" component={AdminDashboard} />

          <ProtectedRoute path="/products" component={ProductList} />
          {/* Admin area concern ends */}

          {/*============= Accounts and Finance area concern starts======================================= */}
          {/* cashout orders  concern starts*/}
          <ProtectedRoute
            path="/finance/cashout_orderslist"
            component={FinanceOrderList}
          />
          <ProtectedRoute
            path="/finance/cashout_orders/:id"
            component={FinanceUpdateOrder}
          />

          {/*Handle  Withdrawal Requests */}
          <ProtectedRoute
            path="/finance/withdrwals"
            component={WithdrawalList}
          />

          <ProtectedRoute
            path="/finance/approved_withdrwals"
            component={ApprovedWithdrawalList}
          />

          <ProtectedRoute
            path="/finance/manage_withdrwals/:id"
            component={ManageWithdrawals}
          />

          {/*============= Accounts and Finance area concern ends======================================= */}
        </Switch>
      </div>
    </Router>
  );
}

export default App;
