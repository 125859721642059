import { combineReducers } from 'redux';
import reducerAuth from '../auth/reducerAuth';
import reducerDept from '../departments/reducerDept';
import reducerDesignation from '../designations/reducerDesignation';
import reducerCountry from '../country/reducerCountry';
import reducerStates from '../states/reducerStates';
import reducerTradehub from '../tradehubs/reducerTradehub';
import reducerProcat from '../procats/reducerProcat';
import reducerProdunit from '../produnits/reducerProdunit';
import reducerMarketcat from '../marketcats/reducerMarketcat';
import reducerMarket from '../markets/reducerMarket';

// stratedHere Again
import reducerPostcat from '../postcats/reducerPostCat';
import reducerPost from '../postss/reducerPost';
import reducerFaq from '../faqs/reducerFaq';
import reducerPartner from '../partners/reducerPartner';
import reducerBarner from '../barners/reducerBarner';
import reducerLegal from '../legal/reducerLegal';
import reducerProduct from '../products/reducerProduct';
import reducerInfo from '../info/reducerInfo';
//start again
import reducerShopplan from '../shopplans/reducerShopplan';
import reducerShop from '../shops/reducerShops';

//users
import reducerUser from '../users/reducerUser';
import reducerAdminUser from '../adminusers/reducerAdminUser';

const rootReducer = combineReducers({
  auth: reducerAuth,
  depts: reducerDept,
  desigs: reducerDesignation,
  countries: reducerCountry,
  bstates: reducerStates,
  hubs: reducerTradehub,
  procats: reducerProcat,
  produnits: reducerProdunit,
  marketcats: reducerMarketcat,
  markets: reducerMarket,

  // started here
  postcates: reducerPostcat,
  posts: reducerPost,
  faqs: reducerFaq,
  partners: reducerPartner,
  banners: reducerBarner,
  legals: reducerLegal,
  products: reducerProduct,
  infos: reducerInfo,
  // started here
  shopsubs: reducerShopplan,
  shops: reducerShop,
  //AfricanShop_users
  users: reducerUser,
  adminusers: reducerAdminUser,
});
export default rootReducer;
