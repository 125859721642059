import React from 'react';
import './feed.scss';
import Share from '../share/Share';
import Post from '../post/Post';
import { Posts } from '../../dummyData';
import List from '../list/List';

const Feed = () => {
  return (
    <div className="feed">
      <div className="feedWrapper">
        <Share />
        {Posts.map((p) => (
          <Post key={p.id} post={p} />
        ))}

        {/* <List/> */}
      </div>
    </div>
  );
};

export default Feed;
