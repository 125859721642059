import React, { useEffect, useState } from 'react';
import {
  // CalendarToday,
  // LocationSearching,
  // MailOutline,
  // PermIdentity,
  // PhoneAndroid,
  Publish,
} from '@material-ui/icons';
import { useParams, useHistory, Redirect, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCountries } from '../../store/country/repository_country';
import { getStates } from '../../store/states/repository_states';
import { getMarketCats } from '../../store/marketcats/repository_marketcat';
import {
  createShopRepo,
  getShops,
  updateShops,
} from '../../store/shops/repository_shops';
import useForm from '../../hooks/useForm';
import { getMarkets } from '../../store/markets/repository_market';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import app from '../../firebase';

// const initialFValues = {
//   id: 0,
//   name: '',
//   businesscountry: '',
//   businezstate: '',
//   marketcategory: '',
//   market: '',
//   zipCode: '',
//   localityaddress: '',
// };

const initialFValues = {
  userOwner: '',
  businessCountry: '',
  businezState: '',
  tradehub: '',
  market: '',
  shopname: '',
  coverimage: '',
  shoplogo: '',
  shopphone: '',
  //   verified: { type: Boolean, required: true, default: false },
  shopplan: '',
  //   planrentdate: { type: String },
  //   planrentexpiry: { type: String },
  //   planrentstatus: { type: String },
  postalCode: '',

  // lat: '',
  // lng: '',
  address: '',
  // name: '',
  // vicinity: '',
  // googleAddressId: '',

  // accountnumber: '',
  accountname: '',
  bankname: '',
  // accountbalance: { type: Number, required: true, default: 0.0 },
  shopaccount: {
    accountnumber: '',
    accountname: '',
    bankname: '',
    // accountbalance: { type: Number, required: true, default: 0.0 },
  },

  //   social: {
  //     instagram: { type: String },
  //     twitter: { type: String },
  //     facebook: { type: String },
  //   },
};

const ShopForm = ({ shopplan }) => {
  const { values, setValues, handleInputChange, resetForm } =
    useForm(initialFValues);
  const { id } = useParams();
  console.log('PlanId', id);
  // if(EditShop){
  //   const { ShopId } = useParams();
  // }
  const dispatch = useDispatch();
  const history = useHistory();

  const [coverimage, setCoverImage] = useState(null);
  const [shoplogo, setShopLogo] = useState(null);
  // const [movie, setMovie] = useState(null);

  const { countries, isFetching } = useSelector((state) => state.countries);
  console.log('StateCountyMrkt:', countries);

  const { bstates } = useSelector((state) => state.bstates);
  const { markets } = useSelector((state) => state.markets);

  // const shop = useSelector((state) =>
  //   id ? state.shops.shops.find((shp) => shp._id === id) : null
  // );

  useEffect(() => {
    dispatch(getCountries());
    dispatch(getStates());

    dispatch(getMarkets());

    // dispatch(getShops());
    // if (shop) setValues(shop);
    // setValues(values.shopplan, shopplan._id);
  }, []);
  console.log(id);

  const upload = (items) => {
    if (items) {
      console.log('fileItems', items);
      items.forEach((item) => {
        const fileName = new Date().getTime() + item.label + item.file.name;
        const storage = getStorage(app);
        // const storageRef = ref(storage, fileName);
        const storageRef = ref(storage, `/shops/${fileName}`);
        const uploadTask = uploadBytesResumable(storageRef, item.file);

        // Register three observers:
        // 1. 'state_changed' observer, called any time the state changes
        // 2. Error observer, called on failure
        // 3. Completion observer, called on successful completion

        // start here

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused');
                break;
              case 'running':
                console.log('Upload is running');
                break;
              default:
            }
          },
          (error) => {
            // Handle unsuccessful uploads
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              console.log('valueWithImageFile :', values, {
                [item.label]: downloadURL,
              });
              // const advert = {
              //   ...values,
              //   [item.label]: downloadURL,
              //   shopplan: shopplan._id,
              // };

              setValues((prev) => {
                return {
                  ...prev,
                  shopplan: shopplan._id,
                  [item.label]: downloadURL,
                };
              });
              // setUploaded((prev) => prev + 1);

              console.log('dispatValues', values);
              dispatch(createShopRepo(values));

              resetForm();

              // createShopRepo(advert).then((response) => {
              //   if (response.data.error) {
              //     // console.log(response.data.error);
              //   } else {
              //     // alert(response.data.success);
              //     alert('Ad added');
              //     // resetForm();
              //   }
              // });
            });
          }
        );
      });
    }
    // else {
    //   dispatch(createShopRepo(values));
    //   // console.log('valueWithOutImageFile :', values);
    //   // createShopRepo(values).then((response) => {
    //   //   if (response.data.error) {
    //   //     // console.log(response.data.error);
    //   //   } else {
    //   //     // alert(response.data.success);
    //   //     alert('Ad added');
    //   //     // resetForm();
    //   //   }
    //   // });
    // }
  };

  const handleShopSubmit = async (e) => {
    e.preventDefault();
    // console.log(values);

    upload([
      { file: shoplogo, label: 'shoplogo' },
      { file: coverimage, label: 'coverimage' },

      // { file: video, label: 'video' },
    ]);
    // dispatch(createShopRepo(values));
  };

  const handleShopUpdate = async (e) => {
    e.preventDefault();

    console.log(values);

    // try {
    //   dispatch(updateShops(id, values));
    //   e.stopPropagation();
    // } catch (error) {
    //   console.log({ message: error.message });
    // }

    // resetForm();
  };

  return (
    <form className="shopErectionUpdateForm" onSubmit={handleShopSubmit}>
      <div className="shopErectionUpdateLeft">
        <div className="productFormLeft">
          {/* <input
            type="text"
            placeholder="annabeck99@gmail.com"
            className="shopErectionUpdateInput"
            name="shopplan"
            value={shopplan._id}
            onChange={handleInputChange}
          /> */}

          <label>Country</label>
          <select
            style={{ maxWidth: '250px' }}
            className=""
            name="businessCountry"
            value={values.businessCountry}
            onChange={handleInputChange}
            id="hub"
          >
            <option value="">Select an option</option>
            {countries.map((cty) => (
              <option
                value={cty._id}
                // onClick={(e) => {
                //   e.stopPropagation();
                //   setCountryId(cty._id);
                // }}
              >
                {cty.name}
              </option>
            ))}
          </select>
          <label>State</label>
          <select
            style={{ maxWidth: '250px' }}
            className=""
            name="businezState"
            value={values.businezState}
            onChange={handleInputChange}
            id="category"
          >
            <option value="">Select an option</option>
            {bstates.map((bst) => (
              <option value={bst._id}>{bst.name}</option>
            ))}
          </select>
          <label>Market</label>
          <select
            style={{ maxWidth: '250px' }}
            className=""
            name="market"
            value={values.market}
            onChange={handleInputChange}
            id="category"
          >
            <option value="">Select an option</option>
            {markets.map((mkt) => (
              <option value={mkt._id}>{mkt.name}</option>
            ))}
            {/* <option value="">Select an option</option>
              <option>Select an option</option>
              <option value="1">category 1</option>
              <option value="2">category 2</option> */}
          </select>
          <label>Shop Name</label>
          {/* <input
            type="hidden"
            placeholder="Ferazi Farms"
            className="shopErectionUpdateInput"
            name="shopname"
            value={values.shopname}
            onChange={handleInputChange}
          /> */}
          <input
            type="text"
            placeholder="Ferazi Farms"
            className="shopErectionUpdateInput"
            name="shopname"
            value={values.shopname}
            onChange={handleInputChange}
          />
        </div>
        <div className="shopErectionUpdateItem">
          <label>Email</label>
          <input
            type="text"
            placeholder="annabeck99@gmail.com"
            className="shopErectionUpdateInput"
            name="shopemail"
            value={values.shopemail}
            onChange={handleInputChange}
          />
        </div>
        <div className="shopErectionUpdateItem">
          <label>Phone</label>
          <input
            type="text"
            placeholder="+1 123 456 67"
            className="shopErectionUpdateInput"
            name="shopphone"
            value={values.shopphone}
            onChange={handleInputChange}
          />
        </div>
        <div className="shopErectionUpdateItem">
          <label>Address</label>
          <input
            type="text"
            placeholder="New York | USA"
            className="shopErectionUpdateInput"
            name="address"
            value={values.address}
            onChange={handleInputChange}
          />
        </div>
        {/* <div className="shopErectionUpdateItem">
                <label>Full Name</label>
                <input
                  type="text"
                  placeholder="Anna Becker"
                  className="shopErectionUpdateInput"
                />
              </div> */}

        {/* Area for bank details start */}
        <span className="shopErectionUpdateTitle">Shop Bank Details</span>
        <div className="shopErectionUpdateItem">
          <label>Bank Name</label>
          <input
            type="text"
            placeholder="GTB"
            className="shopErectionUpdateInput"
            name="bankname"
            value={values.bankname}
            onChange={handleInputChange}
          />
        </div>
        <div className="shopErectionUpdateItem">
          <label>Account Full Name</label>
          <input
            type="text"
            placeholder="Anna Becker"
            className="shopErectionUpdateInput"
            name="accountname"
            value={values.accountname}
            onChange={handleInputChange}
          />
        </div>

        <div className="shopErectionUpdateItem">
          <label>Account Number</label>
          <input
            type="number"
            placeholder="+1 123 456 67"
            className="shopErectionUpdateInput"
            name={values.shopaccount.acountnumber}
            value={values.shopaccount.acountnumber}
            onChange={handleInputChange}
          />
        </div>

        {/* Area for bank details ends */}
      </div>

      <div className="shopErectionUpdateRight">
        <div className="shopErectionUpdateUpload">
          <p style={{ marginLeft: '20px' }}>
            {' '}
            picture showing shop logo/barner{' '}
          </p>

          {values.shoplogo && (
            <img
              className="shopErectionUpdateImg"
              src={values.shoplogo}
              alt=""
            />
          )}

          <label htmlFor="file">
            <Publish className="shopErectionUpdateIcon" />
          </label>

          <input
            type="file"
            style={{ display: 'none' }}
            id="file"
            name={values.shoplogo}
            onChange={(e) => setShopLogo(e.target.files[0])}
          />
        </div>
        <div className="shopErectionUpdateUpload">
          <p style={{ marginLeft: '20px' }}> video showing shop products</p>
          {values.coverimage && (
            <img
              className="shopErectionUpdateImg"
              src={values.coverimage}
              alt=""
            />
          )}
          <label htmlFor="cfile">
            <Publish className="shopErectionUpdateIcon" />
          </label>

          <input
            type="file"
            style={{ display: 'none' }}
            id="cfile"
            name={values.coverimage}
            onChange={(e) => setCoverImage(e.target.files[0])}
          />
        </div>
        {/* <button className="shopErectionUpdateButton">Create Shop</button> */}
        <button type="submit" className="userUpdateButton">
          Submit
        </button>
      </div>
    </form>
  );
};

export default ShopForm;
