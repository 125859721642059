import { actionTypes } from './actionShops';
import Cookies from 'js-cookie';

export const initState = {
  shops: [],
  isFetching: false,
  error: false,
};

function reducer(state = initState, action) {
  switch (action.type) {
    case 'START_LOADING':
      return { ...state, isFetching: true };
    case 'END_LOADING':
      return { ...state, isFetching: false };
    //Get shop
    case actionTypes.SHOP_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.SHOP_SUCCESS:
      return {
        ...state,
        isFetching: false,
        shops: action.payload,
        error: false,
      };
    case actionTypes.SHOP_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Get SHOPById:
    case actionTypes.GET_SHOP_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.GET_SHOP_SUCCESS:
      return {
        ...state,
        isFetching: false,
        shop: action.payload,
        error: false,
      };
    case actionTypes.GET_SHOP_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Update A SHOPnationsById:
    case actionTypes.UPDATE_SHOP_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.UPDATE_SHOP_SUCCESS:
      return {
        ...state,
        isFetching: false,
        shops: state.shops.map((shop) =>
          shop._id === action.payload._id ? action.payload : shop
        ),
        error: false,
      };
    case actionTypes.UPDATE_SHOP_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Create A New SHOPnationsById:
    case actionTypes.CREATE_SHOP_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.CREATE_SHOP_SUCCESS:
      return {
        ...state,
        isFetching: false,
        shops: [...state.shops, action.payload],
        error: false,
      };
    case actionTypes.CREATE_SHOP_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };

    default:
      return { ...state };
  }
}
export default reducer;
