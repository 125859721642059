import * as api from '../api/apiRoutes';
import {
  marketcat,
  marketcatSuccess,
  marketcatFailure,
  getMarketcatById,
  getMarketcatByIdSuccess,
  getMarketcatByIdFailure,
  updateMarketcat,
  updateMarketcatSuccess,
  updateMarketcatFailure,
  createMarketcat,
  createMarketcatSuccess,
  createMarketcatFailure,
} from './actionMarketcat';
const Cookies = require('js-cookie');

//get all Market Categories
export const getMarketCats = () => async (dispatch) => {
  try {
    dispatch(marketcat());
    const { data } = await api.getMarketCategories();
    console.log('procatData :', data);

    // dispatch({
    //   type: FETCH_ALL,
    //   payload: { data, currentPage, numberOfPages },
    // });
    dispatch(marketcatSuccess(data));
  } catch (error) {
    console.log(error);
    dispatch(marketcatFailure({ error: error.message }));
  }
};

//get single Market
export const getMarketcatWithId = (id) => async (dispatch) => {
  dispatch(getMarketcatById());
  // console.log('Acton start');
  try {
    const { data } = await api.getMarketCategoryById(id);
    // console.log('MydesigByID :', data);

    dispatch(getMarketcatByIdSuccess(data));
  } catch (error) {
    console.log(error);
    dispatch(getMarketcatByIdFailure({ error: error.message }));
  }
};

//update a Market Category
export const updateMarketCats = (id, values) => async (dispatch) => {
  // console.log('repoValues', values);
  dispatch(updateMarketcat());
  try {
    const { data } = await api.updateMarketCategoryById(id, values);
    // console.log('actionData :', data);

    dispatch(updateMarketcatSuccess(data));
    alert('Market Category updated');
  } catch (error) {
    console.log(error);
    dispatch(updateMarketcatFailure({ message: error.message }));
  }
};

//Create a Market
export const createMarketCat = (values) => async (dispatch) => {
  try {
    dispatch(createMarketcat());
    const { data } = await api.createMarketCategory(values);
    // console.log('actionData :', data);
    dispatch(createMarketcatSuccess(data));
    // console.log('newDesination:', data);
    alert('Market Category added');
  } catch (error) {
    console.log(error);
    dispatch(createMarketcatFailure({ error: error.message }));
  }
};
