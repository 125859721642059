import React from 'react';
import './managedesignation.scss';
import ShopRightbar from '../../components_shops/shoprightbar/ShopRightbar';
import {
  CalendarToday,
  LocationSearching,
  MailOutline,
  PermIdentity,
  PhoneAndroid,
  Publish,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import Chart from '../../components_shops/chart/Chart';
import { productData } from '../../dummyData';
import DesignationForm from './DesignationForm';

const AddDesignation = () => {
  return (
    <>
      {/* Changing pages rendering starts here */}

      <>
        <div className="shopRight">
          <div className="shopBottom">
            <div className="product">
              <div className="productTop">
                <div className="productTopLeft">
                  <h4>Some Designation Info here</h4>
                </div>
                <div className="productTopRight">
                  <div className="productInfoTop">
                    <img
                      src="https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
                      alt=""
                      className="productInfoImg"
                    />
                    <span className="productName">Apple Airpods</span>
                  </div>
                  <div className="productInfoBottom">
                    <div className="productInfoItem">
                      <span className="productInfoKey">id:</span>
                      <span className="productInfoValue">123</span>
                    </div>
                    <div className="productInfoItem">
                      <span className="productInfoKey">sales:</span>
                      <span className="productInfoValue">5123</span>
                    </div>
                    <div className="productInfoItem">
                      <span className="productInfoKey">active:</span>
                      <span className="productInfoValue">yes</span>
                    </div>
                    <div className="productInfoItem">
                      <span className="productInfoKey">in stock:</span>
                      <span className="productInfoValue">no</span>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="productBottom">
                <DesignationForm />
              </div>
            </div>
          </div>
        </div>

        <>
          <ShopRightbar Shop />
        </>
      </>
      {/* Changing pages rendering ends here */}
    </>
  );
};

export default AddDesignation;
