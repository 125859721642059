import React, { useContext, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './signin.scss';
import { MoreVert } from '@material-ui/icons';

import Api from '../../apiconstant/Api';
import { AuthContext } from '../../context/AuthContext';
// import { loginCall } from '../../context/AuthApiCalls';
import { loginAdmin } from '../../store/auth/repository_auth';
import { useDispatch, useSelector } from 'react-redux';


const SignIn = () => {
  const email = useRef();
  const password = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  // const { isFetching, dispatch } = useContext(AuthContext);

  const submitHandler = async (e) => {
    e.preventDefault();
    let Item = {
      email: email.current.value,
      password: password.current.value,
    };
    dispatch(loginAdmin(Item));
    // loginCall(Item, dispatch);
    //   console.log('item:', Item)
    //    dispatch(login(Item));
  };

  return (
    <div className="signin">
      <div className="signinWrapper">
        <div className="signinTop">
          <div className="signinTopLeft">
            <img
              className="signinProfileImg"
              src="assets/person/1.jpeg"
              alt=""
            />
            <span className="signinUsername">Ferazi Eke</span>
            <span className="signinDate">Signed in 10 mins ago</span>
          </div>
          <div className="signinTopRight">
            <MoreVert className="moreIcon" />
          </div>
        </div>
        <div className="signinCenter">
          {/* beginning of login form */}
          <div className="login">
            <div className="loginWrapper">
              {/* <h3>Sign in here</h3> */}
              <div className="loginPanel">
                <form className="form-horizontal" onSubmit={submitHandler}>
                  <div className="loginBox">
                    <input
                      placeholder="Email"
                      type="Email"
                      ref={email}
                      className="loginInput"
                    />
                    <input
                      placeholder="Password"
                      type="password"
                      ref={password}
                      className="loginInput"
                    />
                    <button className="loginButton">Log In</button>
                    <span className="loginForgot">Forgot Password?</span>
                    <span className="loginRegisterButton">Create account</span>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* end of login form */}
        </div>
        <div className="signinBottom">
          {/* <div className="signinBottomLeft">
                    <img src="/assets/like.png" alt="" className="likeIcon"/>
                    <img src="/assets/heart.png" alt="" className="likeIcon" />
                    <span className="signinLikeCounter">4 people liked this</span>
                </div>
                <div className="signinBottomRight">
                    <span className="signinCommentText">5 comments</span>
                </div> */}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
