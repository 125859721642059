export const actionTypes = {
  TRADEHUB_REQUEST: 'TRADEHUB_REQUEST',
  TRADEHUB_SUCCESS: 'TRADEHUB_SUCCESS',
  TRADEHUB_FAILURE: 'TRADEHUB_FAILURE',

  GET_TRADEHUB_REQUEST: 'GET_TRADEHUB_REQUEST',
  GET_TRADEHUB_SUCCESS: 'GET_TRADEHUB_SUCCESS',
  GET_TRADEHUB_FAILURE: 'GET_TRADEHUB_FAILURE',

  UPDATE_TRADEHUB_REQUEST: 'UPDATE_TRADEHUB_REQUEST',
  UPDATE_TRADEHUB_SUCCESS: 'UPDATE_TRADEHUB_SUCCESS',
  UPDATE_TRADEHUB_FAILURE: 'UPDATE_TRADEHUB_FAILURE',

  CREATE_TRADEHUB_REQUEST: 'CREATE_TRADEHUB_REQUEST',
  CREATE_TRADEHUB_SUCCESS: 'CREATE_TRADEHUB_SUCCESS',
  CREATE_TRADEHUB_FAILURE: 'CREATE_TRADEHUB_FAILURE',

  //whenloading
  END_LOADING: 'END_LOADING',
  START_LOADING: 'START_LOADING',
};

export function hub() {
  return { type: actionTypes.TRADEHUB_REQUEST };
}

export function hubSuccess(tradehubs) {
  return { type: actionTypes.TRADEHUB_SUCCESS, payload: tradehubs.data };
}

export function hubFailure() {
  return { type: actionTypes.TRADEHUB_FAILURE };
}

//Get Department by ID:
export function getHubById() {
  return { type: actionTypes.GET_TRADEHUB_REQUEST };
}

export function getHubByIdSuccess(tradehub) {
  return { type: actionTypes.GET_TRADEHUB_SUCCESS, payload: tradehub };
}

export function getHubByIdFailure() {
  return { type: actionTypes.GET_TRADEHUB_FAILURE };
}

//Update Department by ID:
export function updateHub() {
  return { type: actionTypes.UPDATE_TRADEHUB_REQUEST };
}

export function updateHubSuccess(data) {
  return {
    type: actionTypes.UPDATE_TRADEHUB_SUCCESS,
    payload: data,
  };
}

export function updateHubFailure() {
  return { type: actionTypes.UPDATE_TRADEHUB_FAILURE };
}

//create new Department:
export function createHub() {
  return { type: actionTypes.CREATE_TRADEHUB_REQUEST };
}

export function createHubSuccess(data) {
  return {
    type: actionTypes.CREATE_TRADEHUB_SUCCESS,
    payload: data,
  };
}

export function createHubFailure() {
  return { type: actionTypes.CREATE_TRADEHUB_FAILURE };
}

// export function logOut() {
//   return { type: actionTypes.LOG_OUT };
// }
