import React from 'react';
import ShopRightbar from '../../components_shops/shoprightbar/ShopRightbar';
import {
  CalendarToday,
  LocationSearching,
  MailOutline,
  PermIdentity,
  PhoneAndroid,
  Publish,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import AdminStaffForm from './AdminStaffForm';

import {
  RollbackOutlined,
  SyncOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';
import Chart from '../../components_shops/chart/Chart';
import { productData } from '../../dummyData';
import StaffPersonalInformation from '../../components_shops/staffcards/StaffPersonalInformation';
import StaffInformation from '../../components_shops/staffcards/StaffInformation';

const AdminStaff = () => {
  return (
    <>
      {/* Changing pages rendering starts here */}

      <>
        <div className="shopRight">
          <div className="shopBottom">
            <div className="product">
              <div className="productTop">
                <div className="productTopLeft">
                  <Chart
                    data={productData}
                    dataKey="Sales"
                    title="Sales Performance"
                  />
                </div>
                <div className="productTopRight">
                  <div className="productInfoTop">
                    <img
                      src="https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
                      alt=""
                      className="productInfoImg"
                    />
                    <span className="productName">Order:</span>
                  </div>
                  <div
                    className="productInfoBottom"
                    // style={{ maxHeight: '200px' }}
                  >
                    <div className="productInfoItem">
                      <span className="productInfoKey">order_id: </span>
                      <span className="productInfoValue"></span>
                    </div>

                    <div className="productInfoItem">
                      <span className="productInfoKey">cost:</span>
                      <span className="productInfoValue">N0.00</span>
                    </div>
                    <div className="productInfoItem">
                      <span className="productInfoKey">tax:</span>
                      <span className="productInfoValue">N 0.00</span>
                    </div>
                    <div className="productInfoItem">
                      <span className="productInfoKey">shipping:</span>
                      <span className="productInfoValue">N0.00</span>
                      {/* <p>{JSON.stringify(order, 0, null)}</p> */}
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>

          <section className="ps-dashboard">
            <AdminStaffForm />
          </section>
          {/* </div> */}
        </div>

        <>
          <ShopRightbar Shop />
        </>
      </>

      {/* Changing pages rendering ends here */}
    </>
  );
};

export default AdminStaff;
