export const actionTypes = {
  SHOP_REQUEST: 'SHOP_REQUEST',
  SHOP_SUCCESS: 'SHOP_SUCCESS',
  SHOP_FAILURE: 'SHOP_FAILURE',

  GET_SHOP_REQUEST: 'GET_SHOP_REQUEST',
  GET_SHOP_SUCCESS: 'GET_SHOP_SUCCESS',
  GET_SHOP_FAILURE: 'GET_SHOP_FAILURE',

  UPDATE_SHOP_REQUEST: 'UPDATE_SHOP_REQUEST',
  UPDATE_SHOP_SUCCESS: 'UPDATE_SHOP_SUCCESS',
  UPDATE_SHOP_FAILURE: 'UPDATE_SHOP_FAILURE',

  CREATE_SHOP_REQUEST: 'CREATE_SHOP_REQUEST',
  CREATE_SHOP_SUCCESS: 'CREATE_SHOP_SUCCESS',
  CREATE_SHOP_FAILURE: 'CREATE_SHOP_FAILURE',

  //whenloading
  END_LOADING: 'END_LOADING',
  START_LOADING: 'START_LOADING',
};

export function shop() {
  return { type: actionTypes.SHOP_REQUEST };
}

export function shopSuccess(shops) {
  return { type: actionTypes.SHOP_SUCCESS, payload: shops.data };
}

export function shopFailure() {
  return { type: actionTypes.SHOP_FAILURE };
}

//Get Shop by ID:
export function getShopById() {
  return { type: actionTypes.GET_SHOP_REQUEST };
}

export function getShopByIdSuccess(shop) {
  return { type: actionTypes.GET_SHOP_SUCCESS, payload: shop };
}

export function getShopByIdFailure() {
  return { type: actionTypes.GET_SHOP_FAILURE };
}

//Update PROductnation by ID:
export function updateShop() {
  return { type: actionTypes.UPDATE_SHOP_REQUEST };
}

export function updateShopSuccess(data) {
  return {
    type: actionTypes.UPDATE_SHOP_SUCCESS,
    payload: data,
  };
}

export function updateShopFailure() {
  return { type: actionTypes.UPDATE_SHOP_FAILURE };
}

//create new PROductnation:
export function createShop() {
  return { type: actionTypes.CREATE_SHOP_REQUEST };
}

export function createShopSuccess(data) {
  return {
    type: actionTypes.CREATE_SHOP_SUCCESS,
    payload: data,
  };
}

export function createShopFailure() {
  return { type: actionTypes.CREATE_SHOP_FAILURE };
}

// export function logOut() {
//   return { type: actionTypes.LOG_OUT };
// }
