export const actionTypes = {
  SHOPPLAN_REQUEST: 'SHOPPLAN_REQUEST',
  SHOPPLAN_SUCCESS: 'SHOPPLAN_SUCCESS',
  SHOPPLAN_FAILURE: 'SHOPPLAN_FAILURE',

  GET_SHOPPLAN_REQUEST: 'GET_SHOPPLAN_REQUEST',
  GET_SHOPPLAN_SUCCESS: 'GET_SHOPPLAN_SUCCESS',
  GET_SHOPPLAN_FAILURE: 'GET_SHOPPLAN_FAILURE',

  UPDATE_SHOPPLAN_REQUEST: 'UPDATE_SHOPPLAN_REQUEST',
  UPDATE_SHOPPLAN_SUCCESS: 'UPDATE_SHOPPLAN_SUCCESS',
  UPDATE_SHOPPLAN_FAILURE: 'UPDATE_SHOPPLAN_FAILURE',

  CREATE_SHOPPLAN_REQUEST: 'CREATE_SHOPPLAN_REQUEST',
  CREATE_SHOPPLAN_SUCCESS: 'CREATE_SHOPPLAN_SUCCESS',
  CREATE_SHOPPLAN_FAILURE: 'CREATE_SHOPPLAN_FAILURE',

  //whenloading
  END_LOADING: 'END_LOADING',
  START_LOADING: 'START_LOADING',
};

export function shopplan() {
  return { type: actionTypes.SHOPPLAN_REQUEST };
}

export function shopplanSuccess(shopplans) {
  return { type: actionTypes.SHOPPLAN_SUCCESS, payload: shopplans.data };
}

export function shopplanFailure() {
  return { type: actionTypes.SHOPPLAN_FAILURE };
}

//Get PROduct by ID:
export function getShopplanById() {
  return { type: actionTypes.GET_SHOPPLAN_REQUEST };
}

export function getShopplanByIdSuccess(shopplan) {
  return { type: actionTypes.GET_SHOPPLAN_SUCCESS, payload: shopplan };
}

export function getShopplanByIdFailure() {
  return { type: actionTypes.GET_SHOPPLAN_FAILURE };
}

//Update PROductnation by ID:
export function updateShopplan() {
  return { type: actionTypes.UPDATE_SHOPPLAN_REQUEST };
}

export function updateShopplanSuccess(data) {
  return {
    type: actionTypes.UPDATE_SHOPPLAN_SUCCESS,
    payload: data,
  };
}

export function updateShopplanFailure() {
  return { type: actionTypes.UPDATE_SHOPPLAN_FAILURE };
}

//create new PROductnation:
export function createShopplan() {
  return { type: actionTypes.CREATE_SHOPPLAN_REQUEST };
}

export function createShopplanSuccess(data) {
  return {
    type: actionTypes.CREATE_SHOPPLAN_SUCCESS,
    payload: data,
  };
}

export function createShopplanFailure() {
  return { type: actionTypes.CREATE_SHOPPLAN_FAILURE };
}

// export function logOut() {
//   return { type: actionTypes.LOG_OUT };
// }
