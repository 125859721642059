export const actionTypes = {
  PRODUCT_REQUEST: 'PRODUCT_REQUEST',
  PRODUCT_SUCCESS: 'PRODUCT_SUCCESS',
  PRODUCT_FAILURE: 'PRODUCT_FAILURE',

  GET_PRODUCT_REQUEST: 'GET_PRODUCT_REQUEST',
  GET_PRODUCT_SUCCESS: 'GET_PRODUCT_SUCCESS',
  GET_PRODUCT_FAILURE: 'GET_PRODUCT_FAILURE',

  UPDATE_PRODUCT_REQUEST: 'UPDATE_PRODUCT_REQUEST',
  UPDATE_PRODUCT_SUCCESS: 'UPDATE_PRODUCT_SUCCESS',
  UPDATE_PRODUCT_FAILURE: 'UPDATE_PRODUCT_FAILURE',

  CREATE_PRODUCT_REQUEST: 'CREATE_PRODUCT_REQUEST',
  CREATE_PRODUCT_SUCCESS: 'CREATE_PRODUCT_SUCCESS',
  CREATE_PRODUCT_FAILURE: 'CREATE_PRODUCT_FAILURE',

  //whenloading
  END_LOADING: 'END_LOADING',
  START_LOADING: 'START_LOADING',
};

export function product() {
  return { type: actionTypes.PRODUCT_REQUEST };
}

export function productSuccess(products) {
  return { type: actionTypes.PRODUCT_SUCCESS, payload: products.data };
}

export function productFailure() {
  return { type: actionTypes.PRODUCT_FAILURE };
}

//Get PROduct by ID:
export function getProductById() {
  return { type: actionTypes.GET_PRODUCT_REQUEST };
}

export function getProductByIdSuccess(product) {
  return { type: actionTypes.GET_PRODUCT_SUCCESS, payload: product };
}

export function getProductByIdFailure() {
  return { type: actionTypes.GET_PRODUCT_FAILURE };
}

//Update PROductnation by ID:
export function updateProduct() {
  return { type: actionTypes.UPDATE_PRODUCT_REQUEST };
}

export function updateProductSuccess(data) {
  return {
    type: actionTypes.UPDATE_PRODUCT_SUCCESS,
    payload: data,
  };
}

export function updateProductFailure() {
  return { type: actionTypes.UPDATE_PRODUCT_FAILURE };
}

//create new PROductnation:
export function createProduct() {
  return { type: actionTypes.CREATE_PRODUCT_REQUEST };
}

export function createProductSuccess(data) {
  return {
    type: actionTypes.CREATE_PRODUCT_SUCCESS,
    payload: data,
  };
}

export function createProductFailure() {
  return { type: actionTypes.CREATE_PRODUCT_FAILURE };
}

// export function logOut() {
//   return { type: actionTypes.LOG_OUT };
// }
