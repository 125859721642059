import React from 'react';
import './shopdashboard.scss';
import Topbar from '../../components/topbar/Topbar';
import SocialSidebar from '../../components/socialsidebar/SocialSidebar';
import SingleProduct from '../../components/singleproduct/SingleProduct';
import ShopBoard from '../../components_shops/shopboard/ShopBoard';
import ShopRightbar from '../../components_shops/shoprightbar/ShopRightbar';

const ShopDashboard = () => {
  return (
    <>
      {/* Changing pages rendering starts here */}

      <>
        <div className="shopRight">
          <div className="shopBottom">
            <ShopBoard />
          </div>
        </div>

        <>
          <ShopRightbar Shop />
        </>
      </>
      {/* Changing pages rendering ends here */}
    </>
  );
};

export default ShopDashboard;
