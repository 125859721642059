import * as api from '../api/apiRoutes';
import {
  country,
  countrySuccess,
  countryFailure,
  getCountryById,
  getCountryByIdSuccess,
  getCountryByIdFailure,
  updateCountry,
  updateCountrySuccess,
  updateCountryFailure,
  createCountry,
  createCountrySuccess,
  createCountryFailure,
} from './actionCountry';
const Cookies = require('js-cookie');

export const getCountries = () => async (dispatch) => {
  try {
    dispatch(country());
    const { data } = await api.getCountries();
    console.log('actionData :', data);

    dispatch(countrySuccess(data));
  } catch (error) {
    console.log(error);
    dispatch(countryFailure({ error: error.message }));
  }
};

//get single department
export const getCountryWithId = (id) => async (dispatch) => {
  dispatch(getCountryById());
  // console.log('Acton start');
  try {
    const { data } = await api.getCountryById(id);
    // console.log('MyCountryByID :', data);

    dispatch(getCountryByIdSuccess(data));
  } catch (error) {
    console.log(error);
    dispatch(getCountryByIdFailure({ error: error.message }));
  }
};

//update a country
export const updateCountryRepo = (id, values) => async (dispatch) => {
  // console.log('repoValues', values);
  dispatch(updateCountry());
  try {
    const { data } = await api.updateCountryById(id, values);
    // console.log('actionData :', data);

    dispatch(updateCountrySuccess(data));
    if (data) {
      alert('country updated');
    } else {
      alert('something awkward happened');
    }
  } catch (error) {
    console.log(error);
    dispatch(
      updateCountryFailure(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    );
  }
};

//Create a department
export const createCountryRepo = (values) => async (dispatch) => {
  console.log('newCountrytData :', values);
  try {
    dispatch(createCountry());
    const { data } = await api.createCountry(values);
    // console.log('actionData :', data);
    dispatch(createCountrySuccess(data));
    // console.log('newDepartment:', data);
    if (data) {
      alert('country added');
    } else {
      alert('somethin went wrong');
    }
  } catch (error) {
    console.log(error);
    dispatch(
      createCountryFailure(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    );
  }
};
