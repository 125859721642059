import React, { Fragment, useState, useEffect, useContext } from 'react';
import Api from '../../apiconstant/Api';
import './newspost.scss';
import News from '../news/News';
import { Link } from 'react-router-dom';
import { NewsContext } from '../../context/news/NewsContext';
import { getNewsPosts } from '../../context/news/NewsApiCalls';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPosts } from '../../store/postss/repository_post';
import {
  Paper,
  Typography,
  CircularProgress,
  Divider,
} from '@material-ui/core/';

const NewsPost = () => {
  // newsposts={newsposts}
  // const { news, dispatch } = useContext(NewsContext);

  // useEffect(() => {
  //   getNewsPosts(dispatch);
  // }, [dispatch]);

  const dispatch = useDispatch();
  const { posts, isFetching } = useSelector((state) => state.posts);
  useEffect(() => {
    const data = dispatch(getAllPosts());

    // setPagePosts(data);
  }, [dispatch]);

  // useEffect(()=>{

  //   const getNews = async() =>{

  //    try {
  //     let result =  await Api().get('/blog/posts').then(response => {
  //         setNewsPosts(response.data.posts)
  //       })

  //    } catch (error) {
  //      console.log(error);
  //    }

  //   };
  //   getNews()
  // }, []);

  // co

  return (
    <>
      <div className="newspost">
        <div className="newspostWrapper">
          <>
            <div className="news">
              <div className="newsWrapper">
                <section>
                  <div>
                    {/* row */}
                    <div className="row">
                      {/* Center colunm*/}
                      <div className="col-xs-12" id="center_column">
                        <div className="center_column">
                          <div className="page-title">
                            <h2>News post</h2>
                          </div>
                          <ul className="blog-posts">
                            {isFetching ? (
                              <Paper elevation={6} className="loadingPaper">
                                <CircularProgress
                                  size="7em"
                                  className="loadingPaper"
                                />
                              </Paper>
                            ) : (
                              posts.length > 0 &&
                              posts?.map((post) => (
                                //  <News
                                //  key={post.id}
                                //  title={post.title}
                                //  content={post.content}

                                //  createdAt={post.created_at}
                                //  slug={post.slug}
                                //  />
                                <li className="post-item" key={post.id}>
                                  <article className="entry">
                                    <div className="row">
                                      <div className="col-sm-5">
                                        <div className="entry-thumb image-hover2">
                                          {' '}
                                          <a href="blog_single_post.html">
                                            <Link
                                              to={'/news/single/' + post._id}
                                            >
                                              <figure>
                                                <img
                                                  src={post.featuredImage}
                                                  alt="Blog"
                                                />
                                              </figure>
                                            </Link>
                                          </a>{' '}
                                        </div>
                                      </div>
                                      <div className="col-sm-7">
                                        <h3 className="entry-title">
                                          <Link to={'/news/single/' + post._id}>
                                            {post.title}
                                          </Link>
                                        </h3>
                                        <div className="entry-meta-data">
                                          {' '}
                                          <span className="author">
                                            {' '}
                                            <i className="pe-7s-user" />
                                            &nbsp; by: <a href="#">Admin</a>
                                          </span>{' '}
                                          <span className="cat">
                                            {' '}
                                            <i className="pe-7s-folder" />
                                            &nbsp; <a href="#">News, </a>{' '}
                                            <a href="#">Promotions</a>{' '}
                                          </span>{' '}
                                          <span className="comment-count">
                                            {' '}
                                            <i className="pe-7s-comment" />
                                            &nbsp; 3{' '}
                                          </span>{' '}
                                          <span className="date">
                                            <i className="pe-7s-date" />
                                            &nbsp; {post.created_at}
                                          </span>{' '}
                                        </div>
                                        <div className="rating">
                                          {' '}
                                          <i className="fa fa-star" />{' '}
                                          <i className="fa fa-star" />{' '}
                                          <i className="fa fa-star" />{' '}
                                          <i className="fa fa-star-o" />{' '}
                                          <i className="fa fa-star-o" />
                                          &nbsp; <span>(5 votes)</span>
                                        </div>
                                        <div className="entry-excerpt">
                                          {post.content.substring(0, 300) +
                                            '...'}
                                          .
                                        </div>
                                        <Link
                                          to={'/news/single/' + post._id}
                                          className="button read-more"
                                        >
                                          Read more&nbsp;{' '}
                                          <i className="fa fa-angle-double-right" />
                                        </Link>{' '}
                                      </div>
                                    </div>
                                  </article>
                                </li>
                              ))
                            )}
                          </ul>
                          <div className="sortPagiBar">
                            <div className="pagination-area ">
                              <ul>
                                <li>
                                  <a className="active" href="#">
                                    1
                                  </a>
                                </li>
                                <li>
                                  <a href="#">2</a>
                                </li>
                                <li>
                                  <a href="#">3</a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fa fa-angle-right" />
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* ./ Center colunm */}
                      {/* <BlogAside /> */}
                      {/* ./left colunm */}
                    </div>
                    {/* ./row*/}
                  </div>
                </section>
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default NewsPost;
