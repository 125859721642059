import { CloudUploadOutlined, Publish } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import useForm from '../../hooks/useForm';
import { AuthApi } from '../../apiconstant/Api';
import { useParams, useHistory, Redirect, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import app from '../../firebase';
import {
  createPartnerRepo,
  updatePartnersRepo,
} from '../../store/partners/repository_partner';
import {
  createBannerRepo,
  updateBannersRepo,
} from '../../store/barners/repository_barner';
import { getApiBannerById } from '../../store/api/apiRoutes';
import { CircularProgress, Paper } from '@material-ui/core';

// const initialFValues = {
//     id:0,
//     product:{},
//   }

const DisciplineForm = ({
  handleStaffBlockUpdate,
  handleStaffUnBlockUpdate,
  handleStaffSuspendUpdate,
  handleStaffUnSuspendUpdate,
  // makeAdminLeadership,
  // removeAdminLeadership,
  values,
  handleInputChange,
  id,
  loading,
}) => {
  return (
    <div className="shopErectionUpdate">
      {loading ? (
        <Paper elevation={6} className="loadingPaper">
          <CircularProgress size="7em" className="loadingPaper" />
        </Paper>
      ) : (
        <>
          <form
            // onSubmit={id && handleStaffBlockUpdate}
            onSubmit={
              id && !values?.isBlocked
                ? handleStaffBlockUpdate
                : handleStaffUnBlockUpdate
            }
            className="ps-form--account-setting"
          >
            <div className="ps-form__content">
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label>
                      Block/Unblock:{' '}
                      {values?.isBlocked && 'This User is blocked'}{' '}
                      {!values.isBlocked && 'This User is not blocked'}
                    </label>
                    <select
                      // style={{ maxWidth: '250px' }}
                      className="form-control"
                      name="isBlocked"
                      value={values?.isBlocked}
                      onChange={handleInputChange}
                      disabled
                      // id="department"
                    >
                      <option value="">Select an option</option>
                      <option value="true">true</option>
                      <option value="false">false</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="form-group submit">
                <Link to={'/customers'} className="btn">
                  Cancel
                </Link>
                {/* <button className="ps-btn">Block User </button> */}
                {values?.isBlocked && (
                  <button className="ps-btn">Lift User Block </button>
                )}
                {!values?.isBlocked && (
                  <button className="ps-btn">Block User </button>
                )}
              </div>
            </div>
          </form>

          <form
            onSubmit={
              id && !values?.isSuspended
                ? handleStaffSuspendUpdate
                : handleStaffUnSuspendUpdate
            }
            className="ps-form--account-setting"
          >
            <div className="ps-form__content">
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label>
                      Suspend/Un-Suspend :{' '}
                      {values?.isSuspended && 'This User is suspended'}{' '}
                      {!values?.isSuspended && 'This User is not suspended'}
                    </label>
                    <select
                      // style={{ maxWidth: '250px' }}
                      className="form-control"
                      name="isSuspended"
                      value={values?.isSuspended}
                      onChange={handleInputChange}
                      disabled
                      // id="department"
                    >
                      <option value="">Select an option</option>
                      <option value="true">true</option>
                      <option value="false">false</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="form-group submit">
                <Link to={'/staff'} className="btn">
                  Cancel
                </Link>
                {values?.isSuspended && (
                  <button className="ps-btn">Lift User Suspension </button>
                )}
                {!values?.isSuspended && (
                  <button className="ps-btn">Suspend User </button>
                )}
              </div>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default DisciplineForm;
