import * as api from '../api/apiRoutes';
import {
  desig,
  desigSuccess,
  desigFailure,
  getDesigById,
  getDesigByIdSuccess,
  getDesigByIdFailure,
  updateDesig,
  updateDesigSuccess,
  updateDesigFailure,
  createDesig,
  createDesigSuccess,
  createDesigFailure,
} from './actionDesignation';
const Cookies = require('js-cookie');

export const getDesignations = () => async (dispatch) => {
  try {
    dispatch(desig());
    const { data } = await api.getDesigs();
    console.log('actionData :', data);

    // dispatch({
    //   type: FETCH_ALL,
    //   payload: { data, currentPage, numberOfPages },
    // });
    dispatch(desigSuccess(data));
  } catch (error) {
    console.log(error);
    dispatch(desigFailure({ error: error.message }));
  }
};

//get single Desination
export const getDesigWithId = (id) => async (dispatch) => {
  dispatch(getDesigById());
  // console.log('Acton start');
  try {
    const { data } = await api.getDesigById(id);
    // console.log('MydesigByID :', data);

    dispatch(getDesigByIdSuccess(data));
  } catch (error) {
    console.log(error);
    dispatch(getDesigByIdFailure({ error: error.message }));
  }
};

//update a Desination
export const updateDesigNations = (id, values) => async (dispatch) => {
  // console.log('repoValues', values);
  dispatch(updateDesig());
  try {
    const { data } = await api.updateDesigById(id, values);
    // console.log('actionData :', data);

    dispatch(updateDesigSuccess(data));
    alert('designation updated');
  } catch (error) {
    console.log(error);
    dispatch(updateDesigFailure({ message: error.message }));
  }
};

//Create a Desination
export const createDesigNation = (values) => async (dispatch) => {
  console.log('newdesigtData :', values);
  try {
    dispatch(createDesig());
    const { data } = await api.createDesignation(values);
    // console.log('actionData :', data);
    dispatch(createDesigSuccess(data));
    // console.log('newDesination:', data);
    alert('Desination added');
  } catch (error) {
    console.log(error);
    dispatch(createDesigFailure({ error: error.message }));
  }
};
