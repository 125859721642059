import React, { Fragment, useEffect } from 'react';
import './manageplans.scss';
import ShopRightbar from '../../components_shops/shoprightbar/ShopRightbar';
import { DataGrid } from '@material-ui/data-grid';
import { DeleteOutline, Edit } from '@material-ui/icons';
import {
  Paper,
  Typography,
  CircularProgress,
  Divider,
} from '@material-ui/core/';
import { productRows } from '../../dummyData';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDepartments } from '../../store/departments/repository_dept';
import { getShopPlans } from '../../store/api/apiRoutes';

const ShopPlanList = () => {
  const dispatch = useDispatch();
  const { departments, isFetching } = useSelector((state) => state.depts);
  const [shopplans, setShopPlans] = useState([]);
  const [loading, setLoading] = useState(false);

  // console.log('depts na:', departments);

  // useEffect(() => {
  //   const data = dispatch(getDepartments());

  //   // setPagePosts(data);
  // }, [dispatch]);
  // const [data, setData] = useState(productRows);

  useEffect(() => {
    const getShopPlansCall = async () => {
      setLoading(true);
      try {
        // const { data } = await api.getMarketCategories();
        getShopPlans().then((response) => {
          setShopPlans(response.data.data);
          // console.warn(response.data)
          // console.warn(result)
          setLoading(false);
        });
        // })
      } catch (error) {
        // console.log(error);
        alert({ message: error.message });
        setLoading(false);
      }
    };
    getShopPlansCall();
  }, []);

  const handleDelete = (id) => {
    // setData(data.filter((item) => item.id !== id));
  };

  const columns = [
    // { field: '_id', headerName: 'ID', width: 90 },
    {
      field: 'plansname',
      headerName: 'Plan Name',
      width: 300,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            {/* <img className="productListImg" src={params.row.img} alt="" /> */}
            <h6> {params.row.plansname}</h6>
          </div>
        );
      },
    },
    {
      field: 'support',
      headerName: `Support`,
      width: 200,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            {/* <img className="productListImg" src={params.row.img} alt="" /> */}
            <h6> {params.row.support}</h6>
          </div>
        );
      },
    },
    // {
    //   field: 'status',
    //   headerName: 'Status',
    //   width: 120,
    // },
    // {
    //   field: 'price',
    //   headerName: 'Price',
    //   width: 160,
    // },
    {
      field: 'action',
      headerName: 'Action',
      width: 300,
      renderCell: (params) => {
        // return (
        //   <>
        //     <Link to={"/product/" + params.row.id}>
        //       <button className="productListEdit">Edit</button>
        //     </Link>
        //     <DeleteOutline
        //       className="productListDelete"
        //       onClick={() => handleDelete(params.row.id)}
        //     />
        //   </>
        // );
        return (
          <>
            <div className="actionButtons">
              <Link to={'/plan/update/' + params.row._id}>
                <Edit className="userListEditIcon" />
                {/* <button className="userListEdit">Edit</button> */}
              </Link>
              {/* <DeleteOutline
                className="userListDelete"
                onClick={() => handleDelete(params.row._id)}
              /> */}
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      {/* Changing pages rendering starts here */}

      <>
        {/* start of table grid */}
        <div className="userList">
          <div className="userTitleContainer">
            <h1 className="userTitle"> Shop Plans </h1>
            <Link to="/plan/add">
              <button className="userAddButton">Add Shop Plan </button>
            </Link>
          </div>

          {/* <div className="usertable"> */}
          {loading ? (
            <Paper elevation={6} className="loadingPaper">
              <CircularProgress size="7em" className="loadingPaper" />
            </Paper>
          ) : (
            <DataGrid
              className="tableProps"
              rows={shopplans}
              disableSelectionOnClick
              columns={columns}
              getRowId={(row) => row._id}
              pageSize={8}
              checkboxSelection
            />
          )}
        </div>

        <>
          <ShopRightbar Shop />
        </>
      </>
      {/* Changing pages rendering ends here */}
    </>
  );
};

export default ShopPlanList;
