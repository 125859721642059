import { actionTypes } from './actionMarket';
import Cookies from 'js-cookie';

export const initState = {
  markets: [],
  isFetching: false,
  error: false,
};

function reducer(state = initState, action) {
  switch (action.type) {
    case 'START_LOADING':
      return { ...state, isFetching: true };
    case 'END_LOADING':
      return { ...state, isFetching: false };
    //Get MARKET
    case actionTypes.MARKET_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.MARKET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        markets: action.payload,
        error: false,
      };
    case actionTypes.MARKET_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Get MARKETById:
    case actionTypes.GET_MARKET_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.GET_MARKET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        market: action.payload,
        error: false,
      };
    case actionTypes.GET_MARKET_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Update A MARKETnationsById:
    case actionTypes.UPDATE_MARKET_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.UPDATE_MARKET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        markets: state.markets.map((market) =>
          market._id === action.payload._id ? action.payload : market
        ),
        error: false,
      };
    case actionTypes.UPDATE_MARKET_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Create A New MARKETnationsById:
    case actionTypes.CREATE_MARKET_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.CREATE_MARKET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        markets: [...state.markets, action.payload],
        error: false,
      };
    case actionTypes.CREATE_MARKET_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };

    default:
      return { ...state };
  }
}
export default reducer;
