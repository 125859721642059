import { DeleteOutline, Publish } from '@material-ui/icons';
import React, { useEffect } from 'react';
import useForm from '../../hooks/useForm';
import { AuthApi } from '../../apiconstant/Api';
import { useParams, useHistory, Redirect, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  createDepart,
  getDepartWithId,
  updateDepart,
} from '../../store/departments/repository_dept';
import {
  createShopPlan,
  getShopPlanById,
  updateShopPlanById,
} from '../../store/api/apiRoutes';
import { getDepartments } from '../../store/departments/repository_dept';

// const initialFValues = {
//     id:0,
//     product:{},
//   }

const initialFValues = {
  id: 0,
  plansname: '',
  price: '',
  support: '',
  adsbost: '',
  numberofproducts: '',
  dashboardandanalytics: '',
  numberoffeaturedimages: '',
};

const ShopPlanForm = () => {
  const { values, setValues, handleInputChange, resetForm } =
    useForm(initialFValues);
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const getMarketData = async () => {
      try {
        if (id) {
          getShopPlanById(id).then((response) => {
            setValues(response.data);
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
    getMarketData();
  }, []);

  const handlePlanSubmit = async (e) => {
    e.preventDefault();

    try {
      // dispatch(createDepart(values));

      // const res = createShopPlan(values);
      // console.log(res);

      createShopPlan(values).then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          // alert(response.data.success);
          alert('Plan added');
          // resetForm();
        }
      });
    } catch (error) {
      console.log({ message: error.message });
    }

    resetForm();
  };

  const handlePlanUpdate = async (e) => {
    e.preventDefault();

    try {
      // console.log('storage values are :', values);
      // console.log('UpdateRoutes', updateShopPlanById);

      await updateShopPlanById(id, values).then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          //  alert('department updated');
          console.log(response.data);
          alert('Plan updated');
          history.push('/listplans');

          // resetForm();
        }

        //  browserHistory.push('/manage/posts')
      });
    } catch (error) {
      console.log({ message: error.message });
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    alert('Deleted');
  };

  return (
    <div className="shopErectionUpdate">
      {/* <span className="shopErectionUpdateTitle">Add a Shop Plan </span>
      <form
        className="shopErectionUpdateForm"
        onSubmit={id ? handlePlanUpdate : handlePlanSubmit}
      >
        <div className="shopErectionUpdateLeft">
          <div className="productFormLeft">
            <label>Plan Name</label>
            <input
              type="text"
              placeholder="Shop Plane Name xxx"
              name="plansname"
              value={values.plansname}
              onChange={handleInputChange}
              className="shopErectionUpdateInput"
            />

            <label>Price </label>
            <input
              type="text"
              placeholder="Shop Plane Name xxx"
              name="price"
              value={values.price}
              onChange={handleInputChange}
              className="shopErectionUpdateInput"
            />

            <label>Support</label>
            <input
              type="text"
              placeholder="Shop Plane Name xxx"
              name="support"
              value={values.support}
              onChange={handleInputChange}
              className="shopErectionUpdateInput"
            />

            <label>Ads Boost</label>
            <input
              type="text"
              placeholder="Shop Plane Name xxx"
              name="adsbost"
              value={values.adsbost}
              onChange={handleInputChange}
              className="shopErectionUpdateInput"
            />

            <label>Dashboard And Analytics</label>
            <input
              type="text"
              placeholder="Shop Plane Name xxx"
              name="dashboardandanalytics"
              value={values.dashboardandanalytics}
              onChange={handleInputChange}
              className="shopErectionUpdateInput"
            />

            <label>Number of Products</label>
            <input
              type="text"
              placeholder="Shop Plane Name xxx"
              name="numberofproducts"
              value={values.numberofproducts}
              onChange={handleInputChange}
              className="shopErectionUpdateInput"
            />

            <label>Number of Featured Images</label>
            <input
              type="text"
              placeholder="Shop Plane Name xxx"
              name="numberoffeaturedimages"
              value={values.numberoffeaturedimages}
              onChange={handleInputChange}
              className="shopErectionUpdateInput"
            />

           
          </div>

          
        </div>

        <div className="shopErectionUpdateRight">
          
          <button className="shopErectionUpdateButton">Add/Update Plan</button>
        </div>
      </form> */}

      <span className="shopErectionUpdateTitle">
        Create/Edit a Market Category
      </span>
      {id && (
        <span className="right">
          Delete
          <DeleteOutline
            className="userListDelete right"
            onClick={(e) => handleDelete(e)}
          />
        </span>
      )}

      <hr />
      <form
        onSubmit={id ? handlePlanUpdate : handlePlanSubmit}
        className="ps-form--account-setting"
      >
        <div className="ps-form__content">
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <label>Plan Name</label>
                <input
                  type="text"
                  name="plansname"
                  value={values.plansname}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
            </div>

            <div className="col-sm-12">
              <div className="form-group">
                <label>Price </label>
                <input
                  type="text"
                  name="price"
                  value={values.price}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
            </div>

            <div className="col-sm-12">
              <div className="form-group">
                <label>Support</label>
                <input
                  type="text"
                  name="support"
                  value={values.support}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
            </div>

            <div className="col-sm-12">
              <div className="form-group">
                <label>Ads Boost</label>
                <input
                  type="text"
                  name="adsbost"
                  value={values.adsbost}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
            </div>

            <div className="col-sm-12">
              <div className="form-group">
                <label>Dashboard And Analytics</label>
                <input
                  type="text"
                  name="dashboardandanalytics"
                  value={values.dashboardandanalytics}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
            </div>

            <div className="col-sm-12">
              <div className="form-group">
                <label>Number of Products</label>
                <input
                  type="text"
                  name="numberofproducts"
                  value={values.numberofproducts}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
            </div>

            <div className="col-sm-12">
              <div className="form-group">
                <label>Number of Featured Images</label>
                <input
                  type="text"
                  name="numberoffeaturedimages"
                  value={values.numberoffeaturedimages}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="form-group submit">
          <Link to={'/listplans'} className="btn">
            Cancel
          </Link>
          <button className="ps-btn">Add/Update Market Category</button>
        </div>
      </form>
    </div>
  );
};

export default ShopPlanForm;
