import axios from 'axios';
import Cookies from 'js-cookie';

//"proxy": "https://africanshops.herokuapp.com"
//"proxy": "http://localhost:5000",
//"proxy": 'https://prod-africanshops-server.vercel.app',

//const baseDomain = 'https://prod-africanshops-server.vercel.app';

//const url = 'https://prod-africanshops-server.vercel.app/productcats'

export default function Api() {
  const Api = axios.create({
    //baseURL: 'http://localhost:8000',
    //baseURL: 'https://africanshops.herokuapp.com',

    baseURL: 'https://prod-africanshops-server.vercel.app',
  });

  return Api;
}

export function authApi() {
  const TOKEN = JSON.parse(Cookies.get('authUserInfo')).accessToken;
  // const TOKEN = JSON.parse(Cookies.get('_auth'));

  console.log('PanelToken', TOKEN);

  const Api = axios.create({
    //baseURL: 'http://localhost:5000',
    //baseURL: 'https://africanshops.herokuapp.com',
    baseURL: 'https://prod-africanshops-server.vercel.app',
    headers: { token: `Bearer ${TOKEN}` },
  });

  Api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401 || error.response?.status === 403) {
        AdminLogOutCall();

        return Promise.reject;
      }
      return Promise.reject(error);
    }
  );

  return Api;
}

export const adminSigin = (formData) =>
  Api().post('/authadmin/adminlogin', formData);

//departments
export const getDepts = () => Api().get('/departments');
export const getDeptById = (id) => Api().get(`/departments/${id}`);

export const updateDeptById = (id, deptFormData) =>
  authApi().put(`/departments/${id}`, deptFormData);

export const createDepartMent = (deptFormData) =>
  authApi().post('/departments', deptFormData);

//designation
export const getDesigs = () => Api().get('/designations');
export const getDesigById = (id) => Api().get(`/designations/${id}`);
export const getDesigByDepartmentId = (id) =>
  Api().get(`/designations/bydept/${id}`);

export const updateDesigById = (id, desigFormData) =>
  authApi().put(`/designations/${id}`, desigFormData);

export const createDesignation = (desigFormData) =>
  authApi().post('/designations', desigFormData);

//Countries
export const getCountries = () => Api().get('/buzcountries');
export const getCountryById = (id) => Api().get(`/buzcountries/${id}`);

export const updateCountryById = (id, countryFormData) =>
  authApi().put(`/buzcountries/${id}`, countryFormData);

export const createCountry = (countryFormData) =>
  authApi().post('/buzcountries', countryFormData);
export const deleteCountryById = (id) =>
  authApi().delete(`/buzcountries/${id}`);

//States
export const getBStates = () => Api().get('/buzstates');
export const getStateById = (id) => Api().get(`/buzstates/${id}`);

export const updateStateById = (id, stateFormData) =>
  authApi().put(`/buzstates/${id}`, stateFormData);

export const createBState = (stateFormData) =>
  authApi().post('/buzstates', stateFormData);
export const deleteStateById = (id) => authApi().delete(`/buzstates/${id}`);

//Tradehubs
export const getTradehubs = () => Api().get('/tradehubs');
export const getTradehubById = (id) => Api().get(`/tradehubs/${id}`);

export const updateTradehubById = (id, hubFormData) =>
  authApi().put(`/tradehubs/${id}`, hubFormData);

export const createTradehub = (hubFormData) =>
  authApi().post('/tradehubs', hubFormData);

//Product Categories Routes
export const getProdCats = () => Api().get('/productcats');
export const getProdCatById = (id) => Api().get(`/productcats/${id}`);

export const updateProdCatById = (id, prodcatFormData) =>
  authApi().put(`/productcats/${id}`, prodcatFormData);

export const createProdCat = (prodcatFormData) =>
  authApi().post('/productcats', prodcatFormData);

export const featureProdCatById = (id) =>
  authApi().put(`/productcats/feature-category/${id}`);

export const unFeatureProdCatById = (id) =>
  authApi().put(`/productcats/unfeature-category/${id}`);

//Product Units Routes
export const getProdUnits = () => Api().get('/productunits');
export const getProdUnitById = (id) => Api().get(`/productunits/${id}`);

export const updateProdUnitById = (id, prodUnitFormData) =>
  authApi().put(`/productunits/${id}`, prodUnitFormData);

export const createProdUnit = (prodUnitFormData) =>
  authApi().post('/productunits', prodUnitFormData);

// Product Routes Routes
export const getProducts = () => Api().get('/products');
export const getProductById = (id) => Api().get(`/products/${id}`);

export const updateProductById = (id, productFormData) =>
  authApi().put(`/products/${id}`, productFormData);

export const createProduct = (productFormData) =>
  authApi().post('/products', productFormData);

///============Product related========================//

//Market Categories
export const getMarketCategories = () => Api().get('/marketcategories');
export const getMarketCategoryById = (id) =>
  Api().get(`/marketcategories/${id}`);

export const updateMarketCategoryById = (id, marketCategoryFormData) =>
  authApi().put(`/marketcategories/${id}`, marketCategoryFormData);

export const createMarketCategory = (marketCategoryFormData) =>
  authApi().post('/marketcategories', marketCategoryFormData);

//Market
export const getMarkets = () => Api().get('/markets');
export const getMarketById = (id) => Api().get(`/markets/${id}`);

export const updateMarketById = (id, marketFormData) =>
  authApi().put(`/markets/${id}`, marketFormData);

export const createMarket = (marketFormData) =>
  authApi().post('/markets', marketFormData);

export const featureMarket = (id) =>
  authApi().put(`/markets/feature-market/${id}`);

export const unFeatureMarket = (id) =>
  authApi().put(`/markets/unfeature-market/${id}`);

//SHopPlans Routes
export const getShopPlans = () => Api().get('/shopplans');
export const getShopPlanById = (id) => Api().get(`/shopplans/${id}`);

export const updateShopPlanById = (id, planFormData) =>
  authApi().put(`/shopplans/${id}`, planFormData);

export const createShopPlan = (planFormData) =>
  authApi().post('/shopplans', planFormData);

//SHops Routes
export const getShops = () => Api().get('/shops');
export const getShopById = (id) => Api().get(`/shops/${id}`);

export const updateShopById = (id, shopFormData) =>
  authApi().put(`/shops/${id}`, shopFormData);

export const createApiShop = (shopFormData) =>
  authApi().post('/shops', shopFormData);

export const adminMakeShopHomeSlider = (id, shopFormData) =>
  authApi().put(`/shops/makeshop-homeslider/${id}`, shopFormData);

export const adminUnMakeShopHomeSlider = (id, shopFormData) =>
  authApi().put(`/shops/unmakeshop-homeslider/${id}`, shopFormData);

export const adminMakeShopBannerAd = (id, shopFormData) =>
  authApi().put(`/shops/makeshop-bannerads/${id}`, shopFormData);

export const adminUnMakeShopBannerAd = (id, shopFormData) =>
  authApi().put(`/shops/unmakeshop-bannerads/${id}`, shopFormData);

export const adminMakeShopPromotion = (id, shopFormData) =>
  authApi().put(`/shops/makeshop-promotion/${id}`, shopFormData);

export const adminUnMakeShopPromotion = (id, shopFormData) =>
  authApi().put(`/shops/unmakeshop-promotion/${id}`, shopFormData);

//Ads Routes
export const getAds = () => Api().get('/ads');
export const getAdById = (id) => Api().get(`/ads/${id}`);

export const updateAdById = (id, adFormData) =>
  authApi().put(`/ads/${id}`, adFormData);

export const createAd = (adFormData) => authApi().post('/ads', adFormData);

// Post Routes
export const getPostcats = () => Api().get('/postcats');
export const getPostcatById = (id) => Api().get(`/postcats/${id}`);

export const updatePostcatById = (id, postcatFormData) =>
  authApi().put(`/postcats/${id}`, postcatFormData);

export const createPostcat = (postcatFormData) =>
  authApi().post('/postcats', postcatFormData);

// Post Routes
export const getNewsPosts = () => Api().get('/posts');

export const getAdminPosts = () => authApi().get('/posts/adminposts');

// export const getPosts = () => Api().get('/posts');
// export const getPostById = (id) => Api().get(`/posts/${id}`);
export const getSinglePostById = (id) => Api().get(`/posts/${id}`);

export const getPostById = (id) => authApi().get(`/posts/bycreator/${id}`);

export const updatePostById = (id, postFormData) =>
  authApi().put(`/posts/${id}`, postFormData);

export const createPost = (postFormData) =>
  authApi().post('/posts', postFormData);

// Faq Routes
export const getFaqs = () => Api().get('/faqs');
export const getApiFaqById = (id) => authApi().get(`/faqs/byadmin/${id}`);

export const updateFaqById = (id, faqFormData) =>
  authApi().put(`/faqs/${id}`, faqFormData);

export const createApiFaq = (faqFormData) =>
  authApi().post('/faqs', faqFormData);

// Partners Routes
export const getPartners = () => Api().get('/partners');

export const getAdminPartnerById = (id) =>
  authApi().get(`/partners/byadmin/${id}`);

export const getPartnerById = (id) => Api().get(`/partners/${id}`);

export const updatePartnerById = (id, partnerFormData) =>
  authApi().put(`/partners/${id}`, partnerFormData);

export const createPartner = (partnerFormData) =>
  authApi().post('/partners', partnerFormData);

// Banner Routes users
export const getBanners = () => Api().get('/banners');
export const getApiBannerById = (id) => Api().get(`/banners/${id}`);

export const updateBannerById = (id, bannerFormData) =>
  authApi().put(`/banners/${id}`, bannerFormData);

export const createBanner = (bannerFormData) =>
  authApi().post('/banners', bannerFormData);

export const adminMakeHomeSlider = (id) =>
  authApi().put(`/banners/makehomeslider/${id}`);

export const adminUnMakeHomeSlider = (id) =>
  authApi().put(`/banners/unmakehomeslider/${id}`);

// Legal/Advocacy Routes
export const getLegals = () => Api().get('/privacies');
export const getApiLegalById = (id) => Api().get(`/privacies/${id}`);

export const updateLegalById = (id, legalFormData) =>
  authApi().put(`/privacies/${id}`, legalFormData);

export const createLegal = (legalFormData) =>
  authApi().post('/privacies', legalFormData);

// Website Info Routes
export const getInfos = () => Api().get('/infos');
export const getApiInfoById = (id) => Api().get(`/infos/${id}`);

export const updateInfoById = (id, InfoFormData) =>
  authApi().put(`/infos/${id}`, InfoFormData);

export const createInfo = (InfoFormData) =>
  authApi().post('/infos', InfoFormData);

//Admin Order Routes
export const adminGetOrders = () => authApi().get('/adminorders');
export const adminGetOrderById = (id) => authApi().get(`/adminorders/${id}`);
export const adminDeleteOrders = (id) => authApi().delete(`/adminorders/${id}`);
// export const getShopById = (id) => Api().get(`/shops/${id}`);

export const adminPackOrders = (id) =>
  authApi().put(`/adminorders/pack-unpack/${id}`);

export const adminShipOrders = (id) =>
  authApi().put(`/adminorders/ship-unship/${id}`);

export const adminDeliverOrders = (id) =>
  authApi().put(`/adminorders/deliver-undeliver/${id}`);

//Finance Manage Orders and cashout Routes
export const adminFinanceGetOrders = () =>
  authApi().get('/financehandleorders');

export const adminFinanceGetOrderById = (id) =>
  authApi().get(`/financehandleorders/finance/${id}`);

export const adminFinanceCompleteOrders = (id) =>
  authApi().put(`/financehandleorders/complete-order/${id}`);

//Finance Manage Withdrawals Requests and Approval Routes
export const adminFinanceGetWithdrawals = () =>
  authApi().get('/handlewithdrawals');

export const adminFinanceGetApprovedWithdrawals = () =>
  authApi().get('/handlewithdrawals/showapproved');

export const adminFinanceGetWithdrawalsById = (id) =>
  authApi().get(`/handlewithdrawals/finance/${id}`);

export const adminFinanceApproveWithdrawals = (id) =>
  authApi().put(`/handlewithdrawals/complete-withdrawal/${id}`);

export const adminFinancePayoutWithdrawals = (id) =>
  authApi().put(`/handlewithdrawals/payout-withdrawal/${id}`);

//others 61b3279cdc3ce9f8fb5160f7
// export const getBarners = () => Api().get('/barners');
// export const getPosts = () => Api().get('/posts');
// export const findOnePost = (id) => Api().get(`/posts/${id}`);

// shop product handling starts
// export const storeShopProduct = (formData) =>
//   Api().post('/store/shopproducts', formData);

// export const getShopProducts = () => Api().get('/shop/product/list');

// shop product handling ends

// export const logOut = () => {
//   if (typeof window !== 'undefined') {
//     // remove logged in user's cookie and redirect to login page 'authUserCookie', state.user, 60 * 24
//     try {
//       Api()
//         .post(`/logout`)
//         .then((response) => {
//           // alert('logged out successfully');
//           console.log('logged out successfully');
//         });

//       window.location.reload();
//     } catch (error) {
//       console.log(error);
//     }

//     Cookies.remove('ClientUserInfo');
//     Cookies.remove('isloggedin');
//   }
// };

// Users/Customers Routes users
export const getApiUsers = () => authApi().get('/users');
export const getApiUserById = (id) => authApi().get(`/users/${id}`);

export const updateApiUserById = (id, usersFormData) =>
  authApi().put(`/users/${id}`, usersFormData);

export const createApiUser = (usersFormData) =>
  authApi().post('/users', usersFormData);

export const adminBlockDisciplineUser = (id) =>
  authApi().put(`/users/blockuser/${id}`);
///blockuser/
export const adminUnBlockDisciplineUser = (id) =>
  authApi().put(`/users/unblockuser/${id}`);

export const adminSuspendDisciplineUser = (id) =>
  authApi().put(`/users/suspenduser/${id}`);

export const adminUnSuspendDisciplineUser = (id) =>
  authApi().put(`/users/unsuspenduser/${id}`);

//Users/Customers and discipinary Routes ends here

// Admin/ControlPanel AdminUsers Routes adminusers starts
export const getApiAdminUsers = () => authApi().get('/admin');
export const getApiAdminUserById = (id) => authApi().get(`/admin/${id}`);
///blockuser/
export const adminBlockDisciplineStaff = (id) =>
  authApi().put(`/admin/blockuser/${id}`);

export const adminUnBlockDisciplineStaff = (id) =>
  authApi().put(`/admin/unblockuser/${id}`);
///suspend/unsuspend admin control panel user/
export const adminSuspendDisciplineStaff = (id) =>
  authApi().put(`/admin/suspenduser/${id}`);

export const adminUnSuspendDisciplineStaff = (id) =>
  authApi().put(`/admin/unsuspenduser/${id}`);

///User Leadership Status user/
export const adminMakeLeader = (id) => authApi().put(`/admin/makeceo/${id}`);

export const adminUnMakeLeader = (id) =>
  authApi().put(`/admin/unmakeceo/${id}`);

// Admin/ControlPanel AdminUsers Routes adminusers starts

export const updateApiAdminUserById = (id, adminFormData) =>
  authApi().put(`/admin/${id}`, adminFormData);

export const createApiAdminUser = (adminFormData) =>
  authApi().post('/admin', adminFormData);

export const AdminLogOutCall = () => {
  // if (typeof window !== 'undefined') {
  //   // remove logged in user's cookie and redirect to login page 'authUserCookie', state.user, 60 * 24
  //   Cookies.remove('authUserInfo');
  // }
  if (typeof window !== 'undefined') {
    // remove logged in user's cookie and redirect to login page 'authUserCookie', state.user, 60 * 24
    try {
      Api()
        .post(`/logout`)
        .then((response) => {
          // alert('logged out successfully');
          console.log('logged out successfully');
        });

      window.location.reload();
    } catch (error) {
      console.log(error);
    }

    Cookies.remove('authUserInfo');
    Cookies.remove('isloggedin');
  }
};
