import React from 'react';
import './feed.scss';
import Share from '../share/Share';
import Post from '../post/Post';
import { Posts } from '../../dummyData';
import List from '../list/List';
import { UserInfoForm } from './UserInfoForm';

const EditProfileComponent = () => {
  return (
    <div className="feed">
      <div className="feedWrapper">
        <UserInfoForm />
      </div>
    </div>
  );
};

export default EditProfileComponent;
