import * as api from '../api/apiRoutes';
import {
  market,
  marketSuccess,
  marketFailure,
  getMarketById,
  getMarketByIdSuccess,
  getMarketByIdFailure,
  updateMarket,
  updateMarketSuccess,
  updateMarketFailure,
  createMarket,
  createMarketSuccess,
  createMarketFailure,
} from './actionMarket';
const Cookies = require('js-cookie');

//get all Market Categories
export const getMarkets = () => async (dispatch) => {
  try {
    dispatch(market());
    const { data } = await api.getMarkets();
    // console.log('procatData :', data);

    // dispatch({
    //   type: FETCH_ALL,
    //   payload: { data, currentPage, numberOfPages },
    // });
    dispatch(marketSuccess(data));
  } catch (error) {
    // console.log(error);
    dispatch(marketFailure({ error: error.message }));
  }
};

//get single Market
export const getMarketWithId = (id) => async (dispatch) => {
  dispatch(getMarketById());
  // console.log('Acton start');
  try {
    const { data } = await api.getMarketById(id);
    // console.log('MydesigByID :', data);

    dispatch(getMarketByIdSuccess(data));
  } catch (error) {
    // console.log(error);
    dispatch(getMarketByIdFailure({ error: error.message }));
  }
};

//update a Market
export const updateMarkets = (id, values) => async (dispatch) => {
  // console.log('repoValues', values);
  dispatch(updateMarket());
  try {
    const { data } = await api.updateMarketById(id, values);
    // console.log('actionData :', data);

    dispatch(updateMarketSuccess(data));
    alert('Market Cat updated');
  } catch (error) {
    // console.log(error);
    dispatch(updateMarketFailure({ message: error.message }));
  }
};

//Create a Market
export const createMarketRepo = (values) => async (dispatch) => {
  try {
    dispatch(createMarket());
    const { data } = await api.createMarket(values);
    // console.log('actionData :', data);
    dispatch(createMarketSuccess(data));
    // console.log('newDesination:', data);
    alert('Market Cat added');
  } catch (error) {
    dispatch(createMarketFailure({ error: error.message }));
  }
};
