export const actionTypes = {
  DEPT_REQUEST: 'DEPT_REQUEST',
  DEPT_SUCCESS: 'DEPT_SUCCESS',
  DEPT_FAILURE: 'DEPT_FAILURE',

  GET_DEPT_REQUEST: 'GET_DEPT_REQUEST',
  GET_DEPT_SUCCESS: 'GET_DEPT_SUCCESS',
  GET_DEPT_FAILURE: 'GET_DEPT_FAILURE',

  UPDATE_DEPT_REQUEST: 'UPDATE_DEPT_REQUEST',
  UPDATE_DEPT_SUCCESS: 'UPDATE_DEPT_SUCCESS',
  UPDATE_DEPT_FAILURE: 'UPDATE_DEPT_FAILURE',

  CREATE_DEPT_REQUEST: 'CREATE_DEPT_REQUEST',
  CREATE_DEPT_SUCCESS: 'CREATE_DEPT_SUCCESS',
  CREATE_DEPT_FAILURE: 'CREATE_DEPT_FAILURE',

  //whenloading
  END_LOADING: 'END_LOADING',
  START_LOADING: 'START_LOADING',
};

export function dept() {
  return { type: actionTypes.DEPT_REQUEST };
}

export function deptSuccess(departments) {
  return { type: actionTypes.DEPT_SUCCESS, payload: departments.data };
}

export function deptFailure() {
  return { type: actionTypes.DEPT_FAILURE };
}

//Get Department by ID:
export function getDeptById() {
  return { type: actionTypes.GET_DEPT_REQUEST };
}

export function getDeptByIdSuccess(department) {
  return { type: actionTypes.GET_DEPT_SUCCESS, payload: department };
}

export function getDeptByIdFailure() {
  return { type: actionTypes.GET_DEPT_FAILURE };
}

//Update Department by ID:
export function updateDept() {
  return { type: actionTypes.UPDATE_DEPT_REQUEST };
}

export function updateDeptSuccess(data) {
  return {
    type: actionTypes.UPDATE_DEPT_SUCCESS,
    payload: data,
  };
}

export function updateDeptFailure() {
  return { type: actionTypes.UPDATE_DEPT_FAILURE };
}

//create new Department:
export function createDept() {
  return { type: actionTypes.CREATE_DEPT_REQUEST };
}

export function createDeptSuccess(data) {
  return {
    type: actionTypes.CREATE_DEPT_SUCCESS,
    payload: data,
  };
}

export function createDeptFailure() {
  return { type: actionTypes.CREATE_DEPT_FAILURE };
}

// export function logOut() {
//   return { type: actionTypes.LOG_OUT };
// }
