export const actionTypes = {
  LEGAL_REQUEST: 'LEGAL_REQUEST',
  LEGAL_SUCCESS: 'LEGAL_SUCCESS',
  LEGAL_FAILURE: 'LEGAL_FAILURE',

  GET_LEGAL_REQUEST: 'GET_LEGAL_REQUEST',
  GET_LEGAL_SUCCESS: 'GET_LEGAL_SUCCESS',
  GET_LEGAL_FAILURE: 'GET_LEGAL_FAILURE',

  UPDATE_LEGAL_REQUEST: 'UPDATE_LEGAL_REQUEST',
  UPDATE_LEGAL_SUCCESS: 'UPDATE_LEGAL_SUCCESS',
  UPDATE_LEGAL_FAILURE: 'UPDATE_LEGAL_FAILURE',

  CREATE_LEGAL_REQUEST: 'CREATE_LEGAL_REQUEST',
  CREATE_LEGAL_SUCCESS: 'CREATE_LEGAL_SUCCESS',
  CREATE_LEGAL_FAILURE: 'CREATE_LEGAL_FAILURE',

  //whenloading
  END_LOADING: 'END_LOADING',
  START_LOADING: 'START_LOADING',
};

export function legal() {
  return { type: actionTypes.LEGAL_REQUEST };
}

export function legalSuccess(data) {
  return { type: actionTypes.LEGAL_SUCCESS, payload: data.data };
}

export function legalFailure() {
  return { type: actionTypes.LEGAL_FAILURE };
}

//Get Legal by ID:
export function getLegalById() {
  return { type: actionTypes.GET_LEGAL_REQUEST };
}

export function getLegalByIdSuccess(Legal) {
  return { type: actionTypes.GET_LEGAL_SUCCESS, payload: Legal };
}

export function getLegalByIdFailure() {
  return { type: actionTypes.GET_LEGAL_FAILURE };
}

//Update Legal by ID:
export function updateLegal() {
  return { type: actionTypes.UPDATE_LEGAL_REQUEST };
}

export function updateLegalSuccess(data) {
  return {
    type: actionTypes.UPDATE_Legal_SUCCESS,
    payload: data,
  };
}

export function updateLegalFailure() {
  return { type: actionTypes.UPDATE_LEGAL_FAILURE };
}

//create new Legal:
export function createLegal() {
  return { type: actionTypes.CREATE_LEGAL_REQUEST };
}

export function createLegalSuccess(data) {
  return {
    type: actionTypes.CREATE_LEGAL_SUCCESS,
    payload: data,
  };
}

export function createLegalFailure() {
  return { type: actionTypes.CREATE_LEGAL_FAILURE };
}
