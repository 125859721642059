import React, { useEffect } from 'react';
import './userList.scss';
import ShopRightbar from '../../components_shops/shoprightbar/ShopRightbar';
import UserListGrid from '../../components_shops/userlistgrid/UserListGrid';
import { DataGrid } from '@material-ui/data-grid';
import { DeleteOutline, Edit } from '@material-ui/icons';
import { userRows } from '../../dummyData';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsersRepo } from '../../store/users/repository_user';
import {
  Paper,
  Typography,
  CircularProgress,
  Divider,
} from '@material-ui/core/';

const UserList = () => {
  const [data, setData] = useState(userRows);
  const dispatch = useDispatch();
  const { users, isFetching } = useSelector((state) => state.users);

  useEffect(() => {
    const data = dispatch(getAllUsersRepo());

    // setPagePosts(data);
  }, [dispatch]);

  const handleDelete = (id) => {
    users.filter((item) => item.id !== id);
  };

  const columns = [
    // { field: "id", headerName: "ID", width: 90 },
    {
      field: 'user',
      headerName: 'User',
      width: 150,
      renderCell: (params) => {
        return (
          <div className="userListUser">
            <img
              className="userListImg"
              src={params.row.avatar ? params.row.avatar : params.row.name[0]}
              alt=""
            />
            <h6> {params.row.name}</h6>
          </div>
        );
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 150,
      renderCell: (params) => {
        return (
          <div className="userListUser">
            {/* <img className="userListImg" src={params.row.avatar} alt="" /> */}
            <h6> {params.row.email}</h6>
          </div>
        );
      },
    },

    {
      field: 'Complinace',
      headerName: 'User Compliance',
      width: 160,
      renderCell: (params) => {
        return (
          <div className="userListUser">
            {/* <img className="userListImg" src={params.row.avatar} alt="" /> */}
            <h6>
              {' '}
              {params.row.isSuspended || params.row.isBlocked ? (
                <h6 className="userListStatus">Uncompliant</h6>
              ) : (
                <h6 className="userListEdit">Compliant</h6>
              )}
            </h6>
          </div>
        );
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 170,
      renderCell: (params) => {
        return (
          <>
            <div className="actionButtons">
              <Link to={'/customer/' + params.row._id}>
                <Edit className="userListEditIcon" />
                {/* <button className="userListEdit">Edit</button> */}
              </Link>
              {/* <DeleteOutline
                className="userListDelete"
                onClick={() => handleDelete(params.row._id)}
              /> */}
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <>
        <div className="userRight">
          <div className="userBottom">
            {/* start of table grid */}
            <div className="userList">
              <div className="userTitleContainer">
                <h1 className="userTitle">Our Users/Cusomers</h1>
                <Link to="#">
                  <button className="userAddButton">Create</button>
                </Link>
              </div>

              {/* <div className="usertable"> */}
              {isFetching ? (
                <Paper elevation={6} className="loadingPaper">
                  <CircularProgress size="7em" className="loadingPaper" />
                </Paper>
              ) : (
                <DataGrid
                  className="tableProps"
                  rows={users}
                  disableSelectionOnClick
                  columns={columns}
                  getRowId={(row) => row._id}
                  pageSize={20}
                  checkboxSelection
                />
              )}

              {/* </div> */}
            </div>
            {/* end of table grid */}
          </div>
        </div>

        <>
          <ShopRightbar Shop />
        </>
      </>

      <></>
    </>
  );
};

export default UserList;
