export const actionTypes = {
  PARTNER_REQUEST: 'PARTNER_REQUEST',
  PARTNER_SUCCESS: 'PARTNER_SUCCESS',
  PARTNER_FAILURE: 'PARTNER_FAILURE',

  GET_PARTNER_REQUEST: 'GET_PARTNER_REQUEST',
  GET_PARTNER_SUCCESS: 'GET_PARTNER_SUCCESS',
  GET_PARTNER_FAILURE: 'GET_PARTNER_FAILURE',

  UPDATE_PARTNER_REQUEST: 'UPDATE_PARTNER_REQUEST',
  UPDATE_PARTNER_SUCCESS: 'UPDATE_PARTNER_SUCCESS',
  UPDATE_PARTNER_FAILURE: 'UPDATE_PARTNER_FAILURE',

  CREATE_PARTNER_REQUEST: 'CREATE_PARTNER_REQUEST',
  CREATE_PARTNER_SUCCESS: 'CREATE_PARTNER_SUCCESS',
  CREATE_PARTNER_FAILURE: 'CREATE_PARTNER_FAILURE',

  //whenloading
  END_LOADING: 'END_LOADING',
  START_LOADING: 'START_LOADING',
};

export function partner() {
  return { type: actionTypes.PARTNER_REQUEST };
}

export function partnerSuccess(data) {
  return { type: actionTypes.PARTNER_SUCCESS, payload: data.data };
}

export function partnerFailure() {
  return { type: actionTypes.PARTNER_FAILURE };
}

//Get Partner by ID:
export function getPartnerById() {
  return { type: actionTypes.GET_PARTNER_REQUEST };
}

export function getPartnerByIdSuccess(Partner) {
  return { type: actionTypes.GET_PARTNER_SUCCESS, payload: Partner };
}

export function getPartnerByIdFailure() {
  return { type: actionTypes.GET_PARTNER_FAILURE };
}

//Update Partner by ID:
export function updatePartner() {
  return { type: actionTypes.UPDATE_PARTNER_REQUEST };
}

export function updatePartnerSuccess(data) {
  return {
    type: actionTypes.UPDATE_PARTNER_SUCCESS,
    payload: data,
  };
}

export function updatePartnerFailure() {
  return { type: actionTypes.UPDATE_PARTNER_FAILURE };
}

//create new Partner:
export function createPartner() {
  return { type: actionTypes.CREATE_PARTNER_REQUEST };
}

export function createPartnerSuccess(data) {
  return {
    type: actionTypes.CREATE_PARTNER_SUCCESS,
    payload: data,
  };
}

export function createPartnerFailure() {
  return { type: actionTypes.CREATE_PARTNER_FAILURE };
}
