import React from 'react';
import { Link } from 'react-router-dom';
import './topbar.scss';
import {
  Search,
  ShoppingCart,
  Person,
  Chat,
  Notifications,
  ArrowDropDown,
} from '@material-ui/icons';

import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { Profiler } from 'react';
import Api from '../../apiconstant/Api';
import { logOut } from '../../apiconstant/Api';
import { AdminLogOutCall } from '../../store/api/apiRoutes';
import { Logout } from '../../context/AuthActions';
import { useDispatch, useSelector } from 'react-redux';

const TopBar = ({ user }) => {
  // const { user } = useContext(AuthContext);

  // console.log('topBar User', user);

  const LogoutHandler = (e) => {
    AdminLogOutCall();
    // e.preventDefault();

    // alert(roleById);

    // try {
    //   Api()
    //     .post(`/logout`)
    //     .then((response) => {
    //       alert('logged out successfully');
    //       // if(response.data.error){
    //       //   console.log(response.data.error)
    //       // }else{
    //       //   alert('state added');
    //       //   props.history.push('/manage/states')
    //       // }
    //     });
    //   logOut();
    //   window.location.reload();
    // } catch (error) {
    //   console.log(error);
    // }
  };

  return (
    <div className="topbarContainer">
      <div className="topbarLeft">
        <Link to="/">
          <span className="logo">Afrizone</span>
        </Link>
        <div className="topbarLinks">
          <Link className="topbarLinks" to="/feeds">
            <span className="topbarLink">Feeds</span>
          </Link>
          {/* <Link className="topbarLinks" to="/markets">
            <span className="topbarLink">Markets</span>
          </Link>
          <Link className="topbarLinks" to="/shopping/area">
            <span className="topbarLink">Trade</span>
          </Link> */}
          <Link className="topbarLinks" to="/newsblog">
            <span className="topbarLink">News</span>
          </Link>
        </div>
      </div>
      <div className="topbarCenter">
        <div className="searchbar">
          <Search className="searchIcon" />
          <input
            placeholder="search for friends, post or video"
            className="searchInput"
          />
        </div>
      </div>

      {user ? (
        <>
          <div className="topbarRight">
            {/* <div className="topbarLinks">
                        <Link to="/"><span className="topbarLink">Homepage</span></Link>
                        <Link to="/feeds"><span className="topbarLink">Feeds</span></Link>
                        <Link to="/shopping/area"><span className="topbarLink">Markets</span></Link>
                        <Link to="/newsblog"><span className="topbarLink">News</span></Link>


                </div> */}

            {/* <div className="topbarIcons">
              <Link className="topbarLinks" to="/cart">
                <div className="topbarIconItem">
                  <ShoppingCart />
                  <span className="topbarIconBadge">1</span>
                </div>
              </Link>
              <div className="topbarIconItem">
                <Person />
                <span className="topbarIconBadge">1</span>
              </div>
              <div className="topbarIconItem">
                <Chat />
                <span className="topbarIconBadge">4</span>
              </div>
              <div className="topbarIconItem">
                <Notifications />
                <span className="topbarIconBadge">2</span>
              </div>
            </div> */}

            <div className="avatars">
              {/* <div className="avatarItem">
                   <Link to="/shop/dashboard"> <img src="/assets/person/1.jpeg" alt="" className="topbarImg" /></Link>
               
                </div>
                              */}

              {/* <div className="avatarItem">
                <Link to="/admin/dashboard">
                  {' '}
                  <img
                    src="/assets/person/1.jpeg"
                    alt=""
                    className="topbarImg"
                  />
                </Link>
              </div> */}
              <div className="avatarItem">
                <div
                  style={{
                    display: 'flex',
                    alignItem: 'center',
                    gap: '4px',
                    justifyContent: 'center',
                  }}
                >
                  <img src={user?.avatar} alt="" className="topbarImg" />
                  <h5
                    style={{
                      color: 'white',
                      textWeight: 'bold',
                      padding: '3px',
                      cursor: 'pointer',
                    }}
                  >
                    {user?.name}
                  </h5>
                </div>

                <div className="options">
                  <span>Settings</span>
                  <Link className="topbarLinks" to="/profile">
                    <span>Profile</span>
                  </Link>
                  {user.isShopExist === false && (
                    <Link className="topbarLinks" to="/setup/shop">
                      <span>Set-Shop</span>
                    </Link>
                  )}

                  <Link className="topbarLinks" to="/shop/dashboard">
                    <span>My-Dashboard</span>
                  </Link>
                  <span onClick={LogoutHandler}>Logout</span>
                </div>

                {/* register
                <Link to="/admin/dashboard"><img src="/assets/person/1.jpeg" alt="" className="topbarImg" /></Link>
                <Link to="/login">login</Link> profile  avatarArowDownItem*/}
              </div>
              {/* <div className="avatarItem">
                   <ArrowDropDown className="icon"/>
                  
                </div> */}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="topbarRightAuthLinks">
            <Link className="topbarLinks" to="/login">
              <span className="topbarLink">Login</span>
            </Link>
            <Link className="topbarLinks" to="/login">
              <span className="topbarLink">Register</span>
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default TopBar;
