import { actionTypes } from './actionAdminUsers';
import Cookies from 'js-cookie';

export const initState = {
  adminusers: [],
  isFetching: false,
  error: false,
};

function reducer(state = initState, action) {
  switch (action.type) {
    case 'START_LOADING':
      return { ...state, isFetching: true };
    case 'END_LOADING':
      return { ...state, isFetching: false };
    //Get MARKET
    case actionTypes.ADMINUSER_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.ADMINUSER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        adminusers: action.payload,
        error: false,
      };
    case actionTypes.ADMINUSER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Get MARKETById:
    case actionTypes.GET_ADMINUSER_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.GET_ADMINUSER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        adminuser: action.payload,
        error: false,
      };
    case actionTypes.GET_ADMINUSER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Update A MARKETnationsById:
    case actionTypes.UPDATE_ADMINUSER_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.UPDATE_ADMINUSER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        adminusers: state.adminusers.map((user) =>
          user._id === action.payload._id ? action.payload : user
        ),
        error: false,
      };
    case actionTypes.UPDATE_ADMINUSER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Create A New MARKETnationsById:
    case actionTypes.CREATE_ADMINUSER_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.CREATE_ADMINUSER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        adminusers: [...state.users, action.payload],
        error: false,
      };
    case actionTypes.CREATE_ADMINUSER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };

    default:
      return { ...state };
  }
}
export default reducer;
