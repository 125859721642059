import React from 'react';

const StaffInformation = () => {
  return (
    <div className="ps-card ps-card--order-information ps-card--small">
      <div className="ps-card__header">
        <h4>Staff Information</h4>
      </div>
      <div className="ps-card__content">
        <p>
          <strong>Payment Status:</strong>
        </p>
        <p>
          <strong>Payment Type:</strong> Visa
        </p>
        <p>
          <strong>Provider:</strong> **** **** **** *2121
        </p>
        <p>
          <strong>Valid Date:</strong>
        </p>
      </div>
    </div>
  );
};

export default StaffInformation;
