export const actionTypes = {
  PROCAT_REQUEST: 'PROCAT_REQUEST',
  PROCAT_SUCCESS: 'PROCAT_SUCCESS',
  PROCAT_FAILURE: 'PROCAT_FAILURE',

  GET_PROCAT_REQUEST: 'GET_PROCAT_REQUEST',
  GET_PROCAT_SUCCESS: 'GET_PROCAT_SUCCESS',
  GET_PROCAT_FAILURE: 'GET_PROCAT_FAILURE',

  UPDATE_PROCAT_REQUEST: 'UPDATE_PROCAT_REQUEST',
  UPDATE_PROCAT_SUCCESS: 'UPDATE_PROCAT_SUCCESS',
  UPDATE_PROCAT_FAILURE: 'UPDATE_PROCAT_FAILURE',

  CREATE_PROCAT_REQUEST: 'CREATE_PROCAT_REQUEST',
  CREATE_PROCAT_SUCCESS: 'CREATE_PROCAT_SUCCESS',
  CREATE_PROCAT_FAILURE: 'CREATE_PROCAT_FAILURE',

  //whenloading
  END_LOADING: 'END_LOADING',
  START_LOADING: 'START_LOADING',
};

export function procat() {
  return { type: actionTypes.PROCAT_REQUEST };
}

export function procatSuccess(procats) {
  return { type: actionTypes.PROCAT_SUCCESS, payload: procats.data };
}

export function procatFailure() {
  return { type: actionTypes.PROCAT_FAILURE };
}

//Get PROCATnation by ID:
export function getProcatById() {
  return { type: actionTypes.GET_PROCAT_REQUEST };
}

export function getProcatByIdSuccess(procat) {
  return { type: actionTypes.GET_PROCAT_SUCCESS, payload: procat };
}

export function getProcatByIdFailure() {
  return { type: actionTypes.GET_PROCAT_FAILURE };
}

//Update PROCATnation by ID:
export function updateProcat() {
  return { type: actionTypes.UPDATE_PROCAT_REQUEST };
}

export function updateProcatSuccess(data) {
  return {
    type: actionTypes.UPDATE_PROCAT_SUCCESS,
    payload: data,
  };
}

export function updateProcatFailure() {
  return { type: actionTypes.UPDATE_PROCAT_FAILURE };
}

//create new PROCATnation:
export function createProcat() {
  return { type: actionTypes.CREATE_PROCAT_REQUEST };
}

export function createProcatSuccess(data) {
  return {
    type: actionTypes.CREATE_PROCAT_SUCCESS,
    payload: data,
  };
}

export function createProcatFailure() {
  return { type: actionTypes.CREATE_PROCAT_FAILURE };
}

// export function logOut() {
//   return { type: actionTypes.LOG_OUT };
// }
