export const actionTypes = {
  STATES_REQUEST: 'STATES_REQUEST',
  STATES_SUCCESS: 'STATES_SUCCESS',
  STATES_FAILURE: 'STATES_FAILURE',

  GET_STATES_REQUEST: 'GET_STATES_REQUEST',
  GET_STATES_SUCCESS: 'GET_STATES_SUCCESS',
  GET_STATES_FAILURE: 'GET_STATES_FAILURE',

  UPDATE_STATES_REQUEST: 'UPDATE_STATES_REQUEST',
  UPDATE_STATES_SUCCESS: 'UPDATE_STATES_SUCCESS',
  UPDATE_STATES_FAILURE: 'UPDATE_STATES_FAILURE',

  CREATE_STATES_REQUEST: 'CREATE_STATES_REQUEST',
  CREATE_STATES_SUCCESS: 'CREATE_STATES_SUCCESS',
  CREATE_STATES_FAILURE: 'CREATE_STATES_FAILURE',

  GET_COUNTRYSTATES_REQUEST: 'GET_COUNTRYSTATES_REQUEST',
  GET_COUNTRYSTATES_SUCCESS: 'GET_COUNTRYSTATES_SUCCESS',
  GET_COUNTRYSTATES_FAILURE: 'GET_COUNTRYSTATES_FAILURE',

  //whenloading
  END_LOADING: 'END_LOADING',
  START_LOADING: 'START_LOADING',
};

export function states() {
  return { type: actionTypes.STATES_REQUEST };
}

export function statesSuccess(states) {
  return { type: actionTypes.STATES_SUCCESS, payload: states.data };
}

export function statesFailure() {
  return { type: actionTypes.STATES_FAILURE };
}

//Get STATESnation by ID:
export function getStatesById() {
  return { type: actionTypes.GET_STATES_REQUEST };
}

export function getStatesByIdSuccess(STATESnation) {
  return { type: actionTypes.GET_STATES_SUCCESS, payload: STATESnation };
}

export function getStatesByIdFailure() {
  return { type: actionTypes.GET_STATES_FAILURE };
}

//Update STATESnation by ID:
export function updateStates() {
  return { type: actionTypes.UPDATE_STATES_REQUEST };
}

export function updateStatesSuccess(data) {
  return {
    type: actionTypes.UPDATE_STATES_SUCCESS,
    payload: data,
  };
}

export function updateStatesFailure() {
  return { type: actionTypes.UPDATE_STATES_FAILURE };
}

//create new STATESnation:
export function createStates() {
  return { type: actionTypes.CREATE_STATES_REQUEST };
}

export function createStatesSuccess(data) {
  return {
    type: actionTypes.CREATE_STATES_SUCCESS,
    payload: data,
  };
}

export function createStatesFailure() {
  return { type: actionTypes.CREATE_STATES_FAILURE };
}

//Get States by a country ID
export function countrystates() {
  return { type: actionTypes.GET_COUNTRYSTATES_REQUEST };
}

export function countryStatesSuccess(states) {
  return { type: actionTypes.GET_COUNTRYSTATES_SUCCESS, payload: states.data };
}

export function countryStatesFailure() {
  return { type: actionTypes.GET_COUNTRYSTATES_FAILURE };
}

// export function logOut() {
//   return { type: actionTypes.LOG_OUT };
// }
