import React, { useEffect, useState } from 'react';
import './manageshop.scss';
import ShopRightbar from '../../../components_shops/shoprightbar/ShopRightbar';
import {
  CalendarToday,
  LocationSearching,
  MailOutline,
  PermIdentity,
  PhoneAndroid,
  Publish,
} from '@material-ui/icons';
import { Link, useParams, Redirect, useHistory } from 'react-router-dom';
import Chart from '../../../components_shops/chart/Chart';
import { productData } from '../../../dummyData';
import ShopForm from '../ShopForm';
import {
  adminDeliverOrders,
  adminGetOrderById,
  adminPackOrders,
  adminShipOrders,
} from '../../../store/api/apiRoutes';
import WidgetLg from '../../../components_shops/orderWidgetLg/WidgetLg';
import WidgetSm from '../../../components_shops/widgetSm/WidgetSm';
import ModuleOrderShippingInformation from '../../../components_shops/partials/orders/ModuleOrderShippingInformation';
import ModuleOrderBillingInformation from '../../../components_shops/partials/orders/ModuleOrderBillingInformation';
import {
  RollbackOutlined,
  SyncOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';

const UpdateShop = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState({});

  useEffect(() => {
    // const data = dispatch(getShops());

    fetchOrder();
    // setPagePosts(data);
  }, [id]);

  const fetchOrder = async () => {
    setLoading(true);

    const { data } = await adminGetOrderById(id);
    if (data) {
      setOrder(data);
      // Cookies.remove('cart');
      // setCookie('cart', [], { path: '/' });
      // dispatch(setCartItemsSuccess([]));
      setTimeout(
        function () {
          setLoading(false);
        }.bind(this),
        250
      );
    }
    console.log('adminSingleOrder', data);

    console.log('adminSingleOrder2Check', order);

    // SetLoading(false);
  };

  const handlePack = async (e) => {
    e.preventDefault();
    if (window.confirm('Pack Order?')) {
      try {
        setLoading(true);

        const { data } = await adminPackOrders(order._id);
        // return;
        if (data) {
          fetchOrder();
          // console.log('sucessData2', data);
          setTimeout(
            function () {
              setLoading(false);
            }.bind(this),
            250
          );
          // Router.push(`/account/payment-success/${placedOrder._id}`);
          // Router.push(`/account/payment-success/${data._id}`);
        }
      } catch (error) {
        setLoading(false);
        console.log({ error: JSON.stringify(error) });
      }
    }
  };

  const handleShip = async (e) => {
    e.preventDefault();
    console.log('Your order is shipped');

    if (window.confirm('Ship Order?')) {
      try {
        setLoading(true);

        // console.log('onSucccessID', placedOrder._id);
        console.log('orderIDToShip', order._id);

        const { data } = await adminShipOrders(order._id);
        console.log('shipSuccessData', data);
        // return;
        if (data) {
          fetchOrder();
          // console.log('sucessData2', data);
          setTimeout(
            function () {
              setLoading(false);
            }.bind(this),
            250
          );
          // Router.push(`/account/payment-success/${placedOrder._id}`);
          // Router.push(`/account/payment-success/${data._id}`);
        }
      } catch (error) {
        setLoading(false);
        console.log({ error: JSON.stringify(error) });
      }
    }
  };

  const handleDelivery = async (e) => {
    e.preventDefault();

    if (window.confirm('Deliver Order?')) {
      try {
        setLoading(true);

        const { data } = await adminDeliverOrders(order._id);
        // console.log('shipSuccessData', data);
        // return;
        if (data) {
          fetchOrder();
          // console.log('sucessData2', data);
          setTimeout(
            function () {
              setLoading(false);
            }.bind(this),
            250
          );

          <Redirect to="/admin/listorders" />;

          // Router.push(`/account/payment-success/${placedOrder._id}`);
          // Router.push(`/account/payment-success/${data._id}`);
        }
      } catch (error) {
        setLoading(false);
        console.log({ error: JSON.stringify(error) });
      }
    }
  };

  return (
    <>
      {/* Changing pages rendering starts here */}

      <>
        <div className="shopRight">
          <div className="shopBottom">
            <div className="product">
              <div className="productTop">
                <div className="productTopLeft">
                  <Chart
                    data={productData}
                    dataKey="Sales"
                    title="Sales Performance"
                  />
                </div>
                <div className="productTopRight">
                  <div className="productInfoTop">
                    <img
                      src="https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
                      alt=""
                      className="productInfoImg"
                    />
                    <span className="productName">
                      Order: {order.refOrderId}
                    </span>
                  </div>
                  <div
                    className="productInfoBottom"
                    // style={{ maxHeight: '200px' }}
                  >
                    <div className="productInfoItem">
                      <span className="productInfoKey">order_id: </span>
                      <span className="productInfoValue">{order._id}</span>
                    </div>
                    {/* <div className="productInfoItem">
                      <span className="productInfoKey">ref_id: </span>
                      <span className="productInfoValue">
                        {order.refOrderId}
                      </span>
                    </div> */}
                    <div className="productInfoItem">
                      <span className="productInfoKey">cost:</span>
                      <span className="productInfoValue">
                        N{order.itemsPrice}.00
                      </span>
                    </div>
                    <div className="productInfoItem">
                      <span className="productInfoKey">tax:</span>
                      <span className="productInfoValue">
                        N {order.taxPrice}.00
                      </span>
                    </div>
                    <div className="productInfoItem">
                      <span className="productInfoKey">shipping:</span>
                      <span className="productInfoValue">
                        N{order.shippingPrice}.00
                      </span>
                      {/* <p>{JSON.stringify(order, 0, null)}</p> */}
                    </div>
                    <span className="productInfoKey">=============</span>
                    <div className="productInfoItem">
                      <span className="productInfoKey">Total:</span>
                      <span className="productInfoValue">
                        N{order.totalPrice}.00
                      </span>
                      {/* <p>{JSON.stringify(order, 0, null)}</p> */}
                    </div>
                    <span className="productInfoKey">=============</span>
                    <div className="productInfoItem">
                      <span className="productInfoKey">PaymentStatus:</span>
                      <span className="productInfoValue">
                        {order.isPaid ? 'Paid' : 'Unpaid'}
                      </span>
                      {/* <p>{JSON.stringify(order, 0, null)}</p> */}
                    </div>
                    {order.isPacked && order.isShipped && (
                      <>
                        {order.isDelivered ? (
                          <figure
                            className="active"
                            style={{ backgroundColor: '#a7eaa7' }}
                          >
                            <figcaption>Delivery</figcaption>
                            <p>
                              {order.deliveredAt} <small>4:00pm</small>
                            </p>
                          </figure>
                        ) : (
                          <button
                            onClick={handleDelivery}
                            className="shopErectionUpdateButton"
                          >
                            Deliver Order
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <br />
              <div className="productBottom">
                {/* <ShopForm /> */}
                {/* my additions right here starts */}
                {/* <div className="homeWidgets"> */}
                {/* <WidgetSm /> */}

                <section className="ps-dashboard">
                  <div className="ps-section__left">
                    <div className="row">
                      <div className="col-md-6">
                        <ModuleOrderShippingInformation order={order} />
                      </div>
                      <div className="col-md-6">
                        <ModuleOrderBillingInformation order={order} />
                      </div>
                      {/* <div className="col-md-4">
                        <ModuleOrderShippingInformation order={order} />
                      </div> */}
                    </div>
                    <div className="ps-card ps-card--track-order">
                      <div className="ps-card__header">
                        <h4>#ABD-235711</h4>
                      </div>
                      <div className="ps-card__content">
                        <div className="table-responsive">
                          <table className="table ps-table">
                            <thead>
                              <tr>
                                <th>Product</th>
                                <th>Quantity</th>
                                <th>Price</th>
                                <th>Total</th>
                              </tr>
                            </thead>

                            <tbody>
                              {order.orderItems?.map((item) => (
                                <>
                                  <tr key={item._id}>
                                    <td>
                                      <img
                                        src={item.image}
                                        alt=""
                                        className="widgetLgImg"
                                      />
                                      <a href="#">{item.name}</a>
                                    </td>
                                    <td>x{item.quantity}</td>
                                    <td>N{item.price}.00</td>
                                    <td>N{item.quantity * item.price}.00</td>
                                  </tr>
                                </>
                              ))}

                              <tr>
                                <td colSpan="3">
                                  <strong>Sub Total:</strong>
                                </td>
                                <td>
                                  <strong>N{order.itemsPrice}.00</strong>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="3">
                                  <strong>Shipping Charge:</strong>
                                </td>
                                <td>
                                  <strong>N{order.shippingPrice}.00</strong>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="3">
                                  <strong>Estimated (V.A.T) 7.5%:</strong>
                                </td>
                                <td>
                                  <strong>N{order.taxPrice}.00 </strong>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="3">
                                  <strong>Total:</strong>
                                </td>
                                <td>
                                  <strong>
                                    {/* $211.00 */}N{order.totalPrice}.00
                                  </strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ps-section__right">
                    <div className="ps-card ps-card--track-order">
                      <div className="ps-card__header">
                        <h4>Track Order</h4>
                      </div>
                      <div className="ps-card__content">
                        <div className="ps-block--track-order">
                          <div className="ps-block__header">
                            <div className="row">
                              <div className="col-6">
                                <figure>
                                  <figcaption>Order ID:</figcaption>
                                  <p>{order._id}</p>
                                </figure>
                              </div>
                              <div className="col-6">
                                <figure>
                                  <figcaption>Tracking ID:</figcaption>
                                  <p>{order.refOrderId}</p>
                                </figure>
                              </div>
                            </div>
                          </div>
                          <div className="ps-block__content">
                            <div className="ps-block__timeline">
                              <figure className="active">
                                <figcaption>Order Placed</figcaption>
                                <p>
                                  {order.createdAt} <small>7:00am</small>
                                </p>
                              </figure>
                              {order.isPaid ? (
                                <>
                                  {order.isPacked ? (
                                    <figure className="active">
                                      <figcaption>Packed</figcaption>
                                      <p>
                                        {order.packedAt} <small>10:00am</small>
                                      </p>
                                    </figure>
                                  ) : (
                                    <figure className="inactive">
                                      {/* <figcaption>Packed</figcaption> */}
                                      <button
                                        className="shopErectionUpdateButton"
                                        // className="marginTeft: 10px"
                                        onClick={handlePack}
                                      >
                                        Confirm Order As Packed
                                      </button>
                                    </figure>
                                  )}
                                </>
                              ) : (
                                <>
                                  <figure className="inactive">
                                    <figcaption>Packed</figcaption>
                                    <p>
                                      Order Has not been paid for so cannot be
                                      packed yet
                                    </p>
                                    {/* <figcaption>
                                      
                                    </figcaption> */}
                                  </figure>
                                </>
                              )}
                              {order.isPaid ? (
                                <>
                                  {order.isShipped ? (
                                    <figure className="active">
                                      <figcaption>Shipped</figcaption>
                                      <p>
                                        {order.shippedAt} <small>4:00pm</small>
                                      </p>
                                    </figure>
                                  ) : (
                                    <>
                                      {order.isPacked && (
                                        <figure className="inactive">
                                          {/* <figcaption>Packed</figcaption> */}
                                          <button
                                            // className="marginTeft: 10px"
                                            onClick={handleShip}
                                            className="shopErectionUpdateButton"
                                          >
                                            Confirm Order As Shipped
                                          </button>
                                        </figure>
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  {' '}
                                  <figure className="inactive">
                                    <figcaption>Shipped</figcaption>
                                    <p>
                                      Order Has not been paid for so cannot be
                                      shipped yet
                                    </p>
                                    {/* <figcaption>
                                
                              </figcaption> */}
                                  </figure>
                                </>
                              )}

                              {order.isPaid ? (
                                <>
                                  {order.isDelivered ? (
                                    <figure className="active">
                                      <figcaption>Delivery</figcaption>
                                      <p>
                                        {order.deliveredAt}{' '}
                                        <small>4:00pm</small>
                                      </p>
                                    </figure>
                                  ) : (
                                    <figure>
                                      <figcaption>
                                        Pending Delivery...
                                      </figcaption>
                                      <p>
                                        Estimated delivery within 7 days after
                                        shipping
                                      </p>
                                    </figure>
                                  )}
                                </>
                              ) : (
                                <>
                                  <figure className="inactive">
                                    <figcaption>Delivery</figcaption>
                                    <p>
                                      Order Has not been paid for so cannot be
                                      delivered yet
                                    </p>
                                  </figure>
                                </>
                              )}
                            </div>
                            <Link to="/admin/listorders/">
                              <RollbackOutlined className="userListEditIcon" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                {/* <WidgetLg order={order} /> */}
                {/* </div> */}
                {/* my additions right here ends */}
              </div>
            </div>
          </div>
        </div>

        <>
          <ShopRightbar Shop />
        </>
      </>

      {/* Changing pages rendering ends here */}
    </>
  );
};

export default UpdateShop;
