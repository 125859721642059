import { DeleteOutline, Publish } from '@material-ui/icons';
import {
  Paper,
  Typography,
  CircularProgress,
  Divider,
} from '@material-ui/core/';
import React, { useEffect, useState } from 'react';
import useForm from '../../hooks/useForm';
import { AuthApi } from '../../apiconstant/Api';
import { useParams, useHistory, Redirect, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  createDepart,
  getDepartWithId,
  updateDepart,
} from '../../store/departments/repository_dept';
import { getDeptById, getTradehubById } from '../../store/api/apiRoutes';
import { getDepartments } from '../../store/departments/repository_dept';
import {
  createTradehub,
  getHubs,
  updateTradehub,
} from '../../store/tradehubs/repository_tradehub';

// const initialFValues = {
//     id:0,
//     product:{},
//   }

const initialFValues = {
  id: 0,
  hubname: '',
};

const DepartmentForm = () => {
  const { values, setValues, handleInputChange, resetForm } =
    useForm(initialFValues);
  // const { id } = useParams();
  const params = useParams();
  const { id } = params;

  // const { department, iFetching } = useSelector((state) => state.depts);
  // const hub = useSelector((state) =>
  //   id ? state.hubs.hubs.find((hb) => hb._id === id) : null
  // );
  // const post = useSelector((state) => (currentId ? state.posts.posts.find((message) => message._id === currentId) : null));
  console.log('StateHub:', id);

  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getHubs());
    if (id) {
      const getHubData = async () => {
        setLoading(true);
        try {
          if (id) {
            await getTradehubById(id).then((response) => {
              // setDepartment(response.data);
              setValues(response.data);
            });
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      };
      getHubData();
    }
  }, [dispatch, id, setValues]);

  const handleHubSubmit = async (e) => {
    e.preventDefault();

    // console.log('storage values are :', values);
    try {
      dispatch(createTradehub(values));
      // alert('Hubt Added');
      resetForm();
    } catch (error) {
      console.log({ message: error.message });
    }

    resetForm();
  };

  const handleHubUpdate = async (e) => {
    e.preventDefault();

    console.log('storage values are :', values);
    try {
      dispatch(updateTradehub(id, values));
      // alert('Department Updated');
    } catch (error) {
      console.log({ message: error.message });
    }

    // resetForm();
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    alert('Deleted');
  };

  return (
    <>
      <div className="shopErectionUpdate">
        {/* <span className="shopErectionUpdateTitle">Add a Tradehub</span>
        <form
          className="shopErectionUpdateForm"
          onSubmit={id ? handleHubUpdate : handleHubSubmit}
        >
          <div className="shopErectionUpdateLeft">
            <div className="productFormLeft">
       
              <label>Tradehub Name</label>
              <input
                type="text"
                placeholder="Department xxx"
                name="hubname"
                value={values.hubname}
                onChange={handleInputChange}
                className="shopErectionUpdateInput"
              />
              
            </div>

            <button
              
              className="shopErectionUpdateButton"
            >
              Add/Update Tradehub
            </button>
          </div>
        </form> */}

        <span className="shopErectionUpdateTitle">Create/Edit a Tradehub</span>
        {id && (
          <span className="right">
            Delete
            <DeleteOutline
              className="userListDelete right"
              onClick={(e) => handleDelete(e)}
            />
          </span>
        )}

        <hr />
        <form
          onSubmit={id ? handleHubUpdate : handleHubSubmit}
          className="ps-form--account-setting"
        >
          <div className="ps-form__content">
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Tradehub Here</label>
                  <input
                    className="form-control"
                    type="text"
                    // placeholder="Tradehub Here"
                    name="hubname"
                    value={values.hubname}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="form-group submit">
            <Link to={'/tradehubs'} className="btn">
              Cancel
            </Link>
            <button className="ps-btn">Add/Update Tradehub</button>
          </div>
        </form>
      </div>
    </>
  );
};

export default DepartmentForm;
