import { Publish } from '@material-ui/icons';
import React from 'react';
import useForm from '../../hooks/useForm';
import { AuthApi } from '../../apiconstant/Api';

// const initialFValues = {
//     id:0,
//     product:{},
//   }

const initialFValues = {
  id: 0,
  prod_category_id: '',
  name: '',
  price: '',
  unitweight_id: '',
  status: '',
  quantity: '',
  description: '',
  // prod_image:'',
  user_id: '',
  // shop_id:'',
  hub_id: '',
};

const ProductForm = () => {
  const { values, setValues, handleInputChange, resetForm } =
    useForm(initialFValues);

  const handleProductSubmit = async (e) => {
    e.preventDefault();

    console.log('storage values are :', values);
    try {
      await AuthApi()
        .post(`/store/shopproducts`, values)
        .then((response) => {
          if (response.data.error) {
            console.log(response.data.error);
          } else {
            alert('product added');
            // props.history.push('/manage/posts')
            // resetForm();
          }
        });
    } catch (error) {
      console.log(error);
    }

    // resetForm();
  };

  return (
    //     <form className="productForm">
    //         {/* hub */}
    //         <div className="productFormLeft">
    //         <div className="userUpdateItem">
    //         <label>Hub Name</label>
    //         <select name="inStock" id="idStock">
    //         <option value="yes">Select a corresponding hub to post product for sale</option>
    //             <option value="yes">Yes</option>
    //             <option value="no">No</option>
    //         </select>
    //         </div>
    //         <label>Hub Name</label>
    //         <input type="text" placeholder="Apple AirPod" />

    //         <label>Active</label>
    //         <select name="active" id="active">
    //             <option value="yes">Yes</option>
    //             <option value="no">No</option>
    //         </select>
    //     </div>

    //     <div className="productFormLeft">
    //     {/* <label>Category </label>
    //         <select name="inStock" id="idStock">
    //         <option value="yes">Select a corresponding category to post product for sale</option>
    //             <option value="yes">Yes</option>
    //             <option value="no">No</option>
    //         </select> */}
    //          <div className="shopErectionUpdateItem">
    //           <label>Category </label>
    //         <select name="inStock" id="idStock" style={{"width" : "100px"}}>
    //         <option value="yes">Select a corresponding category to post product for sale</option>
    //             <option value="yes">Yes</option>
    //             <option value="no">No</option>
    //         </select>
    //           </div>

    //         <label>Product Name</label>
    //         <input type="text" placeholder="Apple AirPod" />

    //         <label>Active</label>
    //         <select name="active" id="active">
    //             <option value="yes">Yes</option>
    //             <option value="no">No</option>
    //         </select>
    //     </div>
    //     {/* test start */}
    //     <div className="productFormLeft">
    //         <label>Product Name</label>
    //         <input type="text" placeholder="Apple AirPod" />
    //         <label>In Stock</label>
    //         <select name="inStock" id="idStock">
    //             <option value="yes">Yes</option>
    //             <option value="no">No</option>
    //         </select>
    //         <label>Active</label>
    //         <select name="active" id="active">
    //             <option value="yes">Yes</option>
    //             <option value="no">No</option>
    //         </select>
    //     </div>
    //     {/* test ends */}
    //     <div className="productFormRight">
    //         <div className="productUpload">
    //             <img src="https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500" alt="" className="productUploadImg" />
    //             <label for="file">
    //                 <Publish/>
    //             </label>
    //             <input type="file" id="file" style={{display:"none"}} />
    //         </div>
    //         <button className="productButton">Update</button>
    //     </div>
    // </form>
    <div className="shopErectionUpdate">
      <span className="shopErectionUpdateTitle">Add a Product</span>
      <form className="shopErectionUpdateForm">
        <div className="shopErectionUpdateLeft">
          <div className="productFormLeft">
            <label>Hub</label>
            <select
              style={{ maxWidth: '250px' }}
              className=""
              name="hub_id"
              value={values.hub_id}
              onChange={handleInputChange}
              id="hub"
            >
              <option value="">Select an option</option>
              <option value="1">Commodity</option>
              <option value="2">Agro</option>
            </select>
            <label>Category</label>
            <select
              style={{ maxWidth: '250px' }}
              className=""
              name="prod_category_id"
              value={values.prod_category_id}
              onChange={handleInputChange}
              id="category"
            >
              <option value="">Select an option</option>
              <option>Select an option</option>
              <option value="1">Grains</option>
              <option value="2">Fruits</option>
              {/* <option value="Nigeria">Computer and Electronics</option>
                <option value="Ghana">Phones</option> */}
            </select>
            {/* <label>Market</label>
            <select className="" name="market" id="market">
            <option value="">Select an option</option>
                <option value="Nigeria">Wuse </option>
                <option value="Ghana">Kado</option>
            </select> */}
            <label>Product Name</label>
            <input
              type="text"
              placeholder="Product xxx"
              name="name"
              value={values.name}
              onChange={handleInputChange}
              className="shopErectionUpdateInput"
            />
          </div>
          <div className="shopErectionUpdateItem">
            <label>Our Price</label>
            <input
              type="number"
              placeholder=""
              name="price"
              value={values.price}
              onChange={handleInputChange}
              className="shopErectionUpdateInput"
            />
          </div>
          <div className="productFormLeft">
            <label>Quantity</label>
            <input
              type="number"
              placeholder=""
              name="quantity"
              value={values.quantity}
              onChange={handleInputChange}
              className="shopErectionUpdateInput"
            />
            <label>Quanity Unit</label>
            <select
              style={{ maxWidth: '250px' }}
              className=""
              name="unitweight_id"
              value={values.unitweight_id}
              onChange={handleInputChange}
              id="unit_weight"
            >
              <option value="">Select an option</option>
              <option value="1">Kilograms</option>
              <option value="2">grmas</option>
              <option value="3">tonnes</option>
            </select>

            <select
              style={{ maxWidth: '250px' }}
              className=""
              name="status"
              value={values.status}
              onChange={handleInputChange}
              id="status"
            >
              <option>Select an option</option>
              <option value="1">True</option>
              <option value="2">False</option>
            </select>
          </div>
          {/* <div className="shopErectionUpdateItem">
                <label>Address</label>
                <input
                  type="text"
                  placeholder="New York | USA"
                  className="shopErectionUpdateInput"
                />
              </div> */}
          {/* <div className="shopErectionUpdateItem">
                <label>Full Name</label>
                <input
                  type="text"
                  placeholder="Anna Becker"
                  className="shopErectionUpdateInput"
                />
              </div> */}

          <div className="shopErectionUpdateItem">
            {/* <div className="col-md-12"> */}
            <label>Descripttion</label>
            <textarea
              style={{ maxWidth: '400px' }}
              id="summary-ckeditor"
              name="description"
              value={values.description}
              onChange={handleInputChange}
              className="form-control col-md-12"
              placeholder="Description"
            ></textarea>

            {/* </div> */}
          </div>
        </div>

        <div className="shopErectionUpdateRight">
          <div className="shopErectionUpdateUpload">
            <p style={{ marginLeft: '20px' }}>
              {' '}
              picture showing shop logo/barner{' '}
            </p>
            <img
              className="shopErectionUpdateImg"
              src="https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
              alt=""
            />
            <label htmlFor="file">
              <Publish className="shopErectionUpdateIcon" />
            </label>

            <input type="file" id="file" style={{ display: 'none' }} />
          </div>
          {/* <div className="shopErectionUpdateUpload">
            <p style={{ marginLeft: "20px" }} >  video showing shop products</p>
                <img
                  className="shopErectionUpdateImg"
                  src="https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
                  alt=""
                />
                <label htmlFor="file">
                  <Publish className="shopErectionUpdateIcon" />
                </label>
                
                <input type="file" id="file" style={{ display: "none" }} />
              </div> */}
          <button
            // onClick={handleProductSubmit}
            className="shopErectionUpdateButton"
          >
            Add Product
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProductForm;
