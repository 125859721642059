import * as api from '../api/apiRoutes';
import {
  states,
  statesSuccess,
  statesFailure,
  getStatesById,
  getStatesByIdSuccess,
  getStatesByIdFailure,
  updateStates,
  updateStatesSuccess,
  updateStatesFailure,
  createStates,
  createStatesSuccess,
  createStatesFailure,
} from './actionStates';
const Cookies = require('js-cookie');

export const getStates = () => async (dispatch) => {
  try {
    dispatch(states());
    const { data } = await api.getBStates();
    // console.log('actionData :', data);

    // dispatch({
    //   type: FETCH_ALL,
    //   payload: { data, currentPage, numberOfPages },
    // });
    dispatch(statesSuccess(data));
  } catch (error) {
    console.log(error);
    dispatch(statesFailure({ error: error.message }));
  }
};

//get single Desination
export const getStateWithId = (id) => async (dispatch) => {
  dispatch(getStatesById());
  // console.log('Acton start');
  try {
    const { data } = await api.getStateById(id);
    // console.log('MydesigByID :', data);

    dispatch(getStatesByIdSuccess(data));
  } catch (error) {
    console.log(error);
    dispatch(getStatesByIdFailure({ error: error.message }));
  }
};

//update a Desination
export const updateStatesRepo = (id, values) => async (dispatch) => {
  // console.log('repoValues', values);
  dispatch(updateStates());
  try {
    const { data } = await api.updateStateById(id, values);
    // console.log('actionData :', data);
    alert('States updated');
    dispatch(updateStatesSuccess(data));
  } catch (error) {
    console.log(error);
    dispatch(updateStatesFailure({ message: error.message }));
  }
};

//Create a Desination
export const createState = (values) => async (dispatch) => {
  console.log('newStatesData :', values);
  try {
    dispatch(createStates());
    const { data } = await api.createBState(values);
    // console.log('actionData :', data);
    dispatch(createStatesSuccess(data));
    // console.log('newDesination:', data);
    alert('States added');
  } catch (error) {
    console.log(error);
    dispatch(createStatesFailure({ error: error.message }));
  }
};
