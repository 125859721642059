import React from 'react';
import { Route, Redirect } from 'react-router';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  //   const { user, isFetching } = useContext(AuthContext);
  const { isFetching, user } = useSelector((state) => state.auth);
  // console.log('protectedRoute', user, isFetching);
  // console.log('protected user', user);
  return (
    <>
      {isFetching === false && (
        <Route
          {...rest}
          render={(props) => {
            if (user == null || undefined) {
              return <Redirect to="/login" />;
            } else {
              return <Component {...props} />;
            }
          }}
        />
      )}
    </>
  );
};

export default ProtectedRoute;
