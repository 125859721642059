import React from 'react';

const StaffPersonalInformation = ({ admin }) => {
  return (
    <>
      <div className="ps-card ps-card--order-information ps-card--small">
        <div className="ps-card__header">
          <h4>Staff Information</h4>
        </div>
        <div className="ps-card__content">
          <div className="productInfoTop">
            <img src={admin?.avatar} alt="" className="productInfoImg" />
            <h5>{admin?.name}</h5>
          </div>
          {/* <h5>Name: {admin?.name}</h5> */}
          <p>
            <strong>Address:</strong>
            {admin?.address}
          </p>
          <p>
            <strong>Phone No: </strong> {admin?.phone}
          </p>
          <p>
            <strong>Email:</strong> {admin?.email}
          </p>
        </div>
      </div>
    </>
  );
};

export default StaffPersonalInformation;
