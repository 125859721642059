export const actionTypes = {
  POST_REQUEST: 'POST_REQUEST',
  POST_SUCCESS: 'POST_SUCCESS',
  POST_FAILURE: 'POST_FAILURE',

  GET_POST_REQUEST: 'GET_POST_REQUEST',
  GET_POST_SUCCESS: 'GET_POST_SUCCESS',
  GET_POST_FAILURE: 'GET_POST_FAILURE',

  UPDATE_POST_REQUEST: 'UPDATE_POST_REQUEST',
  UPDATE_POST_SUCCESS: 'UPDATE_POST_SUCCESS',
  UPDATE_POST_FAILURE: 'UPDATE_POST_FAILURE',

  CREATE_POST_REQUEST: 'CREATE_POST_REQUEST',
  CREATE_POST_SUCCESS: 'CREATE_POST_SUCCESS',
  CREATE_POST_FAILURE: 'CREATE_POST_FAILURE',

  //whenloading
  END_LOADING: 'END_LOADING',
  START_LOADING: 'START_LOADING',
};

export function post() {
  return { type: actionTypes.POST_REQUEST };
}

export function postSuccess(posts) {
  return { type: actionTypes.POST_SUCCESS, payload: posts.data };
}

export function postFailure() {
  return { type: actionTypes.POST_FAILURE };
}

//Get POST by ID:
export function getPostById() {
  return { type: actionTypes.GET_POST_REQUEST };
}

export function getPostByIdSuccess(post) {
  return { type: actionTypes.GET_POST_SUCCESS, payload: post };
}

export function getPostByIdFailure() {
  return { type: actionTypes.GET_POST_FAILURE };
}

//Update POST by ID:
export function updatePost() {
  return { type: actionTypes.UPDATE_POST_REQUEST };
}

export function updatePostSuccess(data) {
  return {
    type: actionTypes.UPDATE_POST_SUCCESS,
    payload: data,
  };
}

export function updatePostFailure() {
  return { type: actionTypes.UPDATE_POST_FAILURE };
}

//create new POST:
export function createPost() {
  return { type: actionTypes.CREATE_POST_REQUEST };
}

export function createPostSuccess(data) {
  return {
    type: actionTypes.CREATE_POST_SUCCESS,
    payload: data,
  };
}

export function createPostFailure() {
  return { type: actionTypes.CREATE_POST_FAILURE };
}
