import React from 'react'
import "./login.scss";
import Topbar from "../../components/topbar/Topbar"
import SocialSidebar from "../../components/socialsidebar/SocialSidebar"
import SignIn from "../../components/signin/SignIn";
import SocialRightbar from "../../components/socialrightbar/SocialRightbar"
const Login = () => {
    return (
        <>
        {/* <Topbar/>
        <div className="guest">
            <SocialSidebar/> */}

            <div className="guestRight">
                <div className="guestRightTop">
                    <div className="guestCover">
                    <img src="assets/barner.jpg" alt="" className="guestCoverImg" />
                    <img src="assets/mylogo_500.png" alt="" className="guestImg" />
                    </div>
                    <div className="guestInfo">
                        <h4 className="guestInfoName">Sign In</h4>
                        {/* <span className="guestInfoDesc">Hello guys, Welcome to my guest</span> */}
                    </div>
                    <hr className="guestDivider" />
                </div>

                <div className="guestRightBottom">
                {/* <Feed/> */}
                {/* begininning of signin component */}
                    <SignIn />
                {/*ending of signin component */}
                <SocialRightbar guest/>
                </div>
            </div>
        {/* </div> */}
    </> 
    )
}
export default Login
