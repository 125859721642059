import { DeleteOutline, Publish } from '@material-ui/icons';
import React, { useEffect } from 'react';
import useForm from '../../hooks/useForm';
import { AuthApi } from '../../apiconstant/Api';
import { useParams, useHistory, Redirect, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  createDepart,
  getDepartWithId,
  updateDepart,
} from '../../store/departments/repository_dept';
import { getDeptById } from '../../store/api/apiRoutes';
import { getDepartments } from '../../store/departments/repository_dept';

// const initialFValues = {
//     id:0,
//     product:{},
//   }

const initialFValues = {
  id: 0,
  name: '',
};

const ShopForm = () => {
  const { values, setValues, handleInputChange, resetForm } =
    useForm(initialFValues);
  const { id } = useParams();

  // const { department, iFetching } = useSelector((state) => state.depts);
  const department = useSelector((state) =>
    id ? state.depts.departments.find((dpt) => dpt._id === id) : null
  );
  // const post = useSelector((state) => (currentId ? state.posts.posts.find((message) => message._id === currentId) : null));
  console.log('StateDept:', department);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getDepartments());
    if (department) setValues(department);
  }, []);

  const handleShopSubmit = async (e) => {
    e.preventDefault();

    // console.log('storage values are :', values);
    // try {
    //   dispatch(createDepart(values));
    //   // alert('Shop Added');
    //   resetForm();
    // } catch (error) {
    //   console.log({ message: error.message });
    // }

    // resetForm();
  };

  const handleShopUpdate = async (e) => {
    e.preventDefault();

    // console.log('storage values are :', values);
    // try {
    //   dispatch(updateDepart(id, values));
    //   // alert('Shop Updated');
    // } catch (error) {
    //   console.log({ message: error.message });
    // }

    // resetForm();
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    alert('Deleted');
  };

  return (
    <div className="shopErectionUpdate">
      {/* <span className="shopErectionUpdateTitle">Add a Shop </span>
      <form
        className="shopErectionUpdateForm"
        onSubmit={id ? handleDepartmentUpdate : handleDepartmentSubmit}
      >
        <div className="shopErectionUpdateLeft">
          <div className="productFormLeft">
            <label>Country</label>
            <select
              style={{ maxWidth: '250px' }}
              className=""
              name="hub_id"
              value={values.hub_id}
              onChange={handleInputChange}
              id="hub"
            >
              <option value="">Select an option</option>
              <option value="1">Nigeria</option>
              <option value="2">Ghana</option>
            </select>

            <label>State</label>
            <select
              style={{ maxWidth: '250px' }}
              className=""
              name="prod_category_id"
              value={values.prod_category_id}
              onChange={handleInputChange}
              id="category"
            >
              <option value="">Select an option</option>
              <option>Select an option</option>
              <option value="1">State 1</option>
              <option value="2">state 2</option>
           
            </select>

            <label>Market Category</label>
            <select
              style={{ maxWidth: '250px' }}
              className=""
              name="prod_category_id"
              value={values.prod_category_id}
              onChange={handleInputChange}
              id="category"
            >
              <option value="">Select an option</option>
              <option>Select an option</option>
              <option value="1">category 1</option>
              <option value="2">category 2</option>
           
            </select>

            <label>Market Name</label>
            <input
              type="text"
              placeholder="Product xxx"
              name="name"
              value={values.name}
              onChange={handleInputChange}
              className="shopErectionUpdateInput"
            />

            <label>Locality Address</label>
            <input
              type="text"
              placeholder="Product xxx"
              name="name"
              value={values.name}
              onChange={handleInputChange}
              className="shopErectionUpdateInput"
            />
          </div>

          
        </div>

        <div className="shopErectionUpdateRight">
        
          <button onClick={''} className="shopErectionUpdateButton">
            Add/Update Shop
          </button>
        </div>
      </form> */}

      <span className="shopErectionUpdateTitle">Create/Edit a Shop</span>
      {id && (
        <span className="right">
          Delete
          <DeleteOutline
            className="userListDelete right"
            onClick={(e) => handleDelete(e)}
          />
        </span>
      )}

      <hr />
      <form
        onSubmit={id ? handleShopUpdate : handleShopSubmit}
        className="ps-form--account-setting"
      >
        <div className="ps-form__content">
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <label>Country</label>
                <select
                  className="form-control"
                  name="hub_id"
                  value={values.hub_id}
                  onChange={handleInputChange}
                  id="hub"
                >
                  <option value="">Select an option</option>
                  <option value="1">Nigeria</option>
                  <option value="2">Ghana</option>
                </select>
              </div>
            </div>

            <div className="col-sm-12">
              <div className="form-group">
                <label>State</label>
                <select
                  className="form-control"
                  name="prod_category_id"
                  value={values.prod_category_id}
                  onChange={handleInputChange}
                  id="category"
                >
                  <option value="">Select an option</option>
                  <option>Select an option</option>
                  <option value="1">State 1</option>
                  <option value="2">state 2</option>
                </select>
              </div>
            </div>

            <div className="col-sm-12">
              <div className="form-group">
                <label>Shop Name</label>
                <input
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
            </div>

            {/* <div className="col-sm-12">
              <div className="form-group">
                <label>Ads Boost</label>
                <input
                  type="text"
                  name="adsbost"
                  value={values.adsbost}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
            </div> */}

            {/* <div className="col-sm-12">
              <div className="form-group">
                <label>Dashboard And Analytics</label>
                <input
                  type="text"
                  name="dashboardandanalytics"
                  value={values.dashboardandanalytics}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
            </div> */}

            {/* <div className="col-sm-12">
              <div className="form-group">
                <label>Number of Products</label>
                <input
                  type="text"
                  name="numberofproducts"
                  value={values.numberofproducts}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
            </div> */}

            <div className="col-sm-12">
              <div className="form-group">
                <label>Locality Address</label>
                <input
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="form-group submit">
          <Link to={'/listshops'} className="btn">
            Cancel
          </Link>
          <button className="ps-btn">Add/Update Shop</button>
        </div>
      </form>
    </div>
  );
};

export default ShopForm;
