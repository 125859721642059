

import React from 'react'
import "./guestHome.scss"
import Topbar from "../../components/topbar/Topbar"
import SocialSidebar from "../../components/socialsidebar/SocialSidebar"
import Feed from "../../components/feed/Feed";
import SocialRightbar from "../../components/socialrightbar/SocialRightbar"
import { InfoOutlined, PlayArrow } from '@material-ui/icons';
import List from '../../components/list/List';
import ShoppingFeed from '../../components/shoppingfeed/ShoppingFeed';


const GuestHome = ({ type }) => {
    
    return (
        <>
            <>
            <div className="guestHomeRight">
            {type && (
        <div className="category">
          <span>{type === "movie" ? "Movies" : "Series"}</span>
          <select name="genre" id="genre">
            <option>Genre</option>
            <option value="adventure">Adventure</option>
            <option value="comedy">Comedy</option>
            <option value="crime">Crime</option>
            <option value="fantasy">Fantasy</option>
            <option value="historical">Historical</option>
            <option value="horror">Horror</option>
            <option value="romance">Romance</option>
            <option value="sci-fi">Sci-fi</option>
            <option value="thriller">Thriller</option>
            <option value="western">Western</option>
            <option value="animation">Animation</option>
            <option value="drama">Drama</option>
            <option value="documentary">Documentary</option>
          </select>
        </div>
      )}
                <div className="guestHomeRightTop">
                    <div className="guestHomeCover">
                    <img src="assets/post/3.jpeg" alt="" className="guestHomeCoverImg" />
                    {/* <img src="assets/person/7.jpeg" alt="" className="guestHomeUserImg" /> */}

                    {/* info check starts here */}
                    <div className="info">
                                    <img
                                    src="https://occ-0-1432-1433.1.nflxso.net/dnm/api/v6/LmEnxtiAuzezXBjYXPuDgfZ4zZQ/AAAABUZdeG1DrMstq-YKHZ-dA-cx2uQN_YbCYx7RABDk0y7F8ZK6nzgCz4bp5qJVgMizPbVpIvXrd4xMBQAuNe0xmuW2WjoeGMDn1cFO.webp?r=df1"
                                    alt=""
                                    />
                                    <span className="desc">
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae
                                    adipisci repellendus eum quasi illo, velit numquam, maxime tempora
                                    sint deleniti, aliquid qui? Facilis, adipisci! Ratione hic repudiandae
                                    temporibus eum earum?
                                    </span>
                                    <div className="buttons">
                                    <button className="play">
                                        <PlayArrow />
                                        <span>Play</span>
                                    </button>
                                    <button className="more">
                                        <InfoOutlined />
                                        <span>Info</span>
                                    </button>
                                    </div>                                                                              
                                </div>
                                {/* info check ends here */}



                    </div>

                            

                                {/* <List/> */}

                    
                </div>
               


                <div className="guestHomeRightBottom">
                {/* <ShoppingFeed/> */}
                <Feed/>
                {/* <List/> */}
                {/* <SocialRightbar guestHome/> */}
                </div>
            
            </div>
            </>
           
        {/* </div> */}

    </>
    )
}

export default GuestHome
