export const actionTypes = {
  USER_REQUEST: 'USER_REQUEST',
  USER_SUCCESS: 'USER_SUCCESS',
  USER_FAILURE: 'USER_FAILURE',

  GET_USER_REQUEST: 'GET_USER_REQUEST',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_FAILURE: 'GET_USER_FAILURE',

  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',

  CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_FAILURE: 'CREATE_USER_FAILURE',

  //whenloading
  END_LOADING: 'END_LOADING',
  START_LOADING: 'START_LOADING',
};

export function user() {
  return { type: actionTypes.USER_REQUEST };
}

export function userSuccess(data) {
  return { type: actionTypes.USER_SUCCESS, payload: data.filteredUsers };
}

export function userFailure() {
  return { type: actionTypes.USER_FAILURE };
}

//Get USER by ID:
export function getUserById() {
  return { type: actionTypes.GET_USER_REQUEST };
}

export function getUserByIdSuccess(user) {
  return { type: actionTypes.GET_USER_SUCCESS, payload: user };
}

export function getUserByIdFailure() {
  return { type: actionTypes.GET_USER_FAILURE };
}

//Update USER by ID:
export function updateUser() {
  return { type: actionTypes.UPDATE_USER_REQUEST };
}

export function updateUserSuccess(data) {
  return {
    type: actionTypes.UPDATE_USER_SUCCESS,
    payload: data,
  };
}

export function updateUserFailure() {
  return { type: actionTypes.UPDATE_USER_FAILURE };
}

//create new USER:
export function createUser() {
  return { type: actionTypes.CREATE_USER_REQUEST };
}

export function createUserSuccess(data) {
  return {
    type: actionTypes.CREATE_USER_SUCCESS,
    payload: data,
  };
}

export function createUserFailure() {
  return { type: actionTypes.CREATE_USER_FAILURE };
}
