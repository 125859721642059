import { actionTypes } from './actionCountry';
import Cookies from 'js-cookie';

export const initState = {
  countries: [],
  isFetching: false,
  error: false,
};

function reducer(state = initState, action) {
  switch (action.type) {
    case 'START_LOADING':
      return { ...state, isFetching: true };
    case 'END_LOADING':
      return { ...state, isFetching: false };
    //Get departments
    case actionTypes.COUNTRY_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.COUNTRY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        countries: action.payload,
        error: false,
      };
    case actionTypes.COUNTRY_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Get departmentsById:
    case actionTypes.GET_COUNTRY_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.GET_COUNTRY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        country: action.payload,
        error: false,
      };
    case actionTypes.GET_COUNTRY_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Update A departmentsById:
    case actionTypes.UPDATE_COUNTRY_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.UPDATE_COUNTRY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        countries: state.countries.map((country) =>
          country._id === action.payload._id ? action.payload : country
        ),
        error: false,
      };
    case actionTypes.UPDATE_COUNTRY_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Create A New departmentsById:
    case actionTypes.CREATE_COUNTRY_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.CREATE_COUNTRY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        countries: [...state.countries, action.payload],
        error: false,
      };
    case actionTypes.CREATE_COUNTRY_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };

    default:
      return { ...state };
  }
}
export default reducer;
