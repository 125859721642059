import { DeleteOutline, Publish } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import useForm from '../../hooks/useForm';
import { AuthApi } from '../../apiconstant/Api';
import { useParams, useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getDepartments } from '../../store/departments/repository_dept';
import {
  getDesignations,
  createDesigNation,
  updateDesigNations,
} from '../../store/designations/repository_desig';
import { getDesigById } from '../../store/api/apiRoutes';
// import {
//   createDepart,
//   getDepartWithId,
//   updateDepart,
// } from '../../store/departments/repository_dept';

// const initialFValues = {
//     id:0,
//     product:{},
//   }

const initialFValues = {
  id: 0,
  department: '',
  name: '',
};

const DesignationForm = () => {
  const { values, setValues, handleInputChange, resetForm } =
    useForm(initialFValues);
  const params = useParams();
  const { id } = params;

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { departments, isFetching } = useSelector((state) => state.depts);

  // const designation = useSelector((state) =>
  //   id ? state.desigs.designations.find((desig) => desig._id === id) : null
  // );

  useEffect(() => {
    dispatch(getDepartments());

    // dispatch(getDesignations());

    if (id) {
      const getDesignationData = async () => {
        setLoading(true);
        try {
          if (id) {
            await getDesigById(id).then((response) => {
              // setDepartment(response.data);
              setValues(response.data);
            });
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      };
      getDesignationData();
    }

    // setPagePosts(data);
  }, [dispatch, id, setValues]);
  // console.log('DesiDepts:', departments);

  const handleCreate = async (e) => {
    e.preventDefault();

    // console.log('storage values are :', values);
    try {
      dispatch(createDesigNation(values));
      // alert('Department Added');
      resetForm();
    } catch (error) {
      console.log({ message: error.message });
    }

    // resetForm();
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    // console.log('storage values are :', values);
    try {
      dispatch(updateDesigNations(id, values));
      // alert('Department Updated');
    } catch (error) {
      console.log({ message: error.message });
    }

    // resetForm();
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    alert('Deleted');
  };

  return (
    <div className="shopErectionUpdate">
      {/* <span className="shopErectionUpdateTitle">Add a Designation</span>
      <form
        className="shopErectionUpdateForm"
        onSubmit={id ? handleUpdate : handleCreate}
      >
        <div className="shopErectionUpdateLeft">
          <div className="productFormLeft">
            <label>Department</label>
            <select
              style={{ maxWidth: '250px' }}
              className=""
              name="department"
              value={values.department}
              onChange={handleInputChange}
              id="department"
            >
              <option value="">Select an option</option>
              {departments.map((dt) => (
                <option value={dt._id} onChange={handleInputChange}>
                  {dt.name}
                </option>
              ))}

          
            </select>

            <label>Designation Name</label>
            <input
              type="text"
              placeholder="Product xxx"
              name="name"
              value={values.name}
              onChange={handleInputChange}
              className="shopErectionUpdateInput"
            />
          </div>
        </div>

        <div className="shopErectionUpdateRight">
          
          <button className="shopErectionUpdateButton">
            Add/Update Designation
          </button>
        </div>
      </form> */}

      <span className="shopErectionUpdateTitle">Create/Edit a Designation</span>
      {id && (
        <span className="right">
          Delete
          <DeleteOutline
            className="userListDelete right"
            onClick={(e) => handleDelete(e)}
          />
        </span>
      )}

      <hr />
      <form
        onSubmit={id ? handleUpdate : handleCreate}
        className="ps-form--account-setting"
      >
        <div className="ps-form__content">
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <label>Department</label>
                <select
                  // style={{ maxWidth: '250px' }}
                  className="form-control"
                  name="department"
                  value={values.department}
                  onChange={handleInputChange}
                  // id="department"
                >
                  <option value="">Select an option</option>
                  {departments.map((dt) => (
                    <option value={dt._id} onChange={handleInputChange}>
                      {dt.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-sm-12">
              <div className="form-group">
                <label>Designation Name</label>
                <input
                  className="form-control"
                  type="text"
                  // placeholder="Department Here"
                  // name="name"
                  // value={values?.name}
                  // onChange={handleInputChange}

                  placeholder="Designation Here"
                  name="name"
                  value={values.name}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="form-group submit">
          <Link to={'/designations'} className="btn">
            Cancel
          </Link>
          <button className="ps-btn">Add/Update Designation</button>
        </div>
      </form>
    </div>
  );
};

export default DesignationForm;
