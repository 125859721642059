import { CloudUploadOutlined } from '@material-ui/icons';
import React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

const initialFValues = {
  id: 0,
  name: '',
  email: '',
  password: '',
  gender: '',
  isAdmin: '',
  isCompany: '',
  isShopExist: '',

  isBlocked: '',
  department: '',
  designation: '',

  avatar: '',
  aboutme: '',

  facebook: '',
  instagram: '',
  twitter: '',
  phone: '',
  address: '',
  postalCode: '',
  city: '',
  country: '',
  referralcode: '',
  downlines: [],
};

export const UserInfoForm = () => {
  const { values, setValues, handleInputChange, handleFile, resetForm } =
    useForm(initialFValues);
  const [file, setFile] = useState(null);
  //   const { id } = params;
  //   const history = useHistory();
  //   const dispatch = useDispatch();

  const handleDepartmentSubmit = async (e) => {
    e.preventDefault();
  };

  const updateUserDetails = async (e) => {
    e.preventDefault();
  };

  return (
    <form onSubmit={updateUserDetails} className="ps-form--account-setting">
      <div className="ps-form__header">
        <h3>Account Information</h3>
      </div>
      <div className="ps-form__content">
        {/* {showMessage} */}
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="name"
                placeholder="First name"
                value={values?.name}
                required
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <input
                className="form-control"
                type="email"
                name="email"
                placeholder="Email Address"
                value={values?.email}
                disabled={true}
                required
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="col-sm-6">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="phone"
                value={values?.phone}
                placeholder="Phone Number"
                required
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder="Postal Code"
                name="postalCode"
                value={values?.postalCode}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder="Address"
                name="address"
                value={values?.address}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="city"
                placeholder="City"
                value={values?.city}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="country"
                placeholder="Country"
                value={values?.country}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="col-sm-12">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                // placeholder="Address"
                value={values?.referralcode}
                name="referralcode"
                disabled={true}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <hr />
          <div className="col-sm-6">
            <div className="form-group">
              <input
                className="form-control mb-1"
                type="file"
                name="file"
                onChange={(e) => setFile(e.target.files[0])}
                id="file"
                style={{ display: 'none' }}
                placeholder=""
              />

              <label htmlFor="file">
                <CloudUploadOutlined className="ps-btn" />
              </label>
              {file && <span>{file.name}</span>}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <img
                style={{ borderRadius: '50%' }}
                height={50}
                src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.mm.bing.net%2Fth%3Fid%3DOIP.OlnxO753VRgHKDLLDzCKoAHaHw%26pid%3DApi&f=1"
              />
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <textarea
                className="form-control"
                name="aboutme"
                type="text"
                value={values?.aboutme}
                onChange={handleInputChange}
                rows="6"
              ></textarea>
            </div>
          </div>

          <div className="col-sm-12">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder="Facebook"
                name="facebook"
                value={values?.facebook}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder="Instagram"
                name="instagram"
                value={values?.instagram}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder="Twitter"
                name="twitter"
                value={values?.twitter}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>

        <div className="form-group submit">
          <button className="ps-btn">Update profile</button>
        </div>
      </div>
    </form>
  );
};
