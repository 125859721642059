import { CloudUploadOutlined, Publish } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import useForm from '../../hooks/useForm';
import { AuthApi } from '../../apiconstant/Api';
import { useParams, useHistory, Redirect, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  createDepart,
  getDepartWithId,
  updateDepart,
} from '../../store/departments/repository_dept';
import {
  getDeptById,
  getApiFaqById,
  getPostById,
  getAdminPartnerById,
} from '../../store/api/apiRoutes';
import { getDepartments } from '../../store/departments/repository_dept';
// import {
//   createPostcatRepo,
//   getPostCats,
//   getPostcatWithId,
// } from '../../store/postcats/repository_postcat';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import app from '../../firebase';
// import {
//   createPostRepo,
//   getPosts,
//   getPostWithId,
//   updateAdminPost,
// } from '../../store/postss/repository_post';
import { createFaqRepo, updateAdminFaq } from '../../store/faqs/repository_faq';
import {
  createPartnerRepo,
  updatePartnersRepo,
} from '../../store/partners/repository_partner';

// const initialFValues = {
//     id:0,
//     product:{},
//   }

const initialFValues = {
  id: 0,
  brandname: '',
  brandlogo: '',
  aboutbrand: '',
};

const PostForm = () => {
  const params = useParams();

  // const postId = params.id;
  // console.log('pageProps:', postId);
  const [loading, setLoading] = useState(false);
  // const [post, setPost] = useState({});

  const { values, setValues, handleInputChange, handleFile, resetForm } =
    useForm(initialFValues);
  const [file, setFile] = useState(null);
  const { id } = params;
  const history = useHistory();
  const dispatch = useDispatch();
  // const [postcats, setPostCats] = useState([]);
  // const { postcats } = useSelector((state) => state.postcates);

  // const post = useSelector((state) =>
  //   id ? state.posts.posts.find((pst) => pst._id === id) : null
  // );
  // const { post, isFetching } = useSelector((state) => state.posts);

  useEffect(() => {
    if (id) {
      getPartnerData();
    }

    // if (id) {
    //   // dispatch(getPosts());
    //   dispatch(getPostWithId(id));
    //   setValues(post);
    // }

    // setPagePosts(data);
  }, [id, dispatch]);

  const getPartnerData = async () => {
    setLoading(true);
    try {
      if (id) {
        await getAdminPartnerById(id).then((response) => {
          setValues(response.data.data);
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // console.log('Post CHECK!D :', id);
  // console.log('Post CHECK :', values);

  const handlePartnerSubmit = async (e) => {
    e.preventDefault();

    console.log('FAQ pst Values', values);

    // return;
    if (file) {
      const fileName = new Date().getTime() + file.name;
      const storage = getStorage(app);
      // const storageRef = ref(storage, fileName);
      const storageRef = ref(storage, `/partners/${fileName}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion

      // start here

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
            default:
          }
        },
        (error) => {
          // Handle unsuccessful uploads
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log('valueWithImageFile :', values, {
              adImage: downloadURL,
            });
            const partner = { ...values, brandlogo: downloadURL };

            dispatch(createPartnerRepo(partner));
            resetForm();
            // createPostcatRepo(partner).then((response) => {
            //   if (response.data.error) {
            //     // console.log(response.data.error);
            //   } else {
            //     // alert(response.data.success);
            //     alert('Post added');
            //     // resetForm();
            //   }
            // });
          });
        }
      );
    } else {
      // console.log('valueWithOutImageFile :', values);

      dispatch(createPartnerRepo(values));
      resetForm();

      // createPostcatRepo(values).then((response) => {
      //   if (response.data.error) {
      //     // console.log(response.data.error);
      //   } else {
      //     // alert(response.data.success);
      //     alert('Ad added');
      //     // resetForm();
      //   }
      // });
    }
  };

  //HANDLE POST UPDATE
  const handlePartnerUpdate = async (e) => {
    e.preventDefault();

    console.log(values);
    if (file) {
      const fileName = new Date().getTime() + file.name;
      const storage = getStorage(app);
      // const storageRef = ref(storage, fileName);
      const storageRef = ref(storage, `/partners/${fileName}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion

      // start here

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
            default:
          }
        },
        (error) => {
          // Handle unsuccessful uploads
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log('valueWithImageFile :', values, {
              adImage: downloadURL,
            });
            const faq = { ...values, anyImage: downloadURL };
            dispatch(updatePartnersRepo(id, faq));
            // createPostcatRepo(faq).then((response) => {
            //   if (response.data.error) {
            //     // console.log(response.data.error);
            //   } else {
            //     // alert(response.data.success);
            //     alert('Post added');
            //     // resetForm();
            //   }
            // });
          });
        }
      );
    } else {
      // console.log('valueWithOutImageFile :', values);
      dispatch(updatePartnersRepo(id, values));
      // createPostcatRepo(values).then((response) => {
      //   if (response.data.error) {
      //     // console.log(response.data.error);
      //   } else {
      //     // alert(response.data.success);
      //     alert('Ad added');
      //     // resetForm();
      //   }
      // });
    }
  };

  return (
    <div className="shopErectionUpdate">
      <form
        onSubmit={id ? handlePartnerUpdate : handlePartnerSubmit}
        className="ps-form--account-setting"
      >
        {/* confirmUpdate  updateUserDetails*/}
        <div className="ps-form__header">
          <h3>Create/Edit Partner</h3>
        </div>
        <div className="ps-form__content">
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Questins Here"
                  name="brandname"
                  value={values?.brandname}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <hr />
            <div className="col-sm-6">
              <label> Partner Logo</label>
              <div className="form-group">
                <label htmlFor="file">
                  <Publish className="shopErectionUpdateIcon" />
                </label>

                {/* <label htmlFor="file">
                  <CloudUploadOutlined className="ps-btn" />
                </label> */}

                <input
                  type="file"
                  id="file"
                  style={{ display: 'none' }}
                  name="file"
                  // value={values.adImage}
                  onChange={(e) => setFile(e.target.files[0])}
                />
                {file && <span>{file.name}</span>}

                {/* <input
                                className="form-control"
                                type="file"
                                name="file"
                                value={values?.profile.avatar}
                                // placeholder="City"
                                onChange={handleInputChange}
                            /> */}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                {values?.brandlogo && (
                  <img
                    className="userUpdateImg"
                    src={values?.brandlogo}
                    alt=""
                  />
                )}
                {/* <img
                  style={{ borderRadius: '50%' }}
                  height={50}
                  src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.mm.bing.net%2Fth%3Fid%3DOIP.OlnxO753VRgHKDLLDzCKoAHaHw%26pid%3DApi&f=1"
                /> */}
                {/* <input
                                className="form-control"
                                type="text"
                                placeholder="Country"
                            /> */}
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <textarea
                  id="summary-ckeditor"
                  className="form-control"
                  type="text"
                  name="aboutbrand"
                  value={values?.aboutbrand}
                  // value={values?.aboutme}
                  onChange={handleInputChange}
                  rows="6"
                ></textarea>
              </div>
            </div>
          </div>

          <div className="form-group submit">
            <Link to={'/listpartners'} className="btn">
              Cancel
            </Link>
            <button className="ps-btn">Add/Update Partner</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PostForm;
