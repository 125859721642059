import * as api from '../api/apiRoutes';
import {
  shop,
  shopSuccess,
  shopFailure,
  getShopById,
  getShopByIdSuccess,
  getShopByIdFailure,
  updateShop,
  updateShopSuccess,
  updateShopFailure,
  createShop,
  createShopSuccess,
  createShopFailure,
} from './actionShops';
const Cookies = require('js-cookie');

export const getShops = () => async (dispatch) => {
  try {
    dispatch(shop());
    const { data } = await api.getShops();
    console.log('shopData :', data);

    // dispatch({
    //   type: FETCH_ALL,
    //   payload: { data, currentPage, numberOfPages },
    // });
    dispatch(shopSuccess(data));
  } catch (error) {
    console.log(error);
    dispatch(shopFailure({ error: error.message }));
  }
};

//get single Desination
export const getShopWithId = (id) => async (dispatch) => {
  dispatch(getShopById());
  // console.log('Acton start');
  try {
    const { data } = await api.getShopById(id);
    // console.log('MydesigByID :', data);

    dispatch(getShopByIdSuccess(data));
  } catch (error) {
    console.log(error);
    dispatch(getShopByIdFailure({ error: error.message }));
  }
};

//update a Product Category
export const updateShops = (id, values) => async (dispatch) => {
  // console.log('repoValues', values);
  dispatch(updateShop());
  try {
    const { data } = await api.updateShopById(id, values);
    // console.log('actionData :', data);

    dispatch(updateShopSuccess(data));
    alert('Shop Category updated');
  } catch (error) {
    console.log(error);
    dispatch(updateShopFailure({ message: error.message }));
  }
};

//Create a Shop
export const createShopRepo = (values) => async (dispatch) => {
  try {
    dispatch(createShop());
    const { data } = await api.createApiShop(values);
    // console.log('actionData :', data);
    dispatch(createShopSuccess(data));
    // console.log('newDesination:', data);
    alert('Shop added');
  } catch (error) {
    console.log(error);
    dispatch(createShopFailure({ error: error.message }));
  }
};
