import * as api from '../api/apiRoutes';
import {
  user,
  userSuccess,
  userFailure,
  getUserById,
  getUserByIdSuccess,
  getUserByIdFailure,
  updateUser,
  updateUserSuccess,
  updateUserFailure,
  createUser,
  createUserSuccess,
  createUserFailure,
} from './actionUsers';
const Cookies = require('js-cookie');

//get all Market users
export const getAllUsersRepo = () => async (dispatch) => {
  try {
    dispatch(user());

    console.log('BarnersFrmRepo before ');

    const { data } = await api.getApiUsers();
    // console.log('procatData :', data);

    // dispatch({
    //   type: FETCH_ALL,
    //   payload: { data, currentPage, numberOfPages },
    // });
    if (data) {
      console.log('UsersFrmRepo1', data);
      console.log('UsersFrmRepo222', data.filteredUsers);
      dispatch(userSuccess(data));
    }
  } catch (error) {
    // console.log(error);
    dispatch(userFailure({ error: error.message }));
  }
};

//get single Market
export const getUserWithId = (id) => async (dispatch) => {
  dispatch(getUserById());
  // console.log('Acton start');
  try {
    const { data } = await api.getApiUserById(id);
    // console.log('MydesigByID :', data);

    dispatch(getUserByIdSuccess(data));
  } catch (error) {
    // console.log(error);
    dispatch(getUserByIdFailure({ error: error.message }));
  }
};

//update a Market
export const updateUsersRepo = (id, values) => async (dispatch) => {
  // console.log('repoValues', values);
  dispatch(updateUser());
  try {
    const { data } = await api.updateApiUserById(id, values);
    // console.log('actionData :', data);

    dispatch(updateUserSuccess(data));
    alert('User updated');
  } catch (error) {
    // console.log(error);
    dispatch(updateUserFailure({ message: error.message }));
  }
};

//Create a Market
export const createUserRepo = (values) => async (dispatch) => {
  try {
    dispatch(createUser());
    const { data } = await api.createApiUser(values);
    // console.log('actionData :', data);
    dispatch(createUserSuccess(data));
    // console.log('newDesination:', data);
    alert('User  added');
  } catch (error) {
    dispatch(createUserFailure({ error: error.message }));
  }
};
