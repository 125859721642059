import React, { useEffect, useState } from 'react';
import ShopRightbar from '../../components_shops/shoprightbar/ShopRightbar';
import './usersCustomer.scss';
import {
  CalendarToday,
  LocationSearching,
  MailOutline,
  PermIdentity,
  PhoneAndroid,
  Publish,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import UserCustomerForm from './DisciplineForm';
import {
  adminBlockDisciplineStaff,
  adminBlockDisciplineUser,
  adminMakeLeader,
  adminSuspendDisciplineStaff,
  adminSuspendDisciplineUser,
  adminUnBlockDisciplineStaff,
  adminUnBlockDisciplineUser,
  adminUnMakeLeader,
  adminUnSuspendDisciplineStaff,
  adminUnSuspendDisciplineUser,
  getApiAdminUserById,
  getApiBannerById,
  getApiUserById,
} from '../../store/api/apiRoutes';
import { useParams } from 'react-router-dom';
import useForm from '../../hooks/useForm';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import DisciplineForm from './DisciplineForm';

const initialFValues = {
  id: 0,
  name: '',
  email: '',
  avatar: '',
};

const DisciplineStaff = () => {
  const params = useParams();

  // const postId = params.id;
  // console.log('pageProps:', postId);
  const [loading, setLoading] = useState(false);
  // const [post, setPost] = useState({});

  const { values, setValues, handleInputChange, handleFile, resetForm } =
    useForm(initialFValues);
  const [file, setFile] = useState(null);
  const { id } = params;

  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      getStaffDataForDiscipline();
    }
  }, [id, dispatch]);

  const getStaffDataForDiscipline = async () => {
    setLoading(true);
    try {
      if (id) {
        await getApiAdminUserById(id).then((response) => {
          setValues(response.data);

          // console.log('API USERDETAILS', response.data);
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleStaffBlockUpdate = async (e) => {
    e.preventDefault();

    if (window.confirm('Block User?')) {
      //adminBlockDisciplineUser

      try {
        setLoading(true);

        const { data } = await adminBlockDisciplineStaff(values._id);

        // console.log('shipSuccessData', data); adminSuspendDisciplineUser
        // return;
        if (data) {
          getStaffDataForDiscipline();
          // console.log('sucessData2', data);
          setTimeout(
            function () {
              setLoading(false);
            }.bind(this),
            250
          );
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        //console.log({ error: JSON.stringify(error) });
        //{ error: error.message })
        // console.log({ error: error.message });
        console.log(
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        );
      }
    }
  };

  //Lift User Block
  const handleStaffUnBlockUpdate = async (e) => {
    e.preventDefault();

    if (window.confirm('UnBlock User?')) {
      try {
        setLoading(true);

        const { data } = await adminUnBlockDisciplineStaff(values._id);
        // console.log('shipSuccessData', data);
        // return;
        if (data) {
          getStaffDataForDiscipline();
          // console.log('sucessData2', data);
          setTimeout(
            function () {
              setLoading(false);
            }.bind(this),
            250
          );
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        // console.log({ error: JSON.stringify(error) });
        console.log(
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        );
      }
    }
  };

  //Handle User Suspension
  const handleStaffSuspendUpdate = async (e) => {
    e.preventDefault();

    if (window.confirm('Suspend User?')) {
      try {
        setLoading(true);

        const { data } = await adminSuspendDisciplineStaff(values._id);
        console.log(
          'Unsuspended Disciplinary Message Before SetData(Suspend)',
          data
        );
        // console.log('shipSuccessData', data); adminSuspendDisciplineUser
        // return;
        if (data) {
          getStaffDataForDiscipline();
          // console.log('sucessData2', data);
          setTimeout(
            function () {
              setLoading(false);
            }.bind(this),
            250
          );
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        //console.log({ error: JSON.stringify(error) });
        //{ error: error.message })
        // console.log({ error: error.message });
        console.log(
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        );
      }
    }
  };

  //Lift User Suspension
  const handleStaffUnSuspendUpdate = async (e) => {
    e.preventDefault();

    if (window.confirm('Lift Suspension?')) {
      try {
        setLoading(true);

        const { data } = await adminUnSuspendDisciplineStaff(values._id);
        // console.log('shipSuccessData', data); adminSuspendDisciplineUser
        // return;
        console.log('Unsuspended Disciplinary Message Before SetData', data);
        if (data) {
          getStaffDataForDiscipline();
          // console.log('sucessData2', data);
          setTimeout(
            function () {
              setLoading(false);
            }.bind(this),
            250
          );
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        // console.log({ error: JSON.stringify(error) });
        // console.log({ error: error.message });
        console.log(
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        );
      }
    }
  };

  //Handle User Suspension
  const handleAdminLeadership = async (e) => {
    e.preventDefault();

    if (window.confirm('Make User Leader?')) {
      try {
        setLoading(true);

        const { data } = await adminMakeLeader(values._id);
        console.log('User Leadership Status', data);
        // console.log('shipSuccessData', data); adminSuspendDisciplineUser
        // return;
        if (data) {
          getStaffDataForDiscipline();
          // console.log('sucessData2', data);
          setTimeout(
            function () {
              setLoading(false);
            }.bind(this),
            250
          );
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        //console.log({ error: JSON.stringify(error) });
        //{ error: error.message })
        // console.log({ error: error.message });
        console.log(
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        );
      }
    }
  };

  //Remove User as leader
  const removeAdminLeadership = async (e) => {
    e.preventDefault();

    if (window.confirm('Remove User as leader?')) {
      try {
        setLoading(true);

        const { data } = await adminUnMakeLeader(values._id);
        console.log('User Leadership Status', data);
        // console.log('shipSuccessData', data); adminSuspendDisciplineUser
        // return;
        if (data) {
          getStaffDataForDiscipline();
          // console.log('sucessData2', data);
          setTimeout(
            function () {
              setLoading(false);
            }.bind(this),
            250
          );
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        //console.log({ error: JSON.stringify(error) });
        //{ error: error.message })
        // console.log({ error: error.message });
        console.log(
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        );
      }
    }
  };

  return (
    <>
      {/* Changing pages rendering starts here */}

      <>
        <div className="shoppingRight">
          <div className="shoppingRightBottom">
            {/* page starts */}
            <div className="user">
              <div className="userTitleContainer">
                <h1 className="userTitle">Edit User</h1>
              </div>
              <div className="userContainer">
                <div className="userShow">
                  <div className="userShowTop">
                    <img src={values?.avatar} alt="" className="userShowImg" />
                    <div className="userShowTopTitle">
                      <h5
                        className={
                          values?.isCeoCofounder
                            ? 'userListEdit'
                            : 'userShowUsername'
                        }
                      >
                        {values?.name}
                      </h5>
                      <span className="userShowUserTitle">
                        Software Engineer
                      </span>
                    </div>
                  </div>
                  <div className="userShowBottom">
                    <span className="userShowTitle">Account Details</span>
                    {/* <div className="userShowInfo">
                      <PermIdentity className="userShowIcon" />
                      <span className="userShowInfoTitle">annabeck99</span>
                    </div> */}
                    <div className="userShowInfo">
                      <CalendarToday className="userShowIcon" />
                      <span className="userShowInfoTitle">
                        {values?.createdAt}
                      </span>
                    </div>
                    <span className="userShowTitle">Contact Details</span>
                    <div className="userShowInfo">
                      <PhoneAndroid className="userShowIcon" />
                      <span className="userShowInfoTitle">{values?.phone}</span>
                    </div>
                    <div className="userShowInfo">
                      <MailOutline className="userShowIcon" />
                      <span className="userShowInfoTitle">{values?.email}</span>
                    </div>
                    <div className="userShowInfo">
                      <LocationSearching className="userShowIcon" />
                      <span className="userShowInfoTitle">
                        {values?.address} | country
                      </span>
                    </div>

                    {values?.isCeoCofounder && (
                      <button
                        className="ps-btn"
                        onClick={(e) => {
                          removeAdminLeadership(e);
                        }}
                      >
                        {loading ? 'processing...' : 'Un-Make C.E.O'}
                      </button>
                    )}
                    {!values?.isCeoCofounder && (
                      <button
                        className="ps-btn"
                        onClick={(e) => {
                          handleAdminLeadership(e);
                        }}
                      >
                        {loading ? 'processing...' : 'Make C.E.O'}
                      </button>
                    )}
                  </div>
                </div>

                <div className="userUpdate">
                  <span className="userUpdateTitle">Discipline User </span>
                  {/* <div className="ps-form__header">
                    <h3>Discipline User </h3>
                  </div> */}

                  <DisciplineForm
                    handleStaffBlockUpdate={handleStaffBlockUpdate}
                    handleStaffUnBlockUpdate={handleStaffUnBlockUpdate}
                    handleStaffSuspendUpdate={handleStaffSuspendUpdate}
                    handleStaffUnSuspendUpdate={handleStaffUnSuspendUpdate}
                    // handleAdminLeadership={handleAdminLeadership}
                    // removeAdminLeadership={removeAdminLeadership}
                    values={values}
                    handleInputChange={handleInputChange}
                    id={id}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
            {/* page ends         */}
          </div>
        </div>

        <>
          <ShopRightbar Shop />
        </>
      </>
      {/* Changing pages rendering ends here */}
    </>
  );
};

export default DisciplineStaff;
