import React from 'react'
import "./shoppingrightbar.scss";
const ShoppingRightbar = () => {
    return (
        <div className="shoppingrightbar">
            <>
                       
           <div className="shoppingRightbarTop">
               
               <div className="sidebar-bar-title">
               <h3>User Information</h3>
            </div>
               <div className="shoppingrightbarInfo">
                 <div className="shoppingrightbarInfoItem">
                   <span className="shoppingrightbarInfoKey">City:</span>
                   <span className="shoppingrightbarInfoValue">New York</span>
                 </div>
                 <div className="shoppingrightbarInfoItem">
                   <span className="shoppingrightbarInfoKey">From:</span>
                   <span className="shoppingrightbarInfoValue">Madrid</span>
                 </div>
                 <div className="shoppingrightbarInfoItem">
                   <span className="shoppingrightbarInfoKey">Relationship:</span>
                   <span className="shoppingrightbarInfoValue">Single</span>
                 </div>
               </div>
           </div>

           <aside className="righSidebar">
          <div className="block shop-by-side">
            <div className="sidebar-bar-title">
              <h3>Shop By</h3>
            </div>
            <div className="block-content">
              <div className="layered-Category">
                <div className="layered-content">
                  <ul className="check-box-list">
                    <li>
                      {/* <input type="checkbox" id="jtv1" name="jtvc"/> */}
                      <label for="jtv1"> <span className="button"></span> Camera & Photo<span className="count">(12)</span> </label>
                    </li>
                    <li>
                      {/* <input type="checkbox" id="jtv2" name="jtvc"/> */}
                      <label for="jtv2"> <span className="button"></span> Computers<span className="count">(18)</span> </label>
                    </li>
                    <li>
                      {/* <input type="checkbox" id="jtv3" name="jtvc"/> */}
                      <label for="jtv3"> <span className="button"></span> Apple Store<span className="count">(15)</span> </label>
                    </li>
                    <li>
                      {/* <input type="checkbox" id="jtv4" name="jtvc"/> */}
                      <label for="jtv4"> <span className="button"></span> Car Electronic<span className="count">(03)</span> </label>
                    </li>
                    <li>
                      {/* <input type="checkbox" id="jtv5" name="jtvc"/> */}
                      <label for="jtv5"> <span className="button"></span> Accessories<span className="count">(04)</span> </label>
                    </li>
                    <li>
                      {/* <input type="checkbox" id="jtv7" name="jtvc"/> */}
                      <label for="jtv7"> <span className="button"></span> Game & Video<span className="count">(07)</span> </label>
                    </li>
                    <li>
                      {/* <input type="checkbox" id="jtv8" name="jtvc"/> */}
                      <label for="jtv8"> <span className="button"></span> Best selling<span className="count">(05)</span> </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="block special-product">
            <div className="sidebar-bar-title">
              <h3>Special Products</h3>
            </div>
            <div className="block-content">
              <ul>
                <li >
                  <div className="products-block-left"> <a href="single_product.html" title="Sample Product" className="product-image"><img src="images/products/product-1.jpg" alt="Sample Product "/></a></div>
                  <div className="products-block-right">
                    <p className="product-name"> <a href="single_product.html">Lorem ipsum dolor sit amet Consectetur</a> </p>
                    <span className="price">$19.99</span>
                    <div className="rating"> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star-o"></i> </div>
                  </div>
                </li>
                <li>
                  <div className="products-block-left"> <a href="single_product.html" title="Sample Product" className="product-image"><img src="images/products/product-1.jpg" alt="Sample Product "/></a></div>
                  <div className="products-block-right">
                    <p className="product-name"> <a href="single_product.html">Consectetur utes anet adipisicing elit</a> </p>
                    <span className="price">$89.99</span>
                    <div className="rating"> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star-o"></i> <i className="fa fa-star-o"></i> </div>
                  </div>
                </li>
                <li>
                  <div className="products-block-left"> <a href="single_product.html" title="Sample Product" className="product-image"><img src="images/products/product-1.jpg" alt="Sample Product "/></a></div>
                  <div className="products-block-right">
                    <p className="product-name"> <a href="single_product.html">Consectetur utes anet adipisicing elit</a> </p>
                    <span className="price">$99.99</span>
                    <div className="rating"> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star-o"></i> <i className="fa fa-star-o"></i> </div>
                  </div>
                </li>
              </ul>
              <a className="link-all" href="shop_grid.html">All Products</a> </div>
          </div>
          <div className="block popular-tags-area ">
            <div className="sidebar-bar-title">
              <h3>Popular Tags</h3>
            </div>
            <div className="tag">
              <ul>
                <li><a href="#">Boys</a></li>
                <li><a href="#">Camera</a></li>
                <li><a href="#">good</a></li>
                <li><a href="#">Computers</a></li>
                <li><a href="#">Phone</a></li>
                <li><a href="#">clothes</a></li>
                <li><a href="#">girl</a></li>
                <li><a href="#">shoes</a></li>
                <li><a href="#">women</a></li>
                <li><a href="#">accessoties</a></li>
                <li><a href="#">View All Tags</a></li>
              </ul>
            </div>
          </div>
        </aside>

         </>
        </div>
    )
}

export default ShoppingRightbar
