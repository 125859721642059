import * as api from '../api/apiRoutes';
import {
  shopplan,
  shopplanSuccess,
  shopplanFailure,
  getShopplanById,
  getShopplanByIdSuccess,
  getShopplanByIdFailure,
  updateShopplan,
  updateShopplanSuccess,
  updateShopplanFailure,
  createShopplan,
  createShopplanSuccess,
  createShopplanFailure,
} from './actionShopplan';
const Cookies = require('js-cookie');

export const getShopplans = () => async (dispatch) => {
  try {
    dispatch(shopplan());
    const { data } = await api.getShopPlans();
    console.log('shopplanData :', data);

    // dispatch({
    //   type: FETCH_ALL,
    //   payload: { data, currentPage, numberOfPages },
    // });
    dispatch(shopplanSuccess(data));
  } catch (error) {
    console.log(error);
    dispatch(shopplanFailure({ error: error.message }));
  }
};

//get single Desination
export const getShopplanWithId = (id) => async (dispatch) => {
  dispatch(getShopplanById());
  // console.log('Acton start');
  try {
    const { data } = await api.getShopPlanById(id);
    // console.log('MydesigByID :', data);

    dispatch(getShopplanByIdSuccess(data));
  } catch (error) {
    console.log(error);
    dispatch(getShopplanByIdFailure({ error: error.message }));
  }
};

//update a Product Category
export const updateShopplans = (id, values) => async (dispatch) => {
  // console.log('repoValues', values);
  dispatch(updateShopplan());
  try {
    const { data } = await api.updateShopPlanById(id, values);
    // console.log('actionData :', data);

    dispatch(updateShopplanSuccess(data));
    alert('Shopplan Category updated');
  } catch (error) {
    console.log(error);
    dispatch(updateShopplanFailure({ message: error.message }));
  }
};

//Create a Desination
export const createShopplanRepo = (values) => async (dispatch) => {
  try {
    dispatch(createShopplan());
    const { data } = await api.createShopPlan(values);
    // console.log('actionData :', data);
    dispatch(createShopplanSuccess(data));
    // console.log('newDesination:', data);
    alert('Shopplan Category added');
  } catch (error) {
    console.log(error);
    dispatch(createShopplanFailure({ error: error.message }));
  }
};
