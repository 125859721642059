export const actionTypes = {
  MARKETCAT_REQUEST: 'MARKETCAT_REQUEST',
  MARKETCAT_SUCCESS: 'MARKETCAT_SUCCESS',
  MARKETCAT_FAILURE: 'MARKETCAT_FAILURE',

  GET_MARKETCAT_REQUEST: 'GET_MARKETCAT_REQUEST',
  GET_MARKETCAT_SUCCESS: 'GET_MARKETCAT_SUCCESS',
  GET_MARKETCAT_FAILURE: 'GET_MARKETCAT_FAILURE',

  UPDATE_MARKETCAT_REQUEST: 'UPDATE_MARKETCAT_REQUEST',
  UPDATE_MARKETCAT_SUCCESS: 'UPDATE_MARKETCAT_SUCCESS',
  UPDATE_MARKETCAT_FAILURE: 'UPDATE_MARKETCAT_FAILURE',

  CREATE_MARKETCAT_REQUEST: 'CREATE_MARKETCAT_REQUEST',
  CREATE_MARKETCAT_SUCCESS: 'CREATE_MARKETCAT_SUCCESS',
  CREATE_MARKETCAT_FAILURE: 'CREATE_MARKETCAT_FAILURE',

  //whenloading
  END_LOADING: 'END_LOADING',
  START_LOADING: 'START_LOADING',
};

export function marketcat() {
  return { type: actionTypes.MARKETCAT_REQUEST };
}

export function marketcatSuccess(marketcats) {
  return { type: actionTypes.MARKETCAT_SUCCESS, payload: marketcats.data };
}

export function marketcatFailure() {
  return { type: actionTypes.MARKETCAT_FAILURE };
}

//Get MARKETCATnation by ID:
export function getMarketcatById() {
  return { type: actionTypes.GET_MARKETCAT_REQUEST };
}

export function getMarketcatByIdSuccess(marketcat) {
  return { type: actionTypes.GET_MARKETCAT_SUCCESS, payload: marketcat };
}

export function getMarketcatByIdFailure() {
  return { type: actionTypes.GET_MARKETCAT_FAILURE };
}

//Update MARKETCATnation by ID:
export function updateMarketcat() {
  return { type: actionTypes.UPDATE_MARKETCAT_REQUEST };
}

export function updateMarketcatSuccess(data) {
  return {
    type: actionTypes.UPDATE_MARKETCAT_SUCCESS,
    payload: data,
  };
}

export function updateMarketcatFailure() {
  return { type: actionTypes.UPDATE_MARKETCAT_FAILURE };
}

//create new MARKETCATnation:
export function createMarketcat() {
  return { type: actionTypes.CREATE_MARKETCAT_REQUEST };
}

export function createMarketcatSuccess(data) {
  return {
    type: actionTypes.CREATE_MARKETCAT_SUCCESS,
    payload: data,
  };
}

export function createMarketcatFailure() {
  return { type: actionTypes.CREATE_MARKETCAT_FAILURE };
}

// export function logOut() {
//   return { type: actionTypes.LOG_OUT };
// }
