import './home.scss';
import React from 'react';
import Topbar from '../../components/topbar/Topbar';
import SocialSidebar from '../../components/socialsidebar/SocialSidebar';
import Feed from '../../components/feed/Feed';
import SocialRightbar from '../../components/socialrightbar/SocialRightbar';

const Home = () => {
  return (
    <>
      <>
        <div className="homeRight">
          <div className="homeBottom">
            <Feed />
          </div>
        </div>
        <SocialRightbar />
      </>
    </>
  );
};

export default Home;
