import React from 'react'
import "./adminboard.scss"
import { userData } from "../../dummyData";
import FeaturedPanel from "../featuredpanel/FeaturePanel";
import Chart from "../chart/Chart"
import WidgetSm from "../widgetSm/WidgetSm"
import WidgetLg from "../widgetLg/WidgetLg";

const AdminBoard = () => {
    return (
        <>
         <div className="adminpost">
            <div className="adminpostWrapper">
            <div className="admin">
                <div className="adminWrapper">
                    <FeaturedPanel />
                    <Chart data={userData} title="User Analytics" grid dataKey="Active User"/>
                    {/* <Chart data={userData} title="Product Analytics" grid dataKey="Active User"/> */}
                    <div className="homeWidgets">
                        <WidgetSm/>
                        <WidgetLg/>
                    </div>
                </div>
            </div>
            </div>
        </div>


       
        </>
    )
}

export default AdminBoard
