export const actionTypes = {
  INFO_REQUEST: 'INFO_REQUEST',
  INFO_SUCCESS: 'INFO_SUCCESS',
  INFO_FAILURE: 'INFO_FAILURE',

  GET_INFO_REQUEST: 'GET_INFO_REQUEST',
  GET_INFO_SUCCESS: 'GET_INFO_SUCCESS',
  GET_INFO_FAILURE: 'GET_INFO_FAILURE',

  UPDATE_INFO_REQUEST: 'UPDATE_INFO_REQUEST',
  UPDATE_INFO_SUCCESS: 'UPDATE_INFO_SUCCESS',
  UPDATE_INFO_FAILURE: 'UPDATE_INFO_FAILURE',

  CREATE_INFO_REQUEST: 'CREATE_INFO_REQUEST',
  CREATE_INFO_SUCCESS: 'CREATE_INFO_SUCCESS',
  CREATE_INFO_FAILURE: 'CREATE_INFO_FAILURE',

  //whenloading
  END_LOADING: 'END_LOADING',
  START_LOADING: 'START_LOADING',
};

export function info() {
  return { type: actionTypes.INFO_REQUEST };
}

export function infoSuccess(data) {
  return { type: actionTypes.INFO_SUCCESS, payload: data.data };
}

export function infoFailure() {
  return { type: actionTypes.INFO_FAILURE };
}

//Get Info by ID:
export function getInfoById() {
  return { type: actionTypes.GET_INFO_REQUEST };
}

export function getInfoByIdSuccess(Info) {
  return { type: actionTypes.GET_INFO_SUCCESS, payload: Info };
}

export function getInfoByIdFailure() {
  return { type: actionTypes.GET_INFO_FAILURE };
}

//Update Info by ID:
export function updateInfo() {
  return { type: actionTypes.UPDATE_INFO_REQUEST };
}

export function updateInfoSuccess(data) {
  return {
    type: actionTypes.UPDATE_INFO_SUCCESS,
    payload: data,
  };
}

export function updateInfoFailure() {
  return { type: actionTypes.UPDATE_INFO_FAILURE };
}

//create new Info:
export function createInfo() {
  return { type: actionTypes.CREATE_INFO_REQUEST };
}

export function createInfoSuccess(data) {
  return {
    type: actionTypes.CREATE_INFO_SUCCESS,
    payload: data,
  };
}

export function createInfoFailure() {
  return { type: actionTypes.CREATE_INFO_FAILURE };
}
