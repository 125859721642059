import { CloudUploadOutlined, Publish } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import useForm from '../../hooks/useForm';
import { AuthApi } from '../../apiconstant/Api';
import { useParams, useHistory, Redirect, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import app from '../../firebase';
import {
  createPartnerRepo,
  updatePartnersRepo,
} from '../../store/partners/repository_partner';
import {
  createBannerRepo,
  updateBannersRepo,
} from '../../store/barners/repository_barner';
import {
  adminMakeHomeSlider,
  adminUnMakeHomeSlider,
  getApiBannerById,
} from '../../store/api/apiRoutes';

// const initialFValues = {
//     id:0,
//     product:{},
//   }

const initialFValues = {
  id: 0,
  title: '',
  shortText: '',
  longText: '',
  barnerImage: '',
  key: '',
};

const PostForm = () => {
  const params = useParams();

  const [loading, setLoading] = useState(false);
  // const [post, setPost] = useState({});

  const { values, setValues, handleInputChange, handleFile, resetForm } =
    useForm(initialFValues);
  const [file, setFile] = useState(null);
  const { id } = params;
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      getBannerData();
    }

    // setPagePosts(data);
  }, [id, dispatch]);

  const getBannerData = async () => {
    setLoading(true);
    try {
      if (id) {
        await getApiBannerById(id).then((response) => {
          setValues(response.data);

          console.log('API Banners', response.data);
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleBannerSubmit = async (e) => {
    e.preventDefault();

    // return;
    if (file) {
      const fileName = new Date().getTime() + file.name;
      const storage = getStorage(app);
      // const storageRef = ref(storage, fileName);
      const storageRef = ref(storage, `/banners/${fileName}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion

      // start here

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
            default:
          }
        },
        (error) => {
          // Handle unsuccessful uploads
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log('valueWithImageFile :', values, {
              adImage: downloadURL,
            });
            const banner = { ...values, barnerImage: downloadURL };

            dispatch(createBannerRepo(banner));
            resetForm();
            setFile(null);
            // createPostcatRepo(partner).then((response) => {
            //   if (response.data.error) {
            //     // console.log(response.data.error);
            //   } else {
            //     // alert(response.data.success);
            //     alert('Post added');
            //     // resetForm();
            //   }
            // });
          });
        }
      );
    } else {
      // console.log('valueWithOutImageFile :', values);

      dispatch(createBannerRepo(values));
      resetForm();
      setFile(null);

      // createPostcatRepo(values).then((response) => {
      //   if (response.data.error) {
      //     // console.log(response.data.error);
      //   } else {
      //     // alert(response.data.success);
      //     alert('Ad added');
      //     // resetForm();
      //   }
      // });
    }
  };

  //HANDLE POST UPDATE
  const handleBannerUpdate = async (e) => {
    e.preventDefault();

    console.log(values);
    if (file) {
      const fileName = new Date().getTime() + file.name;
      const storage = getStorage(app);
      // const storageRef = ref(storage, fileName);
      const storageRef = ref(storage, `/banners/${fileName}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion

      // start here

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
            default:
          }
        },
        (error) => {
          // Handle unsuccessful uploads
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log('valueWithImageFile :', values, {
              adImage: downloadURL,
            });
            const banner = { ...values, barnerImage: downloadURL };
            dispatch(updateBannersRepo(id, banner));
            // createPostcatRepo(faq).then((response) => {
            //   if (response.data.error) {
            //     // console.log(response.data.error);
            //   } else {
            //     // alert(response.data.success);
            //     alert('Post added');
            //     // resetForm();
            //   }
            // });
          });
        }
      );
    } else {
      // console.log('valueWithOutImageFile :', values);
      dispatch(updateBannersRepo(id, values));
      // createPostcatRepo(values).then((response) => {
      //   if (response.data.error) {
      //     // console.log(response.data.error);
      //   } else {
      //     // alert(response.data.success);
      //     alert('Ad added');
      //     // resetForm();
      //   }
      // });
    }
  };

  //Handle User Suspension
  const handleisOnHomeSlider = async (e) => {
    e.preventDefault();

    if (window.confirm('Make Home Slider?')) {
      try {
        setLoading(true);
        //adminMakeHomeSlider
        const { data } = await adminMakeHomeSlider(values._id);

        if (data) {
          getBannerData();
          setTimeout(
            function () {
              setLoading(false);
            }.bind(this),
            250
          );
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        //console.log({ error: JSON.stringify(error) });
        //{ error: error.message })
        // console.log({ error: error.message });
        console.log(
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        );
      }
    }
  };

  //Remove User as leader
  const removeisOnHomeSlider = async (e) => {
    e.preventDefault();

    if (window.confirm('Remove From Home Slider?')) {
      try {
        setLoading(true);

        const { data } = await adminUnMakeHomeSlider(values._id);

        if (data) {
          getBannerData();
          setTimeout(
            function () {
              setLoading(false);
            }.bind(this),
            250
          );
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        //console.log({ error: JSON.stringify(error) });
        //{ error: error.message })
        // console.log({ error: error.message });
        console.log(
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        );
      }
    }
  };

  return (
    <div className="shopErectionUpdate">
      <form
        onSubmit={id ? handleBannerUpdate : handleBannerSubmit}
        className="ps-form--account-setting"
      >
        {/* confirmUpdate  updateUserDetails*/}
        <div
          className="ps-form__header"
          style={{ display: 'flex', gap: '20px', marginnBottom: '20px' }}
        >
          <h3>Create/Edit Banners</h3>

          {id && values?.key.includes('banner') && (
            <>
              {values?.isOnHomeSlider && (
                <button
                  className="ps-btn"
                  onClick={(e) => {
                    removeisOnHomeSlider(e);
                  }}
                >
                  {loading ? 'processing...' : 'Un-Make Home Slider'}
                </button>
              )}
              {!values?.isOnHomeSlider && (
                <button
                  className="ps-btn"
                  onClick={(e) => {
                    handleisOnHomeSlider(e);
                  }}
                >
                  {loading ? 'processing...' : 'Make Home Slider'}
                </button>
              )}
            </>
          )}
        </div>

        <div className="ps-form__content">
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label>Title</label>
                <input
                  className="form-control"
                  type="text"
                  name="title"
                  value={values?.title}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <label>Path key</label>
                <input
                  className="form-control"
                  type="text"
                  name="key"
                  value={values?.key}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <hr />
            <div className="col-sm-6">
              <label> Banner Image</label>
              <div className="form-group">
                <label htmlFor="file">
                  <Publish className="shopErectionUpdateIcon" />
                </label>

                {/* <label htmlFor="file">
                  <CloudUploadOutlined className="ps-btn" />
                </label> */}

                <input
                  type="file"
                  id="file"
                  style={{ display: 'none' }}
                  name="file"
                  // value={values.adImage}
                  onChange={(e) => setFile(e.target.files[0])}
                />
                {file && <span>{file.name}</span>}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                {values?.barnerImage && (
                  <img
                    className="userUpdateImg"
                    src={values?.barnerImage}
                    alt=""
                  />
                )}
                {/* <img
                  style={{ borderRadius: '50%' }}
                  height={50}
                  src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.mm.bing.net%2Fth%3Fid%3DOIP.OlnxO753VRgHKDLLDzCKoAHaHw%26pid%3DApi&f=1"
                /> */}
                {/* <input
                                className="form-control"
                                type="text"
                                placeholder="Country"
                            /> */}
              </div>
            </div>

            <div className="col-sm-12">
              <div className="form-group">
                <label>Short Text</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Short Text Here"
                  name="shortText"
                  value={values?.shortText}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="col-sm-12">
              <div className="form-group">
                <label>Long Text</label>
                <textarea
                  id="summary-ckeditor"
                  className="form-control"
                  type="text"
                  placeholder="Long Text Here"
                  name="longText"
                  value={values?.longText}
                  // value={values?.aboutme}
                  onChange={handleInputChange}
                  rows="6"
                ></textarea>
              </div>
            </div>

            {/* <div className="col-sm-12">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Facebook"
                  name="facebook"
                  // value={values?.facebook}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Instagram"
                  name="instagram"
                  // value={values?.instagram}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Twitter"
                  name="twitter"
                  // value={values?.twitter}
                  onChange={handleInputChange}
                />
              </div>
            </div> */}
          </div>

          <div className="form-group submit">
            <Link to={'/listbanners'} className="btn">
              Cancel
            </Link>
            <button className="ps-btn">Add/Update Banners</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PostForm;
