import { actionTypes } from './actionDesignation';
import Cookies from 'js-cookie';

export const initState = {
  designations: [],
  isFetching: false,
  error: false,
};

function reducer(state = initState, action) {
  switch (action.type) {
    case 'START_LOADING':
      return { ...state, isFetching: true };
    case 'END_LOADING':
      return { ...state, isFetching: false };
    //Get designations
    case actionTypes.DESIG_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.DESIG_SUCCESS:
      return {
        ...state,
        isFetching: false,
        designations: action.payload,
        error: false,
      };
    case actionTypes.DESIG_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Get designationsById:
    case actionTypes.GET_DESIG_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.GET_DESIG_SUCCESS:
      return {
        ...state,
        isFetching: false,
        designation: action.payload,
        error: false,
      };
    case actionTypes.GET_DESIG_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Update A designationsById:
    case actionTypes.UPDATE_DESIG_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.UPDATE_DESIG_SUCCESS:
      return {
        ...state,
        isFetching: false,
        designations: state.designations.map((designation) =>
          designation._id === action.payload._id ? action.payload : designation
        ),
        error: false,
      };
    case actionTypes.UPDATE_DESIG_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Create A New designationsById:
    case actionTypes.CREATE_DESIG_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.CREATE_DESIG_SUCCESS:
      return {
        ...state,
        isFetching: false,
        designations: [...state.designations, action.payload],
        error: false,
      };
    case actionTypes.CREATE_DESIG_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };

    default:
      return { ...state };
  }
}
export default reducer;
