import { actionTypes } from './actionPartners';
import Cookies from 'js-cookie';

export const initState = {
  partners: [],
  isFetching: false,
  error: false,
};

function reducer(state = initState, action) {
  switch (action.type) {
    case 'START_LOADING':
      return { ...state, isFetching: true };
    case 'END_LOADING':
      return { ...state, isFetching: false };
    //Get MARKET
    case actionTypes.PARTNER_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.PARTNER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        partners: action.payload,
        error: false,
      };
    case actionTypes.PARTNER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Get MARKETById:
    case actionTypes.GET_PARTNER_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.GET_PARTNER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        partner: action.payload,
        error: false,
      };
    case actionTypes.GET_PARTNER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Update A MARKETnationsById:
    case actionTypes.UPDATE_PARTNER_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.UPDATE_PARTNER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        partners: state.partners.map((partner) =>
          partner._id === action.payload._id ? action.payload : partner
        ),
        error: false,
      };
    case actionTypes.UPDATE_PARTNER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    //Create A New MARKETnationsById:
    case actionTypes.CREATE_PARTNER_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case actionTypes.CREATE_PARTNER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        partners: [...state.partners, action.payload],
        error: false,
      };
    case actionTypes.CREATE_PARTNER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };

    default:
      return { ...state };
  }
}
export default reducer;
