import { DeleteOutline, Publish } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import useForm from '../../hooks/useForm';
import { AuthApi } from '../../apiconstant/Api';
import { useParams, useHistory, Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCountries } from '../../store/country/repository_country';
import {
  createState,
  getStates,
  updateStatesRepo,
} from '../../store/states/repository_states';
import { getStateById, deleteStateById } from '../../store/api/apiRoutes';

// const initialFValues = {
//     id:0,
//     product:{},
//   }

const initialFValues = {
  id: 0,
  businessCountry: '',
  name: '',
};

const StateForm = () => {
  const { values, setValues, handleInputChange, resetForm } =
    useForm(initialFValues);
  // const { id } = useParams();
  const history = useHistory();
  const params = useParams();
  const { id } = params;

  const dispatch = useDispatch();
  const { countries, isFetching } = useSelector((state) => state.countries);
  const [loading, setLoading] = useState(false);

  // const bstate = useSelector((state) =>
  //   id ? state.bstates.bstates.find((bst) => bst._id === id) : null
  // );

  useEffect(() => {
    dispatch(getCountries());

    dispatch(getStates());
    // if (id && bstate) setValues(bstate);
    // if (id) setValues(bstate);  getStateById(id)
    if (id) {
      getStateData(id);
    }

    // setPagePosts(data);
  }, [id, dispatch, setValues]);

  const getStateData = async (id) => {
    setLoading(true);
    try {
      if (id) {
        await getStateById(id).then((response) => {
          // setDepartment(response.data);
          setValues(response.data);
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleCreate = async (e) => {
    e.preventDefault();

    // console.log('storage values are :', values);
    try {
      dispatch(createState(values));
      // alert('Department Added');
      resetForm();
    } catch (error) {
      console.log({ message: error.message });
    }

    // resetForm();
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    // console.log('storage values are :', values);
    try {
      dispatch(updateStatesRepo(id, values));
      // alert('Department Updated');
    } catch (error) {
      console.log({ message: error.message });
    }

    // resetForm();
  };

  const handleDelete = async (e) => {
    e.preventDefault();

    if (window.confirm('Delete state?')) {
      setLoading(true);

      try {
        if (id) {
          await deleteStateById(id).then((response) => {
            // setDepartment(response.data);
            // setValues(response.data);
            alert(response.data.message);
            // <Redirect to="/admin/listorders" />;
            history.push('/bstates');
          });
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };

  return (
    <div className="shopErectionUpdate">
      {/* <span className="shopErectionUpdateTitle">Add a State</span>
      <form
        className="shopErectionUpdateForm"
        onSubmit={id ? handleUpdate : handleCreate}
      >
        <div className="shopErectionUpdateLeft">
          <div className="productFormLeft">
            <label>Country</label>
            <select
              style={{ maxWidth: '250px' }}
              className=""
              name="businessCountry"
              value={values?.businessCountry}
              onChange={handleInputChange}
              id="hub"
            >
              <option value="0">Select a country</option>
              {countries.map((cty) => (
                <option value={cty._id} onChange={handleInputChange}>
                  {cty.name}
                </option>
              ))}
            
            </select>

            <label>State Name</label>
            <input
              type="text"
              placeholder="Product xxx"
              name="name"
              value={values?.name}
              onChange={handleInputChange}
              className="shopErectionUpdateInput"
            />
          </div>
        </div>

        <div className="shopErectionUpdateRight">
          

          <button className="shopErectionUpdateButton">
            Add/Update State
          </button>
        </div>
      </form> */}

      <span className="shopErectionUpdateTitle">Create/Edit a State</span>
      {id && (
        <span className="right">
          Delete
          <DeleteOutline
            className="userListDelete right"
            onClick={(e) => handleDelete(e)}
          />
        </span>
      )}

      <hr />
      <form
        onSubmit={id ? handleUpdate : handleCreate}
        className="ps-form--account-setting"
      >
        <div className="ps-form__content">
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                {/* <select
                  className="form-control"
                  name="operation"
                  value={values.operation}
                  onChange={handleInputChange}
                >
                  <option value="">Select an option</option>
                  <option value="true">true</option>
                  <option value="false">false</option>
                </select> */}
                <label>Country</label>
                <select
                  className="form-control"
                  name="businessCountry"
                  value={values?.businessCountry}
                  onChange={handleInputChange}
                  id="hub"
                >
                  <option value="0">Select a country</option>
                  {countries.map((cty) => (
                    <option value={cty._id} onChange={handleInputChange}>
                      {cty.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-sm-12">
              <div className="form-group">
                <label>State Name</label>
                <input
                  type="text"
                  placeholder="Product xxx"
                  name="name"
                  value={values?.name}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="form-group submit">
          <Link to={'/bstates'} className="btn">
            Cancel
          </Link>
          <button className="ps-btn">Add/Update Country</button>
        </div>
      </form>
    </div>
  );
};

export default StateForm;
