import React, { Fragment, useState, useEffect } from 'react';
import './newspostsingle.scss';
import Api from '../../apiconstant/Api';
import { Link } from 'react-router-dom';
import useForm from '../../hooks/useForm';
import { useHistory } from 'react-router-dom';
import { getPostById } from '../../store/api/apiRoutes';
import {
  Paper,
  Typography,
  CircularProgress,
  Divider,
} from '@material-ui/core/';

const initialFValues = {
  id: 0,
  post: {},
};

const NewsPostSingle = ({ loading, post, user }) => {
  return (
    <>
      <div className="newspost">
        <div className="newspostWrapper">
          <div className="news">
            <div className="newsWrapper">
              {/* news read here */}

              <section>
                <div>
                  {/* row */}
                  <div className="row">
                    {/* Center colunm col-sm-push-3*/}
                    <div className="col-xs-12 col-sm-12 " id="center_column">
                      <div className="center_column">
                        <div className="entry-detail">
                          <div className="page-title"></div>
                          <div className="entry-photo">
                            <figure>
                              <img
                                src={post?.featuredImage}
                                alt="Blog"
                                width="900px"
                                height="350px"
                              />
                            </figure>
                          </div>
                          <div className="entry-meta-data">
                            <div className="blog-top-desc">
                              <div className="blog-date">
                                {' '}
                                Jan 2017 {post.created_at}{' '}
                              </div>
                              <h1>
                                <a href="#">{post.title}</a>
                              </h1>
                              <div className="jtv-entry-meta">
                                {' '}
                                <i className="fa fa-user-o" />{' '}
                                <strong>Admin</strong>{' '}
                                <a href="#">
                                  <i className="fa fa-commenting-o" />{' '}
                                  <strong>15 Comments</strong>
                                </a>{' '}
                                <div className="rating">
                                  {' '}
                                  <i className="fa fa-star" />{' '}
                                  <i className="fa fa-star" />{' '}
                                  <i className="fa fa-star" />{' '}
                                  <i className="fa fa-star-o" />{' '}
                                  <i className="fa fa-star-o" />
                                  &nbsp; <span>(5 votes)</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-text clearfix">
                            <p>{post.content}. </p>
                          </div>
                          <div className="entry-tags">
                            {' '}
                            <span>Tags:</span> <a href="#">beauty,</a>{' '}
                            <a href="#">medicine,</a> <a href="#">health</a>{' '}
                          </div>
                        </div>
                        {/* Related Posts was here*/}

                        {/* ./Related Posts */}
                        {user && (
                          <>
                            {/* Comment */}
                            <div className="single-box">
                              <div className="best-title text-left">
                                <h2>Comments</h2>
                              </div>
                              <div className="comment-list">
                                <ul>
                                  <li>
                                    <div className="avartar">
                                      {' '}
                                      <img
                                        src="/images/avatar.png"
                                        alt="Avatar"
                                      />{' '}
                                    </div>
                                    <div className="comment-body">
                                      <div className="comment-meta">
                                        {' '}
                                        <span className="author">
                                          <a href="#">Admin</a>
                                        </span>{' '}
                                        <span className="date">2017-04-01</span>{' '}
                                      </div>
                                      <div className="comment">
                                        {' '}
                                        Aenean auctor wisi et urna. Aliquam erat
                                        volutpat. Duis ac turpis. Integer rutrum
                                        ante eu lacus. Vestibulum libero nisl,
                                        porta vel, scelerisque eget, malesuada
                                        at, neque.{' '}
                                      </div>
                                    </div>
                                  </li>
                                  {/* <li>
                                <ul>
                                  <li>
                                    <div className="avartar">
                                      {' '}
                                      <img
                                        src="/images/avatar.png"
                                        alt="Avatar"
                                      />{' '}
                                    </div>
                                    <div className="comment-body">
                                      <div className="comment-meta">
                                        {' '}
                                        <span className="author">
                                          <a href="#">Admin</a>
                                        </span>{' '}
                                        <span className="date">2017-04-01</span>{' '}
                                      </div>
                                      <div className="comment">
                                        {' '}
                                        Aenean auctor wisi et urna. Aliquam erat
                                        volutpat. Duis ac turpis. Integer rutrum
                                        ante eu lacus. Vestibulum libero nisl,
                                        porta vel, scelerisque eget, malesuada
                                        at, neque.{' '}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="avartar">
                                      {' '}
                                      <img
                                        src="/images/avatar.png"
                                        alt="Avatar"
                                      />{' '}
                                    </div>
                                    <div className="comment-body">
                                      <div className="comment-meta">
                                        {' '}
                                        <span className="author">
                                          <a href="#">Admin</a>
                                        </span>{' '}
                                        <span className="date">2017-04-01</span>{' '}
                                      </div>
                                      <div className="comment">
                                        {' '}
                                        Aenean auctor wisi et urna. Aliquam erat
                                        volutpat. Duis ac turpis. Integer rutrum
                                        ante eu lacus. Vestibulum libero nisl,
                                        porta vel, scelerisque eget, malesuada
                                        at, neque.{' '}
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </li> */}
                                  {/* <li>
                                <div className="avartar">
                                  {' '}
                                  <img
                                    src="/images/avatar.png"
                                    alt="Avatar"
                                  />{' '}
                                </div>
                                <div className="comment-body">
                                  <div className="comment-meta">
                                    {' '}
                                    <span className="author">
                                      <a href="#">Admin</a>
                                    </span>{' '}
                                    <span className="date">2017-04-01</span>{' '}
                                  </div>
                                  <div className="comment">
                                    {' '}
                                    Aenean auctor wisi et urna. Aliquam erat
                                    volutpat. Duis ac turpis. Integer rutrum
                                    ante eu lacus. Vestibulum libero nisl, porta
                                    vel, scelerisque eget, malesuada at, neque.{' '}
                                  </div>
                                </div>
                              </li> */}
                                  {/* <li>
                                <div className="avartar">
                                  {' '}
                                  <img
                                    src="/images/avatar.png"
                                    alt="Avatar"
                                  />{' '}
                                </div>
                                <div className="comment-body">
                                  <div className="comment-meta">
                                    {' '}
                                    <span className="author">
                                      <a href="#">Admin</a>
                                    </span>{' '}
                                    <span className="date">2017-04-01</span>{' '}
                                  </div>
                                  <div className="comment">
                                    {' '}
                                    Aenean auctor wisi et urna. Aliquam erat
                                    volutpat. Duis ac turpis. Integer rutrum
                                    ante eu lacus. Vestibulum libero nisl, porta
                                    vel, scelerisque eget, malesuada at, neque.{' '}
                                  </div>
                                </div>
                              </li> */}
                                </ul>
                              </div>
                            </div>
                            <div className="single-box comment-box">
                              <div className="best-title text-left">
                                <h2>Leave a Comment</h2>
                              </div>
                              <div className="coment-form">
                                <p>
                                  Make sure you enter the () required
                                  information where indicated. HTML code is not
                                  allowed.
                                </p>
                                <div className="row">
                                  <div className="col-sm-6">
                                    <label htmlFor="name">Name</label>
                                    <input
                                      id="name"
                                      type="text"
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="col-sm-6">
                                    <label htmlFor="email">Email</label>
                                    <input
                                      id="email"
                                      type="text"
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="col-sm-12">
                                    <label htmlFor="website">Website URL</label>
                                    <input
                                      id="website"
                                      type="text"
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="col-sm-12">
                                    <label htmlFor="message">Message</label>
                                    <textarea
                                      name="message"
                                      id="message"
                                      rows={8}
                                      className="form-control"
                                      defaultValue={''}
                                    />
                                  </div>
                                </div>
                                <button className="button">
                                  <span>Post Comment</span>
                                </button>
                              </div>
                            </div>
                            {/* ./Comment */}
                          </>
                        )}
                      </div>
                    </div>
                    {/* ./ Center colunm */}
                    {/* <BlogAside /> */}
                    {/* ./left colunm */}
                  </div>
                  {/* ./row*/}
                </div>
              </section>

              {/* news read ends here */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsPostSingle;
